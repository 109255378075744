/*
    =========================
	Template Name 	 : Swigo
	Author			 : DexignZone
	Version			 : 1.1
	Author Portfolio : https://themeforest.net/user/dexignzone
	=========================
	
	[Table of Contents] 

	=== SCSS Start ===
	
    1. Abstract
		1.1 abstract.scss
		1.2 mixins.scss
		1.3 variable.scss
		
    2. Base
		2.1 base.scss
		2.2 custom-grid.scss
		2.3 fonts.scss
		2.4 preloader.scss
		2.5 reset.scss
		2.6 shortcode.scss
		
    3. Components
		3.1 About
		3.2 Blog Post
		3.3 Iconbox
		3.4 Main Slider
		3.5 Portfolio
		3.6 Team
		3.7 Testimonials
		3.8 Other scss File
		
    4. Layout
		4.1 Banner
		4.2 Footer
		4.3 Header
		4.4 Theme
		4.5 Other scss File
		
    5. Pages
		5.1 blog-details.scss
		5.2 coming-soon.scss
		5.3 contact-us.scss
		5.4 error-404.scss
		5.5 index.scss
		5.6 login.scss
		5.7 pages.scss
		5.8 section-wrapper.scss
		5.9 shop.scss
		5.10 under-construction.scss
	
	=== SCSS Start ===
*/
/*=== Abstract ===*/

@import url("../icons/line-awesome/css/line-awesome.min.css");
@import url("../icons/font-awesome/css/all.min.css");
@import url("../icons/themify/themify-icons.css");
@import url("../icons/flaticon/flaticon_swigo.css");
@import url("../icons/feather/css/iconfont.css");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --primary: #7da640;
  --secondary: #fe9f10;
  --gray: #f3f4f8;
  --primary-hover: #618132;
  --primary-dark: #2a3815;
  --rgba-secondary-1: rgba(254, 159, 16, 0.1);
  --rgba-primary-1: rgba(125, 166, 64, 0.1);
  --rgba-primary-2: rgba(125, 166, 64, 0.2);
  --rgba-primary-3: rgba(125, 166, 64, 0.3);
  --rgba-primary-4: rgba(125, 166, 64, 0.4);
  --rgba-primary-5: rgba(125, 166, 64, 0.5);
  --rgba-primary-6: rgba(125, 166, 64, 0.6);
  --rgba-primary-7: rgba(125, 166, 64, 0.7);
  --rgba-primary-8: rgba(125, 166, 64, 0.8);
  --rgba-primary-9: rgba(125, 166, 64, 0.9);
  --border-radius-base: 6px;
  --font-family-base: Poppins, sans-serif;
  --font-family-title: Poppins, sans-serif;
  --title: #222222;
}

[data-color="color_1"] {
  --primary: #000000;
  --primary-hover: #000000;
  --primary-dark: #000000;
  --rgba-primary-1: rgba(0, 0, 0, 0.1);
  --rgba-primary-2: rgba(0, 0, 0, 0.2);
  --rgba-primary-3: rgba(0, 0, 0, 0.3);
  --rgba-primary-4: rgba(0, 0, 0, 0.4);
  --rgba-primary-5: rgba(0, 0, 0, 0.5);
  --rgba-primary-05: rgba(0, 0, 0, 0.05);
  --rgba-primary-6: rgba(0, 0, 0, 0.6);
  --rgba-primary-7: rgba(0, 0, 0, 0.7);
  --rgba-primary-8: rgba(0, 0, 0, 0.8);
  --rgba-primary-9: rgba(0, 0, 0, 0.9);
  --theme-text-color: #ffffff; /* White text for better contrast */
  --secondary: #cacf7a; /* Secondary color remains the same */
  --title: #000000; /* Title color changed to white for visibility */
  --bg-light: #000000; /* Background color changed to black */
}

[data-color="color_2"] {
  --primary: #fe9f10;
  --primary-hover: #d38004;
  --primary-dark: #d27e00;
  --rgba-primary-1: rgba(254, 159, 16, 0.1);
  --rgba-primary-2: rgba(254, 159, 16, 0.2);
  --rgba-primary-3: rgba(254, 159, 16, 0.3);
  --rgba-primary-4: rgba(254, 159, 16, 0.4);
  --rgba-primary-5: rgba(254, 159, 16, 0.5);
  --rgba-primary-05: rgba(254, 159, 16, 0.05);
  --rgba-primary-6: rgba(254, 159, 16, 0.6);
  --rgba-primary-7: rgba(254, 159, 16, 0.7);
  --rgba-primary-8: rgba(254, 159, 16, 0.8);
  --rgba-primary-9: rgba(254, 159, 16, 0.9);
  --theme-text-color: #fff;
  --secondary: #a8cf96;
  --title: #222222;
  --bg-light: #fff8e2;
}

[data-color="color_3"] {
  --primary: #ee2737;
  --primary-hover: #e10d1e;
  --primary-dark: #b91522;
  --rgba-primary-1: rgba(238, 39, 55, 0.1);
  --rgba-primary-2: rgba(238, 39, 55, 0.2);
  --rgba-primary-3: rgba(238, 39, 55, 0.3);
  --rgba-primary-4: rgba(238, 39, 55, 0.4);
  --rgba-primary-5: rgba(238, 39, 55, 0.5);
  --rgba-primary-05: rgba(238, 39, 55, 0.05);
  --rgba-primary-6: rgba(238, 39, 55, 0.6);
  --rgba-primary-7: rgba(238, 39, 55, 0.7);
  --rgba-primary-8: rgba(238, 39, 55, 0.8);
  --rgba-primary-9: rgba(238, 39, 55, 0.9);
  --theme-text-color: #fff;
  --secondary: #cacf7a;
  --title: #222222;
  --bg-light: #ffeff0;
}

[data-color="color_4"] {
  --primary: #a75d5d;
  --primary-hover: #954c4c;
  --primary-dark: #5e2c2c;
  --rgba-primary-1: rgba(167, 93, 93, 0.1);
  --rgba-primary-2: rgba(167, 93, 93, 0.2);
  --rgba-primary-3: rgba(167, 93, 93, 0.3);
  --rgba-primary-4: rgba(167, 93, 93, 0.4);
  --rgba-primary-5: rgba(167, 93, 93, 0.5);
  --rgba-primary-05: rgba(167, 93, 93, 0.05);
  --rgba-primary-6: rgba(167, 93, 93, 0.6);
  --rgba-primary-7: rgba(167, 93, 93, 0.7);
  --rgba-primary-8: rgba(167, 93, 93, 0.8);
  --rgba-primary-9: rgba(167, 93, 93, 0.9);
  --theme-text-color: #fff;
  --secondary: #ffc3a1;
  --title: #222222;
  --bg-light: #fff2f2;
}

[data-color="color_5"] {
  --primary: #ea5c2b;
  --primary-hover: #bf4115;
  --primary-dark: #ad2c00;
  --rgba-primary-1: rgba(234, 92, 43, 0.1);
  --rgba-primary-2: rgba(234, 92, 43, 0.2);
  --rgba-primary-3: rgba(234, 92, 43, 0.3);
  --rgba-primary-4: rgba(234, 92, 43, 0.4);
  --rgba-primary-5: rgba(234, 92, 43, 0.5);
  --rgba-primary-05: rgba(234, 92, 43, 0.05);
  --rgba-primary-6: rgba(234, 92, 43, 0.6);
  --rgba-primary-7: rgba(234, 92, 43, 0.7);
  --rgba-primary-8: rgba(234, 92, 43, 0.8);
  --rgba-primary-9: rgba(234, 92, 43, 0.9);
  --theme-text-color: #fff;
  --secondary: #ebce5a;
  --title: #222222;
  --bg-light: #fff6f3;
}

[data-color="color_6"] {
  --primary: #a9907e;
  --primary-hover: #91735e;
  --primary-dark: #6a4a33;
  --rgba-primary-1: rgba(169, 144, 126, 0.1);
  --rgba-primary-2: rgba(169, 144, 126, 0.2);
  --rgba-primary-3: rgba(169, 144, 126, 0.3);
  --rgba-primary-4: rgba(169, 144, 126, 0.4);
  --rgba-primary-5: rgba(169, 144, 126, 0.5);
  --rgba-primary-05: rgba(169, 144, 126, 0.05);
  --rgba-primary-6: rgba(169, 144, 126, 0.6);
  --rgba-primary-7: rgba(169, 144, 126, 0.7);
  --rgba-primary-8: rgba(169, 144, 126, 0.8);
  --rgba-primary-9: rgba(169, 144, 126, 0.9);
  --theme-text-color: #fff;
  --secondary: #abc4aa;
  --title: #222222;
  --bg-light: #fff7f2;
}

[data-color="color_7"] {
  --primary: #87805e;
  --primary-hover: #585230;
  --primary-dark: #544d2b;
  --rgba-primary-1: rgba(135, 128, 94, 0.1);
  --rgba-primary-2: rgba(135, 128, 94, 0.2);
  --rgba-primary-3: rgba(135, 128, 94, 0.3);
  --rgba-primary-4: rgba(135, 128, 94, 0.4);
  --rgba-primary-5: rgba(135, 128, 94, 0.5);
  --rgba-primary-05: rgba(135, 128, 94, 0.05);
  --rgba-primary-6: rgba(135, 128, 94, 0.6);
  --rgba-primary-7: rgba(135, 128, 94, 0.7);
  --rgba-primary-8: rgba(135, 128, 94, 0.8);
  --rgba-primary-9: rgba(135, 128, 94, 0.9);
  --theme-text-color: #fff;
  --secondary: #d8cca3;
  --title: #222222;
  --bg-light: #f9f7ee;
}

/*=== Bootstrap ===*/
/*!
   * Bootstrap  v5.3.0-alpha1 (https://getbootstrap.com/)
   * Copyright 2011-2022 The Bootstrap Authors
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
:root,
[data-bs-theme="light"] {
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #7da640;
  --bs-secondary: #fe9f10;
  --bs-success: #13c24d;
  --bs-info: #00aeff;
  --bs-warning: #ffbd13;
  --bs-danger: #ff0003;
  --bs-light: #faf8f2;
  --bs-dark: #222222;
  --bs-primary-rgb: 125, 166, 64;
  --bs-secondary-rgb: 254, 159, 16;
  --bs-success-rgb: 19, 194, 77;
  --bs-info-rgb: 0, 174, 255;
  --bs-warning-rgb: 255, 189, 19;
  --bs-danger-rgb: 255, 0, 3;
  --bs-light-rgb: 250, 248, 242;
  --bs-dark-rgb: 34, 34, 34;
  --bs-primary-text: #20186f;
  --bs-secondary-text: #6c757d;
  --bs-success-text: #027e5e;
  --bs-info-text: #087990;
  --bs-warning-text: #998b0b;
  --bs-danger-text: #cc0002;
  --bs-light-text: #6c757d;
  --bs-dark-text: #495057;
  --bs-primary-bg-subtle: #d4d2e8;
  --bs-secondary-bg-subtle: #f8f9fa;
  --bs-success-bg-subtle: #ccece4;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fffad0;
  --bs-danger-bg-subtle: #ffcccd;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #a9a5d1;
  --bs-secondary-border-subtle: #e9ecef;
  --bs-success-border-subtle: #9ad8c8;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #fff5a1;
  --bs-danger-border-subtle: #ff999a;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 102, 102, 102;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-family: "Poppins", sans-serif;
  --bs-body-font-size: 0.9375rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.6;
  --bs-body-color: #666666;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(102, 102, 102, 0.75);
  --bs-secondary-color-rgb: 102, 102, 102;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(102, 102, 102, 0.5);
  --bs-tertiary-color-rgb: 102, 102, 102;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-body-bg: #ffffff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-heading-color: #222222;
  --bs-link-color: #7da640;
  --bs-link-color-rgb: 125, 166, 64;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #648533;
  --bs-link-hover-color-rgb: 100, 133, 51;
  --bs-code-color: #ff5e78;
  --bs-highlight-bg: #fffad0;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #e1e1f0;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.25rem;
  --bs-border-radius-sm: 0.75rem;
  --bs-border-radius-lg: 1rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb), 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(var(--bs-body-color-rgb), 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(var(--bs-body-color-rgb), 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(var(--bs-body-color-rgb), 0.075);
  --bs-emphasis-color: #000;
  --bs-form-control-bg: var(--bs-body-bg);
  --bs-form-control-disabled-bg: var(--bs-secondary-bg);
  --bs-highlight-bg: #fffad0;
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1170px;
  --bs-breakpoint-xxl: 1440;
}

[data-bs-theme="dark"] {
  --bs-body-color: #adb5bd;
  --bs-body-color-rgb: 173, 181, 189;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #f8f9fa;
  --bs-emphasis-color-rgb: 248, 249, 250;
  --bs-secondary-color: rgba(173, 181, 189, 0.75);
  --bs-secondary-color-rgb: 173, 181, 189;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(173, 181, 189, 0.5);
  --bs-tertiary-color-rgb: 173, 181, 189;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-emphasis-color: #fff;
  --bs-primary-text: #7e78b9;
  --bs-secondary-text: #dee2e6;
  --bs-success-text: #67c5ad;
  --bs-info-text: #6edff6;
  --bs-warning-text: #fff171;
  --bs-danger-text: #ff6668;
  --bs-light-text: #f8f9fa;
  --bs-dark-text: #dee2e6;
  --bs-primary-bg-subtle: #08061c;
  --bs-secondary-bg-subtle: #212529;
  --bs-success-bg-subtle: #002018;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332e04;
  --bs-danger-bg-subtle: #330001;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #181253;
  --bs-secondary-border-subtle: #495057;
  --bs-success-border-subtle: #015f47;
  --bs-info-border-subtle: #055160;
  --bs-warning-border-subtle: #665c08;
  --bs-danger-border-subtle: #990002;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: #fff;
  --bs-link-color: #7e78b9;
  --bs-link-hover-color: #a9a5d1;
  --bs-link-color-rgb: 126, 120, 185;
  --bs-link-hover-color-rgb: 169, 165, 209;
  --bs-code-color: #ff9eae;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}


body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
.wp-block-search .wp-block-search__label,
h5,
.h5,
h6,
.h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: var(--bs-heading-color, inherit);
}

h1,
.h1 {
  font-size: calc(1.3875rem + 1.65vw);
}
@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 2.625rem;
  }
}

h2,
.h2 {
  font-size: calc(1.35rem + 1.2vw);
}
@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 2.25rem;
  }
}

h3,
.h3 {
  font-size: calc(1.3125rem + 0.75vw);
}
@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 1.875rem;
  }
}

h4,
.h4,
.wp-block-search .wp-block-search__label {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4,
  .h4,
  .wp-block-search .wp-block-search__label {
    font-size: 1.5rem;
  }
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1.125rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small,
.small {
  font-size: 0.875em;
}

mark,
.mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.75rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #aeaed5;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not(
    [type="week"]
  ):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.17188rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.17188rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1170px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1200px;
  }
}

.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1170px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1440) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc($table-border-width * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #e5edd9;
  --bs-table-border-color: #ced5c3;
  --bs-table-striped-bg: #dae1ce;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #ced5c3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d4dbc9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #ffeccf;
  --bs-table-border-color: #e6d4ba;
  --bs-table-striped-bg: #f2e0c5;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6d4ba;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecdabf;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d0f3db;
  --bs-table-border-color: #bbdbc5;
  --bs-table-striped-bg: #c6e7d0;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bbdbc5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c0e1cb;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #ccefff;
  --bs-table-border-color: #b8d7e6;
  --bs-table-striped-bg: #c2e3f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8d7e6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdddec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff2d0;
  --bs-table-border-color: #e6dabb;
  --bs-table-striped-bg: #f2e6c6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dabb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece0c0;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #ffcccd;
  --bs-table-border-color: #e6b8b9;
  --bs-table-striped-bg: #f2c2c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6b8b9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecbdbe;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #faf8f2;
  --bs-table-border-color: #e1dfda;
  --bs-table-striped-bg: #eeece6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e1dfda;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e7e5e0;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #222222;
  --bs-table-border-color: #383838;
  --bs-table-striped-bg: #2d2d2d;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #383838;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #333333;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1169.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1439.98) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.17188rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.82031rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #aeaed5;
}

.form-control,
.wp-block-categories-dropdown select,
.wp-block-archives-dropdown select {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  color: var(--bs-body-color);
  background-color: var(--bs-form-control-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control,
  .wp-block-categories-dropdown select,
  .wp-block-archives-dropdown select {
    transition: none;
  }
}
.form-control[type="file"],
.wp-block-categories-dropdown select[type="file"],
.wp-block-archives-dropdown select[type="file"] {
  overflow: hidden;
}
.form-control[type="file"]:not(:disabled):not([readonly]),
.wp-block-categories-dropdown
  select[type="file"]:not(:disabled):not([readonly]),
.wp-block-archives-dropdown select[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus,
.wp-block-categories-dropdown select:focus,
.wp-block-archives-dropdown select:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-form-control-bg);
  border-color: #bed3a0;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(125, 166, 64, 0.25);
}
.form-control::-webkit-date-and-time-value,
.wp-block-categories-dropdown select::-webkit-date-and-time-value,
.wp-block-archives-dropdown select::-webkit-date-and-time-value {
  height: 1.6em;
}
.form-control::-webkit-datetime-edit,
.wp-block-categories-dropdown select::-webkit-datetime-edit,
.wp-block-archives-dropdown select::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder,
.wp-block-categories-dropdown select::placeholder,
.wp-block-archives-dropdown select::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled,
.wp-block-categories-dropdown select:disabled,
.wp-block-archives-dropdown select:disabled {
  background-color: var(--bs-form-control-disabled-bg);
  opacity: 1;
}
.form-control::file-selector-button,
.wp-block-categories-dropdown select::file-selector-button,
.wp-block-archives-dropdown select::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button,
  .wp-block-categories-dropdown select::file-selector-button,
  .wp-block-archives-dropdown select::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button,
.wp-block-categories-dropdown
  select:hover:not(:disabled):not([readonly])::file-selector-button,
.wp-block-archives-dropdown
  select:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.6;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.6em + 0.5rem + calc($input-border-width * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.82031rem;
  border-radius: 0.75rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.6em + 1rem + calc($input-border-width * 2));
  padding: 0.5rem 1rem;
  font-size: 1.17188rem;
  border-radius: 1rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.6em + 0.75rem + calc($input-border-width * 2));
}

textarea.form-control-sm {
  min-height: calc(1.6em + 0.5rem + calc($input-border-width * 2));
}

textarea.form-control-lg {
  min-height: calc(1.6em + 1rem + calc($input-border-width * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.6em + 0.75rem + calc($input-border-width * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  border-radius: 0.25rem;
}
.form-control-color.form-control-sm {
  height: calc(1.6em + 0.5rem + calc($input-border-width * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.6em + 1rem + calc($input-border-width * 2));
}

.form-select,
select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  color: var(--bs-body-color);
  background-color: var(--bs-form-control-bg);
  background-image: var(--bs-form-select-bg-img),
    var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select,
  select {
    transition: none;
  }
}
.form-select:focus,
select:focus {
  border-color: #bed3a0;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(125, 166, 64, 0.25);
}
.form-select[multiple],
select[multiple],
.form-select[size]:not([size="1"]),
select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled,
select:disabled {
  background-color: var(--bs-form-control-disabled-bg);
}
.form-select:-moz-focusring,
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.82031rem;
  border-radius: 0.75rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.17188rem;
  border-radius: 1rem;
}

[data-bs-theme="dark"] .form-select,
[data-bs-theme="dark"] select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23adb5bd' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-form-control-bg);
  width: 1em;
  height: 1em;
  margin-top: 0.3em;
  vertical-align: top;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}
.form-check-input[type="radio"] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #bed3a0;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(125, 166, 64, 0.25);
}
.form-check-input:checked {
  background-color: #7da640;
  border-color: #7da640;
}
.form-check-input:checked[type="checkbox"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type="checkbox"]:indeterminate {
  background-color: #7da640;
  border-color: #7da640;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23bed3a0'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn,
.btn-check[disabled] + .wp-block-button__link,
.btn-check:disabled + .btn,
.btn-check:disabled + .wp-block-button__link {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme="dark"]
  .form-switch
  .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0rem rgba(125, 166, 64, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0rem rgba(125, 166, 64, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #7da640;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #d8e4c6;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #7da640;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #d8e4c6;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating::before:not(.form-control:disabled) {
  position: absolute;
  top: var(--bs-border-width);
  left: var(--bs-border-width);
  width: calc(100% - (calc(calc(0.4em + 0.1875rem) + calc(0.8em + 0.375rem))));
  height: 1.875em;
  content: "";
  background-color: var(--bs-form-control-bg);
  border-radius: 0.25rem;
}
.form-floating > .form-control,
.wp-block-categories-dropdown .form-floating > select,
.wp-block-archives-dropdown .form-floating > select,
.form-floating > .form-control-plaintext,
.form-floating > .form-select,
.form-floating > select {
  height: calc(3.5rem + calc($input-border-width * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.wp-block-categories-dropdown .form-floating > select,
.wp-block-archives-dropdown .form-floating > select,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.wp-block-categories-dropdown .form-floating > select::placeholder,
.wp-block-archives-dropdown .form-floating > select::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus,
.wp-block-categories-dropdown .form-floating > select:focus,
.wp-block-archives-dropdown .form-floating > select:focus,
.form-floating > .form-control:not(:placeholder-shown),
.wp-block-categories-dropdown .form-floating > select:not(:placeholder-shown),
.wp-block-archives-dropdown .form-floating > select:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.wp-block-categories-dropdown .form-floating > select:-webkit-autofill,
.wp-block-archives-dropdown .form-floating > select:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-select,
.form-floating > select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.wp-block-categories-dropdown .form-floating > select:focus ~ label,
.wp-block-archives-dropdown .form-floating > select:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.wp-block-categories-dropdown
  .form-floating
  > select:not(:placeholder-shown)
  ~ label,
.wp-block-archives-dropdown
  .form-floating
  > select:not(:placeholder-shown)
  ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label,
.form-floating > select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label,
.wp-block-categories-dropdown .form-floating > select:-webkit-autofill ~ label,
.wp-block-archives-dropdown .form-floating > select:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > .form-control:disabled ~ label,
.wp-block-categories-dropdown .form-floating > select:disabled ~ label,
.wp-block-archives-dropdown .form-floating > select:disabled ~ label {
  color: #6c757d;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.wp-block-categories-dropdown .input-group > select,
.wp-block-archives-dropdown .input-group > select,
.input-group > .form-select,
.input-group > select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.wp-block-categories-dropdown .input-group > select:focus,
.wp-block-archives-dropdown .input-group > select:focus,
.input-group > .form-select:focus,
.input-group > select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn,
.input-group .wp-block-button__link {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus,
.input-group .wp-block-button__link:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.wp-block-categories-dropdown .input-group-lg > select,
.wp-block-archives-dropdown .input-group-lg > select,
.input-group-lg > .form-select,
.input-group-lg > select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn,
.input-group-lg > .wp-block-button__link {
  padding: 0.5rem 1rem;
  font-size: 1.17188rem;
  border-radius: 1rem;
}

.input-group-sm > .form-control,
.wp-block-categories-dropdown .input-group-sm > select,
.wp-block-archives-dropdown .input-group-sm > select,
.input-group-sm > .form-select,
.input-group-sm > select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn,
.input-group-sm > .wp-block-button__link {
  padding: 0.25rem 0.5rem;
  font-size: 0.82031rem;
  border-radius: 0.75rem;
}

.input-group-lg > .form-select,
.input-group-lg > select,
.input-group-sm > .form-select,
.input-group-sm > select {
  padding-right: 3rem;
}

.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-control,
.wp-block-categories-dropdown
  .input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > select,
.wp-block-archives-dropdown
  .input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > select,
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-select,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation
  > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > .form-control,
.wp-block-categories-dropdown
  .input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > select,
.wp-block-archives-dropdown
  .input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > select,
.input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > .form-select,
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc($input-border-width * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-floating:not(:first-child) > .form-control,
.wp-block-categories-dropdown
  .input-group
  > .form-floating:not(:first-child)
  > select,
.wp-block-archives-dropdown
  .input-group
  > .form-floating:not(:first-child)
  > select,
.input-group > .form-floating:not(:first-child) > .form-select,
.input-group > .form-floating:not(:first-child) > select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-success-text);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.82031rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.was-validated .wp-block-categories-dropdown select:valid,
.wp-block-categories-dropdown .was-validated select:valid,
.was-validated .wp-block-archives-dropdown select:valid,
.wp-block-archives-dropdown .was-validated select:valid,
.form-control.is-valid,
.wp-block-categories-dropdown select.is-valid,
.wp-block-archives-dropdown select.is-valid {
  border-color: var(--bs-success);
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2313c24d' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-control:valid:focus,
.was-validated .wp-block-categories-dropdown select:valid:focus,
.wp-block-categories-dropdown .was-validated select:valid:focus,
.was-validated .wp-block-archives-dropdown select:valid:focus,
.wp-block-archives-dropdown .was-validated select:valid:focus,
.form-control.is-valid:focus,
.wp-block-categories-dropdown select.is-valid:focus,
.wp-block-archives-dropdown select.is-valid:focus {
  border-color: var(--bs-success);
  box-shadow: 0 0 0 0rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .form-select:valid,
.was-validated select:valid,
.form-select.is-valid,
select.is-valid {
  border-color: var(--bs-success);
}
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.was-validated select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"],
select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2313c24d' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-select:valid:focus,
.was-validated select:valid:focus,
.form-select.is-valid:focus,
select.is-valid:focus {
  border-color: var(--bs-success);
  box-shadow: 0 0 0 0rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid,
.form-control-color.is-valid {
  width: calc(3rem + calc(1.6em + 0.75rem));
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: var(--bs-success);
}
.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: var(--bs-success-text);
}
.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-success-text);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid,
.was-validated
  .wp-block-categories-dropdown
  .input-group
  > select:not(:focus):valid,
.wp-block-categories-dropdown
  .was-validated
  .input-group
  > select:not(:focus):valid,
.was-validated
  .wp-block-archives-dropdown
  .input-group
  > select:not(:focus):valid,
.wp-block-archives-dropdown
  .was-validated
  .input-group
  > select:not(:focus):valid,
.input-group > .form-control:not(:focus).is-valid,
.wp-block-categories-dropdown .input-group > select:not(:focus).is-valid,
.wp-block-archives-dropdown .input-group > select:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.was-validated .input-group > select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.input-group > select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-danger-text);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.82031rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.was-validated .wp-block-categories-dropdown select:invalid,
.wp-block-categories-dropdown .was-validated select:invalid,
.was-validated .wp-block-archives-dropdown select:invalid,
.wp-block-archives-dropdown .was-validated select:invalid,
.form-control.is-invalid,
.wp-block-categories-dropdown select.is-invalid,
.wp-block-archives-dropdown select.is-invalid {
  border-color: var(--bs-danger);
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff0003'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff0003' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-control:invalid:focus,
.was-validated .wp-block-categories-dropdown select:invalid:focus,
.wp-block-categories-dropdown .was-validated select:invalid:focus,
.was-validated .wp-block-archives-dropdown select:invalid:focus,
.wp-block-archives-dropdown .was-validated select:invalid:focus,
.form-control.is-invalid:focus,
.wp-block-categories-dropdown select.is-invalid:focus,
.wp-block-archives-dropdown select.is-invalid:focus {
  border-color: var(--bs-danger);
  box-shadow: 0 0 0 0rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .form-select:invalid,
.was-validated select:invalid,
.form-select.is-invalid,
select.is-invalid {
  border-color: var(--bs-danger);
}
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.was-validated select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff0003'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff0003' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-select:invalid:focus,
.was-validated select:invalid:focus,
.form-select.is-invalid:focus,
select.is-invalid:focus {
  border-color: var(--bs-danger);
  box-shadow: 0 0 0 0rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid,
.form-control-color.is-invalid {
  width: calc(3rem + calc(1.6em + 0.75rem));
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: var(--bs-danger);
}
.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: var(--bs-danger-text);
}
.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-danger-text);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid,
.was-validated
  .wp-block-categories-dropdown
  .input-group
  > select:not(:focus):invalid,
.wp-block-categories-dropdown
  .was-validated
  .input-group
  > select:not(:focus):invalid,
.was-validated
  .wp-block-archives-dropdown
  .input-group
  > select:not(:focus):invalid,
.wp-block-archives-dropdown
  .was-validated
  .input-group
  > select:not(:focus):invalid,
.input-group > .form-control:not(:focus).is-invalid,
.wp-block-categories-dropdown .input-group > select:not(:focus).is-invalid,
.wp-block-archives-dropdown .input-group > select:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.was-validated .input-group > select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.input-group > select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn,
.wp-block-button__link {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.9375rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.6;
  --bs-btn-color: #666666;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.25rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0rem
    rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn,
  .wp-block-button__link {
    transition: none;
  }
}
.btn:hover,
.wp-block-button__link:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover,
.btn-check + .wp-block-button__link:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible,
.wp-block-button__link:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn,
.btn-check:focus-visible + .wp-block-button__link {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn,
.btn-check:checked + .wp-block-button__link,
:not(.btn-check) + .btn:active,
:not(.btn-check) + .wp-block-button__link:active,
.btn:first-child:active,
.wp-block-button__link:first-child:active,
.btn.active,
.active.wp-block-button__link,
.btn.show,
.show.wp-block-button__link {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible,
.btn-check:checked + .wp-block-button__link:focus-visible,
:not(.btn-check) + .btn:active:focus-visible,
:not(.btn-check) + .wp-block-button__link:active:focus-visible,
.btn:first-child:active:focus-visible,
.wp-block-button__link:first-child:active:focus-visible,
.btn.active:focus-visible,
.active.wp-block-button__link:focus-visible,
.btn.show:focus-visible,
.show.wp-block-button__link:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled,
.wp-block-button__link:disabled,
.btn.disabled,
.disabled.wp-block-button__link,
fieldset:disabled .btn,
fieldset:disabled .wp-block-button__link {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary,
.wp-block-button__link {
  --bs-btn-color: #000;
  --bs-btn-bg: #7da640;
  --bs-btn-border-color: #7da640;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #91b35d;
  --bs-btn-hover-border-color: #8aaf53;
  --bs-btn-focus-shadow-rgb: 106, 141, 54;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #97b866;
  --bs-btn-active-border-color: #8aaf53;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #7da640;
  --bs-btn-disabled-border-color: #7da640;
}

.btn-secondary {
  --bs-btn-color: #000;
  --bs-btn-bg: #fe9f10;
  --bs-btn-border-color: #fe9f10;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fead34;
  --bs-btn-hover-border-color: #fea928;
  --bs-btn-focus-shadow-rgb: 216, 135, 14;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #feb240;
  --bs-btn-active-border-color: #fea928;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #fe9f10;
  --bs-btn-disabled-border-color: #fe9f10;
}

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #13c24d;
  --bs-btn-border-color: #13c24d;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #36cb68;
  --bs-btn-hover-border-color: #2bc85f;
  --bs-btn-focus-shadow-rgb: 16, 165, 65;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #42ce71;
  --bs-btn-active-border-color: #2bc85f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #13c24d;
  --bs-btn-disabled-border-color: #13c24d;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #00aeff;
  --bs-btn-border-color: #00aeff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #26baff;
  --bs-btn-hover-border-color: #1ab6ff;
  --bs-btn-focus-shadow-rgb: 0, 148, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #33beff;
  --bs-btn-active-border-color: #1ab6ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #00aeff;
  --bs-btn-disabled-border-color: #00aeff;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffbd13;
  --bs-btn-border-color: #ffbd13;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc736;
  --bs-btn-hover-border-color: #ffc42b;
  --bs-btn-focus-shadow-rgb: 217, 161, 16;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffca42;
  --bs-btn-active-border-color: #ffc42b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffbd13;
  --bs-btn-disabled-border-color: #ffbd13;
}

.btn-danger {
  --bs-btn-color: #000;
  --bs-btn-bg: #ff0003;
  --bs-btn-border-color: #ff0003;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ff2629;
  --bs-btn-hover-border-color: #ff1a1c;
  --bs-btn-focus-shadow-rgb: 217, 0, 3;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff3335;
  --bs-btn-active-border-color: #ff1a1c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ff0003;
  --bs-btn-disabled-border-color: #ff0003;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #faf8f2;
  --bs-btn-border-color: #faf8f2;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d5d3ce;
  --bs-btn-hover-border-color: #c8c6c2;
  --bs-btn-focus-shadow-rgb: 213, 211, 206;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c8c6c2;
  --bs-btn-active-border-color: #bcbab6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #faf8f2;
  --bs-btn-disabled-border-color: #faf8f2;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #222222;
  --bs-btn-border-color: #222222;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #434343;
  --bs-btn-hover-border-color: #383838;
  --bs-btn-focus-shadow-rgb: 67, 67, 67;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4e4e4e;
  --bs-btn-active-border-color: #383838;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #222222;
  --bs-btn-disabled-border-color: #222222;
}

.btn-outline-primary,
.is-style-outline .wp-block-button__link {
  --bs-btn-color: #7da640;
  --bs-btn-border-color: #7da640;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #7da640;
  --bs-btn-hover-border-color: #7da640;
  --bs-btn-focus-shadow-rgb: 125, 166, 64;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #7da640;
  --bs-btn-active-border-color: #7da640;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #7da640;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #7da640;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #fe9f10;
  --bs-btn-border-color: #fe9f10;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fe9f10;
  --bs-btn-hover-border-color: #fe9f10;
  --bs-btn-focus-shadow-rgb: 254, 159, 16;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fe9f10;
  --bs-btn-active-border-color: #fe9f10;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fe9f10;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fe9f10;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #13c24d;
  --bs-btn-border-color: #13c24d;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #13c24d;
  --bs-btn-hover-border-color: #13c24d;
  --bs-btn-focus-shadow-rgb: 19, 194, 77;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #13c24d;
  --bs-btn-active-border-color: #13c24d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #13c24d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #13c24d;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #00aeff;
  --bs-btn-border-color: #00aeff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #00aeff;
  --bs-btn-hover-border-color: #00aeff;
  --bs-btn-focus-shadow-rgb: 0, 174, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #00aeff;
  --bs-btn-active-border-color: #00aeff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #00aeff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00aeff;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffbd13;
  --bs-btn-border-color: #ffbd13;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffbd13;
  --bs-btn-hover-border-color: #ffbd13;
  --bs-btn-focus-shadow-rgb: 255, 189, 19;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffbd13;
  --bs-btn-active-border-color: #ffbd13;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffbd13;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffbd13;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #ff0003;
  --bs-btn-border-color: #ff0003;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ff0003;
  --bs-btn-hover-border-color: #ff0003;
  --bs-btn-focus-shadow-rgb: 255, 0, 3;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff0003;
  --bs-btn-active-border-color: #ff0003;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ff0003;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ff0003;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #faf8f2;
  --bs-btn-border-color: #faf8f2;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #faf8f2;
  --bs-btn-hover-border-color: #faf8f2;
  --bs-btn-focus-shadow-rgb: 250, 248, 242;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #faf8f2;
  --bs-btn-active-border-color: #faf8f2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #faf8f2;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #faf8f2;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #222222;
  --bs-btn-border-color: #222222;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #222222;
  --bs-btn-hover-border-color: #222222;
  --bs-btn-focus-shadow-rgb: 34, 34, 34;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #222222;
  --bs-btn-active-border-color: #222222;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #222222;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #222222;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 106, 141, 54;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg,
.btn-group-lg > .btn,
.btn-group-lg > .wp-block-button__link {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.17188rem;
  --bs-btn-border-radius: 1rem;
}

.btn-sm,
.btn-group-sm > .btn,
.btn-group-sm > .wp-block-button__link {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.82031rem;
  --bs-btn-border-radius: 0.75rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 0.9375rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.25rem;
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(
    $dropdown-border-radius - $dropdown-border-width
  );
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb), 0.15);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #7da640;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1170px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1440) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y)
    var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.82031rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #7da640;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group > .wp-block-button__link,
.btn-group-vertical > .btn,
.btn-group-vertical > .wp-block-button__link {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:checked + .wp-block-button__link,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn-check:focus + .wp-block-button__link,
.btn-group > .btn:hover,
.btn-group > .wp-block-button__link:hover,
.btn-group > .btn:focus,
.btn-group > .wp-block-button__link:focus,
.btn-group > .btn:active,
.btn-group > .wp-block-button__link:active,
.btn-group > .btn.active,
.btn-group > .active.wp-block-button__link,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:checked + .wp-block-button__link,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn-check:focus + .wp-block-button__link,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .wp-block-button__link:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .wp-block-button__link:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .wp-block-button__link:active,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .active.wp-block-button__link {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.25rem;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > :not(.btn-check:first-child) + .wp-block-button__link,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc($btn-border-width * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .wp-block-button__link:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .dropdown-toggle-split.wp-block-button__link:first-child,
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn-group:not(:last-child) > .wp-block-button__link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n + 3),
.btn-group > .wp-block-button__link:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > :not(.btn-check) + .wp-block-button__link,
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn-group:not(:first-child) > .wp-block-button__link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-group-sm > .wp-block-button__link + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-group-lg > .wp-block-button__link + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .wp-block-button__link,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .wp-block-button__link:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc($btn-border-width * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical
  > .wp-block-button__link:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn-group:not(:last-child) > .wp-block-button__link {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .wp-block-button__link ~ .btn,
.btn-group-vertical > .btn ~ .wp-block-button__link,
.btn-group-vertical > .wp-block-button__link ~ .wp-block-button__link,
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .wp-block-button__link {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover,
.nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg)
    var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color)
    var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid
    var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.25rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #7da640;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.17188rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.17188rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28102, 102, 102, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: 0.25rem;
  --bs-navbar-toggler-focus-width: 0rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover,
.navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1170px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1440) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme="dark"] .navbar {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(
    var(--bs-border-radius) - (var(--bs-border-width))
  );
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius)
    var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius)
    var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(
    var(--bs-border-radius) - (var(--bs-border-width))
  );
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23666666'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2320186f'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #bed3a0;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0rem rgba(125, 166, 64, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 0.9375rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid
    var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme="dark"] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%237e78b9'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%237e78b9'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/")
    /* rtl: var(--bs-breadcrumb-divider, "/") */;
}

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 0.9375rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0rem rgba(125, 166, 64, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #7da640;
  --bs-pagination-active-border-color: #7da640;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid
    var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active,
.active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled,
.disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc($pagination-border-width * -1);
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.17188rem;
  --bs-pagination-border-radius: 1rem;
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.82031rem;
  --bs-pagination-border-radius: 0.75rem;
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.25rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge,
.wp-block-button__link .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.25rem;
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.70313rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #7da640;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}
@media (min-width: 1200px) {
  .progress,
  .progress-stacked {
    --bs-progress-font-size: 0.70312rem;
  }
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #7da640;
  --bs-list-group-active-border-color: #7da640;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y)
    var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid
    var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1170px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1440) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
}
.list-group-item-primary.list-group-item-action:active {
  --bs-list-group-active-color: var(--bs-emphasis-color);
  --bs-list-group-active-bg: var(--bs-primary-text);
  --bs-list-group-active-border-color: var(--bs-primary-text);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
}
.list-group-item-secondary.list-group-item-action:active {
  --bs-list-group-active-color: var(--bs-emphasis-color);
  --bs-list-group-active-bg: var(--bs-secondary-text);
  --bs-list-group-active-border-color: var(--bs-secondary-text);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
}
.list-group-item-success.list-group-item-action:active {
  --bs-list-group-active-color: var(--bs-emphasis-color);
  --bs-list-group-active-bg: var(--bs-success-text);
  --bs-list-group-active-border-color: var(--bs-success-text);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
}
.list-group-item-info.list-group-item-action:active {
  --bs-list-group-active-color: var(--bs-emphasis-color);
  --bs-list-group-active-bg: var(--bs-info-text);
  --bs-list-group-active-border-color: var(--bs-info-text);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
}
.list-group-item-warning.list-group-item-action:active {
  --bs-list-group-active-color: var(--bs-emphasis-color);
  --bs-list-group-active-bg: var(--bs-warning-text);
  --bs-list-group-active-border-color: var(--bs-warning-text);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
}
.list-group-item-danger.list-group-item-action:active {
  --bs-list-group-active-color: var(--bs-emphasis-color);
  --bs-list-group-active-bg: var(--bs-danger-text);
  --bs-list-group-active-border-color: var(--bs-danger-text);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
}
.list-group-item-light.list-group-item-action:active {
  --bs-list-group-active-color: var(--bs-emphasis-color);
  --bs-list-group-active-bg: var(--bs-light-text);
  --bs-list-group-active-border-color: var(--bs-light-text);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
}
.list-group-item-dark.list-group-item-action:active {
  --bs-list-group-active-color: var(--bs-emphasis-color);
  --bs-list-group-active-bg: var(--bs-dark-text);
  --bs-list-group-active-border-color: var(--bs-dark-text);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0rem rgba(125, 166, 64, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme="dark"] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 30px;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid
    var(--bs-toast-header-border-color);
  border-top-left-radius: calc(
    var(--bs-toast-border-radius) - var(--bs-toast-border-width)
  );
  border-top-right-radius: calc(
    var(--bs-toast-border-radius) - var(--bs-toast-border-width)
  );
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: 0 0.125rem 0.25rem
    rgba(var(--bs-body-color-rgb), 0.075);
  --bs-modal-inner-border-radius: calc(
    var(--bs-border-radius-lg) - (var(--bs-border-width))
  );
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.6;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid
    var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5)
    calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y))
    calc(-0.5 * var(--bs-modal-header-padding-x))
    calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid
    var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb), 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1170px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1169.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1439.98) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.82031rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height)
    calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5)
    var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5)
    var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0
    calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.82031rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(
    $popover-border-radius - $popover-border-width
  );
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb), 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 0.9375rem;
  --bs-popover-header-color: #222222;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(
    -1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)
  );
}
.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before,
.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height)
    calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(
    -1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)
  );
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before,
.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5)
    var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(
    -1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)
  );
}
.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before,
.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5)
    var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid
    var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(
    -1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)
  );
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before,
.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0
    calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid
    var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
[data-bs-theme="dark"] .carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite
    var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.offcanvas-sm,
.offcanvas-md,
.offcanvas-lg,
.offcanvas-xl,
.offcanvas-xxl,
.offcanvas {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem
    rgba(var(--bs-body-color-rgb), 0.075);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.6;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing,
  .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing,
  .offcanvas-sm.hiding,
  .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing,
  .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing,
  .offcanvas-md.hiding,
  .offcanvas-md.show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing,
  .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing,
  .offcanvas-lg.hiding,
  .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1169.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1169.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1169.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing,
  .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing,
  .offcanvas-xl.hiding,
  .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (min-width: 1170px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1439.98) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1439.98) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1439.98) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing,
  .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing,
  .offcanvas-xxl.hiding,
  .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (min-width: 1440) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing,
.offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing,
.offcanvas.hiding,
.offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5)
    calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before,
.placeholder.wp-block-button__link::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

.placeholder-wave {
  mask-image: linear-gradient(
    130deg,
    #000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000 95%
  );
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #000 !important;
  background-color: RGBA(125, 166, 64, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #000 !important;
  background-color: RGBA(254, 159, 16, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(19, 194, 77, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(0, 174, 255, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 189, 19, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #000 !important;
  background-color: RGBA(255, 0, 3, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(250, 248, 242, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(34, 34, 34, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #7da640 !important;
}
.link-primary:hover,
.link-primary:focus {
  color: #97b866 !important;
}

.link-secondary {
  color: #fe9f10 !important;
}
.link-secondary:hover,
.link-secondary:focus {
  color: #feb240 !important;
}

.link-success {
  color: #13c24d !important;
}
.link-success:hover,
.link-success:focus {
  color: #42ce71 !important;
}

.link-info {
  color: #00aeff !important;
}
.link-info:hover,
.link-info:focus {
  color: #33beff !important;
}

.link-warning {
  color: #ffbd13 !important;
}
.link-warning:hover,
.link-warning:focus {
  color: #ffca42 !important;
}

.link-danger {
  color: #ff0003 !important;
}
.link-danger:hover,
.link-danger:focus {
  color: #ff3335 !important;
}

.link-light {
  color: #faf8f2 !important;
}
.link-light:hover,
.link-light:focus {
  color: #fbf9f5 !important;
}

.link-dark {
  color: #222222 !important;
}
.link-dark:hover,
.link-dark:focus {
  color: #1b1b1b !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1170px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1440) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb), 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(var(--bs-body-color-rgb), 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(var(--bs-body-color-rgb), 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-border-opacity)
  ) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-border-opacity)
  ) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-success-rgb),
    var(--bs-border-opacity)
  ) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-border-opacity)
  ) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.3875rem + 1.65vw) !important;
}

.fs-2 {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-3 {
  font-size: calc(1.3125rem + 0.75vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1.125rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.6 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #aeaed5 !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text) !important;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-success-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-body-bg-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-secondary-bg-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-tertiary-bg-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-body-emphasis {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-emphasis-bg-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-2xl) !important;
  border-top-right-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-2xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-2xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-2xl) !important;
  border-top-left-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1170px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1440) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.625rem !important;
  }
  .fs-2 {
    font-size: 2.25rem !important;
  }
  .fs-3 {
    font-size: 1.875rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}

/*=== Base  ===*/
* {
  outline: none;
  padding: 0;
}
*::after {
  margin: 0;
  padding: 0;
}
*::before {
  margin: 0;
  padding: 0;
}

body {
  line-height: 1.6;
  color: #666666;
  font-size: 1rem;
  font-family: var(--font-family-base);
}
@media only screen and (max-width: 575px) {
  body {
    font-size: 15px;
  }
}

h1 a,
.h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a,
.wp-block-search .wp-block-search__label a,
h5 a,
.h5 a,
h6 a,
.h6 a {
  color: var(--title);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
.wp-block-search .wp-block-search__label,
h5,
.h5,
h6,
.h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: var(--font-family-title);
  color: var(--title);
}

h1,
.h1,
.h1 {
  line-height: 1.3;
  font-size: 2.625rem;
  font-weight: 600;
}
@media only screen and (max-width: 991px) {
  h1,
  .h1,
  .h1 {
    font-size: 2.25rem;
  }
}
@media only screen and (max-width: 575px) {
  h1,
  .h1,
  .h1 {
    font-size: 2rem;
  }
}

h2,
.h2,
.h2 {
  font-size: 2.25rem;
  line-height: 1.3;
  font-weight: 600;
}
@media only screen and (max-width: 991px) {
  h2,
  .h2,
  .h2 {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 575px) {
  h2,
  .h2,
  .h2 {
    font-size: 1.75rem;
  }
}

h3,
.h3,
.h3 {
  line-height: 1.3;
  font-size: 1.875rem;
  font-weight: 600;
}
@media only screen and (max-width: 575px) {
  h3,
  .h3,
  .h3 {
    font-size: 1.5625rem;
  }
}

h4,
.h4,
.wp-block-search .wp-block-search__label,
.h4 {
  font-size: 1.5rem;
  line-height: 1.2;
  font-weight: 600;
}
@media only screen and (max-width: 575px) {
  h4,
  .h4,
  .wp-block-search .wp-block-search__label,
  .h4 {
    font-size: 1.375rem;
  }
}

h5,
.h5,
.h5 {
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 600;
}

h6,
.h6,
.h6 {
  font-size: 1.125rem;
  line-height: 1.2;
  font-weight: 600;
}

ul {
  padding: 0;
  margin: 0;
}

img {
  border-style: none;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  outline: none;
  color: var(--primary);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
a:hover,
a:focus,
a.active {
  text-decoration: none;
  outline: none;
  color: var(--primary-hover);
}

.text-primary {
  color: var(--primary) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.text-secondary {
  color: var(--secondary) !important;
}

.bg-secondary {
  background-color: var(--secondary) !important;
}

.bg-light {
  background-color: var(--bg-light) !important;
}

.sticky-top {
  top: 100px;
}

.overflow-unset {
  overflow: unset !important;
}

b,
strong,
.strong {
  font-weight: 500;
}

.h-0 {
  height: 0;
}

#main-wrapper {
  opacity: 0;
  transition: all 0.25s ease-in;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-top: 60px;
}
#main-wrapper.show {
  opacity: 1;
}

.content-body {
  margin-left: 65px;
  margin-top: 18px;
  margin-right: 6px;
  margin-bottom: 45px;
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  padding-left: 30px;
  padding-right: 30px;
}
@media only screen and (max-width: 1280px) {
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    padding-left: 15px;
    padding-right: 15px;
  }
}

::selection {
  color: #fff;
  background: var(--primary);
}

.modal-backdrop {
  z-index: 99999;
}

.modal {
  z-index: 100000;
}

/* Flaticon */
[class*="flaticon-"] {
  font-family: "Flaticon";
}

.content-inner {
  padding-top: 100px;
  padding-bottom: 70px;
}
@media only screen and (max-width: 991px) {
  .content-inner {
    padding-top: 70px;
    padding-bottom: 40px;
  }
}
@media only screen and (max-width: 575px) {
  .content-inner {
    padding-top: 50px;
    padding-bottom: 20px;
  }
}

.content-inner-1 {
  padding-top: 100px;
  padding-bottom: 100px;
}
@media only screen and (max-width: 991px) {
  .content-inner-1 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media only screen and (max-width: 575px) {
  .content-inner-1 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.content-inner-2 {
  padding-top: 100px;
  padding-bottom: 0;
}
@media only screen and (max-width: 991px) {
  .content-inner-2 {
    padding-top: 70px;
  }
}
@media only screen and (max-width: 575px) {
  .content-inner-2 {
    padding-top: 50px;
  }
}

.content-inner-3 {
  padding-top: 60px;
  padding-bottom: 30px;
}
@media only screen and (max-width: 991px) {
  .content-inner-3 {
    padding-top: 50px;
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .content-inner-3 {
    padding-top: 40px;
    padding-bottom: 10px;
  }
}

.mfp-bg {
  z-index: 9999999;
}

.mfp-wrap {
  z-index: 99999999;
}

.rounded {
  border-radius: var(--border-radius-base) !important;
}

@media only screen and (max-width: 991px) {
  .dz-order-1 {
    order: 1;
    margin-top: 20px;
  }
}

hr {
  margin: 30px 0;
}

code {
  font-size: 0.875em;
  color: #ff5e78;
  word-wrap: break-word;
  background-color: #fff2f5;
  padding: 2px 8px;
  border-radius: 4px;
}

i[class*="flaticon-"] {
  display: inline-flex;
  vertical-align: middle;
}

.dz-test img {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999999;
  pointer-events: none;
  opacity: 0.5;
}

.screen-lock {
  overflow: hidden;
}

.DZ-theme-btn.hide,
.styleswitcher.hide {
  display: none;
}

/* Google Recaptcha */
.g-recaptcha,
#rc-imageselect {
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}

@media only screen and (max-width: 1280px) {
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.7);
    -moz-transform: scale(0.7);
    -o-transform: scale(0.7);
    -webkit-transform: scale(0.7);
    transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

@media only screen and (max-width: 575px) {
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.7);
    -moz-transform: scale(0.7);
    -o-transform: scale(0.7);
    -webkit-transform: scale(0.7);
    transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

.dz-move {
  animation: dzMove 7s infinite;
}

@keyframes dzMove {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  25% {
    -webkit-transform: translate(-15px);
    transform: translate(-15px);
  }
  50% {
    -webkit-transform: translate(-8px);
    transform: translate(-8px);
  }
  75% {
    -webkit-transform: translate(-15px);
    transform: translate(-15px);
  }
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

.dz-move-down {
  animation: dzMoveDown 7s infinite;
}

@keyframes dzMoveDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  25% {
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }
  50% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  75% {
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

#pointer-dot {
  left: -4px;
  top: -4px;
  width: 12px;
  height: 12px;
  position: fixed;
  border-radius: 10px;
  z-index: 999998;
  pointer-events: none;
  transition: border-color 0.5s;
  background: var(--secondary);
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
}

#pointer-ring {
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  display: block;
  border: 2px solid var(--primary) !important;
  position: fixed;
  border-radius: 100px;
  z-index: 999999;
  pointer-events: none;
  transition: width 0.3s, height 0.3s;
}

#pointer-ring.active {
  width: 50px;
  height: 50px;
  opacity: 0.5;
}

#pointer-ring.active + #pointer-dot {
  opacity: 0;
}

.bg-parallax {
  background-attachment: fixed;
}

.page-wraper {
  background-color: #fff;
}

/* like button */
.heart {
  width: 60px;
  height: 60px;
  display: inline-block;
  background: url(../images/like.png) no-repeat;
  cursor: pointer;
  margin: -25px -18px;
}

.heart-blast {
  background-position: -1680px 0;
  transition: background 1s steps(28);
}

#loading-area {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}
#loading-area.loading-page-5 .loading-inner .loader {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background: white;
  width: 110px;
  height: 110px;
  position: relative;
}
#loading-area.loading-page-5 .loading-inner .loader .dot {
  transform-origin: 50% -20px;
  position: absolute;
  top: 75px;
  left: 45px;
}
#loading-area.loading-page-5 .loading-inner .loader .dot .dots {
  background: var(--primary);
  width: 20px;
  height: 20px;
  border-radius: 100%;
}
#loading-area.loading-page-3 {
  background: #fff;
}
#loading-area.loading-page-3 lottie-player {
  width: 180px;
}
#loading-area.loading-page-3 .loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
}
#loading-area.loading-page-3 .loader::before,
#loading-area.loading-page-3 .loader::after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}
#loading-area.loading-page-3 .loader::before {
  animation: ball1 1s infinite;
  background-color: #000;
  box-shadow: 30px 0 0 var(--primary);
  margin-bottom: 10px;
}
#loading-area.loading-page-3 .loader::after {
  animation: ball2 1s infinite;
  background-color: var(--primary);
  box-shadow: 30px 0 0 #fff;
}

@keyframes dot-anim-1 {
  0% {
    transform: rotate(0);
  }
  60% {
    transform: rotate(1080deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}

.dot:nth-child(1) {
  transform: rotate(0);
  animation: dot-anim-1 4.5s ease-in-out infinite;
}

@keyframes dot-anim-2 {
  0% {
    transform: rotate(0);
  }
  70% {
    transform: rotate(1080deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}

.dot:nth-child(2) {
  transform: rotate(0);
  animation: dot-anim-2 4.5s ease-in-out infinite;
}

@keyframes dot-anim-3 {
  0% {
    transform: rotate(0);
  }
  80% {
    transform: rotate(1080deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}

.dot:nth-child(3) {
  transform: rotate(0);
  animation: dot-anim-3 4.5s ease-in-out infinite;
}

@keyframes dot-anim-4 {
  0% {
    transform: rotate(0);
  }
  90% {
    transform: rotate(1080deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}

.dot:nth-child(4) {
  transform: rotate(0);
  animation: dot-anim-4 4.5s ease-in-out infinite;
}

@keyframes dot-anim-5 {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(1080deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}

.dot:nth-child(5) {
  transform: rotate(0);
  animation: dot-anim-5 4.5s ease-in-out infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 var(--primary);
  }
  50% {
    box-shadow: 0 0 0 var(--primary);
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 var(--primary);
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #000;
  }
  50% {
    box-shadow: 0 0 0 #000;
    margin-top: -20px;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #000;
    margin-top: 0;
  }
}

@keyframes bounce {
  0%,
  100% {
    font-size: 0.75px;
  }
  50% {
    font-size: 1.5px;
  }
}

.loading-page-1 {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-page-1 span {
  font-size: 150px;
  color: #fff;
  z-index: 1;
  font-weight: 600;
  letter-spacing: 5px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  transition-delay: 0.2s;
}
.loading-page-1::after,
.loading-page-1::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.loading-page-1::after {
  background-color: var(--primary);
  transition-delay: 0.7s;
}
.loading-page-1 .load-text .text-load {
  font-weight: 700;
  letter-spacing: 15px;
  display: inline-block;
  color: rgba(255, 255, 255, 0.2);
  position: relative;
  font-size: 5vw;
  line-height: 5vw;
  letter-spacing: 1px;
  text-align: center;
  user-select: none;
  text-transform: uppercase;
  font-family: var(--font-family-title);
}
.loading-page-1 .load-text .text-load:before {
  animation: letters-loading 4s infinite;
  color: #fff;
  content: attr(data-text);
  left: 0;
  opacity: 0;
  top: 0;
  line-height: 5vw;
  position: absolute;
  text-transform: uppercase;
}
.loading-page-1 .load-text .text-load:nth-child(2):before {
  animation-delay: 0.2s;
}
.loading-page-1 .load-text .text-load:nth-child(3):before {
  animation-delay: 0.4s;
}
.loading-page-1 .load-text .text-load:nth-child(4):before {
  animation-delay: 0.6s;
}
.loading-page-1 .load-text .text-load:nth-child(5):before {
  animation-delay: 0.8s;
}
.loading-page-1 .load-text .text-load:nth-child(6):before {
  animation-delay: 1s;
}
.loading-page-1 .load-text .text-load:nth-child(7):before {
  animation-delay: 1.2s;
}
.loading-page-1 .load-text .text-load:nth-child(8):before {
  animation-delay: 1.4s;
}
@media only screen and (max-width: 575px) {
  .loading-page-1 .load-text .text-load {
    font-size: 8vw;
    line-height: 8vw;
  }
  .loading-page-1 .load-text .text-load:before {
    line-height: 8vw;
  }
}
.loading-page-1.active::after {
  height: 0;
  transform: translateY(-5vh);
}
.loading-page-1.active::before {
  height: 0;
  transform: rotate(5deg) scale(1.5) translateY(-20vh);
  transform-origin: 25vw;
}
.loading-page-1.active span {
  transform: translateY(-50px);
  opacity: 0;
}

@keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }
  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

.loading-page-2 {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-page-2 .text {
  font-size: 32px;
  color: #fff;
  z-index: 1;
  font-weight: 600;
  letter-spacing: 5px;
}
.loading-page-2 .text.show {
  opacity: 0;
  animation-name: zoomLoader;
  animation-timing-function: linear;
  animation-duration: 2s;
  animation-iteration-count: 1;
}
@media only screen and (max-width: 991px) {
  .loading-page-2 .text {
    font-size: 18px;
    letter-spacing: 2px;
  }
}
@media only screen and (max-width: 575px) {
  .loading-page-2 .text {
    font-size: 14px;
  }
}
.loading-page-2::after,
.loading-page-2::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.loading-page-2::after {
  background-color: var(--primary);
}
.loading-page-2::before {
  background-color: #fff;
}
.loading-page-2.active::after {
  width: 0;
  transition-delay: 0.1s;
  transform: translateX(-5vh);
}
.loading-page-2.active::before {
  width: 0;
  transition-delay: 0.2s;
  transform: translateX(-5vh);
}

@keyframes zoomLoader {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  15% {
    transform: scale(5);
  }
  25% {
    transform: scale(9);
  }
  35% {
    transform: scale(12);
  }
  45% {
    transform: scale(15);
  }
  55% {
    transform: scale(18);
  }
  65% {
    transform: scale(18) translateX(-20%);
  }
  75% {
    transform: scale(18) translateX(-40%);
  }
  85% {
    transform: scale(18) translateX(-60%);
  }
  95% {
    transform: scale(18) translateX(-80%);
    opacity: 1;
  }
  100% {
    transform: scale(18) translateX(-100%);
  }
}

sticky-top.font-10 {
  font-size: 10px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-15 {
  font-size: 15px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px;
}

.font-26 {
  font-size: 26px;
}

.font-30 {
  font-size: 30px;
}

.font-35 {
  font-size: 35px;
}

.font-40 {
  font-size: 40px;
}

.font-45 {
  font-size: 45px;
}

.font-50 {
  font-size: 50px;
}

.font-55 {
  font-size: 55px;
}

.font-60 {
  font-size: 60px;
}

.font-70 {
  font-size: 70px;
}

.font-75 {
  font-size: 75px;
}

.font-80 {
  font-size: 80px;
}

.font-90 {
  font-size: 90px;
}

.font-100 {
  font-size: 100px;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-900 {
  font-weight: 900;
}

.p-a0 {
  padding: 0px;
}

.p-t0 {
  padding-top: 0px;
}

.p-b0 {
  padding-bottom: 0px;
}

.p-l0 {
  padding-left: 0px;
}

.p-r0 {
  padding-right: 0px;
}

.p-lr0 {
  padding-left: 0px;
  padding-right: 0px;
}

.p-tb0 {
  padding-bottom: 0px;
  padding-top: 0px;
}

.m-a0 {
  margin: 0px;
}

.m-t0 {
  margin-top: 0px;
}

.m-b0 {
  margin-bottom: 0px;
}

.m-l0 {
  margin-left: 0px;
}

.m-r0 {
  margin-right: 0px;
}

.m-lr0 {
  margin-left: 0px;
  margin-right: 0px;
}

.m-tb0 {
  margin-bottom: 0px;
  margin-top: 0px;
}

.p-a5 {
  padding: 5px;
}

.p-t5 {
  padding-top: 5px;
}

.p-b5 {
  padding-bottom: 5px;
}

.p-l5 {
  padding-left: 5px;
}

.p-r5 {
  padding-right: 5px;
}

.p-lr5 {
  padding-left: 5px;
  padding-right: 5px;
}

.p-tb5 {
  padding-bottom: 5px;
  padding-top: 5px;
}

.m-a5 {
  margin: 5px;
}

.m-t5 {
  margin-top: 5px;
}

.m-b5 {
  margin-bottom: 5px;
}

.m-l5 {
  margin-left: 5px;
}

.m-r5 {
  margin-right: 5px;
}

.m-lr5 {
  margin-left: 5px;
  margin-right: 5px;
}

.m-tb5 {
  margin-bottom: 5px;
  margin-top: 5px;
}

.p-a10 {
  padding: 10px;
}

.p-t10 {
  padding-top: 10px;
}

.p-b10 {
  padding-bottom: 10px;
}

.p-l10 {
  padding-left: 10px;
}

.p-r10 {
  padding-right: 10px;
}

.p-lr10 {
  padding-left: 10px;
  padding-right: 10px;
}

.p-tb10 {
  padding-bottom: 10px;
  padding-top: 10px;
}

.m-a10 {
  margin: 10px;
}

.m-t10 {
  margin-top: 10px;
}

.m-b10 {
  margin-bottom: 10px;
}

.m-l10 {
  margin-left: 10px;
}

.m-r10 {
  margin-right: 10px;
}

.m-lr10 {
  margin-left: 10px;
  margin-right: 10px;
}

.m-tb10 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.p-a15 {
  padding: 15px;
}

.p-t15 {
  padding-top: 15px;
}

.p-b15 {
  padding-bottom: 15px;
}

.p-l15 {
  padding-left: 15px;
}

.p-r15 {
  padding-right: 15px;
}

.p-lr15 {
  padding-left: 15px;
  padding-right: 15px;
}

.p-tb15 {
  padding-bottom: 15px;
  padding-top: 15px;
}

.m-a15 {
  margin: 15px;
}

.m-t15 {
  margin-top: 15px;
}

.m-b15 {
  margin-bottom: 15px;
}

.m-l15 {
  margin-left: 15px;
}

.m-r15 {
  margin-right: 15px;
}

.m-lr15 {
  margin-left: 15px;
  margin-right: 15px;
}

.m-tb15 {
  margin-bottom: 15px;
  margin-top: 15px;
}

.p-a20 {
  padding: 20px;
}

.p-t20 {
  padding-top: 20px;
}

.p-b20 {
  padding-bottom: 20px;
}

.p-l20 {
  padding-left: 20px;
}

.p-r20 {
  padding-right: 20px;
}

.p-lr20 {
  padding-left: 20px;
  padding-right: 20px;
}

.p-tb20 {
  padding-bottom: 20px;
  padding-top: 20px;
}

.m-a20 {
  margin: 20px;
}

.m-t20 {
  margin-top: 20px;
}

.m-b20 {
  margin-bottom: 20px;
}

.m-l20 {
  margin-left: 20px;
}

.m-r20 {
  margin-right: 20px;
}

.m-lr20 {
  margin-left: 20px;
  margin-right: 20px;
}

.m-tb20 {
  margin-bottom: 20px;
  margin-top: 20px;
}

.p-a25 {
  padding: 25px;
}

.p-t25 {
  padding-top: 25px;
}

.p-b25 {
  padding-bottom: 25px;
}

.p-l25 {
  padding-left: 25px;
}

.p-r25 {
  padding-right: 25px;
}

.p-lr25 {
  padding-left: 25px;
  padding-right: 25px;
}

.p-tb25 {
  padding-bottom: 25px;
  padding-top: 25px;
}

.m-a25 {
  margin: 25px;
}

.m-t25 {
  margin-top: 25px;
}

.m-b25 {
  margin-bottom: 25px;
}

.m-l25 {
  margin-left: 25px;
}

.m-r25 {
  margin-right: 25px;
}

.m-lr25 {
  margin-left: 25px;
  margin-right: 25px;
}

.m-tb25 {
  margin-bottom: 25px;
  margin-top: 25px;
}

.p-a30 {
  padding: 30px;
}

.p-t30 {
  padding-top: 30px;
}

.p-b30 {
  padding-bottom: 30px;
}

.p-l30 {
  padding-left: 30px;
}

.p-r30 {
  padding-right: 30px;
}

.p-lr30 {
  padding-left: 30px;
  padding-right: 30px;
}

.p-tb30 {
  padding-bottom: 30px;
  padding-top: 30px;
}

.m-a30 {
  margin: 30px;
}

.m-t30 {
  margin-top: 30px;
}

.m-b30 {
  margin-bottom: 30px;
}

.m-l30 {
  margin-left: 30px;
}

.m-r30 {
  margin-right: 30px;
}

.m-lr30 {
  margin-left: 30px;
  margin-right: 30px;
}

.m-tb30 {
  margin-bottom: 30px;
  margin-top: 30px;
}

.p-a35 {
  padding: 35px;
}

.p-t35 {
  padding-top: 35px;
}

.p-b35 {
  padding-bottom: 35px;
}

.p-l35 {
  padding-left: 35px;
}

.p-r35 {
  padding-right: 35px;
}

.p-lr35 {
  padding-left: 35px;
  padding-right: 35px;
}

.p-tb35 {
  padding-bottom: 35px;
  padding-top: 35px;
}

.m-a35 {
  margin: 35px;
}

.m-t35 {
  margin-top: 35px;
}

.m-b35 {
  margin-bottom: 35px;
}

.m-l35 {
  margin-left: 35px;
}

.m-r35 {
  margin-right: 35px;
}

.m-lr35 {
  margin-left: 35px;
  margin-right: 35px;
}

.m-tb35 {
  margin-bottom: 35px;
  margin-top: 35px;
}

.p-a40 {
  padding: 40px;
}

.p-t40 {
  padding-top: 40px;
}

.p-b40 {
  padding-bottom: 40px;
}

.p-l40 {
  padding-left: 40px;
}

.p-r40 {
  padding-right: 40px;
}

.p-lr40 {
  padding-left: 40px;
  padding-right: 40px;
}

.p-tb40 {
  padding-bottom: 40px;
  padding-top: 40px;
}

.m-a40 {
  margin: 40px;
}

.m-t40 {
  margin-top: 40px;
}

.m-b40 {
  margin-bottom: 40px;
}

.m-l40 {
  margin-left: 40px;
}

.m-r40 {
  margin-right: 40px;
}

.m-lr40 {
  margin-left: 40px;
  margin-right: 40px;
}

.m-tb40 {
  margin-bottom: 40px;
  margin-top: 40px;
}

.p-a45 {
  padding: 45px;
}

.p-t45 {
  padding-top: 45px;
}

.p-b45 {
  padding-bottom: 45px;
}

.p-l45 {
  padding-left: 45px;
}

.p-r45 {
  padding-right: 45px;
}

.p-lr45 {
  padding-left: 45px;
  padding-right: 45px;
}

.p-tb45 {
  padding-bottom: 45px;
  padding-top: 45px;
}

.m-a45 {
  margin: 45px;
}

.m-t45 {
  margin-top: 45px;
}

.m-b45 {
  margin-bottom: 45px;
}

.m-l45 {
  margin-left: 45px;
}

.m-r45 {
  margin-right: 45px;
}

.m-lr45 {
  margin-left: 45px;
  margin-right: 45px;
}

.m-tb45 {
  margin-bottom: 45px;
  margin-top: 45px;
}

.p-a50 {
  padding: 50px;
}

.p-t50 {
  padding-top: 50px;
}

.p-b50 {
  padding-bottom: 50px;
}

.p-l50 {
  padding-left: 50px;
}

.p-r50 {
  padding-right: 50px;
}

.p-lr50 {
  padding-left: 50px;
  padding-right: 50px;
}

.p-tb50 {
  padding-bottom: 50px;
  padding-top: 50px;
}

.m-a50 {
  margin: 50px;
}

.m-t50 {
  margin-top: 50px;
}

.m-b50 {
  margin-bottom: 50px;
}

.m-l50 {
  margin-left: 50px;
}

.m-r50 {
  margin-right: 50px;
}

.m-lr50 {
  margin-left: 50px;
  margin-right: 50px;
}

.m-tb50 {
  margin-bottom: 50px;
  margin-top: 50px;
}

.p-a55 {
  padding: 55px;
}

.p-t55 {
  padding-top: 55px;
}

.p-b55 {
  padding-bottom: 55px;
}

.p-l55 {
  padding-left: 55px;
}

.p-r55 {
  padding-right: 55px;
}

.p-lr55 {
  padding-left: 55px;
  padding-right: 55px;
}

.p-tb55 {
  padding-bottom: 55px;
  padding-top: 55px;
}

.m-a55 {
  margin: 55px;
}

.m-t55 {
  margin-top: 55px;
}

.m-b55 {
  margin-bottom: 55px;
}

.m-l55 {
  margin-left: 55px;
}

.m-r55 {
  margin-right: 55px;
}

.m-lr55 {
  margin-left: 55px;
  margin-right: 55px;
}

.m-tb55 {
  margin-bottom: 55px;
  margin-top: 55px;
}

.p-a60 {
  padding: 60px;
}

.p-t60 {
  padding-top: 60px;
}

.p-b60 {
  padding-bottom: 60px;
}

.p-l60 {
  padding-left: 60px;
}

.p-r60 {
  padding-right: 60px;
}

.p-lr60 {
  padding-left: 60px;
  padding-right: 60px;
}

.p-tb60 {
  padding-bottom: 60px;
  padding-top: 60px;
}

.m-a60 {
  margin: 60px;
}

.m-t60 {
  margin-top: 60px;
}

.m-b60 {
  margin-bottom: 60px;
}

.m-l60 {
  margin-left: 60px;
}

.m-r60 {
  margin-right: 60px;
}

.m-lr60 {
  margin-left: 60px;
  margin-right: 60px;
}

.m-tb60 {
  margin-bottom: 60px;
  margin-top: 60px;
}

.p-a65 {
  padding: 65px;
}

.p-t65 {
  padding-top: 65px;
}

.p-b65 {
  padding-bottom: 65px;
}

.p-l65 {
  padding-left: 65px;
}

.p-r65 {
  padding-right: 65px;
}

.p-lr65 {
  padding-left: 65px;
  padding-right: 65px;
}

.p-tb65 {
  padding-bottom: 65px;
  padding-top: 65px;
}

.m-a65 {
  margin: 65px;
}

.m-t65 {
  margin-top: 65px;
}

.m-b65 {
  margin-bottom: 65px;
}

.m-l65 {
  margin-left: 65px;
}

.m-r65 {
  margin-right: 65px;
}

.m-lr65 {
  margin-left: 65px;
  margin-right: 65px;
}

.m-tb65 {
  margin-bottom: 65px;
  margin-top: 65px;
}

.p-a70 {
  padding: 70px;
}

.p-t70 {
  padding-top: 70px;
}

.p-b70 {
  padding-bottom: 70px;
}

.p-l70 {
  padding-left: 70px;
}

.p-r70 {
  padding-right: 70px;
}

.p-lr70 {
  padding-left: 70px;
  padding-right: 70px;
}

.p-tb70 {
  padding-bottom: 70px;
  padding-top: 70px;
}

.m-a70 {
  margin: 70px;
}

.m-t70 {
  margin-top: 70px;
}

.m-b70 {
  margin-bottom: 70px;
}

.m-l70 {
  margin-left: 70px;
}

.m-r70 {
  margin-right: 70px;
}

.m-lr70 {
  margin-left: 70px;
  margin-right: 70px;
}

.m-tb70 {
  margin-bottom: 70px;
  margin-top: 70px;
}

.p-a75 {
  padding: 75px;
}

.p-t75 {
  padding-top: 75px;
}

.p-b75 {
  padding-bottom: 75px;
}

.p-l75 {
  padding-left: 75px;
}

.p-r75 {
  padding-right: 75px;
}

.p-lr75 {
  padding-left: 75px;
  padding-right: 75px;
}

.p-tb75 {
  padding-bottom: 75px;
  padding-top: 75px;
}

.m-a75 {
  margin: 75px;
}

.m-t75 {
  margin-top: 75px;
}

.m-b75 {
  margin-bottom: 75px;
}

.m-l75 {
  margin-left: 75px;
}

.m-r75 {
  margin-right: 75px;
}

.m-lr75 {
  margin-left: 75px;
  margin-right: 75px;
}

.m-tb75 {
  margin-bottom: 75px;
  margin-top: 75px;
}

.p-a80 {
  padding: 80px;
}

.p-t80 {
  padding-top: 80px;
}

.p-b80 {
  padding-bottom: 80px;
}

.p-l80 {
  padding-left: 80px;
}

.p-r80 {
  padding-right: 80px;
}

.p-lr80 {
  padding-left: 80px;
  padding-right: 80px;
}

.p-tb80 {
  padding-bottom: 80px;
  padding-top: 80px;
}

.m-a80 {
  margin: 80px;
}

.m-t80 {
  margin-top: 80px;
}

.m-b80 {
  margin-bottom: 80px;
}

.m-l80 {
  margin-left: 80px;
}

.m-r80 {
  margin-right: 80px;
}

.m-lr80 {
  margin-left: 80px;
  margin-right: 80px;
}

.m-tb80 {
  margin-bottom: 80px;
  margin-top: 80px;
}

.p-a85 {
  padding: 85px;
}

.p-t85 {
  padding-top: 85px;
}

.p-b85 {
  padding-bottom: 85px;
}

.p-l85 {
  padding-left: 85px;
}

.p-r85 {
  padding-right: 85px;
}

.p-lr85 {
  padding-left: 85px;
  padding-right: 85px;
}

.p-tb85 {
  padding-bottom: 85px;
  padding-top: 85px;
}

.m-a85 {
  margin: 85px;
}

.m-t85 {
  margin-top: 85px;
}

.m-b85 {
  margin-bottom: 85px;
}

.m-l85 {
  margin-left: 85px;
}

.m-r85 {
  margin-right: 85px;
}

.m-lr85 {
  margin-left: 85px;
  margin-right: 85px;
}

.m-tb85 {
  margin-bottom: 85px;
  margin-top: 85px;
}

.p-a90 {
  padding: 90px;
}

.p-t90 {
  padding-top: 90px;
}

.p-b90 {
  padding-bottom: 90px;
}

.p-l90 {
  padding-left: 90px;
}

.p-r90 {
  padding-right: 90px;
}

.p-lr90 {
  padding-left: 90px;
  padding-right: 90px;
}

.p-tb90 {
  padding-bottom: 90px;
  padding-top: 90px;
}

.m-a90 {
  margin: 90px;
}

.m-t90 {
  margin-top: 90px;
}

.m-b90 {
  margin-bottom: 90px;
}

.m-l90 {
  margin-left: 90px;
}

.m-r90 {
  margin-right: 90px;
}

.m-lr90 {
  margin-left: 90px;
  margin-right: 90px;
}

.m-tb90 {
  margin-bottom: 90px;
  margin-top: 90px;
}

.p-a95 {
  padding: 95px;
}

.p-t95 {
  padding-top: 95px;
}

.p-b95 {
  padding-bottom: 95px;
}

.p-l95 {
  padding-left: 95px;
}

.p-r95 {
  padding-right: 95px;
}

.p-lr95 {
  padding-left: 95px;
  padding-right: 95px;
}

.p-tb95 {
  padding-bottom: 95px;
  padding-top: 95px;
}

.m-a95 {
  margin: 95px;
}

.m-t95 {
  margin-top: 95px;
}

.m-b95 {
  margin-bottom: 95px;
}

.m-l95 {
  margin-left: 95px;
}

.m-r95 {
  margin-right: 95px;
}

.m-lr95 {
  margin-left: 95px;
  margin-right: 95px;
}

.m-tb95 {
  margin-bottom: 95px;
  margin-top: 95px;
}

.p-a100 {
  padding: 100px;
}

.p-t100 {
  padding-top: 100px;
}

.p-b100 {
  padding-bottom: 100px;
}

.p-l100 {
  padding-left: 100px;
}

.p-r100 {
  padding-right: 100px;
}

.p-lr100 {
  padding-left: 100px;
  padding-right: 100px;
}

.p-tb100 {
  padding-bottom: 100px;
  padding-top: 100px;
}

.m-a100 {
  margin: 100px;
}

.m-t100 {
  margin-top: 100px;
}

.m-b100 {
  margin-bottom: 100px;
}

.m-l100 {
  margin-left: 100px;
}

.m-r100 {
  margin-right: 100px;
}

.m-lr100 {
  margin-left: 100px;
  margin-right: 100px;
}

.m-tb100 {
  margin-bottom: 100px;
  margin-top: 100px;
}

@media (min-width: 992px) {
  .m-lg-t0 {
    margin-top: 0px;
  }
  .m-lg-b0 {
    margin-bottom: 0px;
  }
  .m-lg-t5 {
    margin-top: 5px;
  }
  .m-lg-b5 {
    margin-bottom: 5px;
  }
  .m-lg-t10 {
    margin-top: 10px;
  }
  .m-lg-b10 {
    margin-bottom: 10px;
  }
  .m-lg-t15 {
    margin-top: 15px;
  }
  .m-lg-b15 {
    margin-bottom: 15px;
  }
  .m-lg-t20 {
    margin-top: 20px;
  }
  .m-lg-b20 {
    margin-bottom: 20px;
  }
  .m-lg-t25 {
    margin-top: 25px;
  }
  .m-lg-b25 {
    margin-bottom: 25px;
  }
  .m-lg-t30 {
    margin-top: 30px;
  }
  .m-lg-b30 {
    margin-bottom: 30px;
  }
  .m-lg-t35 {
    margin-top: 35px;
  }
  .m-lg-b35 {
    margin-bottom: 35px;
  }
  .m-lg-t40 {
    margin-top: 40px;
  }
  .m-lg-b40 {
    margin-bottom: 40px;
  }
  .m-lg-t45 {
    margin-top: 45px;
  }
  .m-lg-b45 {
    margin-bottom: 45px;
  }
  .m-lg-t50 {
    margin-top: 50px;
  }
  .m-lg-b50 {
    margin-bottom: 50px;
  }
  .m-lg-t55 {
    margin-top: 55px;
  }
  .m-lg-b55 {
    margin-bottom: 55px;
  }
  .m-lg-t60 {
    margin-top: 60px;
  }
  .m-lg-b60 {
    margin-bottom: 60px;
  }
  .m-lg-t65 {
    margin-top: 65px;
  }
  .m-lg-b65 {
    margin-bottom: 65px;
  }
  .m-lg-t70 {
    margin-top: 70px;
  }
  .m-lg-b70 {
    margin-bottom: 70px;
  }
  .m-lg-t75 {
    margin-top: 75px;
  }
  .m-lg-b75 {
    margin-bottom: 75px;
  }
  .m-lg-t80 {
    margin-top: 80px;
  }
  .m-lg-b80 {
    margin-bottom: 80px;
  }
  .m-lg-t85 {
    margin-top: 85px;
  }
  .m-lg-b85 {
    margin-bottom: 85px;
  }
  .m-lg-t90 {
    margin-top: 90px;
  }
  .m-lg-b90 {
    margin-bottom: 90px;
  }
  .m-lg-t95 {
    margin-top: 95px;
  }
  .m-lg-b95 {
    margin-bottom: 95px;
  }
  .m-lg-t100 {
    margin-top: 100px;
  }
  .m-lg-b100 {
    margin-bottom: 100px;
  }
  .m-lg-r0 {
    margin-right: 0;
  }
  .m-lg-l0 {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .m-md-t0 {
    margin-top: 0px;
  }
  .m-md-b0 {
    margin-bottom: 0px;
  }
  .m-md-t5 {
    margin-top: 5px;
  }
  .m-md-b5 {
    margin-bottom: 5px;
  }
  .m-md-t10 {
    margin-top: 10px;
  }
  .m-md-b10 {
    margin-bottom: 10px;
  }
  .m-md-t15 {
    margin-top: 15px;
  }
  .m-md-b15 {
    margin-bottom: 15px;
  }
  .m-md-t20 {
    margin-top: 20px;
  }
  .m-md-b20 {
    margin-bottom: 20px;
  }
  .m-md-t25 {
    margin-top: 25px;
  }
  .m-md-b25 {
    margin-bottom: 25px;
  }
  .m-md-t30 {
    margin-top: 30px;
  }
  .m-md-b30 {
    margin-bottom: 30px;
  }
  .m-md-t35 {
    margin-top: 35px;
  }
  .m-md-b35 {
    margin-bottom: 35px;
  }
  .m-md-t40 {
    margin-top: 40px;
  }
  .m-md-b40 {
    margin-bottom: 40px;
  }
  .m-md-t45 {
    margin-top: 45px;
  }
  .m-md-b45 {
    margin-bottom: 45px;
  }
  .m-md-t50 {
    margin-top: 50px;
  }
  .m-md-b50 {
    margin-bottom: 50px;
  }
  .m-md-t55 {
    margin-top: 55px;
  }
  .m-md-b55 {
    margin-bottom: 55px;
  }
  .m-md-t60 {
    margin-top: 60px;
  }
  .m-md-b60 {
    margin-bottom: 60px;
  }
  .m-md-t65 {
    margin-top: 65px;
  }
  .m-md-b65 {
    margin-bottom: 65px;
  }
  .m-md-t70 {
    margin-top: 70px;
  }
  .m-md-b70 {
    margin-bottom: 70px;
  }
  .m-md-t75 {
    margin-top: 75px;
  }
  .m-md-b75 {
    margin-bottom: 75px;
  }
  .m-md-t80 {
    margin-top: 80px;
  }
  .m-md-b80 {
    margin-bottom: 80px;
  }
  .m-md-t85 {
    margin-top: 85px;
  }
  .m-md-b85 {
    margin-bottom: 85px;
  }
  .m-md-t90 {
    margin-top: 90px;
  }
  .m-md-b90 {
    margin-bottom: 90px;
  }
  .m-md-t95 {
    margin-top: 95px;
  }
  .m-md-b95 {
    margin-bottom: 95px;
  }
  .m-md-t100 {
    margin-top: 100px;
  }
  .m-md-b100 {
    margin-bottom: 100px;
  }
  .m-md-r0 {
    margin-right: 0;
  }
  .m-md-l0 {
    margin-left: 0;
  }
}

@media (min-width: 576px) {
  .m-sm-t0 {
    margin-top: 0px;
  }
  .m-sm-b0 {
    margin-bottom: 0px;
  }
  .m-sm-t5 {
    margin-top: 5px;
  }
  .m-sm-b5 {
    margin-bottom: 5px;
  }
  .m-sm-t10 {
    margin-top: 10px;
  }
  .m-sm-b10 {
    margin-bottom: 10px;
  }
  .m-sm-t15 {
    margin-top: 15px;
  }
  .m-sm-b15 {
    margin-bottom: 15px;
  }
  .m-sm-t20 {
    margin-top: 20px;
  }
  .m-sm-b20 {
    margin-bottom: 20px;
  }
  .m-sm-t25 {
    margin-top: 25px;
  }
  .m-sm-b25 {
    margin-bottom: 25px;
  }
  .m-sm-t30 {
    margin-top: 30px;
  }
  .m-sm-b30 {
    margin-bottom: 30px;
  }
  .m-sm-t35 {
    margin-top: 35px;
  }
  .m-sm-b35 {
    margin-bottom: 35px;
  }
  .m-sm-t40 {
    margin-top: 40px;
  }
  .m-sm-b40 {
    margin-bottom: 40px;
  }
  .m-sm-t45 {
    margin-top: 45px;
  }
  .m-sm-b45 {
    margin-bottom: 45px;
  }
  .m-sm-t50 {
    margin-top: 50px;
  }
  .m-sm-b50 {
    margin-bottom: 50px;
  }
  .m-sm-t55 {
    margin-top: 55px;
  }
  .m-sm-b55 {
    margin-bottom: 55px;
  }
  .m-sm-t60 {
    margin-top: 60px;
  }
  .m-sm-b60 {
    margin-bottom: 60px;
  }
  .m-sm-t65 {
    margin-top: 65px;
  }
  .m-sm-b65 {
    margin-bottom: 65px;
  }
  .m-sm-t70 {
    margin-top: 70px;
  }
  .m-sm-b70 {
    margin-bottom: 70px;
  }
  .m-sm-t75 {
    margin-top: 75px;
  }
  .m-sm-b75 {
    margin-bottom: 75px;
  }
  .m-sm-t80 {
    margin-top: 80px;
  }
  .m-sm-b80 {
    margin-bottom: 80px;
  }
  .m-sm-t85 {
    margin-top: 85px;
  }
  .m-sm-b85 {
    margin-bottom: 85px;
  }
  .m-sm-t90 {
    margin-top: 90px;
  }
  .m-sm-b90 {
    margin-bottom: 90px;
  }
  .m-sm-t95 {
    margin-top: 95px;
  }
  .m-sm-b95 {
    margin-bottom: 95px;
  }
  .m-sm-t100 {
    margin-top: 100px;
  }
  .m-sm-b100 {
    margin-bottom: 100px;
  }
}

.max-w50 {
  max-width: 50px;
}

.max-w60 {
  max-width: 60px;
}

.max-w80 {
  max-width: 80px;
}

.max-w100 {
  max-width: 100px;
}

.max-w200 {
  max-width: 200px;
}

.max-w300 {
  max-width: 300px;
}

.max-w400 {
  max-width: 400px;
}

.max-w500 {
  max-width: 500px;
}

.max-w600 {
  max-width: 600px;
}

.max-w700 {
  max-width: 700px;
}

.max-w800 {
  max-width: 800px;
}

.max-w900 {
  max-width: 900px;
}

.max-w1000 {
  max-width: 1000px;
}

.bg-img-fix {
  background-attachment: unset;
  background-size: cover;
}

.full-img {
  width: 100%;
}

.page-title {
  padding: 65px 0px 45px;
  position: relative;
  background: #faf8f2;
  background-size: cover;
  background-position: center;
}
.page-title p {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #7272a8;
  text-transform: capitalize;
}
@media only screen and (max-width: 575px) {
  .page-title p {
    font-size: 16px;
  }
}
.page-title h3,
.page-title .h3 {
  font-size: 40px;
  line-height: 1.2;
  font-weight: 600;
  text-transform: capitalize;
}
@media only screen and (max-width: 575px) {
  .page-title h3,
  .page-title .h3 {
    font-size: 28px;
  }
}

.img-cover {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.img-cover-1 {
  height: 100%;
  width: 100%;
}

.img-cover-2 {
  height: 306px;
  width: 100%;
  object-fit: cover;
}

.img-cover-3 {
  height: 100% !important;
  width: 100%;
  object-fit: cover;
}

section {
  position: relative;
}

.section-head {
  margin-bottom: 50px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.section-head .sub-title {
  text-transform: uppercase;
  color: var(--secondary);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}
@media only screen and (max-width: 767px) {
  .section-head .sub-title {
    font-size: 16px;
  }
}
.section-head .title {
  margin-bottom: 15px;
}
.section-head h2.title,
.section-head .title.h2,
.section-head h3.title,
.section-head .title.h3,
.section-head h4.title,
.section-head .title.h4,
.section-head .wp-block-search .title.wp-block-search__label,
.wp-block-search .section-head .title.wp-block-search__label,
.section-head h5.title,
.section-head .title.h5 {
  font-family: Lobster;
  font-weight: 400;
}
.section-head h2.title,
.section-head .title.h2 {
  font-size: 60px;
  line-height: 1;
  font-style: normal;
  font-family: Lobster;
}
@media only screen and (max-width: 1280px) {
  .section-head h2.title,
  .section-head .title.h2 {
    font-size: 50px;
    line-height: 50px;
  }
}
@media only screen and (max-width: 575px) {
  .section-head h2.title,
  .section-head .title.h2 {
    font-size: 35px;
    line-height: 45px;
  }
}
@media only screen and (max-width: 1280px) {
  .section-head {
    margin-bottom: 35px;
  }
}

.res-pl {
  padding-left: 50px;
}
@media only screen and (max-width: 1280px) {
  .res-pl {
    padding-left: 30px;
  }
}

.row.spno,
.spno {
  margin-left: 0;
  margin-right: 0;
}
.row.spno [class*="col"],
.row.spno [class*="col"],
.spno [class*="col"],
.spno [class*="col"] {
  padding-left: 0;
  padding-right: 0;
}

.row.sp4,
.sp4 {
  margin-left: -4px;
  margin-right: -4px;
}
.row.sp4 [class*="col"],
.row.sp4 [class*="col"],
.sp4 [class*="col"],
.sp4 [class*="col"] {
  padding-left: 4px;
  padding-right: 4px;
}

.row.sp15,
.sp15 {
  margin-left: -7px;
  margin-right: -7px;
}
.row.sp15 [class*="col"],
.row.sp15 [class*="col"],
.sp15 [class*="col"],
.sp15 [class*="col"] {
  padding-left: 7px;
  padding-right: 7px;
}

.row.sp10,
.sp10 {
  margin-left: -5px;
  margin-right: -5px;
}
.row.sp10 [class*="col-"],
.sp10 [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.row.sp60,
.sp60 {
  margin-left: -30px;
  margin-right: -30px;
}
.row.sp60 [class*="col-"],
.sp60 [class*="col-"] {
  padding-left: 30px;
  padding-right: 30px;
}

.row.sp40,
.sp40 {
  margin-left: -20px;
  margin-right: -20px;
}
.row.sp40 [class*="col-"],
.sp40 [class*="col-"] {
  padding-left: 20px;
  padding-right: 20px;
}

.row.sp20,
.sp20 {
  margin-left: -10px;
  margin-right: -10px;
}
.row.sp20 [class*="col-"],
.sp20 [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

.row.sp16,
.sp16 {
  margin-left: -8px;
  margin-right: -8px;
}
.row.sp16 [class*="col-"],
.sp16 [class*="col-"] {
  padding-left: 8px;
  padding-right: 8px;
}

.rounded-xl {
  border-radius: 50% !important;
}

.rounded-lg {
  border-radius: 20px !important;
}

.rounded-md {
  border-radius: 10px !important;
}

.rounded-sm {
  border-radius: 8px !important;
}

.text-orange {
  color: #ff8853;
}

.text-yellow {
  color: #ffe713;
}

.text-skyblue {
  color: #029e76;
}

.text-red {
  color: #ff0003;
}

.text-green {
  color: #029e76;
}

.text-blue {
  color: #281e8b;
}

.bg-gray {
  background-color: #f3f4f8;
}

.bg-orange {
  background-color: #ff8853;
}

.bg-yellow {
  background-color: #ffe713 !important;
}

.bg-skyblue {
  background-color: #00aeff;
}

.bg-red {
  background-color: #ff0003;
}

.bg-green {
  background-color: #029e76;
}

.bg-blue {
  background-color: #281e8b;
}

.bg-pink {
  background-color: #ff5e78;
}

.text-black {
  color: #071c35;
}

.bg-purple {
  background-color: #9a69f9;
}

.bg-dark {
  background-color: #222222;
}

.bg-darkblue {
  background-color: #1e4dc8;
}

.scale8 {
  transform: scale(1.85);
  -moz-transform: scale(1.85);
  -webkit-transform: scale(1.85);
  -ms-transform: scale(1.85);
  -o-transform: scale(1.85);
}

.scale7 {
  transform: scale(1.7);
  -moz-transform: scale(1.7);
  -webkit-transform: scale(1.7);
  -ms-transform: scale(1.7);
  -o-transform: scale(1.7);
}

.scale5 {
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
}

.scale3 {
  transform: scale(1.3);
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
}

.scale2 {
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.scale08 {
  transform: scale(0.8);
  -moz-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
}

.scale05 {
  transform: scale(0.5);
  -moz-transform: scale(0.5);
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
}

.tl {
  transform-origin: left;
  -moz-transform-origin: left;
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  -o-transform-origin: left;
}

.shadow-none {
  box-shadow: unset;
}

.shadow {
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05) !important;
}

.separator {
  height: 1px;
  width: 100%;
  background: lightgrey;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/*=== Layout ===*/
.logo-header {
  display: table;
  float: left;
  vertical-align: middle;
  padding: 0;
  color: #efbb20;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  width: 180px;
  height: 100px;
  position: relative;
  z-index: 9;
}
@media only screen and (max-width: 991px) {
  .logo-header {
    width: 180px;
    height: 80px;
  }
}
@media only screen and (max-width: 575px) {
  .logo-header {
    width: 180px;
    height: 70px;
  }
}
.logo-header a {
  display: table-cell;
  vertical-align: middle;
}
.logo-header img {
  max-width: 180px;
  object-fit: contain;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
@media only screen and (max-width: 991px) {
  .logo-header img {
    width: 165px;
  }
}
@media only screen and (max-width: 575px) {
  .logo-header img {
    max-width: 150px;
  }
}
.logo-header span {
  font-size: 20px;
  letter-spacing: 20px;
}

.top-bar {
  background-color: var(--primary);
  color: #212529;
  padding: 12px 0;
  position: relative;
  border-bottom: 1px solid #d7d7d7;
}
.top-bar .dz-topbar-left {
  float: left;
}
.top-bar .dz-topbar-left li {
  padding-right: 20px;
}
.top-bar .dz-topbar-right {
  float: right;
}
.top-bar .dz-social li a {
  color: #fff;
}
.top-bar .dz-topbar-inner {
  margin-left: -15px;
  margin-right: -15px;
}
.top-bar .dz-topbar-center,
.top-bar .dz-topbar-left,
.top-bar .dz-topbar-right {
  padding-left: 15px;
  padding-right: 15px;
}
.top-bar .dz-topbar-center ul,
.top-bar .dz-topbar-left ul,
.top-bar .dz-topbar-right ul {
  list-style: none;
  margin: 0 -8px;
  padding: 0;
}
.top-bar .dz-topbar-center ul li,
.top-bar .dz-topbar-left ul li,
.top-bar .dz-topbar-right ul li {
  display: inline-block;
  font-size: 15px;
  color: #fff;
}
.top-bar .dz-topbar-center ul i,
.top-bar .dz-topbar-left ul i,
.top-bar .dz-topbar-right ul i {
  margin-right: 5px;
  font-size: 17px;
  color: #fff;
}
.top-bar .dz-topbar-center ul a,
.top-bar .dz-topbar-left ul a,
.top-bar .dz-topbar-right ul a {
  color: #fff;
}
@media only screen and (max-width: 991px) {
  .top-bar {
    display: none;
  }
}

.dz-quik-search {
  background: rgba(33, 37, 41, 0.95);
  position: fixed;
  height: 100%;
  width: 100%;
  top: -100%;
  left: 0;
  padding: 0px 30px;
  z-index: 999;
  overflow: hidden;
  display: none;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-quik-search form {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  position: relative;
  top: 50%;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 1;
  transform: translateY(-50%);
}
.dz-quik-search .form-control,
.dz-quik-search .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .dz-quik-search select,
.dz-quik-search .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .dz-quik-search select {
  padding: 15px 60px 15px 15px;
  width: 100%;
  height: 90px;
  border: none;
  background: 0 0;
  color: #fff;
  font-size: 20px;
  border-bottom: 2px solid #fff;
  border-radius: 0;
}
.dz-quik-search .form-control::-webkit-input-placeholder,
.dz-quik-search .wp-block-categories-dropdown select::-webkit-input-placeholder,
.wp-block-categories-dropdown .dz-quik-search select::-webkit-input-placeholder,
.dz-quik-search .wp-block-archives-dropdown select::-webkit-input-placeholder,
.wp-block-archives-dropdown .dz-quik-search select::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.dz-quik-search .form-control:-ms-input-placeholder,
.dz-quik-search .wp-block-categories-dropdown select:-ms-input-placeholder,
.wp-block-categories-dropdown .dz-quik-search select:-ms-input-placeholder,
.dz-quik-search .wp-block-archives-dropdown select:-ms-input-placeholder,
.wp-block-archives-dropdown .dz-quik-search select:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.dz-quik-search .form-control::placeholder,
.dz-quik-search .wp-block-categories-dropdown select::placeholder,
.wp-block-categories-dropdown .dz-quik-search select::placeholder,
.dz-quik-search .wp-block-archives-dropdown select::placeholder,
.wp-block-archives-dropdown .dz-quik-search select::placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.dz-quik-search span {
  position: absolute;
  right: 15px;
  top: 50%;
  margin: -15px 0;
  height: 25px;
  font-size: 20px;
  cursor: pointer;
}
.dz-quik-search.On {
  top: 0;
}

.site-header {
  position: relative;
  z-index: 9999;
}
.site-header .main-bar {
  background: #fff;
  width: 100%;
  position: relative;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
}
.site-header .container {
  position: relative;
}
.site-header .extra-nav {
  float: right;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 9;
  height: 100px;
  padding-left: 30px;
}
.site-header .extra-nav #quik-search-btn {
  color: #212529;
  cursor: pointer;
  margin-right: 0px;
  border: 0;
  width: 45px;
  height: 45px;
  background: #ebebeb;
  line-height: 45px;
  text-align: center;
  font-size: 18px;
  border-radius: 45px;
}
@media only screen and (max-width: 575px) {
  .site-header .extra-nav .btn,
  .site-header .extra-nav .wp-block-button__link {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.site-header .extra-nav .extra-cell > ul {
  display: flex;
  align-items: center;
}
.site-header .extra-nav .extra-cell > ul > li {
  display: inline-block;
  margin-left: 10px;
}
.site-header .extra-nav .extra-cell > ul > li .icon {
  padding: 0;
  background: transparent;
  min-width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
}
@media only screen and (max-width: 1199px) {
  .site-header .extra-nav {
    height: 100px;
  }
}
@media only screen and (max-width: 991px) {
  .site-header .extra-nav {
    height: 80px;
  }
}
@media only screen and (max-width: 575px) {
  .site-header .extra-nav {
    display: none;
  }
}

.navbar-toggler {
  border: 0 solid #efbb20;
  font-size: 16px;
  line-height: 24px;
  margin: 8px 0px 0px 15px;
  padding: 0;
  float: right;
  outline: none !important;
}

.navicon {
  width: 45px;
  height: 45px;
  background: var(--primary);
  box-shadow: 0 5px 15px -10px var(--primary);
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  border-radius: 6px;
}
.navicon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  border-radius: 1px;
  opacity: 1;
  background: var(--theme-text-color);
  left: 10px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.navicon span:nth-child(1) {
  top: 11px;
  width: 26px;
}
.navicon span:nth-child(2) {
  top: 20px;
  width: 22px;
}
.navicon span:nth-child(3) {
  top: 30px;
  width: 17px;
}
.navicon.open span:nth-child(1) {
  top: 25px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
@media only screen and (max-width: 1280px) {
  .navicon.open span:nth-child(1) {
    top: 22px;
    width: 25px;
  }
}
.navicon.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}
.navicon.open span:nth-child(3) {
  top: 25px;
  width: 26px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
@media only screen and (max-width: 1280px) {
  .navicon.open span:nth-child(3) {
    top: 22px;
    width: 25px;
  }
}
.navicon.style-2 span {
  background: #fff;
}

.header-tb .navicon span {
  background: #fff;
}

.header-nav {
  padding: 0;
}
@media only screen and (max-width: 991px) {
  .header-nav {
    overflow-y: scroll;
    position: fixed;
    width: 60px;
    left: -280px;
    height: 100vh !important;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    top: 0;
    background-color: #fff;
    margin: 0;
    z-index: 99;
  }
}
@media only screen and (max-width: 991px) {
  .header-nav.show {
    left: -1px;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
    margin: 0;
    width: 280px;
  }
}
.header-nav .logo-header {
  display: none;
}
@media only screen and (max-width: 991px) {
  .header-nav .logo-header {
    padding: 30px 20px;
    float: none;
    height: auto;
    width: 240px;
  }
}
.header-nav .nav {
  float: right;
  font-size: 0;
  padding: 0;
}
.header-nav .nav > li {
  margin: 0px;
  font-weight: 400;
  position: relative;
}
.header-nav .nav > li.has-mega-menu {
  position: inherit;
}
.header-nav .nav > li.has-mega-menu > a::after {
  content: "\f078";
  font-size: 10px;
  margin-left: 7px;
  margin-top: 0;
  vertical-align: middle;
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li.has-mega-menu > a::after {
    content: "\f054";
    background-color: var(--primary);
    color: #fff;
    height: 30px;
    line-height: 30px;
    right: 0;
    text-align: center;
    width: 30px;
    z-index: 3;
    float: right;
    font-size: 12px;
    margin: 0;
    border-radius: 6px;
  }
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li.has-mega-menu > a::after::before {
    content: "\f054";
  }
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li {
    border-bottom: 1px solid #eee;
    width: 100%;
  }
}
.header-nav .nav > li.sub-menu-down > a::after {
  content: "\f078";
  font-size: 10px;
  margin-left: 7px;
  margin-top: 0;
  vertical-align: middle;
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li.sub-menu-down > a::after {
    content: "\f054";
    background-color: var(--primary);
    color: #fff;
    height: 30px;
    line-height: 30px;
    right: 0;
    text-align: center;
    width: 30px;
    z-index: 3;
    float: right;
    font-size: 12px;
    margin: 0;
    border-radius: 6px;
  }
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li.sub-menu-down > a::after::before {
    content: "\f054";
  }
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li.sub-menu-down.open > a::after {
    content: "\f078";
  }
}
.header-nav .nav > li > a {
  color: #071c35;
  font-size: 18px;
  padding: 40px 15px;
  font-family: var(--font-family-base);
  cursor: pointer;
  font-weight: 500;
  display: inline-block;
  position: relative;
  border-width: 0 0 0 1px;
  text-transform: capitalize;
  line-height: 1.1;
}
@media only screen and (max-width: 1200px) {
  .header-nav .nav > li > a {
    font-size: 15px;
  }
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li > a {
    padding: 8px 0;
    display: block;
    line-height: 30px;
    font-size: 16px;
  }
}
.header-nav .nav > li > a span {
  display: inline-block;
}
.header-nav .nav > li .mega-menu,
.header-nav .nav > li .sub-menu {
  background-color: #fff;
  display: block;
  left: 0;
  list-style: none;
  opacity: 0;
  padding: 10px 0;
  position: absolute;
  visibility: hidden;
  width: 220px;
  z-index: 10;
  border-radius: 6px;
  margin-top: 20px;
  box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.1);
  text-align: left;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu,
  .header-nav .nav > li .sub-menu {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: transparent;
    box-shadow: none;
    margin: 0;
    border-top: 1px solid #eee;
    padding: 10px 0;
    -webkit-transition: none;
    -ms-transition: none;
    transition: none;
  }
  .header-nav .nav > li .mega-menu > li > a i,
  .header-nav .nav > li .sub-menu > li > a i {
    color: var(--primary) !important;
    height: 30px;
    line-height: 30px;
    right: 0;
    text-align: center;
    width: 30px;
    z-index: 3;
    font-size: 14px !important;
    margin: 0 !important;
    background: var(--rgba-primary-2);
  }
}
.header-nav .nav > li .mega-menu li,
.header-nav .nav > li .sub-menu li {
  position: relative;
}
.header-nav .nav > li .mega-menu li a,
.header-nav .nav > li .sub-menu li a {
  color: #212529;
  display: block;
  font-size: 15px;
  padding: 8px 25px;
  line-height: 1.3;
  text-transform: capitalize;
  position: relative;
  transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  font-weight: 500;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu li a,
  .header-nav .nav > li .sub-menu li a {
    padding: 2px 0 1px 16px;
    display: block;
    line-height: 30px;
    position: relative;
  }
  .header-nav .nav > li .mega-menu li a::after,
  .header-nav .nav > li .sub-menu li a::after {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    width: auto;
    height: auto;
    background: 0 0 !important;
    top: 3px;
    color: #262f5a;
    font-size: 12px;
    position: absolute;
    left: 0;
    font-weight: 700;
  }
}
.header-nav .nav > li .mega-menu li:hover > a,
.header-nav .nav > li .sub-menu li:hover > a {
  color: var(--primary);
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu li:hover > a,
  .header-nav .nav > li .sub-menu li:hover > a {
    padding: 2px 0 1px 16px;
    background: transparent;
  }
}
.header-nav .nav > li .mega-menu li:hover > a::after,
.header-nav .nav > li .sub-menu li:hover > a::after {
  width: 10px;
}
.header-nav .nav > li .mega-menu li > .sub-menu,
.header-nav .nav > li .sub-menu li > .sub-menu {
  left: 220px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu li > .sub-menu,
  .header-nav .nav > li .sub-menu li > .sub-menu {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu .mega-menu,
  .header-nav .nav > li .mega-menu .sub-menu,
  .header-nav .nav > li .sub-menu .mega-menu,
  .header-nav .nav > li .sub-menu .sub-menu {
    border-top: 0;
    padding-left: 20px;
  }
  .header-nav .nav > li .mega-menu .mega-menu li a::after,
  .header-nav .nav > li .mega-menu .sub-menu li a::after,
  .header-nav .nav > li .sub-menu .mega-menu li a::after,
  .header-nav .nav > li .sub-menu .sub-menu li a::after {
    width: 5px;
    content: "";
    height: 5px;
    background: #262f5a !important;
    border-radius: 4px;
    top: 13px;
  }
}
.header-nav .nav > li .sub-menu li > a > i {
  color: inherit;
  display: block;
  float: right;
  font-size: 12px;
  opacity: 1;
  margin-top: 7px;
  border-radius: 6px;
}
.header-nav .nav > li .sub-menu.open > a > i::before {
  content: "\f078";
}
.header-nav .nav > li .sub-menu > li.open > a i::before {
  content: "\f107";
}
.header-nav .nav > li .mega-menu {
  right: 0px;
  width: 100%;
  z-index: 9;
}
@media only screen and (min-width: 991px) {
  .header-nav .nav > li .mega-menu {
    padding: 0;
    display: table;
    border: 1px solid #f8f8f8;
  }
}
.header-nav .nav > li .mega-menu > li {
  display: table-cell;
  padding: 30px 0 20px;
  position: relative;
  vertical-align: top;
  width: 25%;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu > li {
    width: 100%;
    padding: 0;
    display: block;
  }
}
.header-nav .nav > li .mega-menu > li > a {
  color: #222222;
  display: block;
  padding: 0 25px;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 15px;
}
.header-nav .nav > li .mega-menu > li > a > i {
  font-size: 14px;
  margin-right: 5px;
  text-align: center;
  width: 15px;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu > li > a {
    display: none;
  }
}
.header-nav .nav > li .mega-menu > li::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 0px;
  top: 0px;
  display: block;
  width: 1px;
  height: 100%;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu > li::after {
    content: none;
  }
}
.header-nav .nav > li .mega-menu > li:last-child::after {
  display: none;
}
.header-nav .nav > li .mega-menu > li:hover > a {
  color: #222222;
  background-color: transparent;
}
.header-nav .nav > li .mega-menu > li ul {
  margin-top: 15px;
  padding: 0;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu > li ul {
    margin-top: 0px;
  }
}
.header-nav .nav > li .mega-menu > li li i {
  font-size: 14px;
  margin-right: 5px;
  text-align: center;
  width: 15px;
}
.header-nav .nav > li.active > a {
  color: var(--primary);
}
.header-nav .nav > li:hover > .mega-menu,
.header-nav .nav > li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li:hover > .mega-menu,
  .header-nav .nav > li:hover > .sub-menu {
    -webkit-transition: none;
    -ms-transition: none;
    transition: none;
  }
}
.header-nav .nav > li:hover > .mega-menu li:hover > .sub-menu,
.header-nav .nav > li:hover > .sub-menu li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  top: -1px;
}
.header-nav .nav > li.open .mega-menu,
.header-nav .nav > li.open .sub-menu {
  display: block;
  opacity: 1;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}
.header-nav .nav > li.open .open.menu-item-has-children > a::before {
  transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}
.header-nav .nav > li.open .open .mega-menu,
.header-nav .nav > li.open .open .sub-menu {
  display: block;
  opacity: 1;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}
@media only screen and (max-width: 1480px) {
  .header-nav .nav > li:nth-last-child(3) .sub-menu .sub-menu,
  .header-nav .nav > li:nth-last-child(2) .sub-menu .sub-menu,
  .header-nav .nav > li:last-child .sub-menu .sub-menu {
    left: -220px;
  }
}
@media only screen and (max-width: 1199px) {
  .header-nav .nav {
    padding: 0;
  }
  .header-nav .nav > li:last-child .sub-menu {
    left: auto;
    right: 0;
  }
}
@media only screen and (max-width: 991px) {
  .header-nav .nav {
    float: none;
    padding: 0 20px;
  }
}
.header-nav .navbar {
  position: unset;
}
.header-nav .dz-social-icon {
  display: none;
  text-align: center;
}
.header-nav .dz-social-icon ul {
  margin: 0 -2px;
}
.header-nav .dz-social-icon li {
  display: inline-block;
  padding: 0 2px;
}
.header-nav .dz-social-icon a {
  width: 35px;
  height: 35px;
  padding: 0;
  color: var(--primary);
  border: 1px solid lightgrey;
  line-height: 33px;
  text-align: center;
  border-radius: var(--border-radius-base);
  font-size: 15px;
}
.header-nav .dz-social-icon a:hover {
  background-color: var(--primary);
  color: white;
  box-shadow: 0px 5px 15px var(--rgba-primary-4);
}
@media only screen and (max-width: 991px) {
  .header-nav .dz-social-icon {
    display: block;
    padding: 30px 10px 20px;
  }
}

.header-transparent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.header-transparent .top-bar,
.header-transparent .main-bar {
  background: transparent;
}
.header-transparent .container-fluid,
.header-transparent .container-sm,
.header-transparent .container-md,
.header-transparent .container-lg,
.header-transparent .container-xl {
  padding-left: 80px;
  padding-right: 80px;
}
@media only screen and (max-width: 1280px) {
  .header-transparent .container-fluid,
  .header-transparent .container-sm,
  .header-transparent .container-md,
  .header-transparent .container-lg,
  .header-transparent .container-xl {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.header-transparent .is-fixed .main-bar .container > .logo-header.logo-light,
.header-transparent
  .is-fixed
  .main-bar
  .container-fluid
  > .logo-header.logo-light,
.header-transparent .is-fixed .main-bar .container-sm > .logo-header.logo-light,
.header-transparent .is-fixed .main-bar .container-md > .logo-header.logo-light,
.header-transparent .is-fixed .main-bar .container-lg > .logo-header.logo-light,
.header-transparent
  .is-fixed
  .main-bar
  .container-xl
  > .logo-header.logo-light {
  display: none;
}
.header-transparent .is-fixed .main-bar .container > .logo-header.logo-dark,
.header-transparent
  .is-fixed
  .main-bar
  .container-fluid
  > .logo-header.logo-dark,
.header-transparent .is-fixed .main-bar .container-sm > .logo-header.logo-dark,
.header-transparent .is-fixed .main-bar .container-md > .logo-header.logo-dark,
.header-transparent .is-fixed .main-bar .container-lg > .logo-header.logo-dark,
.header-transparent .is-fixed .main-bar .container-xl > .logo-header.logo-dark {
  display: table;
}

.is-fixed .main-bar {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  transition: all 0.5s;
}

.is-fixed .extra-nav .btn-white {
  background-color: var(--primary);
}
.is-fixed .extra-nav .btn-white i {
  color: #fff !important;
}
.is-fixed .extra-nav .btn-white:hover,
.is-fixed .extra-nav .btn-white:focus,
.is-fixed .extra-nav .btn-white:active {
  background-color: var(--primary-hover) !important;
}

@media only screen and (max-width: 991px) {
  .mo-left .header-nav {
    overflow-y: scroll;
    position: fixed;
    width: 280px;
    left: -280px;
    height: 100vh !important;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    top: 0;
    background-color: #fff;
    margin: 0;
    z-index: 99;
  }
  .mo-left .header-nav.nav-dark {
    background-color: #202020;
  }
  .mo-left .header-nav.show {
    left: -1px;
    transition: all 0.8s;
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    -o-transition: all 0.8s;
    margin: 0;
    width: 280px;
  }
  .mo-left .header-nav .logo-header {
    display: block;
    float: none;
    height: auto !important;
    max-width: 100%;
    padding: 25px 20px;
    width: 100% !important;
  }
  .mo-left .header-nav .logo-header img {
    max-width: unset;
    width: 150px;
    vertical-align: middle;
  }
  .mo-left .header-nav li.open a {
    position: relative;
  }
  .mo-left .navbar-toggler.open {
    z-index: 99;
  }
  .mo-left .navbar-toggler.open::after {
    background-color: rgba(0, 0, 0, 0.6);
    content: "";
    left: 0;
    position: fixed;
    right: -20px;
    top: -63px;
    transform: scale(100);
    -o-transform: scale(100);
    -moz-transform: scale(100);
    -webkit-transform: scale(100);
    width: 100%;
    z-index: -1;
    transition: all 0.5s;
    transform-origin: top right;
    margin: 0 0px 0px 20px;
    -webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6);
  }
  .mo-left .navbar-toggler.open span {
    background: #fff;
  }
}

.text-black .nav > li > a {
  color: #222222;
}

.text-black .navbar-toggler span {
  background: #222222;
}

.main-bar {
  -webkit-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -khtml-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -ms-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
}

.is-fixed .main-bar {
  -webkit-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -khtml-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -moz-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -ms-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -o-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
}

@-moz-keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }
  100% {
    margin-top: 0;
  }
}

@-ms-keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }
  100% {
    margin-top: 0;
  }
}

@-webkit-keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }
  100% {
    margin-top: 0;
  }
}

@keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }
  100% {
    margin-top: 0;
  }
}

.cart-list {
  border: 0;
  right: 0;
  left: auto;
  width: 300px;
  top: 100%;
  transition: none;
  margin-top: 0;
}
.cart-list li {
  padding: 10px 15px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
  width: 100%;
}
.cart-list li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.cart-list li .media {
  display: flex;
  align-items: center;
}
.cart-list li .media .media-left {
  width: 80px;
  height: 80px;
  border-radius: 6px;
  overflow: hidden;
}
.cart-list li .media .media-left img {
  width: 100%;
  height: 100%;
}
.cart-list li .media .media-body {
  position: relative;
  padding-left: 10px;
  padding-right: 30px;
  flex: 1;
}
.cart-list li .media .media-body .dz-title {
  color: var(--primary);
}
.cart-list li .media .media-body .dz-price {
  font-size: 16px;
  color: var(--primary);
}
.cart-list li .media .media-body .item-close {
  background-color: var(--bs-danger);
  color: white;
  display: block;
  font-size: 24px;
  height: 24px;
  line-height: 24px;
  position: absolute;
  right: 0px;
  text-align: center;
  top: 0;
  width: 24px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 400;
}
.cart-list.dropdown-menu {
  width: 300px !important;
  box-shadow: 0 60px 50px 0 rgba(0, 0, 0, 0.1);
}

.anim-logo,
.anim-logo-white {
  position: relative;
  margin-bottom: 5px;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .anim-logo:after,
  .anim-logo-white:after {
    top: 10px;
    background-size: 90%;
  }
}

.anim-logo-white:after {
  background-image: url(../images/pan2.png);
}

.header-nav .anim-logo:after,
.header-nav .anim-logo-white:after,
.footer-logo .anim-logo:after,
.footer-logo .anim-logo-white:after {
  top: -7px;
}
@media only screen and (max-width: 767px) {
  .header-nav .anim-logo:after,
  .header-nav .anim-logo-white:after,
  .footer-logo .anim-logo:after,
  .footer-logo .anim-logo-white:after {
    top: -5px;
  }
}

@keyframes swigo-logo {
  from,
  45%,
  55%,
  65%,
  75%,
  80%,
  85%,
  to {
    animation-timing-function: cubic-bezier(0.2, 0.6, 0.35, 1);
  }
  0% {
    transform: rotate(0);
  }
  45% {
    transform: translate3d(-4%, -13%, 0) rotate(-13deg);
  }
  55% {
    transform: translate3d(1%, -2%, 0) rotate(6deg);
  }
  65% {
    transform: translate3d(-2%, -4%, 0) rotate(-3deg);
  }
  75% {
    transform: translate3d(2%, 3%, 0) rotate(3deg);
  }
  80% {
    transform: translate3d(-2%, 1%, 0) rotate(-2deg);
  }
  to {
    transform: none;
  }
}

.site-header.style-1 {
  --headerheight: 64px;
}
.site-header.style-1 .main-bar {
  box-shadow: none;
  padding: 10px 0;
}
.site-header.style-1 .extra-nav {
  height: var(--headerheight);
  padding-left: 60px;
}
@media only screen and (max-width: 1200px) {
  .site-header.style-1 .extra-nav {
    padding-left: 30px;
  }
}
@media only screen and (max-width: 991px) {
  .site-header.style-1 .extra-nav {
    display: none;
  }
}
.site-header.style-1 .logo-header {
  height: var(--headerheight);
}
.site-header.style-1.header-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}
@media only screen and (min-width: 1024px) {
  .site-header.style-1 .header-nav .nav > li > a {
    padding: 20px 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}
.site-header.style-1 .header-nav .nav > li > a.active {
  color: var(--primary);
}
.site-header.style-1 .header-nav .nav > li.active > a,
.site-header.style-1 .header-nav .nav > li:hover > a {
  color: var(--primary);
}
@media only screen and (min-width: 991px) {
  .site-header.style-1 .header-nav .nav > li.active > a::before,
  .site-header.style-1 .header-nav .nav > li:hover > a::before {
    transform: translateY(0);
    opacity: 1;
  }
}
.site-header.style-1
  .main-bar-wraper:not(.is-fixed)
  .header-nav
  .nav.white
  > li
  > a {
  color: #fff;
}
@media only screen and (max-width: 991px) {
  .site-header.style-1
    .main-bar-wraper:not(.is-fixed)
    .header-nav
    .nav.white
    > li
    > a {
    color: var(--title);
  }
}
.site-header.style-1
  .main-bar-wraper:not(.is-fixed)
  .header-nav
  .nav.white
  > li
  > a.active,
.site-header.style-1
  .main-bar-wraper:not(.is-fixed)
  .header-nav
  .nav.white
  > li
  > a:hover {
  color: var(--primary);
}
.site-header.style-1 .top-bar {
  border-bottom: 0;
}
.site-header.style-1 .is-fixed:not(.sticky-no) .main-bar {
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 0;
}

.header-nav .nav > li.active > a {
  color: var(--secondary);
}

.header-transparent:not(.transparent-white) .mostion.logo-header .logo-white,
.header-transparent:not(.transparent-white)
  .mostion.logo-header
  .anim-logo-white {
  display: table-cell;
}

.header-transparent:not(.transparent-white) .mostion.logo-header .anim-logo,
.header-transparent:not(.transparent-white) .mostion.logo-header .logo-dark {
  display: none;
}

.header-transparent:not(.transparent-white)
  .is-fixed
  .mostion.logo-header
  .logo-white,
.header-transparent:not(.transparent-white)
  .is-fixed
  .mostion.logo-header
  .anim-logo-white {
  display: none;
}

.header-transparent:not(.transparent-white)
  .is-fixed
  .mostion.logo-header
  .logo-dark,
.header-transparent:not(.transparent-white)
  .is-fixed
  .mostion.logo-header
  .anim-logo {
  display: table-cell;
}

.header-adv img {
  padding: 15px;
}

@media only screen and (max-width: 991px) {
  .header-adv {
    display: none;
  }
  .header-adv img {
    display: none;
  }
}

.site-header.style-2 {
  --headerheight: 65px;
  --headerspacing: 0 90px;
}
.site-header.style-2 .main-bar {
  box-shadow: none;
  padding: 15px 0;
}
.site-header.style-2 .main-bar .container-fluid,
.site-header.style-2 .main-bar .container-sm,
.site-header.style-2 .main-bar .container-md,
.site-header.style-2 .main-bar .container-lg,
.site-header.style-2 .main-bar .container-xl {
  padding: var(--headerspacing);
}
@media only screen and (max-width: 1280px) {
  .site-header.style-2 .main-bar .container-fluid,
  .site-header.style-2 .main-bar .container-sm,
  .site-header.style-2 .main-bar .container-md,
  .site-header.style-2 .main-bar .container-lg,
  .site-header.style-2 .main-bar .container-xl {
    padding: 0 15px;
  }
}
@media only screen and (max-width: 575px) {
  .site-header.style-2 .main-bar {
    padding: 5px 0;
  }
}
.site-header.style-2 .extra-nav {
  height: var(--headerheight);
  padding-left: 25px;
}
@media only screen and (max-width: 1200px) {
  .site-header.style-2 .extra-nav {
    padding-left: 30px;
  }
}
.site-header.style-2 .logo-header {
  height: var(--headerheight);
}
.site-header.style-2.header-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}
.site-header.style-2 .header-nav .nav > li .mega-menu {
  max-width: 1100px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 1280px) {
  .site-header.style-2 .header-nav .nav > li .mega-menu {
    max-width: 990px;
  }
}
@media only screen and (min-width: 1024px) {
  .site-header.style-2 .header-nav .nav > li > a {
    padding: 20px 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}
.site-header.style-2 .header-nav .nav > li > a.active {
  color: var(--primary);
}
.site-header.style-2 .header-nav .nav > li.active > a,
.site-header.style-2 .header-nav .nav > li:hover > a {
  color: var(--primary);
}
@media only screen and (min-width: 991px) {
  .site-header.style-2 .header-nav .nav > li.active > a::before,
  .site-header.style-2 .header-nav .nav > li:hover > a::before {
    transform: translateY(0);
    opacity: 1;
  }
}
.site-header.style-2 .header-nav .nav.white > li > a {
  color: #fff;
}
.site-header.style-2 .header-nav .nav.white > li > a.active,
.site-header.style-2 .header-nav .nav.white > li > a:hover {
  color: var(--primary);
}
.site-header.style-2 .top-bar {
  border-bottom: 0;
}
.site-header.style-2 .is-fixed:not(.sticky-no) .main-bar {
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 0;
}
.site-header.style-2 .is-fixed:not(.sticky-no) .main-bar .box-header::after {
  box-shadow: none;
}

.header-nav .nav > li.active > a {
  color: var(--secondary);
}

.extra-nav .extra-cell {
  display: flex;
  align-items: center;
}
.extra-nav .extra-cell form input {
  height: 45px;
  border-radius: 25px !important;
  border-color: var(--primary);
}
.extra-nav .extra-cell form .input-group-addon {
  right: 0;
}
.extra-nav .extra-cell form .input-group-addon .btn,
.extra-nav .extra-cell form .input-group-addon .wp-block-button__link {
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  padding: 0;
  justify-content: center;
  border-radius: 100%;
}
.extra-nav .extra-cell form .input-group-addon .btn i,
.extra-nav .extra-cell form .input-group-addon .wp-block-button__link i {
  display: block;
  font-size: 18px;
}
@media only screen and (max-width: 1280px) {
  .extra-nav .extra-cell form {
    display: none;
  }
}

.menu-btn {
  background: transparent;
  width: 45px;
  height: 45px;
  line-height: 45px;
  box-shadow: none;
  margin-left: 30px;
}
@media only screen and (max-width: 1280px) {
  .menu-btn {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 991px) {
  .menu-btn {
    display: none;
  }
}

.contact-sidebar {
  padding: 30px;
  position: fixed;
  height: 100%;
  width: 300px;
  z-index: 999999999;
  background: #fff;
  top: 0;
  left: -300px;
  overflow-y: auto;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.contact-sidebar.active {
  left: 0;
}
.contact-sidebar .logo-contact {
  margin-bottom: 30px;
  height: 65px;
}
.contact-sidebar .logo-contact a {
  display: table-cell;
  vertical-align: middle;
}
.contact-sidebar .dz-title {
  margin-bottom: 12px;
}
.contact-sidebar .dz-title h4,
.contact-sidebar .dz-title .h4,
.contact-sidebar .dz-title .wp-block-search .wp-block-search__label,
.wp-block-search .contact-sidebar .dz-title .wp-block-search__label {
  font-size: 24px;
}
.contact-sidebar .icon-bx-wraper {
  margin-bottom: 20px;
}
.contact-sidebar .icon-bx-wraper .icon-md {
  padding: 0;
  margin-right: 15px !important;
  background: var(--primary);
  box-shadow: 0px 5px 10px 0 var(--rgba-primary-1);
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px !important;
  margin-top: 5px;
  border-radius: var(--border-radius-base);
}
.contact-sidebar .icon-bx-wraper .icon-md i {
  font-size: 28px;
}
.contact-sidebar .icon-bx-wraper .tilte {
  font-family: var(--font-family-title);
  margin-bottom: 5px;
}
.contact-sidebar .icon-bx-wraper p {
  font-size: 14px;
  font-weight: 500;
}

.menu-close {
  width: 0;
  position: fixed;
  height: 100%;
  background: #333;
  top: 0;
  opacity: 0.9;
  left: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 99998;
}

.contact-sidebar.active + .menu-close,
.login-area.active + .menu-close {
  width: 100%;
}

.dz-bnr-inr {
  height: 450px;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 100%;
}
@media only screen and (max-width: 991px) {
  .dz-bnr-inr {
    height: 400px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-bnr-inr {
    height: 320px;
  }
}
.dz-bnr-inr .container {
  display: table;
  height: 100%;
  z-index: 1;
  position: relative;
}
.dz-bnr-inr .dz-bnr-inr-entry {
  vertical-align: middle;
  display: table-cell;
}
@media only screen and (max-width: 767px) {
  .dz-bnr-inr .dz-bnr-inr-entry {
    text-align: center;
  }
  .dz-bnr-inr .dz-bnr-inr-entry .breadcrumb-row {
    display: inline-block;
  }
}
.dz-bnr-inr h1,
.dz-bnr-inr .h1 {
  margin-bottom: 0px;
  color: #fff;
  text-transform: capitalize;
}
.dz-bnr-inr.dz-bnr-inr-sm {
  height: 380px;
}
@media only screen and (max-width: 575px) {
  .dz-bnr-inr.dz-bnr-inr-sm {
    height: 280px;
  }
}
.dz-bnr-inr.dz-bnr-inr-lg {
  height: 100vh;
}
.dz-bnr-inr.bnr-no-img::before {
  content: none;
}

.breadcrumb-row ul {
  background: #fff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  margin: 0;
  border-radius: 10px;
  display: inline-block;
  align-items: center;
  padding: 13px 30px;
}
@media only screen and (max-width: 991px) {
  .breadcrumb-row ul {
    padding: 6px 25px;
  }
}
.breadcrumb-row ul li {
  padding: 0;
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  color: #222222;
}
.breadcrumb-row ul li.active {
  color: #222222;
}
.breadcrumb-row ul li a {
  color: var(--primary);
}
.breadcrumb-row ul .breadcrumb-item + .breadcrumb-item::before {
  content: "\f105";
  color: var(--primary);
  font-weight: 700;
  font-family: "Line Awesome Free";
  font-size: 15px;
  padding-right: 10px;
  top: 0px;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .breadcrumb-row ul {
    padding: 5px 20px;
  }
  .breadcrumb-row ul li {
    font-size: 14px;
  }
}

.dz-breadcrumb-bnr {
  padding: 20px 0;
  background-color: #f9f9f9;
}
.dz-breadcrumb-bnr .breadcrumb-row ul {
  background: transparent;
  box-shadow: none;
  padding: 0;
}
.dz-breadcrumb-bnr .breadcrumb-row ul li a {
  color: var(--primary);
}

.dz-bnr-inr.style-1::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(34, 34, 34, 0.9);
}

.dz-bnr-inr.style-1 h1,
.dz-bnr-inr.style-1 .h1 {
  color: #fff;
  font-weight: 400;
  font-size: 70px;
  line-height: 1.2;
  margin-bottom: 20px;
  font-family: "Lobster";
}
@media only screen and (max-width: 1480px) {
  .dz-bnr-inr.style-1 h1,
  .dz-bnr-inr.style-1 .h1 {
    font-size: 60px;
  }
}
@media only screen and (max-width: 991px) {
  .dz-bnr-inr.style-1 h1,
  .dz-bnr-inr.style-1 .h1 {
    font-size: 50px;
    line-height: 1.1;
  }
}
@media only screen and (max-width: 767px) {
  .dz-bnr-inr.style-1 h1,
  .dz-bnr-inr.style-1 .h1 {
    font-size: 45px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-bnr-inr.style-1 h1,
  .dz-bnr-inr.style-1 .h1 {
    font-size: 40px;
  }
}

.dz-bnr-inr.style-1 .breadcrumb-row {
  z-index: 1;
}
.dz-bnr-inr.style-1 .breadcrumb-row ul {
  background: var(--primary);
}
.dz-bnr-inr.style-1 .breadcrumb-row ul li {
  color: #fff;
}
.dz-bnr-inr.style-1 .breadcrumb-row ul li a {
  color: #fff;
}
.dz-bnr-inr.style-1
  .breadcrumb-row
  ul
  .breadcrumb-item
  + .breadcrumb-item::before {
  color: #fff;
}
@media only screen and (max-width: 991px) {
  .dz-bnr-inr.style-1 .breadcrumb-row {
    width: 100%;
  }
  .dz-bnr-inr.style-1 .breadcrumb-row ul {
    padding: 10px 18px;
  }
  .dz-bnr-inr.style-1 .breadcrumb-row ul li {
    font-size: 14px;
  }
}
@media only screen and (max-width: 767px) {
  .dz-bnr-inr.style-1 .breadcrumb-row ul {
    padding: 7px 14px;
  }
}

.style-1.header-transparent ~ .page-content .dz-bnr-inr.style-1 {
  padding-top: 50px;
}
@media only screen and (max-width: 991px) {
  .style-1.header-transparent ~ .page-content .dz-bnr-inr.style-1 {
    padding-top: 30px;
  }
}

.page-title {
  padding: 30px 0;
}
.page-title.dashboard {
  background: transparent;
  margin-left: 75px;
  padding: 20px 0 0px;
}
.page-title .page-title-content p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
}
.page-title .page-title-content p span {
  font-weight: 500;
  color: var(--primary);
}

.breadcrumbs {
  display: flex;
  justify-content: flex-end;
}
@media only screen and (max-width: 575px) {
  .breadcrumbs {
    justify-content: flex-start;
  }
}
.breadcrumbs li a {
  color: #aeaed5;
  display: inline-block;
  margin-left: 15px;
  font-size: 14px;
}
.breadcrumbs li:first-child a {
  margin-left: 0px;
}
.breadcrumbs li.active a {
  color: var(--primary);
}

.bottom {
  background: #1b2a4e;
}
@media only screen and (max-width: 575px) {
  .bottom {
    padding-bottom: 0;
  }
}
.bottom .bottom-logo p {
  line-height: 30px;
  font-weight: 400;
  color: #999999;
}
@media only screen and (max-width: 991px) {
  .bottom .bottom-widget {
    margin-bottom: 30px;
  }
}
.bottom .bottom-widget .widget-title {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.bottom .bottom-widget ul li a {
  color: #999999;
  font-weight: 400;
  margin-bottom: 10px;
  display: inline-block;
}

footer {
  position: relative;
}
footer .widget {
  margin-bottom: 30px;
}
footer strong {
  color: #fff;
}
footer .footer-logo {
  margin-bottom: 15px;
}
footer .footer-logo img {
  max-width: 100%;
}
footer .widget-logo ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
footer .widget-logo ul li {
  display: inline-block;
  flex: 50%;
  padding: 0 15px 15px 0px;
}
footer .widget_about p {
  margin-bottom: 30px;
}
@media only screen and (max-width: 1200px) {
  footer .widget_about {
    padding: 0;
  }
}
@media only screen and (max-width: 1191px) {
  footer .widget_about {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}
footer .footer-title {
  margin: 0;
  margin-bottom: 12px;
  position: relative;
}
footer .footer-title a {
  color: inherit;
}
footer .footer-title .title {
  color: var(--title);
}
footer .footer-title .dz-separator {
  position: absolute;
  bottom: 0;
  left: 0;
}
footer .footer-top {
  padding-top: 85px;
  padding-bottom: 40px;
  position: relative;
  z-index: 2;
}
@media only screen and (max-width: 1280px) {
  footer .footer-top {
    padding-top: 80px;
    padding-bottom: 40px;
  }
}
@media only screen and (max-width: 991px) {
  footer .footer-top {
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 767px) {
  footer .footer-top {
    padding-top: 60px;
    padding-bottom: 0;
  }
}
footer .footer-bottom {
  padding: 20px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
@media only screen and (max-width: 991px) {
  footer .footer-bottom {
    padding: 18px 0;
    text-align: center !important;
  }
  footer .footer-bottom .text-left,
  footer .footer-bottom .text-right {
    text-align: center !important;
  }
  footer .footer-bottom .text-right {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 767px) {
  footer .footer-bottom {
    padding: 22px 0;
  }
}
footer .widget-link li {
  display: inline-block;
  text-transform: uppercase;
  margin-left: 20px;
}
@media only screen and (max-width: 767px) {
  footer .widget-link li {
    margin-left: 8px;
    margin-right: 7px;
  }
}
footer .widget-link a {
  color: #fff;
}
footer .widget_services ul li a {
  color: inherit;
  display: block;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
footer .wp-block-latest-posts li,
footer .wp-block-categories-list li,
footer .wp-block-archives-list li,
footer .widget_categories ul li,
footer .widget_archive ul li,
footer .widget_meta ul li,
footer .widget_pages ul li,
footer .widget_recent_comments ul li,
footer .widget_nav_menu li,
footer .widget_recent_entries ul li,
footer .widget_services ul li {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
footer .widget_rss ul li {
  --title: #fff;
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1);
}
footer .wp-block-calendar table caption,
footer .wp-block-calendar table tbody {
  color: #fff;
}
footer .wp-block-tag-cloud a,
footer .widget_tag_cloud .tagcloud a {
  border-color: rgba(255, 255, 255, 0.1);
}
footer .wp-block-search,
footer .wp-block-archives,
footer .wp-block-latest-posts,
footer .wp-block-latest-comments,
footer .wp-block-categories,
footer .wp-block-calendar {
  margin-bottom: 0;
}
footer .dz-meta ul li span,
footer .dz-meta ul li a {
  color: var(--primary);
}

.contact-ft {
  text-align: center;
  margin-bottom: 40px;
}
.contact-ft i {
  color: var(--primary);
  font-size: 50px;
  line-height: 1;
  margin-bottom: 15px;
  display: inline-block;
}
.contact-ft h4,
.contact-ft .h4,
.contact-ft .wp-block-search .wp-block-search__label,
.wp-block-search .contact-ft .wp-block-search__label {
  color: #fff;
  text-decoration: underline;
}
.contact-ft h5,
.contact-ft .h5 {
  color: #fff;
  font-weight: 400;
}

.list-column ul {
  display: flex;
  flex-wrap: wrap;
}
.list-column ul li {
  flex: 0 0 50%;
  max-width: 50%;
}

.fb-link {
  list-style: none;
  margin: 0;
  padding: 0;
}
.fb-link li {
  display: inline-block;
}
.fb-link li a {
  color: #fff;
  position: relative;
}
.fb-link li a::after {
  content: "";
  background: var(--primary);
  width: 5px;
  height: 5px;
}

.footer-link {
  margin: 0px -15px;
  text-align: end;
}
@media only screen and (max-width: 991px) {
  .footer-link {
    text-align: center;
  }
}
.footer-link li {
  display: inline-block;
  position: relative;
  padding: 0px 15px;
}
.footer-link li a {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 400;
}

.site-footer .bg1,
.site-footer .bg2 {
  position: absolute;
}

.site-footer .bg1 {
  left: 0;
  bottom: 10px;
}

.site-footer .bg2 {
  right: 10px;
  top: 15px;
}

@media only screen and (max-width: 1480px) {
  .site-footer .bg1,
  .site-footer .bg2 {
    display: none;
  }
}

.site-footer .footer-bottom {
  position: relative;
  z-index: 1;
}
.site-footer .footer-bottom p {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #999999;
  margin-bottom: 0;
}
.site-footer .footer-bottom .copyright-text {
  color: #999999;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.1;
}
.site-footer .footer-bottom .copyright-text a {
  color: var(--primary);
}

.site-footer .widget_services ul li {
  padding: 0px 0 20px 0px;
}
.site-footer .widget_services ul li a {
  color: #cccccc;
  font-weight: 400;
}
.site-footer .widget_services ul li a::before {
  display: none;
}

.site-footer.style-1 .footer-top .footer-title {
  color: #fff;
}

@media only screen and (max-width: 1280px) {
  .site-footer.style-1 .footer-top .footer-title {
    font-size: 20px;
    margin-bottom: 20px !important;
  }
  .site-footer.style-1 .footer-bottom p {
    font-size: 14px;
  }
  .site-footer.style-1 .footer-bottom .copyright-text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .site-footer.style-1 .footer-bottom .copyright-text {
    display: block;
    margin-top: 15px;
  }
}

.footer-title {
  margin-bottom: 30px !important;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.1;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

.site-footer.style-2 {
  background-color: #fff;
}
.site-footer.style-2 .footer-bg-wrapper {
  margin-left: 100px;
  margin-right: 100px;
  z-index: 1;
  position: relative;
  border-radius: 30px;
  background-color: #222222;
}
.site-footer.style-2 .footer-bg-wrapper .particles-js-canvas-el {
  position: absolute;
  bottom: 0;
  left: 0;
}
.site-footer.style-2 .footer-top {
  padding-top: 45px;
  padding-bottom: 30px;
}
.site-footer.style-2 .footer-top .footer-title {
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.1;
  color: #fff;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}
.site-footer.style-2 .footer-bottom .copyright-text {
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.1;
}
.site-footer.style-2 .footer-bottom .footer-link a {
  color: #666666;
  letter-spacing: 0.01em;
}
@media only screen and (max-width: 1480px) {
  .site-footer.style-2 .footer-bg-wrapper {
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 0;
  }
}
@media only screen and (max-width: 1280px) {
  .site-footer.style-2 .footer-top .footer-title {
    font-size: 20px;
    line-height: 1;
  }
}
@media only screen and (max-width: 575px) {
  .site-footer.style-2 .footer-bottom .footer-link li {
    padding: 0px 5px;
  }
  .site-footer.style-2 .footer-bottom .footer-link li a {
    font-size: 13px;
  }
}

.footer-subscribe-wrapper {
  margin-bottom: 60px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 40px;
}
.footer-subscribe-wrapper .wrapper-inner .title {
  font-size: 24px;
  letter-spacing: 0.01em;
}
@media only screen and (max-width: 991px) {
  .footer-subscribe-wrapper {
    margin-bottom: 40px;
  }
  .footer-subscribe-wrapper .wrapper-inner .title {
    font-size: 22px;
  }
}
@media only screen and (max-width: 575px) {
  .footer-subscribe-wrapper {
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
}

.site-footer.style-3 {
  background-color: rgba(34, 34, 34, 0.05);
  z-index: 0;
}
.site-footer.style-3 .footer-subscribe-wrapper,
.site-footer.style-3 .footer-top {
  border-bottom: 1px solid rgba(34, 34, 34, 0.1);
}
.site-footer.style-3 .footer-subscribe-wrapper {
  margin-bottom: 55px;
}
.site-footer.style-3 .footer-title {
  margin-bottom: 20px !important;
}
@media only screen and (max-width: 991px) {
  .site-footer.style-3 .footer-title {
    font-size: 20px;
    margin-bottom: 15px !important;
  }
}
.site-footer.style-3 .footer-top {
  padding-top: 40px;
  padding-bottom: 50px;
}
@media only screen and (max-width: 991px) {
  .site-footer.style-3 .footer-top {
    padding-top: 20px;
  }
}
.site-footer.style-3 .footer-menu ul {
  margin: 0 -15px;
}
.site-footer.style-3 .footer-menu ul li {
  margin: 0 15px;
  display: inline-block;
}
.site-footer.style-3 .footer-menu ul li a {
  letter-spacing: 0.01em;
  color: #666666;
  font-size: 14px;
  line-height: 1.1;
}
@media only screen and (max-width: 1280px) {
  .site-footer.style-3 .footer-menu ul {
    margin: 0 -8px;
  }
  .site-footer.style-3 .footer-menu ul li {
    margin: 0 8px;
  }
}
@media only screen and (max-width: 767px) {
  .site-footer.style-3 .footer-menu ul {
    margin: 0;
  }
  .site-footer.style-3 .footer-menu ul li {
    display: block;
    margin: 0;
    padding: 5px 0;
  }
}
@media only screen and (max-width: 767px) {
  .site-footer.style-3 .footer-menu {
    margin-bottom: 35px;
  }
}
@media only screen and (max-width: 575px) {
  .site-footer.style-3 .footer-menu {
    margin-bottom: 22px;
  }
}
.site-footer.style-3 .footer-bottom p {
  color: #666666;
}
.site-footer.style-3 .footer-bottom .copyright-text {
  color: #666666;
}
@media only screen and (max-width: 1280px) {
  .site-footer.style-3 .footer-bottom p {
    font-size: 14px;
  }
  .site-footer.style-3 .footer-bottom .copyright-text {
    font-size: 14px;
  }
}

.data-typography-1 {
  --font-family-base: "Poppins", sans-serif;
  --font-family-title: "Poppins", sans-serif;
}

#bg {
  background-attachment: fixed;
  background-size: cover;
}

.main-bar-wraper.sticky-no .main-bar {
  position: relative;
}

@media only screen and (min-width: 1281px) {
  /* boxed */
  [data-layout="boxed"] .page-wraper {
    max-width: 1280px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  }
  [data-layout="boxed"] .main-bar {
    transform: translateX(-50%);
    left: 50%;
    max-width: 1280px;
  }
  [data-layout="boxed"] .site-header.style-2 {
    --headerspacing: 0 50px;
  }
  [data-layout="boxed"] .main-bnr-one .banner-inner .banner-content h1,
  [data-layout="boxed"] .main-bnr-one .banner-inner .banner-content .h1 {
    font-size: 70px;
    line-height: 80px;
  }
  [data-layout="boxed"] .main-bnr-one .banner-inner .garlic {
    right: -135px;
  }
  [data-layout="boxed"] .main-bnr-one .main-swiper1-pagination {
    left: 0;
  }
  [data-layout="boxed"] .swiper-btn-lr .btn-prev-long,
  [data-layout="boxed"] .swiper-btn-lr .btn-prev {
    left: -30px;
  }
  [data-layout="boxed"] .swiper-btn-lr .btn-next-long,
  [data-layout="boxed"] .swiper-btn-lr .btn-next {
    right: -30px;
  }
  [data-layout="boxed"] .section-wrapper-2 .bg1 {
    width: 22vw;
  }
  [data-layout="boxed"] .section-wrapper-2 .bg2 {
    top: 20px;
    right: 0px;
  }
  [data-layout="boxed"] .section-wrapper-2.right .bg1 {
    width: 22vw;
    right: -306px;
  }
  [data-layout="boxed"] .main-bnr-two .banner-inner .banner-content h1,
  [data-layout="boxed"] .main-bnr-two .banner-inner .banner-content .h1 {
    font-size: 70px;
    line-height: 80px;
  }
  [data-layout="boxed"] .section-wrapper-5,
  [data-layout="boxed"] .section-wrapper-4,
  [data-layout="boxed"] .section-wrapper-8 {
    margin-left: 20px;
    margin-right: 20px;
  }
  [data-layout="boxed"] .site-footer.style-1 .bg1 {
    left: -114px;
    bottom: -36px;
    width: 10vw;
  }
  [data-layout="boxed"] .site-footer.style-1 .bg2 {
    right: -18px;
  }
  [data-layout="boxed"] .site-footer.style-2 .footer-bg-wrapper {
    margin-left: 20px;
    margin-right: 20px;
  }
  [data-layout="boxed"] .site-footer.style-3 .bg1 {
    left: -115px;
    bottom: 110px;
  }
  [data-layout="boxed"] .site-footer.style-3 .bg2 {
    top: -100px;
    right: 0;
  }
  [data-layout="boxed"] .under-construction .construction-media::after {
    left: 50px;
  }
  [data-layout="boxed"] .under-construction .construction-media img {
    left: 40px;
  }
  [data-layout="boxed"] .section-wrapper-5 .bg1 {
    top: 0;
    left: -190px;
  }
  [data-layout="boxed"] .section-wrapper-5 .bg2 {
    width: 30vw;
    right: 80px;
  }
  [data-layout="boxed"] .section-wrapper-7 .bg1 {
    top: 9px;
    width: 10vw;
    left: -106px;
  }
  [data-layout="boxed"] .section-wrapper-7 .bg2 {
    width: 30vw;
    right: -80px;
  }
  [data-layout="boxed"] .section-wrapper-7::after {
    width: 550px;
    height: 550px;
    right: -12%;
  }
  [data-layout="boxed"] .icon-bx-wraper.style-5 .icon-content {
    padding: 90px 15px 40px;
  }
  /* Frame */
  [data-layout="frame"] {
    padding: 30px;
  }
  [data-layout="frame"] .page-wraper {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
    height: auto !important;
  }
  [data-layout="frame"] .is-fixed .main-bar {
    left: 0;
    width: 100%;
    margin: 0;
    position: unset;
  }
  [data-layout="frame"] button.scroltop {
    right: 50px;
    bottom: 50px;
  }
}

@media only screen and (max-width: 1281px) {
  [data-layout="frame"] {
    padding: 0 !important;
  }
  [data-layout="frame"] button.scroltop {
    right: 15px;
    bottom: 15px;
  }
}

.theme-sharped {
  --border-radius-base: 0;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}

.theme-rounded {
  --border-radius-base: 10px;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}

.theme-bg-color {
  background-color: var(--theme-bg-color);
}

[data-body-bg="color_1"] {
  background-color: #7da640;
}

[data-body-bg="color_2"] {
  background-color: #fe9f10;
}

[data-body-bg="color_3"] {
  background-color: #ee2737;
}

[data-body-bg="color_4"] {
  background-color: #a75d5d;
}

[data-body-bg="color_5"] {
  background-color: #ea5c2b;
}

[data-body-bg="color_6"] {
  background-color: #a9907e;
}

[data-body-bg="color_7"] {
  background-color: #87805e;
}

/*=== Components ===*/
.card {
  border: 0px solid #e1e1f0;
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: 0px 36px 48px rgba(31, 66, 135, 0.04);
  background: #fff;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e1e1f0;
  background: #fff;
  padding: 20px 30px;
}
.card-title {
  font-size: 24px;
  margin-bottom: 0px;
}
.card .card-body {
  padding: 20px 30px;
  background: #fff;
  border-radius: 15px;
}

.form-label {
  font-size: 14px;
  font-weight: 500;
  color: #666666;
  margin-bottom: 10px;
  background: transparent;
  border-color: #e1e1f0;
}

input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
textarea,
.form-control,
.wp-block-categories-dropdown select,
.wp-block-archives-dropdown select {
  height: 50px;
  border: 1px solid #dddddd;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 400;
  color: #666666;
  transition: all 0.3s ease-in-out;
  background: #fff;
  border-radius: var(--border-radius-base);
  width: 100%;
}
input[type="email"] .select,
input[type="number"] .select,
input[type="password"] .select,
input[type="search"] .select,
input[type="tel"] .select,
input[type="text"] .select,
input[type="url"] .select,
textarea .select,
.form-control .select,
.wp-block-categories-dropdown select .select,
.wp-block-archives-dropdown select .select {
  padding: 12px 0;
}
input[type="email"] span,
input[type="number"] span,
input[type="password"] span,
input[type="search"] span,
input[type="tel"] span,
input[type="text"] span,
input[type="url"] span,
textarea span,
.form-control span,
.wp-block-categories-dropdown select span,
.wp-block-archives-dropdown select span {
  margin-top: 0;
}
input[type="email"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="search"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
input[type="text"]::-webkit-input-placeholder,
input[type="url"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder,
.wp-block-categories-dropdown select::-webkit-input-placeholder,
.wp-block-archives-dropdown select::-webkit-input-placeholder {
  color: #666666;
}
input[type="email"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="search"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="text"]:-ms-input-placeholder,
input[type="url"]:-ms-input-placeholder,
textarea:-ms-input-placeholder,
.form-control:-ms-input-placeholder,
.wp-block-categories-dropdown select:-ms-input-placeholder,
.wp-block-archives-dropdown select:-ms-input-placeholder {
  color: #666666;
}
input[type="email"]::placeholder,
input[type="number"]::placeholder,
input[type="password"]::placeholder,
input[type="search"]::placeholder,
input[type="tel"]::placeholder,
input[type="text"]::placeholder,
input[type="url"]::placeholder,
textarea::placeholder,
.form-control::placeholder,
.wp-block-categories-dropdown select::placeholder,
.wp-block-archives-dropdown select::placeholder {
  color: #666666;
}
input[type="email"]:focus,
input[type="email"]:active,
input[type="email"].active,
input[type="number"]:focus,
input[type="number"]:active,
input[type="number"].active,
input[type="password"]:focus,
input[type="password"]:active,
input[type="password"].active,
input[type="search"]:focus,
input[type="search"]:active,
input[type="search"].active,
input[type="tel"]:focus,
input[type="tel"]:active,
input[type="tel"].active,
input[type="text"]:focus,
input[type="text"]:active,
input[type="text"].active,
input[type="url"]:focus,
input[type="url"]:active,
input[type="url"].active,
textarea:focus,
textarea:active,
textarea.active,
.form-control:focus,
.wp-block-categories-dropdown select:focus,
.wp-block-archives-dropdown select:focus,
.form-control:active,
.wp-block-categories-dropdown select:active,
.wp-block-archives-dropdown select:active,
.form-control.active,
.wp-block-categories-dropdown select.active,
.wp-block-archives-dropdown select.active {
  border-color: var(--primary);
}
@media only screen and (max-width: 991px) {
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="url"],
  textarea,
  .form-control,
  .wp-block-categories-dropdown select,
  .wp-block-archives-dropdown select {
    height: 50px;
  }
}

textarea.form-control {
  min-height: unset;
  height: auto;
}

textarea {
  height: 120px;
  resize: none;
}

input:-internal-autofill-selected {
  background: white !important;
  background-image: none !important;
  color: -internal-light-dark-color(black, white) !important;
}

.input-group-text {
  padding: 5px 15px;
  background: #ffffff;
  margin-bottom: 0px !important;
  color: #666666;
  border-color: transparent;
}

.toggle {
  cursor: pointer;
  display: block;
}

.toggle-switch {
  display: inline-block;
  background: #f7f7f7;
  border-radius: 16px;
  width: 45px;
  height: 20px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
}
.toggle-switch::before,
.toggle-switch::after {
  content: "";
}
.toggle-switch::before {
  display: block;
  background: linear-gradient(to bottom, #fff 0%, #eee 100%);
  border-radius: 50%;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 1px;
  left: 3px;
  transition: left 0.25s;
}
.toggle:hover .toggle-switch::before {
  background: linear-gradient(to bottom, #fff 0%, #fff 100%);
}
.toggle-checkbox:checked + .toggle-switch {
  background: var(--primary);
}
.toggle-checkbox:checked + .toggle-switch::before {
  left: 26px;
}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.toggle-label {
  margin-left: 15px;
  position: relative;
  top: 2px;
  font-size: 16px;
  font-weight: 400;
}

.file-upload-wrapper {
  position: relative;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  border: 1px solid #e1e1f0;
  border-radius: 5px;
  color: #aeaed5;
}
.file-upload-wrapper::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff;
  padding: 4px 15px;
  display: block;
  width: calc(100% - 40px);
  pointer-events: none;
  z-index: 20;
  height: calc(1.5em + 1rem + 2px);
  line-height: 30px;
  border-radius: 5px 10px 10px 5px;
  font-weight: 400;
  overflow: hidden;
}
.file-upload-wrapper::before {
  content: "Upload";
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  height: calc(1.5em + 1rem + 2px);
  background: var(--primary);
  color: #fff;
  font-weight: 400;
  z-index: 25;
  font-size: 14px;
  line-height: 40px;
  padding: 0 15px;
  text-transform: capitalize;
  pointer-events: none;
  border-radius: 0 5px 5px 0;
}
.file-upload-wrapper:hover::before {
  background: #618132;
}
.file-upload-wrapper input {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  height: calc(1.5em + 1rem + 2px);
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  width: 100%;
}

#ui-datepicker-div {
  display: none;
  background: #fafafa;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  margin-top: 0.25rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.ui-datepicker-calendar thead th {
  padding: 0.25rem 0;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 400;
  color: #666666;
}

.ui-datepicker-calendar tbody td {
  width: 2.5rem;
  text-align: center;
  padding: 0;
}
.ui-datepicker-calendar tbody td a {
  display: block;
  border-radius: 0.25rem;
  line-height: 2rem;
  transition: 0.3s all;
  color: #666666;
  font-size: 0.875rem;
  text-decoration: none;
}
.ui-datepicker-calendar tbody td a:hover {
  background-color: var(--primary);
  color: white;
}
.ui-datepicker-calendar tbody td a.ui-state-active {
  background-color: var(--primary);
  color: white;
}

.ui-datepicker-header a.ui-corner-all {
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
  border-radius: 0.25rem;
  transition: 0.3s all;
}
.ui-datepicker-header a.ui-corner-all:hover {
  background-color: #c5dca3;
  color: #fff;
}

.ui-datepicker-header a.ui-datepicker-prev {
  left: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: 0.5rem;
  background-position: 50%;
  transform: rotate(180deg);
}

.ui-datepicker-header a.ui-datepicker-next {
  right: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 50%;
}

.ui-datepicker-header a > span {
  display: none;
}

.ui-datepicker-title {
  text-align: center;
  line-height: 2rem;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  padding-bottom: 0.25rem;
}

.ui-datepicker-week-col {
  color: #666666;
  font-weight: 400;
  font-size: 0.75rem;
}

label.error {
  color: #ff0003;
  position: absolute;
  bottom: 0;
  margin-bottom: -22px;
  font-size: 12px;
  font-weight: 400;
}

.nice-select {
  display: flex;
  align-items: center;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}
.nice-select ul {
  width: 100% !important;
}

.nice-select .list {
  background: #222222;
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: #222222;
  color: var(--primary);
}

.form-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  ),
select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}

.form-select,
select {
  border: 1px solid transparent;
}
.form-select .dropdown-toggle,
select .dropdown-toggle {
  border: 0 !important;
  padding: 0;
  font-weight: normal;
  text-transform: unset;
  outline: none;
  background: none;
  font-size: 18px;
  line-height: 40px;
}
.form-select .dropdown-toggle:focus,
select .dropdown-toggle:focus,
.form-select .dropdown-toggle:hover,
select .dropdown-toggle:hover {
  outline: 0 !important;
  outline-offset: 0;
  background: none;
}
.form-select .dropdown-toggle::after,
select .dropdown-toggle::after {
  content: none;
}
.form-select.show,
select.show {
  box-shadow: none !important;
  outline: 0px !important;
}
.form-select.show .dropdown-toggle,
select.show .dropdown-toggle {
  box-shadow: none !important;
  outline: 0px !important;
}
.form-select.show.btn-group .dropdown-menu.inner,
select.show.btn-group .dropdown-menu.inner {
  display: block;
}
.form-select .dropdown-menu,
select .dropdown-menu {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  margin-top: -1px;
  padding: 10px 0;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  font-size: 14px;
}
.form-select .dropdown-menu ul li,
select .dropdown-menu ul li {
  padding: 0;
}
.form-select .dropdown-menu ul li a,
select .dropdown-menu ul li a {
  display: block;
  padding: 8px 20px;
  clear: both;
  font-weight: 400;
  color: #333;
  white-space: nowrap;
}
.form-select .dropdown-menu ul li a img,
select .dropdown-menu ul li a img {
  border-radius: 100%;
  -webkit-border-radius: 100%;
  margin-right: 5px;
}
.form-select .dropdown-menu ul li a:hover,
select .dropdown-menu ul li a:hover {
  background-color: #f2f2f4;
}
.form-select .dropdown-menu ul li.selected a,
select .dropdown-menu ul li.selected a {
  background-color: var(--primary);
  color: #fff;
}
.form-select:focus,
select:focus,
.form-select:active,
select:active,
.form-select.active,
select.active {
  border-color: var(--primary);
}

.custom-file-label {
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
  height: 45px;
  border-radius: 30px !important;
  padding: 10px 20px;
  font-size: 15px;
  border-color: transparent;
}
.custom-file-label::after {
  content: none !important;
}

.input-line {
  border-bottom: 1px solid #fff;
  align-items: center;
}
.input-line .form-control,
.input-line .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .input-line select,
.input-line .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .input-line select {
  padding: 0;
  height: 48px !important;
  line-height: 24px;
  padding-bottom: 10px;
  font-size: 18px;
  border: none;
  border-radius: 0;
  color: #fff;
  font-weight: 400;
  background-color: transparent;
}
.input-line textarea::placeholder {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
}
.input-line .form-control::placeholder,
.input-line .wp-block-categories-dropdown select::placeholder,
.wp-block-categories-dropdown .input-line select::placeholder,
.input-line .wp-block-archives-dropdown select::placeholder,
.wp-block-archives-dropdown .input-line select::placeholder {
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #fff;
}
.input-line .default-select {
  background: transparent;
  border: 0;
  border-radius: 0;
  height: 48px !important;
  padding-bottom: 10px;
  background-color: transparent;
  color: #fff;
  font-weight: 400;
  background-image: none;
  position: relative;
  font-size: 18px;
  line-height: 24px;
  padding: 0;
}
.input-line .default-select .btn.show,
.input-line .default-select .show.wp-block-button__link {
  color: #fff;
}
.input-line .default-select select {
  display: none !important;
}
.input-line .default-select:active,
.input-line .default-select:hover,
.input-line .default-select:focus {
  background-color: transparent;
  border-color: transparent;
}
.input-line .input-group-prepand {
  width: 35px;
  height: 50px;
  line-height: 40px;
  position: absolute;
  left: 0;
}
.input-line .input-group-prepand i {
  font-size: 24px;
  color: #fff;
}
.input-line .input-group-prepand + .default-select {
  padding: 0 0 0px 40px;
}
.input-line .input-group-prepand + .form-control,
.input-line .wp-block-categories-dropdown .input-group-prepand + select,
.wp-block-categories-dropdown .input-line .input-group-prepand + select,
.input-line .wp-block-archives-dropdown .input-group-prepand + select,
.wp-block-archives-dropdown .input-line .input-group-prepand + select {
  padding-left: 40px;
}
.input-line.input-black::before,
.input-line.input-black::after {
  content: "";
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 25px;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.input-line.input-black::after {
  width: 100%;
  background-color: #000;
  opacity: 0.1;
  box-shadow: 10px 10px 20px 0 rgba(0, 7, 66, 0.2);
}
.input-line.input-black::before {
  width: 0;
  background-color: #000;
  transform: translateX(-50%);
  left: 50%;
}
.input-line.input-black:hover::before,
.input-line.input-black:focus::before,
.input-line.input-black:active::before,
.input-line.input-black.focused::before {
  width: 100%;
}
.input-line.input-black .form-control,
.input-line.input-black .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .input-line.input-black select,
.input-line.input-black .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .input-line.input-black select {
  color: #222;
  border-color: #222222;
}
.input-line.input-black .form-control::placeholder,
.input-line.input-black .wp-block-categories-dropdown select::placeholder,
.wp-block-categories-dropdown .input-line.input-black select::placeholder,
.input-line.input-black .wp-block-archives-dropdown select::placeholder,
.wp-block-archives-dropdown .input-line.input-black select::placeholder {
  color: #222;
}
.input-line.input-black textarea {
  height: 105px !important;
}
.input-line.input-black .default-select {
  color: #fff;
  border-bottom: 1px solid #222222 !important;
  border-radius: 0;
}
.input-line.input-black .default-select button {
  color: #222 !important;
  border-bottom: none !important;
}
.input-line.input-black .default-select:option {
  color: #222 !important;
}
.input-line .input-group-prepand + .bootstrap-select .dropdown-toggle {
  padding: 0 0 0 40px;
}
.input-line .bootstrap-select {
  padding: 0 !important;
}

.dz-form-card {
  padding: 40px 40px 50px;
  border-radius: 10px;
  margin-top: -415px;
}
.dz-form-card .section-head {
  margin-bottom: 30px;
}
.dz-form-card .section-head .title {
  font-weight: 400;
  font-size: 45px;
  line-height: 56px;
  color: #fff;
}
.dz-form-card .section-head p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  opacity: 0.8;
}
.dz-form-card .input-group {
  margin-bottom: 30px;
}
.dz-form-card .input-line {
  position: relative;
  border: 0;
}
.dz-form-card .input-line::before,
.dz-form-card .input-line::after {
  content: "";
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 25px;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.dz-form-card .input-line::after {
  width: 100%;
  background-color: #fff;
  opacity: 0.25;
  box-shadow: 10px 10px 20px 0 rgba(0, 7, 66, 0.2);
}
.dz-form-card .input-line::before {
  width: 0;
  background-color: #fff;
  transform: translateX(-50%);
  left: 50%;
}
.dz-form-card .input-line:hover::before,
.dz-form-card .input-line:focus::before,
.dz-form-card .input-line:active::before,
.dz-form-card .input-line.focused::before {
  width: 100%;
}
.dz-form-card .input-line textarea {
  height: 120px !important;
}
.dz-form-card textarea::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  opacity: 0.6;
}
.dz-form-card .form-control::placeholder,
.dz-form-card .wp-block-categories-dropdown select::placeholder,
.wp-block-categories-dropdown .dz-form-card select::placeholder,
.dz-form-card .wp-block-archives-dropdown select::placeholder,
.wp-block-archives-dropdown .dz-form-card select::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  opacity: 0.6;
}
@media only screen and (max-width: 1280px) {
  .dz-form-card {
    padding: 30px;
    margin-top: -390px;
  }
}
@media only screen and (max-width: 991px) {
  .dz-form-card {
    margin-bottom: 40px;
    margin-top: 0;
  }
}

.input-group .form-control,
.input-group .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .input-group select,
.input-group .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .input-group select {
  height: 65px;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
.input-group .form-control.bg-grey,
.input-group .wp-block-categories-dropdown select.bg-grey,
.wp-block-categories-dropdown .input-group select.bg-grey,
.input-group .wp-block-archives-dropdown select.bg-grey,
.wp-block-archives-dropdown .input-group select.bg-grey {
  background-color: #f8f8f8;
}
@media only screen and (max-width: 991px) {
  .input-group .form-control,
  .input-group .wp-block-categories-dropdown select,
  .wp-block-categories-dropdown .input-group select,
  .input-group .wp-block-archives-dropdown select,
  .wp-block-archives-dropdown .input-group select {
    height: 55px;
  }
}

.input-group .input-group-addon {
  position: absolute;
  right: 7px;
  top: 50%;
  z-index: 9;
  transform: translateY(-50%);
}
.input-group .input-group-addon .btn,
.input-group .input-group-addon .wp-block-button__link {
  text-align: center;
}
.input-group .input-group-addon .btn span,
.input-group .input-group-addon .wp-block-button__link span {
  display: block;
}
.input-group .input-group-addon .btn i,
.input-group .input-group-addon .wp-block-button__link i {
  display: none;
}
@media only screen and (max-width: 575px) {
  .input-group .input-group-addon .btn,
  .input-group .input-group-addon .wp-block-button__link {
    padding: 10px 12px;
  }
  .input-group .input-group-addon .btn span,
  .input-group .input-group-addon .wp-block-button__link span {
    display: none;
  }
  .input-group .input-group-addon .btn i,
  .input-group .input-group-addon .wp-block-button__link i {
    display: block;
  }
}

.search-wraper {
  margin-top: -135px;
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
}
.search-wraper input {
  box-shadow: 0px 15px 55px rgba(34, 34, 34, 0.15);
  border: none;
  border-radius: 10px !important;
  height: 80px !important;
  padding: 25px 12px 25px 30px;
}
.search-wraper input:focus {
  box-shadow: 0px 15px 55px rgba(34, 34, 34, 0.15);
}
.search-wraper .input-group-addon {
  right: 12px;
}
.search-wraper .input-group-addon .btn,
.search-wraper .input-group-addon .wp-block-button__link {
  padding: 15px 30px;
}
.search-wraper .input-group-addon .btn i,
.search-wraper .input-group-addon .wp-block-button__link i {
  color: #fff;
  font-family: "feather";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 20px;
}
.search-wraper .input-group-prepand {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
}
.search-wraper .input-group-prepand .btn,
.search-wraper .input-group-prepand .wp-block-button__link {
  padding: 30px;
  border: none;
  background-color: transparent;
}
.search-wraper .input-group-prepand .btn i,
.search-wraper .input-group-prepand .wp-block-button__link i {
  color: #292d32;
  font-family: "feather";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 24px;
}
@media only screen and (max-width: 1280px) {
  .search-wraper .input-group-prepand .btn,
  .search-wraper .input-group-prepand .wp-block-button__link {
    padding: 20px;
  }
}
.search-wraper .input-group-prepand .btn.active,
.search-wraper .input-group-prepand .active.wp-block-button__link {
  border: none;
}
.search-wraper input-group textarea::placeholder {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}
.search-wraper.style-1 {
  margin-bottom: 70px;
}
.search-wraper.style-1 input {
  padding: 27px 20px 27px 77px;
}
@media only screen and (max-width: 1280px) {
  .search-wraper {
    margin-top: -140px;
    margin-bottom: 60px;
  }
  .search-wraper.style-1 input {
    padding: 12px 20px 12px 60px;
  }
}
@media only screen and (max-width: 991px) {
  .search-wraper {
    margin-top: -100px;
    margin-bottom: 50px;
  }
  .search-wraper input {
    height: 60px !important;
    padding: 10px 10px 10px 25px;
    border-radius: 6px;
  }
  .search-wraper .input-group-addon {
    right: 10px;
  }
  .search-wraper .input-group-addon .btn,
  .search-wraper .input-group-addon .wp-block-button__link {
    padding: 10px 15px;
  }
  .search-wraper input-group textarea::placeholder {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .search-wraper input {
    padding: 10px 10px 10px 15px;
  }
}
@media only screen and (max-width: 575px) {
  .search-wraper {
    margin-top: -80px;
    margin-bottom: 40px;
  }
}

.form-group .form-select .dropdown-toggle,
.form-group select .dropdown-toggle {
  color: #666 !important;
  font-size: 15px;
  line-height: 1.5;
  height: 50px;
  padding: 10px 20px;
}
.form-group .form-select .dropdown-toggle:hover,
.form-group select .dropdown-toggle:hover {
  color: #666 !important;
}

.form-group .default-select {
  border: 1px solid #dddddd !important;
  padding: 0;
}

.form-group .bootstrap-select .dropdown-toggle .filter-option {
  height: auto !important;
}

button:hover,
button:active,
button:focus {
  outline: 0;
}

.btn,
.wp-block-button__link {
  padding: 12px 24px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  line-height: 1.5;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  color: var(--theme-text-color);
}
@media only screen and (max-width: 1199px) {
  .btn,
  .wp-block-button__link {
    padding: 9px 20px;
    font-size: 15px;
  }
}
@media only screen and (max-width: 575px) {
  .btn,
  .wp-block-button__link {
    font-size: 14px;
    padding: 12px 24px;
  }
}
.btn.btn-md,
.btn-md.wp-block-button__link {
  padding: 15px 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
}
@media only screen and (max-width: 991px) {
  .btn.btn-md,
  .btn-md.wp-block-button__link {
    padding: 15px 20px;
  }
}
@media only screen and (max-width: 767px) {
  .btn.btn-md,
  .btn-md.wp-block-button__link {
    padding: 10px 20px;
    font-size: 14px;
  }
}
.btn.btn-lg,
.btn-group-lg > .btn,
.btn-group-lg > .wp-block-button__link,
.btn-lg.wp-block-button__link {
  padding: 18px 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
@media only screen and (max-width: 991px) {
  .btn.btn-lg,
  .btn-group-lg > .btn,
  .btn-group-lg > .wp-block-button__link,
  .btn-lg.wp-block-button__link {
    padding: 15px 20px;
  }
}
@media only screen and (max-width: 767px) {
  .btn.btn-lg,
  .btn-group-lg > .btn,
  .btn-group-lg > .wp-block-button__link,
  .btn-lg.wp-block-button__link {
    padding: 10px 20px;
    font-size: 14px;
  }
}
.btn:hover,
.wp-block-button__link:hover {
  color: var(--theme-text-color);
}
.btn.btn-secondary,
.btn-secondary.wp-block-button__link {
  color: #fff;
}
.btn.btn-secondary:hover,
.btn-secondary.wp-block-button__link:hover {
  color: #fff;
}
.btn i,
.wp-block-button__link i {
  font-size: 20px;
  line-height: 1;
}

.google-btn {
  color: #000000;
  border: 1px solid #b1b1b1;
}
.google-btn i {
  margin-right: 15px;
}
.google-btn svg {
  margin-right: 15px;
}
.google-btn:hover {
  color: #000;
  border: 1px solid #b1b1b1;
}

.btn-block {
  display: block;
  width: 100%;
}

.shadow-primary {
  box-shadow: 0px 5px 30px 0px var(--rgba-primary-2);
}

.btn-rounded {
  border-radius: 50px;
}

.btn-info {
  color: #fff;
}
.btn-info:hover {
  color: #fff;
}
.btn-info .btn-icon-left {
  color: #00aeff;
}

.btn-white {
  background-color: #fff;
  color: #222;
}
.btn-white:hover {
  color: #222 !important;
  background-color: #fff !important;
}

.btn-success {
  color: #fff;
}
.btn-success .btn-icon-left {
  color: #13c24d;
}
.btn-success:hover {
  color: #fff;
}

.btn-primary,
.wp-block-button__link {
  border-color: var(--primary);
  background-color: var(--primary);
}
.btn-primary.disabled,
.disabled.wp-block-button__link,
.btn-primary:disabled,
.wp-block-button__link:disabled,
.btn-primary:not(:disabled):not(.disabled).active,
.wp-block-button__link:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.wp-block-button__link:not(:disabled):not(.disabled):active,
.btn-primary:active,
.wp-block-button__link:active,
.btn-primary:focus,
.wp-block-button__link:focus,
.btn-primary:hover,
.wp-block-button__link:hover {
  border-color: var(--primary-hover);
  background-color: var(--primary-hover);
  color: var(--theme-text-color);
}
.btn-primary .btn-icon-left,
.wp-block-button__link .btn-icon-left {
  color: var(--primary);
}

.btn-secondary {
  border-color: var(--secondary);
  background-color: var(--secondary);
  color: #fff;
}
.btn-secondary .btn-icon-left {
  color: var(--secondary);
}

.btn-link {
  color: var(--primary);
  text-decoration: none;
}
.btn-link:hover {
  color: var(--primary-hover);
}

.btn-underline {
  font-size: 1rem;
  color: var(--primary);
  font-weight: 400;
  border-bottom: 2px solid var(--primary);
}

.btn-dark {
  color: #fff !important;
}
.btn-dark:hover {
  color: #fff;
}
.btn-dark .btn-icon-left {
  color: #222222;
}
.btn-dark.light {
  background-color: #7d95ce;
  border-color: #7d95ce;
  color: #151f37 !important;
}
.btn-dark.light:hover {
  color: #fff !important;
}

.btn-outline-primary,
.is-style-outline .wp-block-button__link {
  color: var(--primary);
  border-color: var(--primary);
}
.btn-outline-primary.disabled,
.is-style-outline .disabled.wp-block-button__link,
.btn-outline-primary:disabled,
.is-style-outline .wp-block-button__link:disabled,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.is-style-outline .wp-block-button__link:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.is-style-outline .wp-block-button__link:not(:disabled):not(.disabled):active,
.btn-outline-primary:active,
.is-style-outline .wp-block-button__link:active,
.btn-outline-primary:focus,
.is-style-outline .wp-block-button__link:focus,
.btn-outline-primary:hover,
.is-style-outline .wp-block-button__link:hover {
  border-color: var(--primary-hover);
  background-color: var(--primary-hover);
  color: #fff;
}

.btn-outline-secondary {
  color: var(--secondary);
  border-color: var(--secondary);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:active,
.btn-outline-secondary:focus,
.btn-outline-secondary:hover {
  border-color: var(--secondary);
  background-color: var(--secondary);
  color: #fff;
}

.btn-outline-danger {
  color: #ff0003;
}
.btn-outline-danger:hover {
  color: #fff;
}

.btn-outline-info {
  color: #00aeff;
}
.btn-outline-info:hover {
  color: #fff;
}

.btn-outline-success {
  color: #13c24d;
}
.btn-outline-success:hover {
  color: #fff;
}

.btn-outline-warning {
  color: #ffbd13;
}
.btn-outline-warning:hover {
  color: #fff;
}

.btn-outline-dark {
  color: #222222;
}
.btn-outline-dark:hover {
  color: #fff;
}

.btn-check:checked + .btn,
.btn-check:checked + .wp-block-button__link {
  color: #fff;
}

.btn-xl {
  font-size: 22px;
  padding: 25px 35px;
}

.btn-sm,
.btn-group-sm > .btn,
.btn-group-sm > .wp-block-button__link {
  padding: 5px 10px;
  font-size: 12px;
  border-radius: var(--border-radius-base);
}

.btn-link-lg {
  font-weight: 700;
  font-size: 18px;
}
.btn-link-lg:hover {
  text-decoration: unset;
}

.btn-light {
  background-color: #fff;
}
.btn-light:hover {
  background-color: var(--primary);
  color: #fff !important;
  border-color: transparent;
}
.btn-light.text-primary:hover {
  color: #fff !important;
}

.light.btn-success {
  color: #13c24d;
  border: 1px solid #c2f9d4;
  background-color: #f0fef4;
}
.light.btn-success:hover {
  background-color: #13c24d;
  border-color: #13c24d;
  color: #fff;
}

.light.btn-info {
  color: #00aeff;
  border: 1px solid #c7edff;
  background-color: #e6f7ff;
}
.light.btn-info:hover {
  background-color: #00aeff;
  border-color: #00aeff;
  color: #fff;
}

.light.btn-primary,
.light.wp-block-button__link {
  color: #7da640;
  border: 1px solid var(--rgba-primary-3);
  background-color: var(--rgba-primary-1);
}
.light.btn-primary:hover,
.light.wp-block-button__link:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.light.btn-secondary {
  color: #fe9f10;
  border: 1px solid #ffe0b3;
  background-color: #ffedd1;
}
.light.btn-secondary:hover {
  background-color: #fe9f10;
  border-color: #fe9f10;
  color: #fff;
}

.light.btn-warning {
  color: #ffbd13;
  border: 1px solid #ffebb6;
  background-color: #fff3d5;
}
.light.btn-warning:hover {
  background-color: #ffbd13;
  border-color: #ffbd13;
  color: #fff;
}

.light.btn-danger {
  color: #ff0003;
  border: 1px solid #ffd6d7;
  background-color: #ffe6e6;
}
.light.btn-danger:hover {
  background-color: #ff0003;
  border-color: #ff0003;
  color: #fff;
}

.light.btn-dark {
  color: #222222;
  border: 1px solid #d5d5d5;
  background-color: #e1e1e1;
}
.light.btn-dark:hover {
  background-color: #222222;
  border-color: #222222;
  color: #fff;
}

.btn-danger {
  color: #fff;
}

.btn-icon-right {
  color: #fff;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  display: inline-block;
  margin: -15px -10px -15px 20px;
  padding: 15px 10px;
}

.btn-icon-left {
  background: #fff;
  border-radius: 50px;
  display: inline-block;
  float: left;
  color: inherit;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: -8px 15px -8px -20px;
}

.wp-block-button {
  margin-bottom: 0.3125rem;
}

.is-style-squared .wp-block-button__link {
  border-radius: 0;
}

.wp-block-button__link {
  border-radius: 60px;
}
.wp-block-button__link::after {
  content: none;
}

@media only screen and (max-width: 575px) {
  .btn-group .btn,
  .btn-group .wp-block-button__link {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.fb-btn {
  background: #4867aa !important;
}

.tw-btn {
  background: #81b2df !important;
}

.in-btn {
  background: #d74141 !important;
}

.lin-btn {
  background: #3a9df9 !important;
}

.btn.btn-cart,
.btn-cart.wp-block-button__link {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 4px;
}
.btn.btn-cart i,
.btn-cart.wp-block-button__link i {
  font-size: 22px;
}
.btn.btn-cart.btn-white,
.btn-cart.btn-white.wp-block-button__link {
  background-color: #fff;
}
.btn.btn-cart.btn-square,
.btn-cart.btn-square.wp-block-button__link {
  border-radius: 6px;
}
.btn.btn-cart.btn-circle,
.btn-cart.btn-circle.wp-block-button__link {
  border-radius: 20px;
}
.btn.btn-cart.btn-primary,
.btn-cart.wp-block-button__link {
  background: var(--primary);
}

.btn-gray {
  background-color: #f3f3f3;
  color: var(--title);
  transition: 0.5s !important;
}
.btn-gray:hover {
  background: var(--primary);
  color: #fff;
}

.filter-btn i {
  font-family: "feather" !important;
  font-style: normal;
  text-transform: none;
  vertical-align: middle;
}

@keyframes animationPlayBtn1 {
  0% {
    opacity: 0.8;
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    -webkit-transform: scale(0.9);
  }
  100% {
    transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    opacity: 0;
  }
}

@-webkit-keyframes animationPlayBtn1 {
  0% {
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    -webkit-transform: scale(0.9);
  }
  100% {
    transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    opacity: 0;
  }
}

.btn-hover-1 {
  overflow: hidden;
  z-index: 1;
}
.btn-hover-1::before,
.btn-hover-1::after {
  background: #fff;
  content: "";
  position: absolute;
}
.btn-hover-1::before {
  width: 120%;
  height: 0;
  padding-bottom: 120%;
  top: -110%;
  left: -10%;
  border-radius: 50%;
  transform: translate3d(0, 68%, 0) scale3d(0, 0, 0);
}
.btn-hover-1::after {
  width: 90%;
  top: 0;
  left: 0;
  transform: translate3d(0, -100%, 0);
  transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
}
.btn-hover-1 span {
  display: block;
  position: relative;
  z-index: 2;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.btn-hover-1.btn-white::before {
  background: var(--primary);
}
.btn-hover-1.btn-white::after {
  background: var(--primary);
}
.btn-hover-1:focus::before,
.btn-hover-1:active::before,
.btn-hover-1:hover::before {
  transform: translate3d(0, 0, 0) scale3d(1.2, 1, 1);
  transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
}
.btn-hover-1:focus::after,
.btn-hover-1:active::after,
.btn-hover-1:hover::after {
  transform: translate3d(0, 0, 0);
  transition-duration: 0.05s;
  transition-delay: 0.4s;
  transition-timing-function: linear;
}
.btn-hover-1:focus span,
.btn-hover-1:active span,
.btn-hover-1:hover span {
  animation: MoveScaleUpInitial 0.3s forwards, MoveScaleUpEnd 0.3s forwards 0.3s;
  color: var(--primary);
}
.btn-hover-1:focus.btn-primary span,
.btn-hover-1.wp-block-button__link:focus span,
.btn-hover-1:focus.btn-outline-primary span,
.is-style-outline .btn-hover-1.wp-block-button__link:focus span,
.btn-hover-1:active.btn-primary span,
.btn-hover-1.wp-block-button__link:active span,
.btn-hover-1:active.btn-outline-primary span,
.is-style-outline .btn-hover-1.wp-block-button__link:active span,
.btn-hover-1:hover.btn-primary span,
.btn-hover-1.wp-block-button__link:hover span,
.btn-hover-1:hover.btn-outline-primary span,
.is-style-outline .btn-hover-1.wp-block-button__link:hover span {
  color: var(--primary);
}
.btn-hover-1:focus.btn-secondary span,
.btn-hover-1:focus.btn-outline-secondary span,
.btn-hover-1:active.btn-secondary span,
.btn-hover-1:active.btn-outline-secondary span,
.btn-hover-1:hover.btn-secondary span,
.btn-hover-1:hover.btn-outline-secondary span {
  color: var(--secondary);
}
.btn-hover-1:focus.btn-dark span,
.btn-hover-1:focus.btn-outline-dark span,
.btn-hover-1:active.btn-dark span,
.btn-hover-1:active.btn-outline-dark span,
.btn-hover-1:hover.btn-dark span,
.btn-hover-1:hover.btn-outline-dark span {
  color: #222222;
}
.btn-hover-1:focus.btn-white span,
.btn-hover-1:active.btn-white span,
.btn-hover-1:hover.btn-white span {
  color: #fff;
}

@keyframes MoveScaleUpInitial {
  to {
    transform: translate3d(0, -105%, 0) scale3d(1, 2, 1);
    opacity: 0;
  }
}

@keyframes MoveScaleUpEnd {
  from {
    transform: translate3d(0, 100%, 0) scale3d(1, 2, 1);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.btn-hover-2 {
  color: rgba(0, 0, 0, 0) !important;
  transition: all 0.3s ease !important;
  text-shadow: 0 0 0 #fff, 500px 0 0 #fff;
  overflow: hidden;
}
.btn-hover-2.btn-white {
  text-shadow: 0 0 0 #000, 500px 0 0 #000;
}
.btn-hover-2.btn-gray {
  text-shadow: 0 0 0 var(--title), 500px 0 0 var(--title);
}
.btn-hover-2:hover {
  text-shadow: -600px 0 0 #fff, 0 0 0 #fff;
}
.btn-hover-2:hover.btn-white {
  text-shadow: -600px 0 0 #000, 0 0 0 #000;
}
.btn-hover-2:hover.btn-gray {
  text-shadow: -600px 0 0 #fff, 0 0 0 #fff;
}

.btn-shadow {
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
}

.btn.btn-square,
.btn-square.wp-block-button__link {
  padding: 0;
  min-width: 45px;
  width: 45px;
  height: 45px;
  min-height: 45px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  border-radius: var(--border-radius-base);
  outline: none;
  border: 0;
}
.btn.btn-square.btn-white i,
.btn-square.btn-white.wp-block-button__link i {
  color: #222222;
}
.btn.btn-square i,
.btn-square.wp-block-button__link i {
  font-size: 24px;
}
.btn.btn-square .badge,
.btn-square.wp-block-button__link .badge {
  position: absolute;
  top: -7px;
  right: -6px;
  padding: 0;
  height: 20px;
  width: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  background: #666666;
  border-radius: 10px;
}

.btn.btn-hover-3,
.btn-hover-3.wp-block-button__link {
  overflow: hidden;
}
.btn.btn-hover-3 .btn-text,
.btn-hover-3.wp-block-button__link .btn-text {
  transition: opacity 0.5s, transform 0.8s;
  transition-timing-function: cubic-bezier(0.12, 0.8, 0.3, 1);
}
.btn.btn-hover-3 .btn-text::before,
.btn-hover-3.wp-block-button__link .btn-text::before {
  content: attr(data-text);
  position: absolute;
  white-space: nowrap;
  display: inline-block;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, 100%);
  transition: opacity 0.5s, transform 0.8s;
  transition-timing-function: cubic-bezier(0.12, 0.8, 0.3, 1);
}
.btn.btn-hover-3:hover .btn-text,
.btn-hover-3.wp-block-button__link:hover .btn-text {
  transform: translateY(-200%);
}
.btn.btn-hover-3:hover .btn-text::before,
.btn-hover-3.wp-block-button__link:hover .btn-text::before {
  transform: translate(-50%, 150%);
  opacity: 1;
}

.table-responsive-sm {
  min-width: 48rem;
}

.table-responsive-md {
  min-width: 60rem;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

.table {
  margin-bottom: 0px;
  font-weight: 400;
}
.table tr td,
.table tr th {
  padding: 15px;
}

.table tr th {
  color: #222222;
  font-weight: 500;
}

.table-striped thead tr th {
  padding-bottom: 15px !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #ffffff;
}

.table-striped tfoot {
  border-top: 2px solid #e1e1f0;
}
.table-striped tfoot tr th {
  padding-top: 10px;
}

.table-responsive {
  border-radius: var(--border-radius-base);
}

.order-detail {
  margin-top: 40px;
}
.order-detail table {
  width: 100% !important;
  margin-bottom: 25px;
}
.order-detail table tr td {
  padding: 6px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}
.order-detail table tr td.price {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: end;
}
.order-detail table tr.charges {
  border-bottom: 1px dashed rgba(34, 34, 34, 0.2);
}
.order-detail table tr.charges td {
  padding-bottom: 15px;
}
.order-detail table tr.tax {
  border-bottom: 2px solid rgba(34, 34, 34, 0.2);
}
.order-detail table tr.tax td {
  padding: 15px 0;
}
.order-detail table tr.total td {
  padding: 20px 0 0;
}
.order-detail .btn i,
.order-detail .wp-block-button__link i {
  vertical-align: middle;
  margin-left: 10px;
}

.check-tbl {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  vertical-align: middle;
}
.check-tbl thead th {
  font-size: 16px;
  font-weight: 600;
  padding: 15px 15px;
  color: #fff;
  background: #222;
}
.check-tbl tbody td {
  padding: 15px;
  font-weight: 500;
}
.check-tbl tbody td img {
  width: 100px;
  border-radius: 6px;
}
.check-tbl .product-item-close {
  text-align: right;
}
.check-tbl .product-item-close a {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background: var(--primary);
  display: inline-block;
  color: #fff;
  text-align: center;
  line-height: 40px;
}

.dz-separator {
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 8px;
  position: relative;
}
.dz-separator.m-b0 {
  margin-bottom: 0;
}
.dz-separator.style-icon {
  width: 30px;
  height: auto;
  text-align: center;
  font-size: 20px;
  color: var(--primary);
}
.dz-separator.style-icon::after,
.dz-separator.style-icon::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 40px;
  width: 70px;
  height: 2px;
  background: #fff;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 12px;
}
.dz-separator.style-icon::before {
  left: auto;
  right: 40px;
}

.dz-separator-outer {
  overflow: hidden;
}

.dz-separator.style-1 {
  height: 3px;
  width: 60px;
  background-color: var(--primary);
  border-radius: 8px;
}

.site-filters {
  margin-bottom: 30px;
}
.site-filters ul {
  margin: 0;
  list-style: none;
}
.site-filters li {
  display: inline-block;
  padding: 0;
  margin-bottom: 3px;
}
.site-filters li.btn,
.site-filters li.wp-block-button__link {
  box-shadow: none;
  -webkit-box-shadow: none;
  outline: none !important;
}
.site-filters li input {
  display: none;
}
.site-filters li a {
  margin: 0 5px 5px 0;
  display: block;
}
.site-filters.center {
  text-align: center;
}
.site-filters.center ul {
  display: inline-block;
  margin: auto;
}
.site-filters.center [class*="btn"] {
  display: inline-block;
  margin: 0 5px 10px;
  text-transform: uppercase;
}
@media only screen and (max-width: 575px) {
  .site-filters.center [class*="btn"] {
    margin: 0 1px 5px;
    padding: 5px 10px;
    font-size: 12px;
  }
}
.site-filters.style-1 {
  margin-bottom: 40px;
}
.site-filters.style-1 ul {
  margin-right: -10px;
}
.site-filters.style-1 ul li {
  padding: 8px 15px;
  border: none;
  margin-right: 10px;
}
.site-filters.style-1 ul li a {
  color: #222222;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  margin: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.site-filters.style-1 ul li a i {
  font-size: 25px;
  margin-right: 10px;
}
.site-filters.style-1 ul li:hover,
.site-filters.style-1 ul li.active {
  background: var(--bg-light);
}
.site-filters.style-1 ul li:hover a,
.site-filters.style-1 ul li.active a {
  color: var(--primary);
}
.site-filters.style-1 ul li:hover a i,
.site-filters.style-1 ul li.active a i {
  -webkit-animation: upToDown 0.5s forwards;
  -moz-animation: upToDown 0.5s forwards;
  animation: upToDown 0.5s forwards;
}
@media only screen and (max-width: 1280px) {
  .site-filters.style-1 ul {
    margin-right: -5px;
  }
  .site-filters.style-1 ul li {
    padding: 8px;
    margin-right: 5px;
  }
  .site-filters.style-1 ul li a {
    font-size: 13px;
  }
  .site-filters.style-1 ul li a i {
    margin-right: 5px;
  }
}
@media only screen and (max-width: 991px) {
  .site-filters.style-1 {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .site-filters.style-1 {
    text-align: center;
  }
}
.site-filters.style-2 {
  margin-bottom: 20px;
}
.site-filters.style-2 ul li {
  text-align: center;
  border: none;
  padding: 0px 30px;
}
.site-filters.style-2 ul li a {
  color: #222222;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  margin: 0;
}
.site-filters.style-2 ul li a span {
  display: block;
  margin-bottom: 10px;
}
.site-filters.style-2 ul li a span i {
  font-size: 40px;
}
.site-filters.style-2 ul li:hover a,
.site-filters.style-2 ul li.active a {
  color: var(--primary);
}
.site-filters.style-2 ul li:hover a i,
.site-filters.style-2 ul li.active a i {
  animation: shake 1s;
}
@media only screen and (max-width: 991px) {
  .site-filters.style-2 ul li {
    padding: 0px 20px;
  }
}
@media only screen and (max-width: 767px) {
  .site-filters.style-2 ul li {
    margin-bottom: 20px;
    padding: 0px 10px;
  }
  .site-filters.style-2 ul li a {
    font-size: 14px;
  }
  .site-filters.style-2 ul li a span i {
    font-size: 30px;
  }
}

@-webkit-keyframes upToDown {
  49% {
    -webkit-transform: translateY(100%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }
  51% {
    opacity: 1;
  }
}

@-moz-keyframes upToDown {
  49% {
    -moz-transform: translateY(100%);
  }
  50% {
    opacity: 0;
    -moz-transform: translateY(-100%);
  }
  51% {
    opacity: 1;
  }
}

@keyframes upToDown {
  49% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  51% {
    opacity: 1;
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.dz-img-overlay1,
.dz-img-overlay2,
.dz-img-overlay3,
.dz-img-overlay4,
.dz-img-overlay5,
.dz-img-overlay6,
.dz-img-overlay7,
.dz-img-overlay8,
.dz-img-overlay9,
.dz-img-overlay11,
.dz-img-overlay12 {
  position: relative;
  overflow: hidden;
}
.dz-img-overlay1::before,
.dz-img-overlay1::after,
.dz-img-overlay2::before,
.dz-img-overlay2::after,
.dz-img-overlay3::before,
.dz-img-overlay3::after,
.dz-img-overlay4::before,
.dz-img-overlay4::after,
.dz-img-overlay5::before,
.dz-img-overlay5::after,
.dz-img-overlay6::before,
.dz-img-overlay6::after,
.dz-img-overlay7::before,
.dz-img-overlay7::after,
.dz-img-overlay8::before,
.dz-img-overlay8::after,
.dz-img-overlay9::before,
.dz-img-overlay9::after,
.dz-img-overlay11::before,
.dz-img-overlay11::after,
.dz-img-overlay12::before,
.dz-img-overlay12::after {
  content: "";
  background: #282d32;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0.4;
  z-index: 1;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-img-overlay1.overlay-primary,
.dz-img-overlay2.overlay-primary,
.dz-img-overlay3.overlay-primary,
.dz-img-overlay4.overlay-primary,
.dz-img-overlay5.overlay-primary,
.dz-img-overlay6.overlay-primary,
.dz-img-overlay7.overlay-primary,
.dz-img-overlay8.overlay-primary,
.dz-img-overlay9.overlay-primary,
.dz-img-overlay11.overlay-primary,
.dz-img-overlay12.overlay-primary {
  background: var(--primary);
}

.dz-box:hover .dz-img-overlay1::before {
  opacity: 0.7;
}

.dz-box:hover .dz-img-overlay2::before {
  opacity: 0.9;
}

.dz-box:hover .dz-img-overlay3::before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.dz-box:hover .dz-img-overlay4::before {
  width: 100%;
  left: 0;
}

.dz-box:hover .dz-img-overlay5::before {
  height: 100%;
  top: 0;
}

.dz-box:hover .dz-img-overlay6::before {
  height: 100%;
}

.dz-box:hover .dz-img-overlay7::before,
.dz-box:hover .dz-img-overlay7::after {
  width: 50%;
}

.dz-box:hover .dz-img-overlay8::before,
.dz-box:hover .dz-img-overlay8::after {
  width: 100%;
  opacity: 0.3;
}

.dz-box:hover .dz-img-overlay9::before,
.dz-box:hover .dz-img-overlay9::after {
  width: 100%;
  height: 100%;
  opacity: 0.3;
}

.dz-img-overlay1::before {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dz-img-overlay1:hover::before {
  opacity: 0.5;
}

.dz-img-overlay2::before {
  width: 100%;
  height: 100%;
  opacity: 0;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
}
.dz-img-overlay2::before:hover::before {
  opacity: 0.9;
}

.dz-img-overlay3::before {
  left: 50%;
  top: 50%;
}

.dz-img-overlay3:hover::before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.dz-img-overlay4::before {
  left: 50%;
  top: 0;
  height: 100%;
}

.dz-img-overlay4:hover::before {
  width: 100%;
  left: 0;
}

.dz-img-overlay5::before {
  left: 0;
  top: 50%;
  width: 100%;
}

.dz-img-overlay5:hover::before {
  height: 100%;
  top: 0;
}

.dz-img-overlay6::before {
  left: 0;
  top: auto;
  bottom: 0;
  width: 100%;
}

.dz-img-overlay6:hover::before {
  height: 100%;
}

.dz-img-overlay7::before,
.dz-img-overlay7::after {
  height: 100%;
}

.dz-img-overlay7::after {
  left: auto;
  right: 0;
}

.dz-img-overlay7:hover::before,
.dz-img-overlay7:hover::after {
  width: 50%;
}

.dz-img-overlay8::before,
.dz-img-overlay8::after {
  height: 100%;
}

.dz-img-overlay8::after {
  left: auto;
  right: 0;
}

.dz-img-overlay8:hover::before,
.dz-img-overlay8:hover::after {
  width: 100%;
  opacity: 0.3;
}

.dz-img-overlay9::before::before,
.dz-img-overlay9::before::after,
.dz-img-overlay9::after::before,
.dz-img-overlay9::after::after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
}

.dz-img-overlay9::before:hover::before,
.dz-img-overlay9::before:hover::after,
.dz-img-overlay9::after:hover::before,
.dz-img-overlay9::after:hover::after {
  width: 100%;
  height: 100%;
  opacity: 0.3;
}

/*  */
.overlay-black-light,
.overlay-black-middle,
.overlay-black-dark,
.overlay-gradient-light,
.overlay-gradient-middle,
.overlay-gradient-dark,
.overlay-white-light,
.overlay-white-middle,
.overlay-white-dark,
.overlay-primary-light,
.overlay-primary-middle,
.overlay-primary-dark,
.overlay-primary {
  position: relative;
}
.overlay-black-light::before,
.overlay-black-middle::before,
.overlay-black-dark::before,
.overlay-gradient-light::before,
.overlay-gradient-middle::before,
.overlay-gradient-dark::before,
.overlay-white-light::before,
.overlay-white-middle::before,
.overlay-white-dark::before,
.overlay-primary-light::before,
.overlay-primary-middle::before,
.overlay-primary-dark::before,
.overlay-primary::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.overlay-black-light .container,
.overlay-black-light .container-fluid,
.overlay-black-light .container-sm,
.overlay-black-light .container-md,
.overlay-black-light .container-lg,
.overlay-black-light .container-xl,
.overlay-black-middle .container,
.overlay-black-middle .container-fluid,
.overlay-black-middle .container-sm,
.overlay-black-middle .container-md,
.overlay-black-middle .container-lg,
.overlay-black-middle .container-xl,
.overlay-black-dark .container,
.overlay-black-dark .container-fluid,
.overlay-black-dark .container-sm,
.overlay-black-dark .container-md,
.overlay-black-dark .container-lg,
.overlay-black-dark .container-xl,
.overlay-gradient-light .container,
.overlay-gradient-light .container-fluid,
.overlay-gradient-light .container-sm,
.overlay-gradient-light .container-md,
.overlay-gradient-light .container-lg,
.overlay-gradient-light .container-xl,
.overlay-gradient-middle .container,
.overlay-gradient-middle .container-fluid,
.overlay-gradient-middle .container-sm,
.overlay-gradient-middle .container-md,
.overlay-gradient-middle .container-lg,
.overlay-gradient-middle .container-xl,
.overlay-gradient-dark .container,
.overlay-gradient-dark .container-fluid,
.overlay-gradient-dark .container-sm,
.overlay-gradient-dark .container-md,
.overlay-gradient-dark .container-lg,
.overlay-gradient-dark .container-xl,
.overlay-white-light .container,
.overlay-white-light .container-fluid,
.overlay-white-light .container-sm,
.overlay-white-light .container-md,
.overlay-white-light .container-lg,
.overlay-white-light .container-xl,
.overlay-white-middle .container,
.overlay-white-middle .container-fluid,
.overlay-white-middle .container-sm,
.overlay-white-middle .container-md,
.overlay-white-middle .container-lg,
.overlay-white-middle .container-xl,
.overlay-white-dark .container,
.overlay-white-dark .container-fluid,
.overlay-white-dark .container-sm,
.overlay-white-dark .container-md,
.overlay-white-dark .container-lg,
.overlay-white-dark .container-xl,
.overlay-primary-light .container,
.overlay-primary-light .container-fluid,
.overlay-primary-light .container-sm,
.overlay-primary-light .container-md,
.overlay-primary-light .container-lg,
.overlay-primary-light .container-xl,
.overlay-primary-middle .container,
.overlay-primary-middle .container-fluid,
.overlay-primary-middle .container-sm,
.overlay-primary-middle .container-md,
.overlay-primary-middle .container-lg,
.overlay-primary-middle .container-xl,
.overlay-primary-dark .container,
.overlay-primary-dark .container-fluid,
.overlay-primary-dark .container-sm,
.overlay-primary-dark .container-md,
.overlay-primary-dark .container-lg,
.overlay-primary-dark .container-xl,
.overlay-primary .container,
.overlay-primary .container-fluid,
.overlay-primary .container-sm,
.overlay-primary .container-md,
.overlay-primary .container-lg,
.overlay-primary .container-xl {
  position: relative;
  z-index: 1;
}

.overlay-black-light::before,
.overlay-black-middle::before,
.overlay-black-dark::before {
  background: #050d36;
}

.overlay-gradient-light::before,
.overlay-gradient-middle::before,
.overlay-gradient-dark::before {
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  );
}

.overlay-white-light::before,
.overlay-white-middle::before,
.overlay-white-dark::before {
  background: #fff;
}

.overlay-primary-light::before,
.overlay-primary-middle::before,
.overlay-primary-dark::before,
.overlay-primary::before {
  background: var(--primary);
}

.overlay-primary-light::before,
.overlay-white-light::before,
.overlay-gradient-light::before,
.overlay-black-light::before {
  opacity: 0.2;
}

.overlay-primary-middle::before,
.overlay-white-middle::before,
.overlay-gradient-middle::before,
.overlay-black-middle::before {
  opacity: 0.5;
}

.overlay-primary-dark::before,
.overlay-white-dark::before,
.overlay-gradient-dark::before,
.overlay-black-dark::before {
  opacity: 0.9;
}

.overlay-bx {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.overlay-bx .align-b {
  height: auto;
  left: 0;
  list-style: outside none none;
  margin: 0;
  position: absolute;
  top: auto;
  bottom: 0;
  width: 100%;
  transform: translate(0);
  -o-transform: translate(0);
  -moz-transform: translate(0);
  -webkit-transform: translate(0);
}
.overlay-bx .align-m {
  height: auto;
  left: 50%;
  list-style: outside none none;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 10px;
}

.overlay-icon {
  height: auto;
  left: 50%;
  list-style: outside none none;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 10px;
}
.overlay-icon a {
  display: inline-block;
  padding: 0;
  margin: 0 2px;
  background-color: #fff;
}
.overlay-icon a i {
  font-size: 18px;
}

.overlay-bx:hover a > i,
.dz-media:hover .overlay-bx a > i,
.dz-box-bx:hover .overlay-bx a > i {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.overlay-bx:hover,
.dz-media:hover .overlay-bx,
.dz-box:hover .overlay-bx,
.ow-img:hover .overlay-bx,
.ow-portfolio-img:hover .overlay-bx {
  opacity: 1;
  visibility: visible;
}

.gallery-box-1 {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}
.gallery-box-1::after {
  content: "";
  position: absolute;
  z-index: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.gallery-box-1 span {
  position: absolute;
  bottom: 5px;
  left: 12px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .gallery-box-1 span {
    font-size: 16px;
    left: 8px;
  }
}

.overlay-shine .dz-media {
  position: relative;
  overflow: hidden;
}
.overlay-shine .dz-media::before {
  position: absolute;
  top: 0;
  left: -85%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.overlay-shine .dz-media img {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.overlay-shine:hover .dz-media::before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.dz-img-effect {
  position: relative;
  overflow: hidden;
  display: block;
  vertical-align: middle;
}
.dz-img-effect img {
  display: block;
  margin: 0;
  width: 100%;
  height: auto;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.dz-img-effect.opacity img:hover {
  opacity: 0.8;
}
.dz-img-effect.zoom-slow img {
  transition: all 10s;
  -moz-transition: all 10s;
  -webkit-transition: all 10s;
  -o-transition: all 10s;
}
.dz-img-effect.zoom-slow:hover img {
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}
.dz-img-effect.zoom:hover img {
  transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}
.dz-img-effect.shrink:hover img {
  transform: scale(0.8);
  -moz-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
}
.dz-img-effect.side-pan:hover img {
  margin-left: -11%;
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}
.dz-img-effect.vertical-pan:hover img {
  margin-top: -10%;
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}
.dz-img-effect.fade-in {
  background: #000;
}
.dz-img-effect.fade-in img {
  opacity: 0.65;
}
.dz-img-effect.fade-in:hover img {
  opacity: 1;
}
.dz-img-effect.fade-out {
  background: #000;
}
.dz-img-effect.fade-out:hover img {
  opacity: 0.7;
}
.dz-img-effect.rotate:hover img {
  -moz-transform: scale(1.5) rotate(-20deg);
  -webkit-transform: scale(1.5) rotate(-20deg);
  -o-transform: scale(1.5) rotate(-20deg);
  -ms-transform: scale(1.5) rotate(-20deg);
  transform: scale(1.5) rotate(-20deg);
}
.dz-img-effect.image-sepia img {
  -webkit-filter: sepia(100%);
  filter: sepia(100%);
}
.dz-img-effect.blurr img {
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.dz-img-effect.blurr:hover img {
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
}
.dz-img-effect.blurr-invert img {
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
}
.dz-img-effect.blurr-invert:hover img {
  filter: blur(0px);
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
}
.dz-img-effect.off-color img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -o-filter: grayscale(0);
  -ms-filter: grayscale(0);
}
.dz-img-effect.off-color:hover img {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  -ms-filter: grayscale(1);
}
.dz-img-effect.on-color img {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  -ms-filter: grayscale(1);
}
.dz-img-effect.on-color:hover img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -o-filter: grayscale(0);
  -ms-filter: grayscale(0);
}

.dz-box:hover .dz-img-effect.rotate img {
  -moz-transform: scale(1.5) rotate(-20deg);
  -webkit-transform: scale(1.5) rotate(-20deg);
  -o-transform: scale(1.5) rotate(-20deg);
  -ms-transform: scale(1.5) rotate(-20deg);
  transform: scale(1.5) rotate(-20deg);
}

.dz-accordion {
  margin-bottom: 60px;
}
@media only screen and (max-width: 991px) {
  .dz-accordion {
    margin-bottom: 30px;
  }
}
.dz-accordion .accordion-item {
  background: #fff;
  border: 1px solid #b1b1b1;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  border-radius: 0;
}
.dz-accordion:last-child {
  border-bottom: 1px solid #b1b1b1;
}
.dz-accordion .accordion-body {
  padding: 25px 0px;
  border-radius: 0;
  margin-top: -9px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-accordion .accordion-body p {
  font-size: 15px;
  max-width: 515px;
  line-height: 25px;
  letter-spacing: 0.02em;
}
@media only screen and (max-width: 991px) {
  .dz-accordion .accordion-body {
    padding: 20px 0;
  }
  .dz-accordion .accordion-body p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-accordion .accordion-body p {
    font-size: 13px;
    line-height: 20px;
  }
}
.dz-accordion .accordion-collapse {
  border: 0;
  border: 0;
  border-radius: 0 !important;
}
.dz-accordion .accordion-header {
  background-color: #fff;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-accordion .accordion-header .accordion-button {
  font-size: 18px;
  border: 0;
  font-weight: 400;
  padding: 28px 30px 28px 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  color: black;
}
.dz-accordion .accordion-header .accordion-button::after {
  content: none;
}
.dz-accordion .accordion-header .accordion-button .toggle-close {
  background-color: transparent;
  height: 28px;
  width: 28px;
  display: flex;
  border: 2px solid #000;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  line-height: 28px;
  text-align: center;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 1;
  overflow: hidden;
}
.dz-accordion .accordion-header .accordion-button .toggle-close::before {
  content: "\2b";
  color: var(--title);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  font-size: 18px;
  font-family: "Font Awesome 5 Free";
  z-index: 99;
  opacity: 1;
  transition: all 0.5s;
  display: block;
  font-weight: 900;
}
.dz-accordion .accordion-header .accordion-button.collapsed {
  background: transparent;
  color: inherit;
}
.dz-accordion .accordion-header .accordion-button.collapsed::after {
  opacity: 0;
}
.dz-accordion .accordion-header .accordion-button.collapsed .toggle-close {
  content: "";
}
.dz-accordion
  .accordion-header
  .accordion-button.collapsed
  .toggle-close::after {
  opacity: 1;
}
.dz-accordion
  .accordion-header
  .accordion-button.collapsed
  .toggle-close::before {
  opacity: 1;
}
.dz-accordion .accordion-header .accordion-button:not(.collapsed) {
  color: var(--title);
  background-color: #fff;
  box-shadow: none;
  padding: 28px 30px 0px 0;
}
.dz-accordion
  .accordion-header
  .accordion-button:not(.collapsed)
  .toggle-close::before {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  content: "\f068";
}
@media only screen and (max-width: 991px) {
  .dz-accordion .accordion-header .accordion-button {
    padding: 20px 25px 20px 0;
    font-size: 16px;
  }
  .dz-accordion .accordion-header .accordion-button:not(.collapsed) {
    padding: 20px 25px 0px 0;
  }
  .dz-accordion .accordion-header .accordion-button .toggle-close {
    width: 22px;
    height: 22px;
    line-height: 22px;
  }
  .dz-accordion .accordion-header .accordion-button .toggle-close::before {
    font-size: 16px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-accordion .accordion-header .accordion-button {
    padding: 20px 30px 20px 0;
    font-size: 14px;
  }
  .dz-accordion .accordion-header .accordion-button:not(.collapsed) {
    padding: 20px 30px 0px 0;
  }
}

.widget.dz-widget_services .form-check {
  margin-bottom: 15px;
}
.widget.dz-widget_services .form-check .form-check-input {
  border: 1px solid rgba(34, 34, 34, 0.3);
  border-radius: 4px;
  width: 20px;
  height: 20px;
}
.widget.dz-widget_services .form-check .form-check-input:checked {
  background-color: var(--primary);
  border: 1px solid var(--primary);
}
.widget.dz-widget_services .form-check .form-check-label {
  font-family: var(--font-family-title);
  margin-left: 10px;
  font-size: 15px;
}

@media only screen and (max-width: 991px) {
  .faq-media {
    width: 450px;
    height: 450px;
    margin: auto;
  }
}

@media only screen and (max-width: 767px) {
  .faq-media {
    width: 300px;
    height: 300px;
  }
}

.faq-info .title {
  font-size: 50px;
}

.faq-info input {
  background: #f8f8f8;
  border: 1px solid #b9b9b9;
}

@media only screen and (max-width: 991px) {
  .faq-info .title {
    font-size: 35px;
  }
}

.pagination {
  justify-content: center;
}
.pagination .page-item {
  margin: 0 8px;
}
.pagination .page-item .page-link {
  height: 45px;
  min-width: 45px;
  line-height: 45px;
  padding: 0;
  border: 0;
  text-align: center;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
}
.pagination .page-item .page-link.current,
.pagination .page-item .page-link.active,
.pagination .page-item .page-link:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}
.pagination.pagination-md .page-item .page-link {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
}
.pagination.pagination-sm .page-item .page-link {
  padding: 0;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  text-align: center;
}
.pagination.pagination-xs .page-item .page-link {
  padding: 0;
  min-width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 12px;
  text-align: center;
}
.pagination-primary .page-item .page-link {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: #222222;
}
.pagination-primary .page-item .page-link:hover,
.pagination-primary .page-item .page-link.active {
  color: #fff;
  background: var(--primary);
  border-color: var(--primary);
  box-shadow: 0 0.625rem 1.25rem 0rem rgba(255, 193, 180, 0.3);
}
.pagination-danger .page-item .page-link {
  background: rgba(255, 0, 3, 0.1);
  border-color: rgba(255, 0, 3, 0.1);
  color: #ff0003;
}
.pagination-danger .page-item .page-link:hover,
.pagination-danger .page-item .page-link.active {
  background: #ff0003;
  border-color: #ff0003;
  color: #fff;
  box-shadow: 0 0.625rem 1.25rem 0rem rgba(255, 0, 3, 0.2);
}
.pagination-info .page-item .page-link {
  background: rgba(0, 174, 255, 0.1);
  border-color: rgba(0, 174, 255, 0.1);
  color: #00aeff;
}
.pagination-info .page-item .page-link:hover,
.pagination-info .page-item .page-link.active {
  background: #00aeff;
  border-color: #00aeff;
  color: #fff;
  box-shadow: 0 0.625rem 1.25rem 0rem rgba(0, 174, 255, 0.2);
}
.pagination.style-1 {
  display: flex;
  align-items: center;
}
.pagination.style-1 .main-button-next {
  top: auto !important;
  bottom: 30%;
}
.pagination.style-1 .main-button-next,
.pagination.style-1 .main-button-prev {
  position: absolute;
  top: 30%;
  align-items: center;
  color: var(--primary);
  z-index: 2;
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  left: 105px;
  font-size: 30px;
  margin: 10px 0;
}
.pagination.style-1 .main-button-next i,
.pagination.style-1 .main-button-prev i {
  font-family: "feather";
  font-style: normal;
}
@media only screen and (max-width: 1480px) {
  .pagination.style-1 .main-button-next,
  .pagination.style-1 .main-button-prev {
    left: 23px;
  }
}
@media only screen and (max-width: 1320px) {
  .pagination.style-1 .main-button-next,
  .pagination.style-1 .main-button-prev {
    left: 10px;
  }
}
@media only screen and (max-width: 1280px) {
  .pagination.style-1 .main-button-next,
  .pagination.style-1 .main-button-prev {
    display: none;
  }
}
.pagination.style-1.left-align .main-button-next,
.pagination.style-1.left-align .main-button-prev {
  left: 10px;
  top: 30%;
}
.pagination.style-1.left-align .main-button-next {
  bottom: 30%;
}

.list-circle li,
.list-angle-double li,
.list-square li,
.list-square-check li {
  position: relative;
  padding: 6px 5px 6px 22px;
}
.list-circle li::before,
.list-angle-double li::before,
.list-square li::before,
.list-square-check li::before {
  position: absolute;
  left: 0;
  top: 7px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.list-circle.primary li::before,
.list-angle-double.primary li::before,
.list-square.primary li::before,
.list-square-check.primary li::before {
  color: var(--primary);
}

.list-check li {
  position: relative;
  padding: 6px 5px 6px 30px;
}
.list-check li::before {
  font-size: 20px;
  content: "\e83f";
  font-family: "feather" !important;
  font-style: normal;
  position: absolute;
  left: 0;
  top: 5px;
}

.list-check.primary li::before {
  color: var(--primary);
}

.avatar-list.avatar-list-stacked .avatar {
  margin-right: -17px;
  display: inline-block;
}
.avatar-list.avatar-list-stacked .avatar:last-child {
  margin-right: 0;
}
.avatar-list.avatar-list-stacked .avatar span {
  background-color: var(--primary);
  color: #fff;
  height: 2.375rem;
  line-height: 2.375rem;
  width: 2.375rem;
  border-radius: 100%;
  font-size: 12px;
  display: block;
  text-align: center;
}
.avatar-list.avatar-list-stacked .avatar img {
  object-fit: cover;
  border-radius: 100%;
  border: 2px solid #fff;
  height: 2.375rem;
  width: 2.375rem;
}
.avatar-list.avatar-list-stacked .avatar:hover img {
  z-index: 1;
}

/* Light Gallery */
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-sub-html,
.lg-toolbar {
  background-color: rgba(30, 30, 30, 0.6);
}

.lg-outer {
  z-index: 999999;
}
.lg-outer .lg-toogle-thumb,
.lg-outer .lg-thumb-outer,
.lg-outer .lg-img-wrap,
.lg-outer .lg-item {
  background-color: transparent;
}
.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
  border-color: var(--primary);
}

.lg-backdrop {
  z-index: 99999;
  background-color: rgba(30, 30, 30, 0.9);
}

.lg-outer .lg-toogle-thumb,
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-toolbar .lg-icon,
#lg-counter {
  color: #fff;
}

.lg-item {
  cursor: pointer;
  display: block;
}

.lg-progress-bar .lg-progress {
  background-color: var(--primary);
}

.progress-bx {
  overflow: hidden;
  position: relative;
}
.progress-bx .progress {
  overflow: unset;
}
.progress-bx .progress .progress-bar {
  position: relative;
  overflow: unset;
}
.progress-bx.style-1 .progress-label {
  font-weight: 500;
  letter-spacing: 0.02em;
  margin-bottom: 15px;
}
.progress-bx.style-1 .progress-label .pull-end {
  position: absolute;
  right: 0;
}
.progress-bx.style-1 .progress {
  height: 3px;
  background: #d0d0d0;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 30px;
  overflow: visible;
}
.progress-bx.style-1 .progress .progress-bar {
  box-shadow: none;
  -webkit-animation: myanimation 2s;
  animation: myanimation 2s;
}
.progress-bx.style-1 .progress.primary .progress-bar {
  background: var(--primary);
}

.scroltop {
  box-shadow: -4px 4px 24px -10px var(--primary) !important;
  background: var(--primary);
  border-color: var(--primary);
  border-style: solid;
  border-width: 0;
  bottom: 15px;
  color: #fff;
  cursor: pointer;
  display: none;
  height: 50px;
  line-height: 50px;
  margin: 0;
  position: fixed;
  right: 15px;
  text-align: center;
  width: 50px;
  z-index: 999;
  border-radius: 6px;
  box-shadow: -4px 4px 24px -10px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
@media only screen and (max-width: 575px) {
  .scroltop {
    height: 35px;
    width: 35px;
    line-height: 35px;
  }
}

.scroltop-progress {
  position: fixed;
  cursor: pointer;
  bottom: 15px;
  right: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: block;
  opacity: 0;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
  z-index: 999;
  visibility: hidden;
  transform: translateY(20px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.scroltop-progress::after {
  content: "\f062";
  font-size: 18px;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  position: absolute;
  left: 0;
  top: 0;
  height: 50px;
  cursor: pointer;
  width: 50px;
  line-height: 50px;
  color: var(--dark);
  text-align: center;
  transition: all 200ms linear;
}
.scroltop-progress svg path {
  fill: none;
  stroke-width: 4;
  box-sizing: border-box;
  stroke: var(--dark);
  transition: all 200ms linear;
}
.scroltop-progress.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.scroltop-progress.scroltop-primary::after {
  color: var(--primary);
}
.scroltop-progress.scroltop-primary svg path {
  stroke: var(--primary);
}
.scroltop-progress.scroltop-secondary::after {
  color: var(--secondary);
}
.scroltop-progress.scroltop-secondary svg path {
  stroke: var(--secondary);
}
.scroltop-progress:hover::after {
  color: var(--primary);
}
.scroltop-progress:hover svg path {
  stroke: var(--primary);
}

.video {
  z-index: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 87px;
  height: 87px;
  line-height: 87px;
  background-color: var(--primary);
  color: #fff;
  border-radius: 50%;
  text-align: center;
  font-size: 24px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.video:hover {
  transform: translate(-50%, -50%) scale(1.2);
}
.video i {
  color: #fff;
}
@media only screen and (max-width: 1280px) {
  .video {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .video {
    width: 55px;
    height: 55px;
    line-height: 55px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 575px) {
  .video svg {
    width: 50px;
    height: 50px;
  }
}
.video.style-1 {
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 18px;
}

@keyframes animationSignal1 {
  /*Video Popup*/
  0% {
    opacity: 0.8;
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    -webkit-transform: scale(0.9);
  }
  100% {
    transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    opacity: 0;
  }
}

@-webkit-keyframes animationSignal1 {
  /*Video Popup*/
  0% {
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    -webkit-transform: scale(0.9);
  }
  100% {
    transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes animationSignal2 {
  /*Video Popup*/
  0% {
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
  }
  100% {
    transform: scale(1.9);
    -moz-transform: scale(1.9);
    -webkit-transform: scale(1.9);
    -ms-transform: scale(1.9);
    -o-transform: scale(1.9);
    opacity: 0;
  }
}

@-webkit-keyframes animationSignal2 {
  /*Video Popup*/
  0% {
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
  }
  100% {
    transform: scale(1.9);
    -moz-transform: scale(1.9);
    -webkit-transform: scale(1.9);
    -ms-transform: scale(1.9);
    -o-transform: scale(1.9);
    opacity: 0;
  }
}

.swiper-pagination {
  position: relative;
  width: auto;
  display: flex;
  align-items: center;
}
.swiper-pagination.style-1 {
  display: inline-block;
  position: absolute;
  bottom: 10px;
}
.swiper-pagination.style-1 .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  text-align: center;
  font-size: 12px;
  opacity: 1;
  border-radius: 30px;
  background: #fff;
  border: 1px solid var(--primary);
}
.swiper-pagination.style-1 .swiper-pagination-bullet-active {
  background: var(--primary);
}

.swiper-button-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.num-pagination {
  display: flex;
  align-items: center;
}

.btn-next,
.btn-prev {
  position: static;
  width: 48px;
  height: 48px;
  line-height: 48px;
  border-radius: 0px;
  color: #fff;
  background: var(--primary);
  font-size: 15px;
  z-index: 1;
  text-align: center;
  margin: 0 10px;
  display: inline-block;
  box-shadow: 0px 10px 30px 0px var(--rgba-primary-3);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 10px;
}
.btn-next:hover,
.btn-prev:hover {
  background: #222222;
  color: #fff;
  box-shadow: 0px 10px 30px 0px rgba(34, 34, 34, 0.2);
}
.btn-next::after,
.btn-prev::after {
  content: none;
}
.btn-next.dark:hover,
.btn-prev.dark:hover {
  background: #fff;
  color: #000;
  box-shadow: 0px 10px 30px 0px rgba(34, 34, 34, 0.2);
}
.btn-next i,
.btn-prev i {
  font-size: 20px;
  line-height: inherit;
}
@media only screen and (max-width: 1199px) {
  .btn-next,
  .btn-prev {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
  .btn-next i,
  .btn-prev i {
    font-size: 16px;
  }
}
@media only screen and (max-width: 575px) {
  .btn-next,
  .btn-prev {
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin: 0 2px 2px;
  }
  .btn-next i,
  .btn-prev i {
    font-size: 15px;
  }
}

.swiper-visible {
  overflow: unset;
}
.swiper-visible .swiper-slide {
  opacity: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.swiper-visible .swiper-slide-active,
.swiper-visible:not(.swiper-single) .swiper-slide-next,
.swiper-visible:not(.swiper-single)
  .swiper-slide-visible:not(.swiper-slide-duplicate-prev) {
  opacity: 1;
}
.swiper-visible.swiper-item-4 .swiper-slide-next + .swiper-slide,
.swiper-visible.swiper-item-4
  .swiper-slide-next
  + .swiper-slide
  + .swiper-slide {
  opacity: 1;
}

.menu-align {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-prev-long,
.btn-next-long {
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.btn-prev-long i,
.btn-next-long i {
  color: #fff;
  font-size: 16px;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.btn-prev-long::before,
.btn-prev-long::after,
.btn-next-long::before,
.btn-next-long::after {
  content: "";
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.btn-prev-long::before,
.btn-next-long::before {
  width: 30px;
  border-radius: 50px;
  height: 30px;
  line-height: 30px;
  background-color: var(--primary);
}
.btn-prev-long::after,
.btn-next-long::after {
  width: 40px;
  height: 2px;
  background-color: #000;
}
.btn-prev-long:hover:not(.swiper-button-disabled) i,
.btn-next-long:hover:not(.swiper-button-disabled) i {
  color: var(--primary);
}
.btn-prev-long:hover:not(.swiper-button-disabled)::after,
.btn-next-long:hover:not(.swiper-button-disabled)::after {
  z-index: 1;
  width: 10px;
  background-color: var(--primary);
}
.btn-prev-long:hover:not(.swiper-button-disabled)::before,
.btn-next-long:hover:not(.swiper-button-disabled)::before {
  border: 1.5px dashed var(--primary);
  background-color: transparent;
  height: 50px;
  width: 50px;
  line-height: 50px;
  animation: dzRotate360 10s linear infinite;
}

.btn-prev-long::before {
  left: -14px;
}

.btn-prev-long::after {
  left: 10px;
  z-index: -2;
}

.btn-prev-long:hover:not(.swiper-button-disabled) i {
  margin-left: 4px;
}

.btn-next-long::before {
  right: -14px;
  left: auto;
}

.btn-next-long::after {
  right: 10px;
  left: auto;
  z-index: -2;
}

.btn-next-long:hover:not(.swiper-button-disabled) i {
  margin-right: 4px;
}

.swiper-btn-lr {
  position: relative;
}
.swiper-btn-lr .btn-prev-long,
.swiper-btn-lr .btn-next-long,
.swiper-btn-lr .btn-prev,
.swiper-btn-lr .btn-next {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  right: -100px;
}
.swiper-btn-lr .btn-prev-long,
.swiper-btn-lr .btn-prev {
  left: -100px;
  right: auto;
}
@media only screen and (max-width: 1480px) {
  .swiper-btn-lr .btn-prev-long,
  .swiper-btn-lr .btn-prev {
    left: -60px;
  }
  .swiper-btn-lr .btn-next-long,
  .swiper-btn-lr .btn-next {
    right: -60px;
  }
}
@media only screen and (max-width: 1280px) {
  .swiper-btn-lr .btn-prev-long,
  .swiper-btn-lr .btn-next-long {
    position: static;
    transform: translate(0);
    margin: -10px 50px 0;
  }
}
.swiper-btn-lr .btn-prev-1,
.swiper-btn-lr .btn-next-1 {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  right: 0;
  background-color: rgba(34, 34, 34, 0.1);
  border-radius: var(--border-radius-base);
  height: 80px;
  width: 27px;
  line-height: 80px;
  text-align: center;
  color: #222222;
  font-size: 14px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.swiper-btn-lr .btn-prev-1:hover,
.swiper-btn-lr .btn-next-1:hover {
  background-color: var(--primary);
  color: #fff;
}
.swiper-btn-lr .btn-prev-1 {
  left: 0;
  right: auto;
}

.widget {
  margin-bottom: 50px;
}
@media only screen and (max-width: 767px) {
  .widget {
    margin-bottom: 30px;
  }
}

.widget-title,
.comments-title,
.comment-reply-title {
  margin-bottom: 25px;
  position: relative;
  font-size: 1.25rem;
  padding-bottom: 12px;
}
@media only screen and (max-width: 1199px) {
  .widget-title,
  .comments-title,
  .comment-reply-title {
    margin-bottom: 20px;
  }
}
.widget-title:after,
.widget-title:before,
.comments-title:after,
.comments-title:before,
.comment-reply-title:after,
.comment-reply-title:before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 6px;
  border-radius: 3px;
}
.widget-title:after,
.comments-title:after,
.comment-reply-title:after {
  left: 12px;
  width: 53px;
  height: 4px;
  background: var(--primary);
}
.widget-title:before,
.comments-title:before,
.comment-reply-title:before {
  left: 0;
  width: 6px;
  border-radius: 30px;
  background: var(--secondary);
}
.widget-title .title,
.comments-title .title,
.comment-reply-title .title {
  position: relative;
  margin-bottom: 0px;
  font-size: 1.25rem;
}

.comments-title,
.comment-reply-title {
  margin-bottom: 30px;
}

.comment-reply-para {
  margin-bottom: 25px;
  font-size: 14px;
}
@media only screen and (max-width: 767px) {
  .comment-reply-para {
    margin-bottom: 30px;
  }
}

.search-bx form {
  margin-bottom: 10px;
}

.search-bx .form-control,
.search-bx .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .search-bx select,
.search-bx .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .search-bx select {
  background-color: #fff;
  border: 0;
  border-radius: 6px;
  z-index: 0 !important;
  height: 50px;
  padding: 6px 0px 6px 20px;
}

.search-bx .input-side {
  background-color: var(--primary);
  border-radius: 6px;
  display: flex;
  margin: 0;
  padding: 5px;
  align-items: center;
  width: 100%;
  height: 60px;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.search-bx .input-group-btn .btn,
.search-bx .input-group-btn .wp-block-button__link {
  margin-left: 5px;
  height: 50px;
  width: 50px;
  padding: 0;
  justify-content: center;
  min-width: 50px;
}
.search-bx .input-group-btn .btn i,
.search-bx .input-group-btn .wp-block-button__link i {
  font-size: 21px;
}
.search-bx .input-group-btn .btn:active,
.search-bx .input-group-btn .wp-block-button__link:active {
  background-color: transparent;
  border-color: transparent;
}
.search-bx .input-group-btn .btn:hover,
.search-bx .input-group-btn .wp-block-button__link:hover {
  background-color: var(--primary);
  border-color: transparent;
}

.search-bx.style-1 form .input-group {
  border: 1px solid #ddd;
}

.search-bx.style-1 form .input-side {
  border-radius: 0 !important;
  background-color: transparent;
  display: flex;
  margin: 0;
}

.search-bx.style-1 form .input-group-btn {
  position: absolute;
  right: 3px;
  top: 2px;
}
.search-bx.style-1 form .input-group-btn button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: 0;
  font-size: 15px;
  height: 45px;
  width: 60px;
  border-width: 0;
}

.search-bx.style-1 form .form-control,
.search-bx.style-1 form .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .search-bx.style-1 form select,
.search-bx.style-1 form .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .search-bx.style-1 form select {
  border: 1px solid #efefef;
  border-width: 0;
  font-size: 15px;
  height: 45px;
  padding: 5px 20px;
}

.download-file {
  background-image: var(--gradient);
  color: #fff;
  padding: 25px 25px 20px 20px;
  border-radius: 4px;
}
.download-file .title {
  color: #fff;
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}
.download-file ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.download-file ul li a {
  background-color: #fff;
  color: #000;
  display: block;
  margin-bottom: 10px;
  border-radius: 4px;
  width: 100%;
  padding: 15px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.download-file ul li a i {
  width: 18px;
  height: 18px;
  position: relative;
}
.download-file ul li a i::after,
.download-file ul li a i::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
}
.download-file ul li a i::before {
  -webkit-animation: download1 2s ease infinite;
  animation: download1 2s ease infinite;
}
.download-file ul li a .text {
  display: inline;
  position: relative;
}
.download-file ul li a:hover .text {
  color: var(--primary);
}

@-webkit-keyframes download1 {
  0%,
  10%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@keyframes download1 {
  0%,
  10%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

.widget_contact {
  position: relative;
  padding: 50px 30px;
  border-radius: 6px;
  background-size: cover;
  text-align: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.widget_contact::after {
  background: var(--secondary);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  z-index: -1;
  background-size: 200%;
}
.widget_contact img {
  margin-bottom: 20px;
}
.widget_contact h4,
.widget_contact .h4,
.widget_contact .wp-block-search .wp-block-search__label,
.wp-block-search .widget_contact .wp-block-search__label {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 30px;
}
.widget_contact .phone-number {
  position: relative;
  font-size: 24px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 11px;
}
.widget_contact .phone-number a {
  color: #fff;
}
.widget_contact .email {
  position: relative;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 30px;
}
.widget_contact .email a {
  color: #fff;
}

.list-2 {
  display: table;
}
.list-2 li {
  width: 50% !important;
  float: left;
}
@media only screen and (max-width: 1200px) {
  .list-2 li {
    width: 100% !important;
  }
}
@media only screen and (max-width: 991px) {
  .list-2 li {
    width: 50% !important;
  }
}
@media only screen and (max-width: 575px) {
  .list-2 li {
    width: 100% !important;
  }
}

.wp-block-latest-posts,
.wp-block-categories-list,
.wp-block-archives-list,
.widget_categories ul,
.widget_archive ul,
.widget_meta ul,
.widget_pages ul,
.widget_recent_comments ul,
.widget_nav_menu ul,
.widget_recent_entries ul,
.widget_services ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.wp-block-latest-posts li,
.wp-block-categories-list li,
.wp-block-archives-list li,
.widget_categories ul li,
.widget_archive ul li,
.widget_meta ul li,
.widget_pages ul li,
.widget_recent_comments ul li,
.widget_nav_menu ul li,
.widget_recent_entries ul li,
.widget_services ul li {
  padding-bottom: 1.125rem;
  margin-bottom: 0.8125rem;
  position: relative;
  padding: 10px 0 10px 20px;
  margin-bottom: 0;
  line-height: 1.25rem;
}
.wp-block-latest-posts li a,
.wp-block-categories-list li a,
.wp-block-archives-list li a,
.widget_categories ul li a,
.widget_archive ul li a,
.widget_meta ul li a,
.widget_pages ul li a,
.widget_recent_comments ul li a,
.widget_nav_menu ul li a,
.widget_recent_entries ul li a,
.widget_services ul li a {
  color: inherit;
  text-transform: capitalize;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  font-size: 15px;
  font-weight: 500;
}
.wp-block-latest-posts li a::before,
.wp-block-categories-list li a::before,
.wp-block-archives-list li a::before,
.widget_categories ul li a::before,
.widget_archive ul li a::before,
.widget_meta ul li a::before,
.widget_pages ul li a::before,
.widget_recent_comments ul li a::before,
.widget_nav_menu ul li a::before,
.widget_recent_entries ul li a::before,
.widget_services ul li a::before {
  content: "\f0da";
  font-family: "Font Awesome 5 Free";
  background: transparent;
  width: auto;
  height: auto;
  position: absolute;
  left: 0;
  top: 9px;
  font-size: 1rem;
  font-weight: 700;
  color: var(--primary);
}
.wp-block-latest-posts li a:hover,
.wp-block-categories-list li a:hover,
.wp-block-archives-list li a:hover,
.widget_categories ul li a:hover,
.widget_archive ul li a:hover,
.widget_meta ul li a:hover,
.widget_pages ul li a:hover,
.widget_recent_comments ul li a:hover,
.widget_nav_menu ul li a:hover,
.widget_recent_entries ul li a:hover,
.widget_services ul li a:hover {
  color: var(--primary);
}
.wp-block-latest-posts li li,
.wp-block-categories-list li li,
.wp-block-archives-list li li,
.widget_categories ul li li,
.widget_archive ul li li,
.widget_meta ul li li,
.widget_pages ul li li,
.widget_recent_comments ul li li,
.widget_nav_menu ul li li,
.widget_recent_entries ul li li,
.widget_services ul li li {
  border-bottom: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  line-height: 1.375rem;
}
.wp-block-latest-posts li li:last-child,
.wp-block-categories-list li li:last-child,
.wp-block-archives-list li li:last-child,
.widget_categories ul li li:last-child,
.widget_archive ul li li:last-child,
.widget_meta ul li li:last-child,
.widget_pages ul li li:last-child,
.widget_recent_comments ul li li:last-child,
.widget_nav_menu ul li li:last-child,
.widget_recent_entries ul li li:last-child,
.widget_services ul li li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.wp-block-latest-posts li li::before,
.wp-block-categories-list li li::before,
.wp-block-archives-list li li::before,
.widget_categories ul li li::before,
.widget_archive ul li li::before,
.widget_meta ul li li::before,
.widget_pages ul li li::before,
.widget_recent_comments ul li li::before,
.widget_nav_menu ul li li::before,
.widget_recent_entries ul li li::before,
.widget_services ul li li::before {
  top: 0.5rem;
  left: 0;
}
.wp-block-latest-posts ul,
.wp-block-categories-list ul,
.wp-block-archives-list ul,
.widget_categories ul ul,
.widget_archive ul ul,
.widget_meta ul ul,
.widget_pages ul ul,
.widget_recent_comments ul ul,
.widget_nav_menu ul ul,
.widget_recent_entries ul ul,
.widget_services ul ul {
  padding-left: 1.125rem;
  margin-top: 0.5rem;
  margin-left: -0.9375rem;
}

.widget_archive li ul,
.wp-block-categories-list li ul,
.wp-block-archives-list li ul,
.wp-block-latest-posts li ul,
.widget_categories li ul {
  margin-bottom: -0.625rem;
}

.widget_archive ul li,
.wp-block-categories-list li,
.wp-block-archives-list li,
.wp-block-latest-posts li,
.widget_categories ul li {
  text-align: right;
  display: table;
  width: 100%;
  padding: 10px 0 10px 20px !important;
  line-height: 1.3 !important;
}

.wp-block-categories-list li::before,
.wp-block-archives-list li::before,
.wp-block-latest-posts li::before {
  content: none !important;
}

.widget_archive li a,
.wp-block-latest-posts li a,
.wp-block-categories-list li a,
.wp-block-archives-list li a,
.widget_categories li a {
  float: left;
  text-transform: capitalize;
  text-align: left;
}

.wp-block-latest-posts,
.wp-block-categories-list,
.wp-block-archives-list {
  padding: 0 !important;
}

.widget_recent_comments ul {
  margin-top: -0.625rem;
}
.widget_recent_comments ul li {
  padding-left: 1.875rem;
  line-height: 1.625rem;
}
.widget_recent_comments ul li a:hover {
  color: inherit;
}
.widget_recent_comments ul li a::before {
  content: none;
}
.widget_recent_comments ul li::before {
  content: "\f27a";
  font-family: "Font Awesome 5 Free";
  background: transparent;
  width: auto;
  height: auto;
  position: absolute;
  left: 0;
  top: 11px;
  font-size: 1.125rem;
  font-weight: 500;
}

.widget_meta ul li a abbr[title] {
  color: #333;
  border-bottom: none;
}

.widget_calendar th,
.widget_calendar td {
  text-align: center;
  border: 0.0625rem solid var(--rgba-primary-1);
  padding: 0.4em;
}

.widget_calendar th,
.widget_calendar tfoot td {
  border-color: rgba(0, 0, 0, 0.05);
}
.widget_calendar th:last-child,
.widget_calendar tfoot td:last-child {
  border: 0;
}

.widget_calendar tr {
  border-bottom: 0.0625rem solid var(--rgba-primary-1);
}
.widget_calendar tr:nth-child(2n + 2) {
  background-color: var(--rgba-primary-05);
}

.widget_calendar table {
  border-collapse: collapse;
  margin: 0;
  width: 100%;
}

.widget_calendar .calendar_wrap th,
.widget_calendar tfoot td a {
  color: #fff;
  background: var(--primary);
}

.widget_calendar .calendar_wrap td a {
  font-weight: 600;
  text-transform: uppercase;
  color: inherit;
}

.widget_calendar caption {
  text-align: center;
  font-weight: 600;
  caption-side: top;
  background: var(--primary);
  border: 2px solid #fff;
  border-width: 0 2px;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.9375rem;
}

.wp-block-calendar table th {
  font-weight: 400;
  background: var(--primary);
  color: #fff;
}

.wp-block-calendar table caption {
  color: #fff;
}

footer .widget_calendar th,
footer .widget_calendar td {
  border-color: rgba(255, 255, 255, 0.1);
}

footer .widget_calendar tr {
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1);
}
footer .widget_calendar tr:nth-child(2n + 2) {
  background-color: rgba(255, 255, 255, 0.05);
}

.wp-calendar-nav {
  background: var(--primary);
  color: #fff;
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.wp-calendar-nav span {
  padding: 0.3125rem 1.25rem;
  width: 44%;
  border-right: 0.0625rem solid rgba(255, 255, 255, 0.2);
}
.wp-calendar-nav span a {
  color: #fff;
}

.widget_archive select {
  width: 100%;
  padding: 0.3125rem;
  border: 0.0625rem solid #ccc;
}

.list-2-column ul {
  display: table;
}
.list-2-column ul li {
  width: 50%;
  float: left;
}

.widget_categories select,
.widget_archive select {
  width: 100%;
  border: 0.0625rem solid #ccc;
}

.widget_text select {
  width: 100%;
  border: 0.0625rem solid #ccc;
}
.widget_text select option {
  width: 100%;
}

.widget_text p {
  font-size: 0.9375rem;
  line-height: 1.75rem;
}

.rsswidget img {
  display: inherit;
}

.widget-title .rsswidget {
  display: inline-block;
}

ul a.rsswidget,
ul cite {
  font-weight: 600;
  color: var(--title);
}

ul a.rsswidget {
  font-size: 1.125rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
ul a.rsswidget:hover {
  color: var(--primary);
}

.rssSummary {
  margin: 1.25rem 0;
  line-height: 1.7;
  font-size: 15px;
}

.rss-date {
  display: inline-block;
  margin-top: 0.3125rem;
  color: var(--primary);
  font-weight: 400;
}

.widget_rss ul li {
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 0;
}
.widget_rss ul li:last-child {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.widget_gallery ul {
  padding-left: 0;
  display: inline-block;
}

.widget_gallery .gallery-grid-8 li {
  width: 12.5%;
}
@media only screen and (max-width: 767px) {
  .widget_gallery .gallery-grid-8 li {
    width: 25%;
  }
}

.widget_gallery.gallery-grid-2 li {
  width: 50%;
}

.widget_gallery.gallery-grid-4 li {
  width: 25%;
}

.widget_gallery li {
  display: inline-block;
  width: 33.33%;
  float: left;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  padding: 5px;
}
.widget_gallery li img {
  display: inline-block;
  width: 100%;
  border-radius: 5px;
}
.widget_gallery li:nth-child(3n-3) {
  margin-right: 0;
}
.widget_gallery li:hover {
  opacity: 0.7;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.widget_gallery li a {
  display: inline-block;
}

.widget_tag_cloud .tagcloud {
  clear: both;
  display: table;
}
.widget_tag_cloud .tagcloud a {
  position: relative;
  padding: 9px 15px;
  display: inline-block;
  margin: 0 10px 10px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  background: #fff;
  border-radius: 8px !important;
  color: #666666;
  border: 1px solid rgba(34, 34, 34, 0.1);
}
@media only screen and (max-width: 1199px) {
  .widget_tag_cloud .tagcloud a {
    padding: 6px 14px;
    margin: 0 8px 8px 0;
  }
}
.widget_tag_cloud .tagcloud a:hover {
  background: #ececec;
  border-color: #ececec;
}
.widget_tag_cloud .tagcloud a span {
  display: inline-block;
}

.recent-posts-entry .widget-post-bx .widget-post {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid rgba(34, 34, 34, 0.1);
  border-radius: 10px;
}
.recent-posts-entry .widget-post-bx .widget-post .dz-media {
  height: 80px;
  min-width: 100px;
  width: 100px;
  margin-right: 15px;
  border-radius: var(--border-radius-base);
}
@media only screen and (max-width: 1199px) {
  .recent-posts-entry .widget-post-bx .widget-post .dz-media {
    height: 80px;
    min-width: 80px;
    width: 80px;
  }
}
.recent-posts-entry .widget-post-bx .widget-post .dz-meta {
  margin-bottom: 0px;
}
.recent-posts-entry .widget-post-bx .widget-post .dz-meta ul {
  line-height: 1;
  margin: 0 -10px;
}
.recent-posts-entry .widget-post-bx .widget-post .dz-meta ul li {
  margin: 0 10px;
  color: #666666;
  position: relative;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
}
.recent-posts-entry .widget-post-bx .widget-post .dz-meta ul li i {
  color: var(--primary);
  font-size: 14px;
}
@media only screen and (max-width: 1280px) {
  .recent-posts-entry .widget-post-bx .widget-post .dz-meta ul {
    margin: 0 -6px;
  }
  .recent-posts-entry .widget-post-bx .widget-post .dz-meta ul li {
    margin: 0 6px;
  }
}
.recent-posts-entry .widget-post-bx .widget-post .title {
  line-height: 1.3;
  font-family: "Poppins";
  margin-bottom: 8px;
  display: block;
  font-size: 16px;
}
@media only screen and (max-width: 1199px) {
  .recent-posts-entry .widget-post-bx .widget-post .title {
    font-size: 16px;
  }
}
.recent-posts-entry .widget-post-bx .widget-post .post-date {
  font-size: 13px;
  font-weight: 500;
  color: #777777;
  line-height: 20px;
  display: inline-block;
}

.recent-posts-entry .widget-post-bx.style-1 .widget-post {
  padding: 0;
  background: transparent;
  border: none;
  align-items: center;
  margin-bottom: 30px;
}
@media only screen and (max-width: 575px) {
  .recent-posts-entry .widget-post-bx.style-1 .widget-post {
    margin-bottom: 20px;
  }
}
.recent-posts-entry .widget-post-bx.style-1 .widget-post .dz-media {
  width: 100px;
  min-width: 100px;
  height: 100px;
}
.recent-posts-entry .widget-post-bx.style-1 .widget-post .title {
  font-size: 16px;
  line-height: 24px;
  color: #1f1f1f;
}
.recent-posts-entry .widget-post-bx.style-1 .widget-post .dz-meta ul li {
  color: #1f1f1f;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  margin-right: 25px;
}
.recent-posts-entry .widget-post-bx.style-1 .widget-post .dz-meta ul li.date {
  color: #707070;
  font-weight: 500;
  font-size: 13px;
  line-height: 30px;
}
.recent-posts-entry
  .widget-post-bx.style-1
  .widget-post
  .dz-meta
  ul
  li:last-child {
  margin-right: 0;
}
.recent-posts-entry
  .widget-post-bx.style-1
  .widget-post
  .dz-meta
  ul
  li:not(:last-child)::after {
  content: "";
  height: 6px;
  width: 6px;
  position: absolute;
  right: -20px;
  top: 50%;
  border-radius: 50%;
  background-color: #707070;
  transform: translate(0%, -50%);
}
@media only screen and (max-width: 1280px) {
  .recent-posts-entry .widget-post-bx.style-1 .widget-post .dz-meta ul li {
    margin-right: 20px;
  }
  .recent-posts-entry
    .widget-post-bx.style-1
    .widget-post
    .dz-meta
    ul
    li:not(:last-child)::after {
    right: -15px;
  }
}

.service_menu_nav {
  background: #fff;
  padding: 30px 30px;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.service_menu_nav li a {
  background-color: #fff;
  display: block;
  border-radius: 6px;
  padding: 14px 25px;
  margin-bottom: 10px;
  color: #000;
  font-weight: 600;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  background-size: 200%;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.service_menu_nav li a::before {
  content: "\f30b";
  font-weight: 900;
  font-family: "Line Awesome Free";
  position: absolute;
  right: 50px;
  top: 3px;
  font-size: 30px;
  opacity: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.service_menu_nav li a::after {
  background-color: var(--primary);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  content: "";
  background-size: 200%;
  opacity: 0;
  z-index: -1;
}
.service_menu_nav li:hover a,
.service_menu_nav li.active a {
  padding-left: 35px;
  border-color: transparent;
  color: #fff;
}
.service_menu_nav li:hover a::before,
.service_menu_nav li.active a::before {
  opacity: 1;
  right: 25px;
  color: #fff;
}
.service_menu_nav li:hover a::after,
.service_menu_nav li.active a::after {
  opacity: 1;
}

.wp-block-categories-dropdown,
.wp-block-archives-dropdown {
  margin-bottom: 20px;
}
.wp-block-categories-dropdown select::after,
.wp-block-archives-dropdown select::after {
  content: "\f078";
  font-weight: 900;
  font-family: "Line Awesome Free";
  position: absolute;
  right: 50px;
  left: auto;
  top: 50%;
}

.post-tags strong {
  font-weight: 700;
  color: #212529;
  margin-right: 10px;
}

.post-tags a {
  margin-right: 10px;
  display: inline-block;
}

.widget_profile .dz-media {
  width: 100%;
  min-width: 100%;
}
.widget_profile .dz-media img {
  border-radius: var(--border-radius-base);
}

.widget_profile p {
  font-size: 16px;
}

.widget-newslatter .news-box {
  background-color: var(--rgba-primary-1);
  padding: 20px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.widget-newslatter .news-box form::before {
  font-family: themify;
  content: "\e75a";
  font-size: 50px;
  font-weight: 700;
  opacity: 0.05;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  opacity: 0.05;
}
.widget-newslatter .news-box form::after {
  color: var(--primary);
  content: "Newsletter";
  font-size: 50px;
  font-weight: 700;
  opacity: 0.3;
  position: absolute;
  bottom: 100px;
  left: 15px;
  z-index: -1;
}

.widget-newslatter .form-control,
.widget-newslatter .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .widget-newslatter select,
.widget-newslatter .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .widget-newslatter select {
  background-color: #fff;
  border-radius: var(--border-radius-base);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.widget-newslatter p {
  font-size: 15px;
  margin-bottom: 30px;
}

.widget-newslatter .input-group {
  margin-bottom: 10px;
}

#masonry.row {
  width: auto !important;
}

.shop-widget,
.widget.style-1 {
  box-shadow: 0 0 60px 20px rgba(0, 0, 0, 0.1);
  padding: 35px;
  border-bottom: 4px solid;
  border-color: var(--primary);
}
.shop-widget .search-bx,
.widget.style-1 .search-bx {
  margin-bottom: 0;
}
.shop-widget .search-bx .form-control,
.shop-widget .search-bx .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .shop-widget .search-bx select,
.shop-widget .search-bx .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .shop-widget .search-bx select,
.widget.style-1 .search-bx .form-control,
.widget.style-1 .search-bx .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .widget.style-1 .search-bx select,
.widget.style-1 .search-bx .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .widget.style-1 .search-bx select {
  background-color: #f3f4f6;
  box-shadow: unset;
}
.shop-widget .search-bx .input-group,
.widget.style-1 .search-bx .input-group {
  margin-bottom: 0;
}
@media only screen and (max-width: 1280px) {
  .shop-widget,
  .widget.style-1 {
    padding: 25px;
  }
}

.side-bar h1,
.side-bar .h1 {
  font-size: 2rem;
}

.side-bar h2,
.side-bar .h2 {
  font-size: 1.625rem;
}

.side-bar h3,
.side-bar .h3 {
  font-size: 1.5rem;
}

.side-bar h4,
.side-bar .h4,
.side-bar .wp-block-search .wp-block-search__label,
.wp-block-search .side-bar .wp-block-search__label {
  font-size: 1.25rem;
}

.side-bar h5,
.side-bar .h5 {
  font-size: 1rem;
}

.sidebar-widget .wp-block-woocommerce-attribute-filter > h1,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h1,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h2,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h2,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h3,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h3,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h4,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h4,
.sidebar-widget
  .wp-block-search
  .wp-block-woocommerce-attribute-filter
  > .wp-block-search__label,
.wp-block-search
  .sidebar-widget
  .wp-block-woocommerce-attribute-filter
  > .wp-block-search__label,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h5,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h5,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h6,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h6,
.sidebar-widget .wp-block-group__inner-container > h1,
.sidebar-widget .wp-block-group__inner-container > .h1,
.sidebar-widget .wp-block-group__inner-container > h2,
.sidebar-widget .wp-block-group__inner-container > .h2,
.sidebar-widget .wp-block-group__inner-container > h3,
.sidebar-widget .wp-block-group__inner-container > .h3,
.sidebar-widget .wp-block-group__inner-container > h4,
.sidebar-widget .wp-block-group__inner-container > .h4,
.sidebar-widget
  .wp-block-search
  .wp-block-group__inner-container
  > .wp-block-search__label,
.wp-block-search
  .sidebar-widget
  .wp-block-group__inner-container
  > .wp-block-search__label,
.sidebar-widget .wp-block-group__inner-container > h5,
.sidebar-widget .wp-block-group__inner-container > .h5,
.sidebar-widget .wp-block-group__inner-container > h6,
.sidebar-widget .wp-block-group__inner-container > .h6,
.shop-widget .wp-block-woocommerce-attribute-filter > h1,
.shop-widget .wp-block-woocommerce-attribute-filter > .h1,
.shop-widget .wp-block-woocommerce-attribute-filter > h2,
.shop-widget .wp-block-woocommerce-attribute-filter > .h2,
.shop-widget .wp-block-woocommerce-attribute-filter > h3,
.shop-widget .wp-block-woocommerce-attribute-filter > .h3,
.shop-widget .wp-block-woocommerce-attribute-filter > h4,
.shop-widget .wp-block-woocommerce-attribute-filter > .h4,
.shop-widget
  .wp-block-search
  .wp-block-woocommerce-attribute-filter
  > .wp-block-search__label,
.wp-block-search
  .shop-widget
  .wp-block-woocommerce-attribute-filter
  > .wp-block-search__label,
.shop-widget .wp-block-woocommerce-attribute-filter > h5,
.shop-widget .wp-block-woocommerce-attribute-filter > .h5,
.shop-widget .wp-block-woocommerce-attribute-filter > h6,
.shop-widget .wp-block-woocommerce-attribute-filter > .h6,
.shop-widget .wp-block-group__inner-container > h1,
.shop-widget .wp-block-group__inner-container > .h1,
.shop-widget .wp-block-group__inner-container > h2,
.shop-widget .wp-block-group__inner-container > .h2,
.shop-widget .wp-block-group__inner-container > h3,
.shop-widget .wp-block-group__inner-container > .h3,
.shop-widget .wp-block-group__inner-container > h4,
.shop-widget .wp-block-group__inner-container > .h4,
.shop-widget
  .wp-block-search
  .wp-block-group__inner-container
  > .wp-block-search__label,
.wp-block-search
  .shop-widget
  .wp-block-group__inner-container
  > .wp-block-search__label,
.shop-widget .wp-block-group__inner-container > h5,
.shop-widget .wp-block-group__inner-container > .h5,
.shop-widget .wp-block-group__inner-container > h6,
.shop-widget .wp-block-group__inner-container > .h6 {
  position: relative;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  margin-bottom: 30px;
}

.wp-block-search .wp-block-search__label {
  color: var(--title);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  margin-bottom: 30px;
}

.wp-block-group:where(.has-background) {
  padding: 1.25em 2.375em;
}

.widget_working ul li {
  display: block;
}
.widget_working ul li .time a {
  font-weight: 600;
  color: var(--secondary);
  display: block;
}

.widget_getintuch ul li {
  position: relative;
  margin-bottom: 25px;
  padding-left: 40px;
  color: #000;
}
@media only screen and (max-width: 1199px) {
  .widget_getintuch ul li {
    margin-bottom: 25px;
  }
}
.widget_getintuch ul li h5,
.widget_getintuch ul li .h5 {
  font-size: 20px;
  line-height: 1.3;
  margin-bottom: 5px;
}
.widget_getintuch ul li i {
  position: absolute;
  font-size: 30px;
  line-height: 1;
  left: 0;
  top: 5px;
  color: var(--primary);
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.widget_getintuch ul li p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #cccccc;
}

.widget.bg-white {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius-base);
}

.widget_calendar table {
  border-collapse: separate;
  border-spacing: 2px;
  width: 100%;
}
.widget_calendar table thead {
  background-color: #999999;
}
.widget_calendar table thead tr th {
  font-size: 11px;
  padding: 5px;
  text-align: center;
  border: none;
  color: #fff;
}
.widget_calendar table tbody td {
  font-size: 13px;
  padding: 6px 5px;
  text-align: center;
  background-color: var(--rgba-primary-2);
  border: none;
  color: #444;
}
.widget_calendar table tbody td#today {
  background-color: var(--secondary);
  color: #fff;
}
.widget_calendar table tbody td#today a {
  color: #fff;
}
.widget_calendar table tfoot tr td {
  border: none;
  padding: 0px;
}
.widget_calendar table tfoot tr td a {
  background-color: #fff;
  padding: 4px 10px;
}

.searchform {
  position: relative;
}
.searchform input[type="text"] {
  width: 100%;
  height: 40px;
  padding: 10px 90px 10px 15px;
  border: 1px solid #cccccc;
}
.searchform input[type="submit"] {
  height: 40px;
  padding: 5px 15px;
  background-color: var(--color-primary);
  position: absolute;
  right: 0;
  bottom: 0;
  color: #ffffff;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: none;
}
.searchform input[type="submit"]:hover,
.searchform input[type="submit"]:focus,
.searchform input[type="submit"]:active {
  background-color: var(--color-hover);
  color: #ffffff !important;
  border-bottom-color: #5a9e2f;
}

.widget_services li a span {
  position: relative;
}
.widget_services li a span:after {
  content: "";
  position: absolute;
  height: 1px;
  background-color: var(--primary);
  bottom: 0;
  right: 0;
  width: 0;
  transform-origin: left;
  -moz-transform-origin: left;
  -ms-transform-origin: left;
  -webkit-transform-origin: left;
  -o-transform-origin: left;
  transition: all 0.8s;
  -moz-transition: all 0.8s;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
}

.widget_services li a:hover span:after {
  width: 100%;
  left: 0;
  right: auto;
  transform-origin: right;
  -moz-transform-origin: right;
  -ms-transform-origin: right;
  -webkit-transform-origin: right;
  -o-transform-origin: right;
}

.modal-dialog {
  max-width: 970px !important;
}
@media only screen and (max-width: 991px) {
  .modal-dialog {
    max-width: 750px !important;
    padding: 15px;
  }
}
@media only screen and (max-width: 575px) {
  .modal-dialog {
    padding: 10px;
  }
}

.dzmove1 {
  animation: dzMove1 5s linear infinite;
}

@keyframes dzMove1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  50% {
    -webkit-transform: translate(25px, 25px);
    transform: translate(25px, 25px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

.dzmove2 {
  -webkit-animation: move1 5s infinite;
  animation: move1 5s infinite;
}

@-webkit-keyframes dzmove2 {
  0% {
    -webkit-transform: rotate(-25deg) translate(-50px, -50px);
    transform: rotate(-25deg) translate(-50px, -50px);
  }
  50% {
    -webkit-transform: rotate(25deg) translate(50px, 50px);
    transform: rotate(25deg) translate(50px, 50px);
  }
  100% {
    -webkit-transform: rotate(-25deg) translate(-50px, -50px);
    transform: rotate(-25deg) translate(-50px, -50px);
  }
}

.dzheart {
  animation: dzHeart 1s linear infinite;
}

@keyframes dzHeart {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.9);
  }
}

.range-slider.style-1 {
  padding: 45px 0 0;
}
.range-slider.style-1 .noUi-horizontal {
  height: 8px;
}
.range-slider.style-1 .noUi-horizontal .noUi-handle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid var(--primary);
  background-color: #fff;
  box-shadow: none;
  top: -5px;
}
.range-slider.style-1 .noUi-horizontal .noUi-handle::before,
.range-slider.style-1 .noUi-horizontal .noUi-handle::after {
  content: none;
}
.range-slider.style-1 .noUi-horizontal .noUi-handle .noUi-tooltip {
  border: 0px;
  background: var(--primary);
  color: white;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 600;
  padding: 2px 5px;
  bottom: 30px;
}
.range-slider.style-1 .noUi-horizontal .noUi-handle .noUi-tooltip::after {
  content: "";
  position: absolute;
  background-color: inherit;
  width: 10px;
  height: 10px;
  bottom: -11px;
  margin-left: -4px;
  left: 50%;
  transform: rotate(45deg) translate(-50%, -50%);
}
.range-slider.style-1
  .noUi-horizontal
  .noUi-handle.noUi-handle-lower
  .noUi-tooltip {
  background: var(--title);
}
.range-slider.style-1
  .noUi-horizontal
  .noUi-handle.noUi-handle-lower
  .noUi-tooltip.after {
  background-color: var(--title);
}
.range-slider.style-1 .noUi-target {
  background: #d3d3d3;
  border-radius: 4px;
  border: 0;
  box-shadow: none;
}
.range-slider.style-1 .noUi-target .noUi-connect {
  background: var(--primary);
}

.checkbox-info .form-check-input:focus {
  border-color: #00aeff;
  outline: 0;
}

.checkbox-info .form-check-input:checked {
  background-color: #00aeff;
  border-color: #00aeff;
}

.checkbox-danger .form-check-input:focus {
  border-color: #ff0003;
  outline: 0;
}

.checkbox-danger .form-check-input:checked {
  background-color: #ff0003;
  border-color: #ff0003;
}

.checkbox-success .form-check-input:focus {
  border-color: #13c24d;
  outline: 0;
}

.checkbox-success .form-check-input:checked {
  background-color: #13c24d;
  border-color: #13c24d;
}

.checkbox-warning .form-check-input:focus {
  border-color: #ffbd13;
  outline: 0;
}

.checkbox-warning .form-check-input:checked {
  background-color: #ffbd13;
  border-color: #ffbd13;
}

.checkbox-secondary .form-check-input:focus {
  border-color: var(--secondary);
  outline: 0;
}

.checkbox-secondary .form-check-input:checked {
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

.form-check-input.checkbox-primary {
  border-color: var(--primary);
  background-color: var(--secondary);
}
.form-check-input.checkbox-primary:checked {
  background-color: var(--primary);
}

.form-check-input.checkbox-secondary:checked {
  background-color: var(--secondary);
}

.search-content .form-check-input {
  width: 24px;
  height: 24px;
  border: 2px solid var(--primary);
  margin-top: 0;
}

.btn-social {
  color: #fff;
  height: 40px;
  width: 40px;
  min-width: 40px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  border-radius: 4px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  display: block;
  padding: 0;
}
.btn-social.btn-transparent {
  background-color: rgba(255, 255, 255, 0.2);
}
.btn-social.btn-transparent:hover {
  background-color: var(--secondary);
  color: #fff;
}
.btn-social.btn-primary:hover,
.btn-social.wp-block-button__link:hover {
  background-color: var(--primary);
  color: #fff;
}
.btn-social.btn-secondary:hover {
  background-color: var(--primary);
  color: #fff;
}
.btn-social.btn-circle {
  border: 0;
  border-radius: 50%;
}
.btn-social.btn-sm,
.btn-group-sm > .btn-social.btn,
.btn-group-sm > .btn-social.wp-block-button__link {
  height: 30px;
  width: 30px;
  min-width: 30px;
  line-height: 30px;
  font-size: 14px;
}
.btn-social.btn-lg,
.btn-group-lg > .btn-social.btn,
.btn-group-lg > .btn-social.wp-block-button__link {
  height: 50px;
  width: 50px;
  min-width: 50px;
  line-height: 50px;
  font-size: 18px;
}

.dz-social-icon ul {
  margin-left: -5px;
  margin-right: -5px;
}
.dz-social-icon ul li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}

.dz-social-icon.icon-gap-10 ul {
  margin-left: -10px;
  margin-right: -10px;
}
.dz-social-icon.icon-gap-10 ul li {
  padding-left: 10px;
  padding-right: 10px;
}

.dz-social-icon.icon-gap-5 ul {
  margin-left: -5px;
  margin-right: -5px;
}
.dz-social-icon.icon-gap-5 ul li {
  padding-left: 5px;
  padding-right: 5px;
}

.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus,
.ps__rail-x.ps--clicking,
.ps__rail-y.ps--clicking {
  background-color: transparent;
  opacity: 0.9;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #dae2f3;
  width: 4px;
}

.ps__thumb-y {
  background-color: #dae2f3;
  width: 4px;
}

.dz-img-box {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.dz-img-box.style-1 {
  border-radius: 10px;
}
.dz-img-box.style-1::after {
  position: absolute;
  content: "";
  z-index: 1;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0.39deg,
    var(--primary) 0.33%,
    rgba(125, 166, 64, 0) 101%
  );
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-img-box.style-1 .dz-tag {
  position: absolute;
  top: -30px;
  background: var(--secondary);
  left: 0;
  color: #fff;
  border-radius: 9px 0px 10px;
  padding: 4px 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-img-box.style-1 .dz-content {
  display: flex;
  justify-content: space-between;
  opacity: 0;
  align-items: center;
  margin-bottom: -90px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 2;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  padding: 20px 30px;
}
.dz-img-box.style-1 .dz-content .dz-info .dz-title a {
  color: #fff;
}
.dz-img-box.style-1 .dz-content .dz-info .dz-price {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: var(--secondary);
}
.dz-img-box.style-1:hover::after {
  opacity: 1;
}
.dz-img-box.style-1:hover .dz-tag {
  top: 0px;
}
.dz-img-box.style-1:hover .dz-content {
  opacity: 1;
  width: 100%;
  margin-bottom: 0;
}
.dz-img-box.style-2 {
  text-align: center;
  padding: 40px 20px 30px;
  border: 1px solid #f5f5f5;
  background: #fff;
  border-radius: var(--border-radius-base);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.dz-img-box.style-2::before,
.dz-img-box.style-2::after {
  position: absolute;
  content: "";
  z-index: -1;
  top: -190px;
  left: 50%;
  transform: translateX(-50%);
  background: #f5f5f5;
  width: 335px;
  height: 335px;
  border-radius: 50%;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-img-box.style-2::before {
  top: -100%;
  background-color: var(--primary);
  z-index: 0;
}
.dz-img-box.style-2 .dz-media {
  width: 150px;
  min-width: 150px;
  margin: 0 auto 10px;
  border-radius: 50%;
  border: 9px solid #fff;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-img-box.style-2 .dz-content {
  margin-top: auto;
}
.dz-img-box.style-2 .dz-content p {
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 8px;
}
.dz-img-box.style-2 .dz-content .btn,
.dz-img-box.style-2 .dz-content .wp-block-button__link {
  margin-top: 18px;
}
.dz-img-box.style-2:hover,
.dz-img-box.style-2.active {
  border-color: var(--primary);
}
.dz-img-box.style-2:hover::after,
.dz-img-box.style-2.active::after {
  top: 0;
  border-radius: 0;
  height: 100%;
  width: 100%;
  opacity: 0.5;
}
.dz-img-box.style-2:hover::before,
.dz-img-box.style-2.active::before {
  top: -190px;
}
.dz-img-box.style-2:hover .dz-media,
.dz-img-box.style-2.active .dz-media {
  animation: dzRotate360 10s linear infinite;
}
@media only screen and (max-width: 991px) {
  .dz-img-box.style-2 {
    padding: 40px 20px 30px;
  }
}
.dz-img-box.style-3 {
  border-radius: 10px;
  box-shadow: 0px 15px 55px rgba(34, 34, 34, 0.15);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-img-box.style-3::after {
  position: absolute;
  content: "";
  z-index: 1;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0.39deg, #222222 0.33%, rgba(34, 34, 34, 0) 101%);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-img-box.style-3 .dz-media {
  width: 100%;
}
.dz-img-box.style-3 .dz-tag {
  position: absolute;
  top: 0px;
  background: var(--secondary);
  left: 0;
  color: #fff;
  border-radius: 9px 0px 10px;
  padding: 4px 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}
.dz-img-box.style-3 .dz-content {
  background: #fff;
  text-align: center;
  padding: 23px 15px;
  opacity: 1;
  display: block;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  width: 100%;
  position: absolute;
  bottom: 0px;
  margin-bottom: 0px;
}
.dz-img-box.style-3 .dz-content p {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 2px;
}
.dz-img-box.style-3 .dz-hover-content {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  position: absolute;
  bottom: 0;
  opacity: 0;
  z-index: 2;
  width: 100%;
  align-items: center;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  margin-bottom: -100px;
}
.dz-img-box.style-3 .dz-hover-content .dz-info .dz-title a {
  color: #fff;
}
.dz-img-box.style-3 .dz-hover-content .dz-info .dz-price {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: var(--secondary);
}
.dz-img-box.style-3:hover::after,
.dz-img-box.style-3.active::after {
  opacity: 1;
}
.dz-img-box.style-3:hover .dz-content,
.dz-img-box.style-3.active .dz-content {
  bottom: -150px;
  opacity: 0;
}
.dz-img-box.style-3:hover .dz-media,
.dz-img-box.style-3.active .dz-media {
  width: 100%;
  min-width: 100%;
  height: 100%;
}
.dz-img-box.style-3:hover .dz-hover-content,
.dz-img-box.style-3.active .dz-hover-content {
  opacity: 1;
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  .dz-img-box.style-3 .dz-content {
    padding: 20px;
  }
}
.dz-img-box.style-4 {
  background: #fff;
  box-shadow: 0px 15px 55px rgba(34, 34, 34, 0.15);
  border-radius: 10px;
  padding: 18px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  height: 160px;
}
.dz-img-box.style-4::before {
  content: "";
  height: 0;
  width: 0;
  background-color: #222222;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  border-radius: 100%;
}
.dz-img-box.style-4 .menu-detail {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.dz-img-box.style-4 .menu-detail .dz-media {
  margin-right: 20px;
  width: 60px;
  height: 60px;
  min-width: 60px;
}
.dz-img-box.style-4 .menu-detail .dz-content .title {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  margin-bottom: 3px;
}
.dz-img-box.style-4 .menu-detail .dz-content .title a {
  color: var(--title);
}
.dz-img-box.style-4 .menu-detail .dz-content p {
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 0;
}
.dz-img-box.style-4 .menu-footer {
  max-width: 100px;
  margin-top: auto;
}
.dz-img-box.style-4 .menu-footer span {
  font-weight: 500;
  font-size: 13px;
  line-height: 30px;
}
.dz-img-box.style-4 .menu-footer .price {
  color: var(--primary);
  margin-bottom: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  font-size: 18px;
  display: block;
  font-weight: 600;
  line-height: 1.2;
}
.dz-img-box.style-4 .detail-btn {
  background-color: var(--primary);
  width: 48px;
  height: 48px;
  line-height: 48px;
  color: #fff;
  text-align: center;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 10px 0px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-img-box.style-4.active::before,
.dz-img-box.style-4:hover::before {
  height: 120px;
  width: 120px;
  transform: scale(6);
  right: 40%;
}
.dz-img-box.style-4.active .menu-detail .dz-content .title a,
.dz-img-box.style-4:hover .menu-detail .dz-content .title a {
  color: #fff;
}
.dz-img-box.style-4.active .menu-footer .price,
.dz-img-box.style-4:hover .menu-footer .price {
  color: #fff;
}
.dz-img-box.style-4.active .detail-btn,
.dz-img-box.style-4:hover .detail-btn {
  background: #fff;
  color: var(--primary);
}
.dz-img-box.style-4.active .detail-btn i,
.dz-img-box.style-4:hover .detail-btn i {
  animation: dzRotate360 10s linear infinite;
}
.dz-img-box.style-5 {
  background: #fff;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 30px);
}
.dz-img-box.style-5 .dz-content {
  margin-bottom: 15px;
}
.dz-img-box.style-5 .dz-content .weight,
.dz-img-box.style-5 .dz-content .price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dz-img-box.style-5 .dz-content .weight {
  margin-bottom: 5px;
}
.dz-img-box.style-5 .dz-content .weight span {
  font-size: 12px;
}
.dz-img-box.style-5 .dz-content .price h6,
.dz-img-box.style-5 .dz-content .price .h6 {
  margin-bottom: 0;
}
.dz-img-box.style-5 .dz-media {
  border-radius: 10px;
  margin-top: auto;
  width: 100%;
  height: 100%;
}
.dz-img-box.style-5 .dz-media::after {
  background: linear-gradient(
    0deg,
    rgba(34, 34, 34, 0.8),
    rgba(34, 34, 34, 0.8)
  );
  content: "";
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-img-box.style-5 .dz-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.dz-img-box.style-5 .dz-media .detail-btn {
  width: 48px;
  height: 48px;
  line-height: 48px;
  display: block;
  text-align: center;
  position: absolute;
  top: -50px;
  transform: translateX(-50%);
  left: 50%;
  color: #fff;
  background-color: var(--primary);
  border-radius: 10px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 2;
  opacity: 0;
}
.dz-img-box.style-5:hover .dz-media::after {
  opacity: 1;
}
.dz-img-box.style-5:hover .dz-media .detail-btn {
  opacity: 1;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}
@media only screen and (max-width: 1280px) {
  .dz-img-box.style-5 {
    padding: 10px;
  }
}
.dz-img-box.style-6 {
  display: flex;
  align-items: center;
  padding: 20px 25px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 10px;
}
.dz-img-box.style-6 .dz-media {
  margin-right: 12px;
  min-width: 80px;
  width: 80px;
}
.dz-img-box.style-6 .dz-content {
  width: 100%;
}
.dz-img-box.style-6 .dz-content .dz-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dz-img-box.style-6 .dz-content .dz-head .header-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}
.dz-img-box.style-6 .dz-content .dz-head .header-text a {
  color: var(--title);
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}
.dz-img-box.style-6 .dz-content .dz-head .header-text a:hover {
  color: var(--primary);
}
.dz-img-box.style-6 .dz-content .dz-head .img-line {
  width: 100%;
  height: 1px;
  margin: 0 15px;
  border-bottom: 1px dashed #7d7d7d;
}
.dz-img-box.style-6 .dz-content .dz-head .header-price {
  color: var(--primary);
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}
.dz-img-box.style-6 .dz-content .dz-body {
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  margin-bottom: 0;
}
.dz-img-box.style-6:hover {
  box-shadow: 0px 15px 55px rgba(34, 34, 34, 0.15);
}
@media only screen and (max-width: 1191px) {
  .dz-img-box.style-6 {
    padding: 10px;
  }
}
@media only screen and (max-width: 991px) {
  .dz-img-box.style-6 {
    display: block;
    text-align: center;
  }
  .dz-img-box.style-6 .dz-media {
    min-width: 80px;
    width: 80px;
    margin: 0 auto 20px;
  }
  .dz-img-box.style-6 .dz-content {
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .dz-img-box.style-6 {
    display: flex;
    align-items: center;
    text-align: left;
  }
  .dz-img-box.style-6 .dz-media {
    margin: 0 10px 0 0;
  }
  .dz-img-box.style-6 .dz-content {
    text-align: left;
  }
}
@media only screen and (max-width: 575px) {
  .dz-img-box.style-6 {
    margin-bottom: 10px;
  }
  .dz-img-box.style-6 .dz-content .dz-head .header-text a {
    font-size: 16px;
  }
  .dz-img-box.style-6 .dz-content .dz-head .img-line,
  .dz-img-box.style-6 .dz-content .dz-head .header-price {
    display: none;
  }
  .dz-img-box.style-6 .dz-content .dz-body {
    font-size: 14px;
  }
}
.dz-img-box.style-7 {
  border-radius: 10px;
  background: #fff;
  /* box-shadow: 0px 15px 55px rgba(34, 34, 34, 0.15); */
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  text-align: center;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  position: relative;
  height: 100%;
}
.dz-img-box.style-7 .dz-media img {
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.dz-img-box.style-7 .dz-meta {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.dz-img-box.style-7 .dz-meta ul li.seller {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  background-color: #fe9f10;
  color: var(--theme-text-color);
  border-radius: 9px 0px 10px;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 10px;
  margin-right: 0;
  text-transform: uppercase;
  display: block;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.dz-img-box.style-7 .dz-meta ul li.rating {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 1;
  background-color: #fff;
  color: var(--title);
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 10px;
  margin-right: 0;
  text-transform: uppercase;
  display: block;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  align-items: center;
}
.dz-img-box.style-7 .dz-meta ul li.rating i {
  font-size: 12px;
  top: -2px;
  color: #fe9f10;
}
.dz-img-box.style-7 .dz-content {
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
}
.dz-img-box.style-7 .dz-content p {
  font-size: 14px;
  margin-bottom: 10px;
}
.dz-img-box.style-7 .dz-content .price {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.1;
  color: var(--primary);
}
.dz-img-box.style-7:hover {
  transform: translateY(-10px);
}
.dz-img-box.style-7:hover .dz-media img {
  transform: scale(1.1);
}
@media only screen and (max-width: 1280px) {
  .dz-img-box.style-7 .dz-meta ul li.rating {
    bottom: 15px;
    right: 15px;
  }
  .dz-img-box.style-7 .dz-content {
    padding: 20px 15px;
  }
}
.dz-img-box.style-8 {
  overflow: unset;
  margin-bottom: 45px;
  display: flex;
  flex-direction: column;
}
.dz-img-box.style-8 .dz-media {
  border-radius: 5px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.dz-img-box.style-8 .dz-media img {
  object-fit: cover;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-img-box.style-8 .dz-content {
  padding: 15px 0 0;
  position: relative;
}
.dz-img-box.style-8 .dz-content .dz-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dz-img-box.style-8 .dz-content .dz-head .heart {
  width: 50px;
  height: 50px;
  display: inline-block;
  background: url(../images/background/pic19.png);
  cursor: pointer;
  margin: -10px -9px;
  position: absolute;
  right: 0;
  top: 5px;
}
.dz-img-box.style-8 .dz-content .dz-head .heart.heart-blast {
  background-position: -1680px 0 !important;
  transition: background 1s steps(28);
}
.dz-img-box.style-8 .dz-content .category {
  font-size: 13px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 0;
}
.dz-img-box.style-8:hover .dz-media img {
  transform: scale(1.1);
}
@media only screen and (max-width: 1280px) {
  .dz-img-box.style-8 {
    margin-bottom: 30px;
  }
  .dz-img-box.style-8 .dz-media {
    width: 100%;
    min-width: 100%;
    height: 100%;
  }
}

@keyframes dzRotate360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dz-divider {
  height: 1px;
  position: relative;
  margin: 30px 0;
}
.dz-divider.tb20 {
  margin: 20px 0;
}
.dz-divider.tb15 {
  margin: 15px 0;
}
.dz-divider.tb10 {
  margin: 10px 0;
}
.dz-divider.tb0 {
  margin: 0;
}
.dz-divider.divider-2px {
  height: 2px;
}
.dz-divider.divider-3px {
  height: 3px;
}
.dz-divider.divider-4px {
  height: 4px;
}
.dz-divider i {
  position: absolute;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  display: block;
  border-radius: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.dz-divider.icon-left {
  margin-left: 40px;
}
.dz-divider.icon-left i {
  left: -40px;
}
.dz-divider.icon-right {
  margin-right: 40px;
}
.dz-divider.icon-right i {
  left: auto;
  right: -40px;
}
.dz-divider.icon-center i {
  left: 50%;
  margin-left: -5px;
}

.bg-gray-dark {
  background-color: #d3d3d3;
}

.adv-box {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  height: 100%;
}
.adv-box .adv-media {
  position: relative;
  overflow: hidden;
}
.adv-box.style-1 {
  overflow: unset;
  background-color: #054f48;
  text-align: center;
}
.adv-box.style-1 .adv-content {
  padding: 30px 20px 0px;
}
.adv-box.style-1 .adv-content span {
  font-weight: 700;
  font-size: 20px;
  color: #fff;
}
.adv-box.style-1 .adv-content .title {
  color: #fecc48;
  font-weight: 800;
  font-size: 43px;
  font-family: var(--font-family-title);
  line-height: 64px;
}
.adv-box.style-1 .adv-media {
  position: relative;
  overflow: unset;
  width: 400px;
  left: -31px;
  top: 0px;
}
.adv-box.style-1 .adv-media .item-media {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
@media only screen and (max-width: 1280px) {
  .adv-box.style-1 .adv-content {
    margin-bottom: 30px;
  }
  .adv-box.style-1 .adv-media {
    width: 370px;
    left: 0;
  }
}
@media only screen and (max-width: 1199px) {
  .adv-box.style-1 .adv-media {
    width: 100%;
    left: 0;
    text-align: right;
  }
  .adv-box.style-1 .adv-content {
    margin-bottom: 45px;
  }
  .adv-box.style-1 .adv-content .title {
    font-size: 33px;
    line-height: 44px;
  }
}
@media only screen and (max-width: 991px) {
  .adv-box.style-1 .adv-media .main-media {
    width: 500px;
    height: 300px;
    object-fit: cover;
  }
}
@media only screen and (max-width: 767px) {
  .adv-box.style-1 .adv-media .main-media {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
.adv-box.style-2 {
  background-color: var(--primary);
  display: flex;
  align-items: center;
  overflow: unset;
}
.adv-box.style-2 .adv-content {
  padding: 10px;
  margin-left: -10px;
}
.adv-box.style-2 .adv-content .price {
  font-weight: 800;
  font-size: 24px;
  line-height: 36px;
  color: #f9fe10;
  display: block;
  margin-bottom: 8px;
}
.adv-box.style-2 .adv-content .special {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}
.adv-box.style-2 .adv-content .title {
  font-weight: 700;
  font-size: 50px;
  line-height: 55px;
}
.adv-box.style-2 .adv-media {
  position: relative;
  overflow: unset;
  height: 220px;
  min-height: 220px;
  width: 200px;
}
.adv-box.style-2 .adv-media img {
  position: absolute;
  bottom: -1px;
  object-fit: cover;
}
@media only screen and (max-width: 1280px) {
  .adv-box.style-2 .adv-media {
    width: 180px;
  }
  .adv-box.style-2 .adv-content {
    padding: 0;
    margin-left: -15px;
  }
  .adv-box.style-2 .adv-content .special {
    font-size: 16px;
    line-height: 20px;
  }
  .adv-box.style-2 .adv-content .title {
    font-size: 40px;
    line-height: 40px;
  }
  .adv-box.style-2 .adv-content .price {
    font-size: 18px;
    line-height: 25px;
  }
}
@media only screen and (max-width: 1024px) {
  .adv-box.style-2 .adv-media {
    width: 145px;
    height: 185px;
    min-height: 185px;
  }
}
@media only screen and (max-width: 767px) {
  .adv-box.style-2 .adv-media {
    width: 120px;
    margin-right: 30px;
  }
}
.adv-box.style-3 .adv-content {
  padding: 40px 15px 40px 190px;
}
.adv-box.style-3 .adv-content .special {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.adv-box.style-3 .adv-content .title {
  font-weight: 800;
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 20px;
  text-shadow: 4px 8px 4px rgba(0, 0, 0, 0.25);
  color: #fecc48;
}
@media only screen and (max-width: 1024px) {
  .adv-box.style-3 .adv-content {
    padding: 30px 14px 30px 131px;
  }
  .adv-box.style-3 .adv-content .title {
    font-size: 30px;
    line-height: 50px;
  }
}
@media only screen and (max-width: 991px) {
  .adv-box.style-3 .adv-content {
    padding: 30px 14px 30px 160px;
  }
}
@media only screen and (max-width: 767px) {
  .adv-box.style-3 .adv-content {
    padding: 30px 14px 30px 265px;
  }
}
@media only screen and (max-width: 575px) {
  .adv-box.style-3 .adv-content {
    padding: 25px 14px 25px 162px;
  }
}
.adv-box.style-4 {
  overflow: unset;
  background-color: #138919;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 30px 0 30px;
}
.adv-box.style-4 .adv-media {
  position: relative;
  overflow: unset;
  width: 250px;
  min-width: 250px;
  height: 150px;
}
.adv-box.style-4 .adv-media img {
  position: absolute;
  top: -55px;
  object-fit: cover;
  left: -90px;
}
.adv-box.style-4 .adv-content .special {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}
.adv-box.style-4 .adv-content .price {
  font-weight: 800;
  font-size: 54px;
  line-height: 81px;
  color: #fff;
}
@media only screen and (max-width: 1024px) {
  .adv-box.style-4 {
    padding: 0px 20px 0 0px;
  }
  .adv-box.style-4 .adv-content .price {
    font-size: 40px;
    line-height: 50px;
  }
  .adv-box.style-4 .adv-media {
    width: 200px;
    min-width: 200px;
  }
  .adv-box.style-4 .adv-media img {
    top: -20px;
    left: -70px;
  }
}
@media only screen and (max-width: 767px) {
  .adv-box.style-4 .adv-content .price {
    font-size: 40px;
    line-height: 40px;
  }
  .adv-box.style-4 .adv-content .special {
    font-size: 14px;
    line-height: 24px;
  }
  .adv-box.style-4 .adv-media {
    width: 150px;
    min-width: 150px;
  }
  .adv-box.style-4 .adv-media img {
    top: 0;
    left: 0;
  }
}
@media only screen and (max-width: 575px) {
  .adv-box.style-4 {
    display: block;
    text-align: center;
    padding: 20px;
  }
  .adv-box.style-4 .adv-media img {
    left: 50px;
    margin: auto;
  }
}

.offer-label {
  width: 100px;
  height: 100px;
}
.offer-label img {
  animation: dzRotate360 10s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
}
.offer-label span {
  color: var(--title);
  font-size: 22px;
  font-weight: 700;
  position: absolute;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  line-height: 1.2;
}

.tempus-dominus-widget {
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.tempus-dominus-widget .date-container-days .day {
  border-radius: 0.475rem !important;
}
.tempus-dominus-widget .picker-switch {
  font-weight: 600;
}
.tempus-dominus-widget .date-container-days div:not(.no-highlight) {
  font-size: 15px;
}
.tempus-dominus-widget button {
  border-radius: 0.475rem !important;
}
.tempus-dominus-widget .toggleMeridiem {
  border: 0;
  padding: 8px !important;
}
.tempus-dominus-widget.light
  .date-container-days
  div:not(.no-highlight).active {
  background-color: var(--primary);
}
.tempus-dominus-widget.light
  .date-container-days
  div:not(.no-highlight).today::before {
  border-bottom-color: var(--primary);
}
.tempus-dominus-widget.light .date-container-days .dow {
  font-size: 13px;
  color: #222222;
}
.tempus-dominus-widget.light button {
  background-color: var(--primary);
  border-color: var(--primary);
}
.tempus-dominus-widget.light
  .date-container-days
  div.range-end:not(.no-highlight),
.tempus-dominus-widget.light
  .date-container-days
  div.range-in:not(.no-highlight),
.tempus-dominus-widget.light
  .date-container-days
  div.range-start:not(.no-highlight),
.tempus-dominus-widget.light .date-container-days div:not(.no-highlight).active,
.tempus-dominus-widget.light
  .date-container-decades
  div:not(.no-highlight).active,
.tempus-dominus-widget.light
  .date-container-months
  div:not(.no-highlight).active,
.tempus-dominus-widget.light
  .date-container-years
  div:not(.no-highlight).active,
.tempus-dominus-widget.light
  .time-container-clock
  div:not(.no-highlight).active,
.tempus-dominus-widget.light .time-container-hour div:not(.no-highlight).active,
.tempus-dominus-widget.light
  .time-container-minute
  div:not(.no-highlight).active,
.tempus-dominus-widget.light
  .time-container-second
  div:not(.no-highlight).active {
  background-color: var(--primary);
}

.separator.no-highlight {
  background: transparent;
}

.nav-tabs {
  margin: 0 -5px 25px;
  border-bottom: none;
}
.nav-tabs li {
  color: #fff;
  margin: 0 5px 5px;
  border-radius: 6px;
}
.nav-tabs li button i {
  font-family: "feather" !important;
  font-style: normal;
  text-transform: none;
  vertical-align: middle;
}
.nav-tabs li button:active {
  background-color: var(--primary-hover);
}

.tabs-style-1 {
  border-bottom: 2px solid #eaeaea;
  margin: 0 0 30px;
}
.tabs-style-1 li {
  margin: 0 3px -1px 0;
}
.tabs-style-1 .nav-link {
  border: 2px solid rgba(255, 255, 255, 0);
  border-width: 2px 2px 0;
  color: var(--title);
}
.tabs-style-1 .nav-link.active,
.tabs-style-1 .nav-link:hover,
.tabs-style-1 .nav-link:active,
.tabs-style-1 .nav-link:focus {
  border: 2px solid #eaeaea;
  background-color: #eaeaea;
  color: var(--title);
}

.main-bnr-one {
  position: relative;
  align-items: center;
  z-index: 1;
  overflow: hidden;
}
.main-bnr-one .banner-inner {
  position: relative;
  z-index: 1;
  background-color: var(--primary);
  height: 930px;
}
.main-bnr-one .banner-inner::after {
  position: absolute;
  z-index: -1;
  content: "";
  height: 100%;
  width: 70%;
  top: 0;
  left: 0;
  background: var(--bg-light);
}
@media only screen and (min-width: 2400px) {
  .main-bnr-one .banner-inner::after {
    width: 65%;
  }
}
.main-bnr-one .banner-inner .banner-content {
  position: relative;
  z-index: 1;
  margin-bottom: 60px;
}
.main-bnr-one .banner-inner .banner-content .sub-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 30px;
  color: #fe9f10;
  transition: all 0.5s ease-out 0.1s;
  transform: translateX(-15px);
  transition-delay: 0.5s;
  opacity: 0;
  display: block;
}
.main-bnr-one .banner-inner .banner-content h1,
.main-bnr-one .banner-inner .banner-content .h1 {
  font-family: "Lobster";
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  line-height: 1.2;
  margin-bottom: 10px;
  transition: all 0.6s ease-out 0.1s;
  transform: translateX(-15px);
  transition-delay: 0.7s;
  opacity: 0;
}
.main-bnr-one .banner-inner .banner-content p {
  font-size: 18px;
  line-height: 27px;
  max-width: 500px;
  transition: all 0.7s ease-out 0.1s;
  transform: translateX(-15px);
  transition-delay: 0.9s;
  opacity: 0;
}
.main-bnr-one .banner-inner .banner-content .banner-btn {
  margin-top: 40px;
  transition: all 0.8s ease-out 0.1s;
  transform: translateX(-15px);
  transition-delay: 1.1s;
  opacity: 0;
}
.main-bnr-one .banner-inner .banner-media {
  position: relative;
  z-index: 1;
  margin-top: 70px;
  transition: all 0.5s ease-out 0.1s;
  transform: translateY(15px);
  transition-delay: 0.5s;
  opacity: 0.5;
}
.main-bnr-one .banner-inner .img1,
.main-bnr-one .banner-inner .img2,
.main-bnr-one .banner-inner .img3 {
  position: absolute;
  z-index: 1;
}
.main-bnr-one .banner-inner .img1 {
  right: 0;
  min-width: 50vw;
  width: 50vw;
  top: 0;
  opacity: 0.25;
  z-index: -1;
}
.main-bnr-one .banner-inner .img2 {
  top: 156px;
  right: 147px;
  opacity: 0;
  transform: rotate(5deg);
  transition: all 0.5s ease-out 0.1s;
  transition-delay: 0.5s;
}
.main-bnr-one .banner-inner .img3 {
  top: 100px;
  left: 50%;
  width: 150px;
  transform: translateX(-50%);
  animation: move1 7s infinite;
}
@media only screen and (max-width: 1680px) {
  .main-bnr-one .banner-inner .img2 {
    right: 45px;
  }
}
@media only screen and (max-width: 1480px) {
  .main-bnr-one .banner-inner {
    height: 850px;
  }
  .main-bnr-one .banner-inner::after {
    width: 75%;
  }
  .main-bnr-one .banner-inner .img2 {
    right: -60px;
  }
  .main-bnr-one .banner-inner .banner-content h1,
  .main-bnr-one .banner-inner .banner-content .h1 {
    font-size: 70px;
    line-height: 85px;
  }
  .main-bnr-one .banner-inner .banner-media {
    margin-top: 0px;
  }
}
@media only screen and (max-width: 1320px) {
  .main-bnr-one .banner-inner .img2 {
    right: -128px;
  }
}
@media only screen and (max-width: 1280px) {
  .main-bnr-one .banner-inner {
    height: 750px;
  }
  .main-bnr-one .banner-inner::after {
    width: 100%;
  }
  .main-bnr-one .banner-inner .banner-content h1,
  .main-bnr-one .banner-inner .banner-content .h1 {
    font-size: 60px;
    line-height: 75px;
  }
  .main-bnr-one .banner-inner .banner-content p {
    font-size: 16px;
    line-height: 24px;
  }
  .main-bnr-one .banner-inner .banner-media img {
    height: 750px !important;
    object-fit: cover;
  }
  .main-bnr-one .banner-inner .img2,
  .main-bnr-one .banner-inner .img3 {
    display: none;
  }
  .main-bnr-one .banner-inner .main-thumb1-area {
    bottom: 95px;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-one .banner-inner {
    padding-top: 50px;
    height: 650px;
  }
  .main-bnr-one .banner-inner .banner-content {
    margin-bottom: 0;
  }
  .main-bnr-one .banner-inner .banner-content h1,
  .main-bnr-one .banner-inner .banner-content .h1 {
    font-size: 50px;
    line-height: 65px;
  }
  .main-bnr-one .banner-inner .banner-content .sub-title {
    margin-bottom: 15px;
  }
  .main-bnr-one .banner-inner .banner-content .banner-btn {
    margin-top: 25px;
  }
  .main-bnr-one .banner-inner .banner-media img {
    height: 600px !important;
    object-fit: cover;
  }
}
@media only screen and (max-width: 767px) {
  .main-bnr-one .banner-inner {
    padding-top: 110px;
    height: 800px;
  }
  .main-bnr-one .banner-inner .banner-content {
    margin-bottom: 30px;
  }
  .main-bnr-one .banner-inner .banner-content .sub-title {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .main-bnr-one .banner-inner .banner-media {
    text-align: center;
  }
  .main-bnr-one .banner-inner .banner-media img {
    object-fit: cover;
    margin: auto;
    width: 250px;
    height: 375px !important;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-one .banner-inner .banner-content h1,
  .main-bnr-one .banner-inner .banner-content .h1 {
    font-size: 45px;
    line-height: 55px;
  }
  .main-bnr-one .banner-inner .banner-content p {
    font-size: 14px;
  }
}
.main-bnr-one .main-thumb1 {
  width: 612px;
  height: auto;
  overflow: hidden;
}
.main-bnr-one .main-thumb1 .swiper-wrapper {
  height: auto;
}
.main-bnr-one .main-thumb1 .swiper-slide {
  width: 295px !important;
  opacity: 0.5;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
}
.main-bnr-one .main-thumb1 .swiper-slide.swiper-slide-thumb-active {
  opacity: 1;
}
.main-bnr-one .main-thumb1 .food-card {
  display: flex;
  align-items: center;
}
.main-bnr-one .main-thumb1 .food-card .dz-media {
  width: 80px;
  min-width: 80px;
  border-radius: var(--border-radius-base);
}
.main-bnr-one .main-thumb1 .food-card .dz-content {
  margin-left: 15px;
}
.main-bnr-one .main-thumb1 .food-card .dz-content p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
  margin-bottom: 0;
}
.main-bnr-one .main-thumb1-area {
  padding: 0 50px;
  position: absolute;
  bottom: 55px;
  z-index: 999;
}
@media only screen and (max-width: 991px) {
  .main-bnr-one .main-thumb1-area {
    display: none;
  }
}
.main-bnr-one
  .swiper-slide.swiper-slide-active
  .banner-inner
  .banner-content
  .sub-title,
.main-bnr-one
  .swiper-slide.swiper-slide-active
  .banner-inner
  .banner-content
  .banner-btn,
.main-bnr-one
  .swiper-slide.swiper-slide-active
  .banner-inner
  .banner-content
  h1,
.main-bnr-one
  .swiper-slide.swiper-slide-active
  .banner-inner
  .banner-content
  .h1,
.main-bnr-one
  .swiper-slide.swiper-slide-active
  .banner-inner
  .banner-content
  p {
  transform: translateY(0);
  opacity: 1;
}
.main-bnr-one .swiper-slide.swiper-slide-active .banner-inner .banner-media {
  transform: translateY(0);
  opacity: 1;
}
.main-bnr-one .swiper-slide.swiper-slide-active .banner-inner .img2 {
  opacity: 1;
  transform: rotate(0);
}

.main-bnr-one .slider-pagination {
  position: absolute;
  width: auto;
  left: 50px;
  z-index: 99;
  top: 50%;
  transform: translateY(-50%);
}
.main-bnr-one .slider-pagination .main-button-next,
.main-bnr-one .slider-pagination .main-button-prev {
  position: static;
  align-items: center;
  color: var(--primary);
  z-index: 2;
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  font-size: 30px;
  margin: 10px 0;
}
.main-bnr-one .slider-pagination .main-button-next i,
.main-bnr-one .slider-pagination .main-button-prev i {
  font-family: "feather";
  font-style: normal;
}
@media only screen and (max-width: 991px) {
  .main-bnr-one .slider-pagination .main-button-next,
  .main-bnr-one .slider-pagination .main-button-prev {
    display: none;
  }
}
.main-bnr-one .slider-pagination .swiper-pagination-bullet {
  font-weight: 400;
  font-size: 24px;
  margin: 10px 0;
  text-align: center;
  opacity: 1;
  color: var(--rgba-primary-4);
  font-family: "Lobster";
  display: block;
  height: 55px;
  width: 55px;
  line-height: 55px;
  border-radius: 0;
  background: transparent;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.main-bnr-one
  .slider-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  color: var(--primary);
}
@media only screen and (max-width: 1480px) {
  .main-bnr-one .slider-pagination {
    left: 20px;
  }
}
@media only screen and (max-width: 1280px) {
  .main-bnr-one .slider-pagination {
    right: 20px;
    left: auto;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-one .slider-pagination {
    right: 10px;
  }
  .main-bnr-one .slider-pagination .swiper-pagination-bullet {
    font-size: 18px;
    height: 40px;
    width: 40px;
    line-height: 40px;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-one .slider-pagination {
    right: 10px;
    top: auto;
    bottom: 15px;
    transform: translate(0);
  }
}

@keyframes move1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  25% {
    -webkit-transform: translate(-15px, -15px);
    transform: translate(-15px, -15px);
  }
  50% {
    -webkit-transform: translate(-8px, -8px);
    transform: translate(-8px, -8px);
  }
  75% {
    -webkit-transform: translate(-15px, 8px);
    transform: translate(-15px, 8px);
  }
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@media only screen and (min-width: 1281px) {
  [data-layout="boxed"] .main-bnr-one .banner-inner::after {
    width: 80%;
  }
  [data-layout="boxed"] .main-bnr-one .slider-pagination {
    left: auto;
    right: 20px;
  }
  [data-layout="boxed"]
    .main-bnr-one
    .slider-pagination
    .swiper-pagination-bullet {
    color: #fff;
  }
  [data-layout="boxed"]
    .main-bnr-one
    .slider-pagination
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
    color: #fff;
    opacity: 0.5;
  }
  [data-layout="boxed"] .main-bnr-one .slider-pagination .main-button-next,
  [data-layout="boxed"] .main-bnr-one .slider-pagination .main-button-prev {
    color: #fff;
  }
}

.main-bnr-two {
  position: relative;
  align-items: center;
  z-index: 1;
  overflow: hidden;
}
.main-bnr-two .banner-inner {
  position: relative;
  z-index: 1;
  background-color: #fff;
}
.main-bnr-two .banner-inner .banner-content {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  margin-right: -100px;
}
.main-bnr-two .banner-inner .banner-content .sub-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}
.main-bnr-two .banner-inner .banner-content h1,
.main-bnr-two .banner-inner .banner-content .h1 {
  font-family: "Lobster";
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  line-height: 1.2;
  margin-bottom: 10px;
}
.main-bnr-two .banner-inner .banner-content p {
  font-size: 18px;
  line-height: 28px;
  max-width: 522px;
}
.main-bnr-two .banner-inner .banner-content .banner-btn {
  margin-top: 42px;
}
@media only screen and (max-width: 1480px) {
  .main-bnr-two .banner-inner .banner-content h1,
  .main-bnr-two .banner-inner .banner-content .h1 {
    font-size: 70px;
  }
}
@media only screen and (max-width: 1280px) {
  .main-bnr-two .banner-inner .banner-content {
    margin-right: 0;
    margin-bottom: 0;
    margin-top: 30px;
  }
  .main-bnr-two .banner-inner .banner-content h1,
  .main-bnr-two .banner-inner .banner-content .h1 {
    font-size: 60px;
  }
  .main-bnr-two .banner-inner .banner-content p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-two .banner-inner .banner-content h1,
  .main-bnr-two .banner-inner .banner-content .h1 {
    font-size: 42px;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-two .banner-inner .banner-content h1,
  .main-bnr-two .banner-inner .banner-content .h1 {
    font-size: 36px;
  }
  .main-bnr-two .banner-inner .banner-content p {
    width: 100%;
  }
  .main-bnr-two .banner-inner .banner-content .banner-btn {
    margin-top: 20px;
  }
}
.main-bnr-two .banner-inner .banner-media {
  position: relative;
  min-width: 50vw;
  height: 100%;
  float: left;
}
.main-bnr-two .banner-inner .item-media {
  position: absolute;
  bottom: 50px;
  margin-left: -50px;
  width: 53vw;
}
.main-bnr-two .banner-inner .item-media img:not(.crown) {
  width: 100%;
}
.main-bnr-two .banner-inner .item-bg {
  position: relative;
  width: 50vw;
}
.main-bnr-two .banner-inner .crown {
  position: absolute;
  left: 18%;
  top: -12%;
  width: 80%;
}
.main-bnr-two .banner-inner .offer {
  position: absolute;
  top: -120px;
  right: 120px;
  width: auto !important;
}
.main-bnr-two .banner-inner .primary-box {
  position: relative;
  z-index: 1;
}
.main-bnr-two .banner-inner .primary-box::before,
.main-bnr-two .banner-inner .primary-box::after {
  position: absolute;
  content: "";
  z-index: 1;
  width: 214px;
  height: 214px;
  border-radius: 25px;
  background: var(--primary);
  transform: rotate(30deg);
  top: -127px;
  left: -117px;
}
.main-bnr-two .banner-inner .primary-box::after {
  background: var(--rgba-primary-2);
  top: -103px;
  left: -112px;
}
.main-bnr-two .banner-inner .primary-box.style-1::before,
.main-bnr-two .banner-inner .primary-box.style-1::after {
  width: 160px;
  height: 160px;
  bottom: 70px;
  left: -125px;
  top: auto;
}
.main-bnr-two .banner-inner .primary-box.style-1::after {
  bottom: 38px;
  left: -117px;
}
@media only screen and (max-width: 1680px) {
  .main-bnr-two .banner-inner .offer {
    top: -100px;
    right: 50px;
  }
  .main-bnr-two .banner-inner .item-media {
    bottom: 20px;
    margin-left: -30px;
  }
}
@media only screen and (max-width: 1480px) {
  .main-bnr-two .banner-inner .primary-box::before {
    left: -145px;
  }
  .main-bnr-two .banner-inner .primary-box::after {
    top: -119px;
    left: -140px;
  }
  .main-bnr-two .banner-inner .primary-box.style-1::before {
    left: -138px;
  }
  .main-bnr-two .banner-inner .primary-box.style-1::after {
    bottom: 50px;
    left: -140px;
  }
}
@media only screen and (max-width: 1280px) {
  .main-bnr-two .banner-inner .crown,
  .main-bnr-two .banner-inner .offer {
    display: none;
  }
  .main-bnr-two .banner-inner .primary-box {
    display: none;
  }
  .main-bnr-two .banner-inner .banner-media {
    width: 55vw;
  }
  .main-bnr-two .banner-inner .item-bg {
    width: 100%;
  }
}
@media only screen and (max-width: 1024px) {
  .main-bnr-two .banner-inner .banner-media {
    width: 60vw;
    transform: translateX(-50px);
  }
  .main-bnr-two .banner-inner .item-media {
    margin-left: 0;
    left: -60px;
    bottom: 50px;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-two .banner-inner {
    padding: 90px 0 50px;
    background-color: var(--secondary);
  }
  .main-bnr-two .banner-inner .item-bg {
    display: none;
  }
  .main-bnr-two .banner-inner .banner-media {
    transform: unset;
  }
  .main-bnr-two .banner-inner .item-media {
    position: static;
  }
}
@media only screen and (max-width: 767px) {
  .main-bnr-two .banner-inner {
    padding: 80px 0 30px;
  }
  .main-bnr-two .banner-inner .banner-media {
    width: 100%;
  }
  .main-bnr-two .banner-inner .item-media {
    position: initial;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    width: 100%;
  }
  .main-bnr-two .banner-inner .item-media img {
    width: 100%;
    height: auto;
  }
}

.main-thumb2-area {
  display: flex;
  flex-direction: row;
  gap: 20px;
  position: relative;
  bottom: -130px;
  left: 0;
}
.main-thumb2-area .food-card {
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.main-thumb2-area .food-card .icon {
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  width: 78px;
  min-width: 78px;
  height: 78px;
  line-height: 78px;
  border-radius: var(--border-radius-base);
  background: var(--rgba-primary-1);
  color: var(--primary);
  text-align: center;
}
.main-thumb2-area .food-card .icon i {
  font-size: 45px;
}
.main-thumb2-area .food-card:hover .icon {
  background: var(--primary);
  color: #fff;
}
@media only screen and (max-width: 1680px) {
  .main-thumb2-area {
    bottom: -80px;
  }
}
@media only screen and (max-width: 1280px) {
  .main-thumb2-area {
    bottom: -95px;
  }
}
@media only screen and (max-width: 1280px) {
  .main-thumb2-area {
    display: none;
  }
}

@media only screen and (min-width: 1281px) {
  [data-layout="boxed"] .main-bnr-two .banner-inner .item-media {
    width: 48vw;
  }
}

@media only screen and (min-width: 1481px) {
  [data-layout="boxed"] .main-bnr-two .banner-inner .item-media {
    width: 44vw;
  }
}

@media only screen and (min-width: 1681px) {
  [data-layout="boxed"] .main-bnr-two .banner-inner .item-bg {
    width: 45vw;
  }
  [data-layout="boxed"] .main-bnr-two .banner-inner .item-media {
    width: 40vw;
  }
  [data-layout="boxed"] .main-bnr-two .banner-inner .offer {
    top: -150px;
    right: 100px;
  }
}

.main-bnr-three {
  position: relative;
  align-items: center;
  z-index: 1;
  overflow: hidden;
  --slider-left-spacing: 80px;
}
.main-bnr-three.top-space {
  transform: translateY(95px);
  margin-bottom: 95px;
}
@media only screen and (max-width: 1280px) {
  .main-bnr-three.top-space {
    transform: translateY(75px);
    margin-bottom: 75px;
  }
}
.main-bnr-three .swiper-wrapper {
  min-height: 825px;
  height: calc(100vh - 95px);
}
@media only screen and (max-width: 1280px) {
  .main-bnr-three .swiper-wrapper {
    min-height: 600px;
    height: 600px;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-three .swiper-wrapper {
    min-height: 580px;
    height: 580px;
  }
}
.main-bnr-three .swiper-slide {
  height: 100% !important;
}
.main-bnr-three .banner-inner {
  position: relative;
  z-index: 1;
  margin-left: var(--slider-left-spacing);
  padding: 120px 0 30px;
  border-radius: 30px 0 0 30px;
  overflow: hidden !important;
  height: 100%;
}
.main-bnr-three .banner-inner::before,
.main-bnr-three .banner-inner::after {
  position: absolute;
  z-index: -1;
  content: "";
  height: 100%;
  width: 70%;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, #222222 0%, rgba(34, 34, 34, 0) 99.38%);
}
.main-bnr-three .banner-inner::after {
  opacity: 0.8;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  left: auto;
  right: 0;
}
@media only screen and (max-width: 1280px) {
  .main-bnr-three .banner-inner {
    border-radius: 0;
    margin-left: 0;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-three .banner-inner::before,
  .main-bnr-three .banner-inner::after {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .main-bnr-three .banner-inner {
    padding: 50px 0 30px;
  }
}
.main-bnr-three .banner-inner .banner-content {
  position: relative;
  z-index: 2;
  margin-bottom: 50px;
}
.main-bnr-three .banner-inner .banner-content .sub-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: block;
}
.main-bnr-three .banner-inner .banner-content h1,
.main-bnr-three .banner-inner .banner-content .h1 {
  font-family: "Lobster";
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  line-height: 1.2;
  margin-bottom: 16px;
}
.main-bnr-three .banner-inner .banner-content .bnr-text {
  font-size: 18px;
  line-height: 27px;
  color: rgba(255, 255, 255, 0.7);
  max-width: 580px;
  font-weight: 300;
}
.main-bnr-three .banner-inner .banner-content .banner-btn {
  margin-top: 40px;
  margin-bottom: 80px;
}
.main-bnr-three .banner-inner .banner-content .food-card {
  border: 1px dashed rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  width: 370px;
  position: relative;
}
.main-bnr-three .banner-inner .banner-content .food-card::before {
  content: "";
  position: absolute;
  border-radius: 100%;
  width: 13px;
  height: 13px;
  background: #f3b700;
  z-index: 1;
  bottom: 200px;
  right: -500px;
}
.main-bnr-three .banner-inner .banner-content .food-card .dz-head {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: space-between;
  padding: 15px 25px;
}
.main-bnr-three .banner-inner .banner-content .food-card .dz-head ul {
  display: flex;
}
.main-bnr-three .banner-inner .banner-content .food-card .dz-head ul li i {
  color: #ebbc16;
}
.main-bnr-three .banner-inner .banner-content .food-card .dz-body {
  display: flex;
  justify-content: space-between;
  padding: 16px 25px;
}
.main-bnr-three
  .banner-inner
  .banner-content
  .food-card
  .dz-body
  .dz-left
  .profile-info {
  display: flex;
}
.main-bnr-three
  .banner-inner
  .banner-content
  .food-card
  .dz-body
  .dz-left
  .profile-info
  .dz-media {
  min-width: 45px;
  width: 45px;
  height: 45px;
  border-radius: 100%;
}
.main-bnr-three
  .banner-inner
  .banner-content
  .food-card
  .dz-body
  .dz-left
  .profile-info
  .dz-content {
  margin-left: 8px;
}
.main-bnr-three
  .banner-inner
  .banner-content
  .food-card
  .dz-body
  .dz-left
  .profile-info
  .dz-content
  .title {
  margin-bottom: 3px;
}
.main-bnr-three
  .banner-inner
  .banner-content
  .food-card
  .dz-body
  .dz-left
  .profile-info
  .dz-content
  p {
  color: #c4c4c4;
  font-size: 13px;
  line-height: 20px;
}
.main-bnr-three
  .banner-inner
  .banner-content
  .food-card
  .dz-body
  .dz-left
  .text {
  font-size: 15px;
  margin-bottom: 0;
  color: #dcdcdc;
}
@media only screen and (max-width: 1280px) {
  .main-bnr-three .banner-inner .banner-content .food-card {
    display: none;
  }
}
@media only screen and (max-width: 1480px) {
  .main-bnr-three .banner-inner .banner-content h1,
  .main-bnr-three .banner-inner .banner-content .h1 {
    font-size: 70px;
  }
}
@media only screen and (max-width: 1280px) {
  .main-bnr-three .banner-inner .banner-content {
    margin-bottom: 0;
  }
  .main-bnr-three .banner-inner .banner-content h1,
  .main-bnr-three .banner-inner .banner-content .h1 {
    font-size: 60px;
  }
  .main-bnr-three .banner-inner .banner-content .bnr-text {
    max-width: 500px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-three .banner-inner .banner-content h1,
  .main-bnr-three .banner-inner .banner-content .h1 {
    font-size: 50px;
  }
  .main-bnr-three .banner-inner .banner-content .bnr-text {
    width: 350px;
  }
}
@media only screen and (max-width: 767px) {
  .main-bnr-three .banner-inner .banner-content .bnr-text {
    width: 100%;
  }
  .main-bnr-three .banner-inner .banner-content .banner-btn {
    margin-top: 25px;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-three .banner-inner .banner-content h1,
  .main-bnr-three .banner-inner .banner-content .h1 {
    font-size: 36px;
  }
}
.main-bnr-three .banner-inner .banner-media {
  position: relative;
  z-index: 1;
  height: 100%;
  float: left;
  min-width: 50vw;
  top: -42px;
  left: -37px;
}
.main-bnr-three .banner-inner .banner-media::after {
  content: "";
  position: absolute;
  border-radius: 100%;
}
.main-bnr-three .banner-inner .banner-media::after {
  width: 60vw;
  height: 60vw;
  border: 180px solid var(--primary);
  top: -25vw;
  right: -20vw;
  z-index: -1;
  transform: rotate(-45deg);
}
.main-bnr-three .banner-inner .banner-media img {
  width: 40vw;
  min-width: 40vw;
  height: 100%;
  transform: rotate(-25deg);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
@media only screen and (max-width: 1680px) {
  .main-bnr-three .banner-inner .banner-media {
    left: -100px;
  }
  .main-bnr-three .banner-inner .banner-media::after {
    border: 100px solid var(--primary);
  }
}
@media only screen and (max-width: 1280px) {
  .main-bnr-three .banner-inner .banner-media {
    left: -30px;
  }
  .main-bnr-three .banner-inner .banner-media::after {
    border: 80px solid var(--primary);
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-three .banner-inner .banner-media {
    left: -30px;
    top: -30px;
  }
  .main-bnr-three .banner-inner .banner-media img {
    width: 60vw;
    min-width: 60vw;
  }
  .main-bnr-three .banner-inner .banner-media::after {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .main-bnr-three .banner-inner .banner-media {
    left: 0;
    width: 100%;
  }
  .main-bnr-three .banner-inner .banner-media img {
    width: 85vw;
    min-width: 85vw;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-three .banner-inner .banner-media img {
    width: 90vw;
    min-width: 90vw;
  }
}
.main-bnr-three .banner-inner .target-line {
  position: absolute;
  bottom: 50px;
  right: -100%;
  z-index: 1;
  transform: translateX(25%);
  min-width: 485px;
}
@media only screen and (max-width: 1480px) {
  .main-bnr-three .banner-inner .target-line {
    display: none;
  }
}
.main-bnr-three .banner-inner .leaf {
  position: absolute;
  top: 0;
  left: 48.5%;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .main-bnr-three .banner-inner .leaf {
    display: none;
  }
}
.main-bnr-three
  .swiper-slide-visible.swiper-slide-active
  .banner-inner
  .banner-media
  img {
  transform: rotate(0deg);
}

.main-bnr-three .swiper-bnr-pagination {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: var(--slider-left-spacing);
  height: 100%;
  position: absolute;
}
@media only screen and (max-width: 575px) {
  .main-bnr-three .swiper-bnr-pagination {
    width: auto;
    right: 0;
  }
}
.main-bnr-three .swiper-bnr-pagination .main-button-next,
.main-bnr-three .swiper-bnr-pagination .main-button-prev {
  color: var(--primary);
  font-size: 30px;
  margin: 10px 0;
}
.main-bnr-three .swiper-bnr-pagination .main-button-next i,
.main-bnr-three .swiper-bnr-pagination .main-button-prev i {
  font-family: "feather";
  font-style: normal;
}
@media only screen and (max-width: 1280px) {
  .main-bnr-three .swiper-bnr-pagination .main-button-next,
  .main-bnr-three .swiper-bnr-pagination .main-button-prev {
    display: none;
  }
}

.main-bnr-three .main-swiper3-pagination {
  position: static;
  transform: unset !important;
  height: auto !important;
  z-index: 9999;
}
.main-bnr-three .main-swiper3-pagination .swiper-pagination-bullet {
  font-weight: 400;
  font-size: 24px;
  line-height: 55px;
  margin: 10px 0;
  text-align: center;
  opacity: 1;
  color: var(--rgba-primary-4);
  font-family: "Lobster";
  display: block;
  width: 55px;
  border-radius: 0;
  height: 55px;
  background: transparent;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.main-bnr-three
  .main-swiper3-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  color: var(--primary);
}
@media only screen and (max-width: 1320px) {
  .main-bnr-three .main-swiper3-pagination {
    left: 0;
  }
  .main-bnr-three .main-swiper3-pagination .swiper-pagination-bullet {
    font-size: 22px;
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
}
@media only screen and (max-width: 1280px) {
  .main-bnr-three .main-swiper3-pagination .swiper-pagination-bullet {
    display: inline-block;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-three .main-swiper3-pagination {
    width: 30px;
  }
  .main-bnr-three .main-swiper3-pagination .swiper-pagination-bullet {
    font-size: 16px;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 1281px) {
  [data-layout="boxed"] .main-bnr-three .banner-inner .banner-media img {
    width: 40vw;
    min-width: 40vw;
  }
}

@media only screen and (min-width: 1481px) {
  [data-layout="boxed"] .main-bnr-three .banner-inner .banner-media::after {
    border: 120px solid var(--primary);
    top: -20vw;
    right: -8vw;
    width: 50vw;
    height: 50vw;
  }
  [data-layout="boxed"] .main-bnr-three .banner-inner .banner-media img {
    width: 32vw;
    min-width: 32vw;
  }
  [data-layout="boxed"] .main-bnr-three .banner-inner .banner-media {
    min-width: 50vw;
    top: -40px;
    left: -100px;
  }
}

.move-1 {
  -webkit-animation: move1 5s infinite;
  animation: move1 5s infinite;
}

.move-2 {
  -webkit-animation: move2 5s infinite;
  animation: move2 5s infinite;
}

.move-3 {
  -webkit-animation: move3 5s infinite;
  animation: move3 5s infinite;
}

.move-4 {
  -webkit-animation: move4 5s infinite;
  animation: move4 5s infinite;
}

.move-5 {
  -webkit-animation: move5 5s infinite;
  animation: move5 5s infinite;
}

.move-6 {
  -webkit-animation: move6 5s infinite;
  animation: move6 5s infinite;
}

.move-7 {
  -webkit-animation: move7 5s infinite;
  animation: move7 5s infinite;
}

.move-lr {
  animation: move-lr 3s infinite linear;
}

.rotate-360 {
  animation: rotate-360 30s infinite linear;
}

@keyframes rotate-360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@-webkit-keyframes move1 {
  0% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px);
  }
  50% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px);
  }
  100% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px);
  }
}

@-webkit-keyframes move2 {
  0% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px);
  }
  50% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px);
  }
  100% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px);
  }
}

@-webkit-keyframes move3 {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(5px, 0);
  }
  40% {
    transform: translate(5px, 5px);
  }
  65% {
    transform: translate(0, 5px);
  }
  65% {
    transform: translate(5px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes move4 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  25% {
    -webkit-transform: translate(10px, 10px);
    transform: translate(10px, 10px);
  }
  50% {
    -webkit-transform: translate(5px, 5px);
    transform: translate(5px, 5px);
  }
  75% {
    -webkit-transform: translate(10px, -5px);
    transform: translate(10px, -5px);
  }
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes move5 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  25% {
    -webkit-transform: translate(-10px, -10px);
    transform: translate(-10px, -10px);
  }
  50% {
    -webkit-transform: translate(-5px, -5px);
    transform: translate(-5px, -5px);
  }
  75% {
    -webkit-transform: translate(-10px, 5px);
    transform: translate(-10px, 5px);
  }
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes move6 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  25% {
    -webkit-transform: translate(15px, 15px);
    transform: translate(15px, 15px);
  }
  50% {
    -webkit-transform: translate(8px, 8px);
    transform: translate(8px, 8px);
  }
  75% {
    -webkit-transform: translate(15px, -8px);
    transform: translate(15px, -8px);
  }
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes move7 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  25% {
    -webkit-transform: translate(-15px, -15px);
    transform: translate(-15px, -15px);
  }
  50% {
    -webkit-transform: translate(-8px, -8px);
    transform: translate(-8px, -8px);
  }
  75% {
    -webkit-transform: translate(-15px, 8px);
    transform: translate(-15px, 8px);
  }
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes move-lr {
  0% {
    transform: rotate(0);
  }
  10% {
    transform: rotate(-15deg);
  }
  20% {
    transform: rotate(20deg);
  }
  30% {
    transform: rotate(-24deg);
  }
  40% {
    transform: rotate(21deg);
  }
  50% {
    transform: rotate(-18deg);
  }
  60% {
    transform: rotate(14deg);
  }
  70% {
    transform: rotate(-11deg);
  }
  80% {
    transform: rotate(7deg);
  }
  90% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(1deg);
  }
}

.about-p {
  width: 815px;
  margin: auto;
}
@media only screen and (max-width: 991px) {
  .about-p {
    width: 100%;
    font-size: 14px;
  }
}

.about-media {
  height: 430px;
}
.about-media img {
  height: 100% !important;
  object-fit: cover;
}
@media only screen and (max-width: 991px) {
  .about-media {
    height: 380px;
  }
}
@media only screen and (max-width: 575px) {
  .about-media {
    height: 300px;
  }
}

.dz-box {
  position: relative;
}

.portfolio-slider .swiper-slide {
  width: auto;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.portfolio-wrapper {
  position: relative;
  z-index: 2;
  padding-top: 80px;
  padding-bottom: 50px;
}
.portfolio-wrapper .portfolio-wrapper-inner {
  z-index: 1;
  position: relative;
  overflow: hidden;
  padding-bottom: 125px;
}
.portfolio-wrapper .bg-image {
  margin-bottom: -2px;
  position: absolute;
  bottom: 0;
  width: 100%;
}
@media only screen and (max-width: 1280px) {
  .portfolio-wrapper .portfolio-wrapper-inner {
    padding-bottom: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .portfolio-wrapper {
    padding-top: 40px;
    padding-bottom: 0px;
  }
  .portfolio-wrapper .portfolio-wrapper-inner {
    padding-bottom: 50px;
  }
}

.testimonial-pic {
  background: #fff;
  width: 100px;
  height: 100px;
  position: relative;
  display: inline-block;
  border: 5px solid #fff;
}
.testimonial-pic.radius {
  border-radius: 100%;
  -webkit-border-radius: 100%;
}
.testimonial-pic.radius img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  -webkit-border-radius: 100%;
}
.testimonial-pic.shadow {
  -webkit-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
  box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
}

.quote-left,
.quote-right {
  position: relative;
}
.quote-left::before,
.quote-right::before {
  font-family: "Flaticon";
  position: absolute;
  z-index: 10;
  height: 36px;
  width: 36px;
  line-height: 36px;
  background-color: var(--primary);
  color: #fff;
  border-radius: 30px;
  text-align: center;
  font-size: 18px;
}

.quote-left::before {
  top: 15px;
  content: "\f115";
  left: 0;
}

.quote-right::before {
  top: 15px;
  content: "\f11e";
  right: 0;
}

.testimonial-text {
  position: relative;
  font-size: 16px;
  font-weight: 400;
  margin: 15px 0;
}
.testimonial-text p:last-child {
  margin: 0;
}

.testimonial-detail {
  padding: 5px;
}

.testimonial-position {
  font-size: 15px;
}

.testimonial-name,
.testimonial-position {
  display: block;
}

.testimonial-name {
  color: #222;
}

.star-rating li {
  display: inline-block;
}

.testimonial-1 {
  background: var(--primary);
  position: relative;
  padding: 35px 40px;
  height: 100%;
  border-radius: 0px 10px 10px 0px;
  display: flex;
  flex-direction: column;
}
.testimonial-1 .testimonial-text {
  margin: 0 0 10px;
}
.testimonial-1 .testimonial-text p {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #fff;
}
.testimonial-1 .testimonial-info {
  position: relative;
  z-index: 1;
  margin-top: auto;
  padding-left: 15px;
}
.testimonial-1 .testimonial-info::after {
  background: var(--secondary);
  border-radius: 4px;
  height: 5px;
  width: 50px;
  content: "";
  position: absolute;
  z-index: -1;
  transform: rotate(90deg);
  top: 25px;
  left: -22px;
}
.testimonial-1 .testimonial-info .testimonial-name {
  font-size: 22px;
  color: #fff;
  line-height: 33px;
  font-weight: 700;
  margin-bottom: 0;
}
.testimonial-1 .testimonial-info .testimonial-position {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
}
.testimonial-1 .quote {
  position: absolute;
  right: 35px;
  bottom: 5px;
  font-size: 140px;
  color: var(--secondary);
}
@media only screen and (max-width: 1024px) {
  .testimonial-1 {
    padding: 25px;
  }
  .testimonial-1 .testimonial-text p {
    font-size: 16px;
    line-height: 30px;
  }
}
@media only screen and (max-width: 991px) {
  .testimonial-1 {
    border-radius: 0 0 10px 10px;
  }
  .testimonial-1 .quote {
    font-size: 100px;
  }
}
@media only screen and (max-width: 575px) {
  .testimonial-1 {
    padding: 25px 15px;
  }
  .testimonial-1 .testimonial-text {
    margin: 0 0 15px;
  }
  .testimonial-1 .quote {
    font-size: 85px;
    right: 20px;
  }
}

.testimonial-one-thumb.swiper {
  width: 100%;
  height: 450px;
  margin-left: auto;
  margin-right: auto;
}

.testimonial-one-thumb .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: calc(100% / 2) !important;
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.testimonial-one-thumb .swiper-slide img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.testimonial-one-thumb .swiper-slide.swiper-slide-thumb-active {
  padding: 10px;
}

@media only screen and (max-width: 575px) {
  .testimonial-one-thumb.swiper {
    height: 250px;
  }
  .testimonial-one-thumb .swiper-slide.swiper-slide-thumb-active {
    padding: 5px;
  }
}

.testimonial-2 {
  background: #fff;
  position: relative;
  display: flex;
}
.testimonial-2 .dz-media {
  border-radius: 8px;
  width: 570px;
  min-width: 570px;
}
.testimonial-2 .dz-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.testimonial-2 .testimonial-detail {
  padding: 20px 30px 30px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}
.testimonial-2 .dz-media + .testimonial-detail {
  padding: 20px 0 30px 30px;
}
.testimonial-2 .testimonial-text p {
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: var(--title);
}
.testimonial-2 .testimonial-info {
  position: relative;
  z-index: 1;
  margin-top: auto;
  padding-left: 20px;
}
.testimonial-2 .testimonial-info::after {
  background: var(--primary);
  border-radius: 4px;
  height: 5px;
  width: 50px;
  content: "";
  position: absolute;
  z-index: -1;
  transform: rotate(90deg);
  top: 25px;
  left: -22px;
}
.testimonial-2 .testimonial-info .testimonial-name {
  font-size: 22px;
  line-height: 33px;
  font-weight: 700;
  margin-bottom: 0;
}
.testimonial-2 .testimonial-info .testimonial-position {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--primary);
}
.testimonial-2 .quote {
  position: absolute;
  right: 26px;
  bottom: 15px;
  font-size: 102px;
  color: var(--primary);
}
@media only screen and (max-width: 1280px) {
  .testimonial-2 .dz-media {
    width: 450px;
    min-width: 450px;
  }
  .testimonial-2 .dz-media img {
    height: 100%;
    object-fit: cover;
  }
  .testimonial-2 .testimonial-text {
    margin: 0 0 15px;
  }
  .testimonial-2 .testimonial-text p {
    font-size: 16px;
    line-height: 30px;
  }
}
@media only screen and (max-width: 991px) {
  .testimonial-2 {
    border-radius: 0 0 10px 10px;
    flex-direction: column;
  }
  .testimonial-2 .testimonial-detail {
    padding: 20px 0;
  }
  .testimonial-2 .dz-media + .testimonial-detail {
    padding: 20px 0;
  }
  .testimonial-2 .dz-media {
    width: 100%;
    min-width: 100%;
  }
  .testimonial-2 .testimonial-detail {
    padding: 15px 0;
  }
  .testimonial-2 .quote {
    font-size: 65px;
    bottom: 10px;
    right: 10px;
  }
  .testimonial-2.right {
    flex-direction: column-reverse;
  }
  .testimonial-2 .testimonial-info .testimonial-name {
    font-size: 18px;
  }
}

.icon-bx-xl {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 150px;
  height: 150px;
  line-height: 150px;
}
.icon-bx-xl.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.icon-bx-xl i {
  font-size: 80px;
  vertical-align: middle;
}
.icon-bx-xl img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 80px;
}

.icon-bx-lg {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 120px;
  height: 120px;
  line-height: 120px;
}
.icon-bx-lg.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.icon-bx-lg i {
  font-size: 60px;
  vertical-align: middle;
}
.icon-bx-lg img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 55px;
}

.icon-bx-md {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.icon-bx-md.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.icon-bx-md i {
  font-size: 45px;
  vertical-align: middle;
}
.icon-bx-md img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 40px;
}

.icon-bx-sm {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 67px;
  height: 67px;
  min-width: 67px;
  line-height: 67px;
}
.icon-bx-sm.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.icon-bx-sm i {
  font-size: 35px;
  vertical-align: middle;
}
.icon-bx-sm img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 35px;
}

.icon-bx-xs {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 60px;
  height: 60px;
  line-height: 60px;
}
.icon-bx-xs.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.icon-bx-xs i {
  font-size: 30px;
  vertical-align: middle;
}
.icon-bx-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 20px;
}

.icon-xl {
  display: inline-block;
  text-align: center;
  width: 100px;
  line-height: 1;
}
.icon-xl i {
  vertical-align: middle;
  font-size: 80px;
}
.icon-xl img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 80px;
}

.icon-lg {
  display: inline-block;
  text-align: center;
  line-height: 1;
}
.icon-lg i {
  vertical-align: middle;
  font-size: 60px;
}
@media only screen and (max-width: 767px) {
  .icon-lg i {
    font-size: 50px;
    line-height: 1.2;
  }
}
.icon-lg img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 70px;
}

.icon-md {
  display: inline-block;
  text-align: center;
  line-height: 1;
}
.icon-md i {
  vertical-align: middle;
  font-size: 45px;
}
.icon-md img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 50px;
}

.icon-sm {
  display: inline-block;
  text-align: center;
  width: 40px;
  line-height: 1;
}
.icon-sm i {
  vertical-align: middle;
  font-size: 30px;
}
.icon-sm img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 30px;
}

.icon-xs {
  display: inline-block;
  text-align: center;
  width: 30px;
  line-height: 1;
}
.icon-xs i {
  vertical-align: middle;
  font-size: 20px;
}
.icon-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 20px;
}

.icon-bx-wraper {
  position: relative;
}
.icon-bx-wraper .dz-tilte {
  margin-top: 0;
  font-weight: 600;
}
.icon-bx-wraper .after-titile-line {
  margin-bottom: 10px;
  font-size: 15px;
}
.icon-bx-wraper p:last-child {
  margin: 0;
}
.icon-bx-wraper.center {
  text-align: center;
}
.icon-bx-wraper.left .icon-bx-xl,
.icon-bx-wraper.left .icon-bx-lg,
.icon-bx-wraper.left .icon-bx-md,
.icon-bx-wraper.left .icon-bx-sm,
.icon-bx-wraper.left .icon-bx-xs {
  float: left;
  margin-right: 15px;
}
.icon-bx-wraper.left .icon-xl,
.icon-bx-wraper.left .icon-lg,
.icon-bx-wraper.left .icon-md,
.icon-bx-wraper.left .icon-sm,
.icon-bx-wraper.left .icon-xs {
  line-height: 1;
  float: left;
  margin-right: 10px;
}
.icon-bx-wraper.right {
  text-align: right;
}
.icon-bx-wraper.right .icon-bx-xl,
.icon-bx-wraper.right .icon-bx-lg,
.icon-bx-wraper.right .icon-bx-md,
.icon-bx-wraper.right .icon-bx-sm,
.icon-bx-wraper.right .icon-bx-xs {
  float: right;
  margin-left: 20px;
}
.icon-bx-wraper.right .icon-xl,
.icon-bx-wraper.right .icon-lg,
.icon-bx-wraper.right .icon-md,
.icon-bx-wraper.right .icon-sm,
.icon-bx-wraper.right .icon-xs {
  line-height: 1;
  float: right;
  margin-left: 10px;
}
.icon-bx-wraper.bx-style-1,
.icon-bx-wraper.bx-style-2 {
  border-width: 1px;
  border-style: solid;
  border-color: #ebedf2;
}
.icon-bx-wraper.bx-style-2.center [class*="icon-bx-"] {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.icon-bx-wraper.bx-style-2.center .icon-bx-xl {
  margin-left: -75px;
}
.icon-bx-wraper.bx-style-2.center .icon-bx-lg {
  margin-left: -60px;
}
.icon-bx-wraper.bx-style-2.center .icon-bx-md {
  margin-left: -50px;
}
.icon-bx-wraper.bx-style-2.center .icon-bx-sm {
  margin-left: -40px;
}
.icon-bx-wraper.bx-style-2.center .icon-bx-xs {
  margin-left: -20px;
}
.icon-bx-wraper.bx-style-2.left [class*="icon-bx-"] {
  position: absolute;
  top: auto;
  left: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.icon-bx-wraper.bx-style-2.right [class*="icon-bx-"] {
  position: absolute;
  top: auto;
  right: 0;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

.icon-content {
  overflow: hidden;
}

[class*="icon-bx-"][class*="bg-"] a,
[class*="icon-bx-"][class*="bg-"] span {
  color: #fff;
}

[class*="icon-bx-"].bg-white a {
  color: inherit;
}

[class*="icon-bx-"][class*="border-"] {
  display: table;
  line-height: normal;
  margin-left: auto;
  margin-right: auto;
}

[class*="icon-bx-"][class*="border-"] .icon-cell {
  display: table-cell;
  vertical-align: middle;
}

.dz-box,
.dz-info,
.dz-tilte,
.dz-tilte-inner {
  position: relative;
}

.dz-tilte-inner {
  display: inline-block;
}

.dz-box[class*="border-"],
.dz-info[class*="border-"] {
  border-color: #eee;
}

.dz-info.border-1,
.dz-info.border-2,
.dz-info.border-3,
.dz-info.border-4,
.dz-info.border-5 {
  border-top: none;
}

.border-1,
.border-2,
.border-3,
.border-4,
.border-5 {
  border-style: solid;
}

.border-1 {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-4 {
  border-width: 4px;
}

.border-5 {
  border-width: 5px;
}

.left-border,
.right-border {
  position: relative;
}
.left-border::before,
.right-border::before {
  content: "";
  position: absolute;
  top: 5px;
  width: 1px;
  height: 90%;
  background: #ccc;
}

.right-border::before {
  right: 0;
}

.dz-media,
.dz-post-media {
  position: relative;
  overflow: hidden;
}
.dz-media img,
.dz-post-media img {
  width: 100%;
  height: auto;
}

.dz-box-bg {
  overflow: hidden;
  background-size: cover;
}
.dz-box-bg .btn,
.dz-box-bg .wp-block-button__link {
  overflow: unset;
}
.dz-box-bg .glyph-icon {
  font-size: 50px;
}
.dz-box-bg .icon-bx-wraper {
  background-color: #f8f8f8;
}
.dz-box-bg .text-primary,
.dz-box-bg .icon-content .dz-tilte,
.dz-box-bg .icon-content p,
.dz-box-bg .icon-box-btn .btn,
.dz-box-bg .icon-box-btn .wp-block-button__link {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-box-bg.active .icon-bx-wraper {
  background-color: rgba(0, 0, 0, 0.7);
}
.dz-box-bg.active .text-primary,
.dz-box-bg.active .icon-content .dz-tilte,
.dz-box-bg.active .icon-content p,
.dz-box-bg.active .icon-box-btn .btn,
.dz-box-bg.active .icon-box-btn .wp-block-button__link {
  color: #fff;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dz-box-bg .icon-box-btn .btn,
.dz-box-bg .icon-box-btn .wp-block-button__link {
  background-color: #eeeeee;
  padding: 15px 20px;
  text-transform: uppercase;
  font-size: 15px;
  border-radius: 0;
  color: #1abc9c;
  font-weight: 600;
  margin-bottom: 0;
}

.icon-bx-wraper.style-1 {
  padding: 40px 24px;
  background-color: #fff;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  justify-content: center;
}
.icon-bx-wraper.style-1 .inner-content {
  position: relative;
  z-index: 1;
}
.icon-bx-wraper.style-1 .inner-content .icon-cell i {
  font-size: 70px;
  color: var(--secondary);
}
.icon-bx-wraper.style-1::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.icon-bx-wraper.style-1:hover,
.icon-bx-wraper.style-1.active {
  transform: scale(1.1);
  border-radius: 10px;
  z-index: 1;
}
.icon-bx-wraper.style-1:hover::after,
.icon-bx-wraper.style-1.active::after {
  background: var(--rgba-primary-9);
}
.icon-bx-wraper.style-1:hover .icon-content .dz-title,
.icon-bx-wraper.style-1.active .icon-content .dz-title {
  color: #fff;
}
.icon-bx-wraper.style-1:hover .icon-content p,
.icon-bx-wraper.style-1.active .icon-content p {
  color: #fff;
}
@media only screen and (max-width: 991px) {
  .icon-bx-wraper.style-1:hover,
  .icon-bx-wraper.style-1.active {
    transform: scale(1);
  }
}

.icon-wrapper1 {
  position: relative;
  box-shadow: 0px 15px 55px rgba(34, 34, 34, 0.15);
}
.icon-wrapper1::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  z-index: -1;
}
@media only screen and (max-width: 991px) {
  .icon-wrapper1 {
    box-shadow: 0px 10px 30px rgba(34, 34, 34, 0.15);
  }
}

.icon-bx-wraper.style-2 {
  background-color: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-2 .icon-bx {
  background-color: var(--rgba-primary-1);
  width: 100px;
  height: 100px;
  min-width: 100px;
  border-radius: 100%;
  line-height: 100px;
  text-align: center;
  margin-right: 20px;
}
.icon-bx-wraper.style-2 .icon-bx .icon-cell {
  color: var(--primary);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-2 .icon-bx .icon-cell i {
  font-size: 45px;
}
.icon-bx-wraper.style-2 .icon-content p {
  font-size: 15px;
  font-weight: 300;
  font-family: var(--font-family-title);
}
.icon-bx-wraper.style-2:hover .icon-bx,
.icon-bx-wraper.style-2.active .icon-bx {
  background-color: var(--primary);
}
.icon-bx-wraper.style-2:hover .icon-bx .icon-cell,
.icon-bx-wraper.style-2.active .icon-bx .icon-cell {
  color: #fff;
}
.icon-bx-wraper.style-2:hover .icon-bx .icon-cell i,
.icon-bx-wraper.style-2.active .icon-bx .icon-cell i {
  animation: shake 1s;
}
@media only screen and (max-width: 1280px) {
  .icon-bx-wraper.style-2 .icon-bx {
    width: 80px;
    min-width: 80px;
    height: 80px;
    line-height: 80px;
    margin-right: 15px;
  }
  .icon-bx-wraper.style-2 .icon-bx .icon-cell i {
    font-size: 35px;
  }
  .icon-bx-wraper.style-2 .icon-content .dz-title {
    font-size: 18px;
  }
}

.icon-wrapper2 {
  position: relative;
}

@media only screen and (min-width: 991px) {
  .icon-wrapper2 > div:not(:nth-child(3)) {
    border-right: 1px dashed rgba(102, 102, 102, 0.2);
  }
  .icon-wrapper2 > div:last-child {
    border-right: none;
  }
}

.icon-bx-wraper.style-3 {
  border-radius: 10px;
  background: rgba(34, 34, 34, 0.05);
  text-align: center;
  padding: 30px 12px;
  border: 2px solid transparent;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.icon-bx-wraper.style-3 .icon-bx {
  width: 95px;
  height: 95px;
  line-height: 95px;
  border-radius: 100%;
  text-align: center;
  background: var(--primary);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.icon-bx-wraper.style-3 .icon-bx .icon-cell i {
  font-size: 50px;
  line-height: 100px;
  color: #fff;
}
.icon-bx-wraper.style-3 .icon-content {
  margin-top: auto;
}
.icon-bx-wraper.style-3 .icon-content p {
  font-size: 14px;
}
.icon-bx-wraper.style-3:hover {
  transform: translateY(-10px);
  border: 2px solid var(--primary);
  background-color: #fff;
  box-shadow: 0px 15px 55px rgba(34, 34, 34, 0.15);
}
.icon-bx-wraper.style-3:hover .icon-bx .icon-cell i {
  animation: shake 1s;
}
@media only screen and (max-width: 1280px) {
  .icon-bx-wraper.style-3 .icon-content .title {
    font-size: 18px;
  }
}
@media only screen and (max-width: 991px) {
  .icon-bx-wraper.style-3 .icon-content .title {
    font-size: 20px;
  }
}

.icon-bx-wraper.style-4 {
  border-radius: 10px;
  text-align: center;
  padding: 20px 12px 30px;
  min-height: 180px;
}
.icon-bx-wraper.style-4 .icon-bx {
  margin-bottom: 20px;
}
.icon-bx-wraper.style-4 .icon-bx .icon-cell {
  width: 65px;
  height: 65px;
  margin: auto;
  min-width: 65px;
  line-height: 65px;
}
.icon-bx-wraper.style-4 .icon-bx .icon-cell i {
  font-size: 60px;
  color: var(--primary);
}
.icon-bx-wraper.style-4 .icon-content p {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: var(--title);
}
.icon-bx-wraper.style-4:hover .icon-bx .icon-cell i {
  animation: shake 1s;
}
@media only screen and (max-width: 1280px) {
  .icon-bx-wraper.style-4 .icon-content p {
    font-size: 15px;
    line-height: 1.5;
  }
}

.icon-bx-wraper.style-5 {
  position: relative;
  text-align: center;
  margin-bottom: 30px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  padding-top: 60px;
}
.icon-bx-wraper.style-5 .icon-bx {
  width: 115px;
  height: 115px;
  line-height: 115px;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 2;
}
.icon-bx-wraper.style-5 .icon-bx .icon-cell {
  border-radius: 100%;
  box-shadow: 0px 15px 55px rgba(34, 34, 34, 0.15);
  background-color: #fff;
  color: var(--primary);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-5 .icon-bx .icon-cell i {
  font-size: 50px;
  vertical-align: middle;
  line-height: 50px;
}
.icon-bx-wraper.style-5 .icon-content {
  padding: 90px 40px 30px;
  box-shadow: 0px 15px 55px rgba(34, 34, 34, 0.15);
  border-radius: 10px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.icon-bx-wraper.style-5 .icon-content .title {
  margin-bottom: 10px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-5 .icon-content p {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-5 .icon-content p:last-child {
  margin-bottom: 0;
}
.icon-bx-wraper.style-5 .icon-content .effect {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
}
.icon-bx-wraper.style-5:hover .icon-cell i,
.icon-bx-wraper.style-5.active .icon-cell i {
  animation: shake 1s;
}
.icon-bx-wraper.style-5:hover .icon-content .title,
.icon-bx-wraper.style-5.active .icon-content .title {
  color: #fff;
}
.icon-bx-wraper.style-5:hover .icon-content p,
.icon-bx-wraper.style-5.active .icon-content p {
  color: rgba(255, 255, 255, 0.8);
}
.icon-bx-wraper.style-5:hover .icon-content .effect,
.icon-bx-wraper.style-5.active .icon-content .effect {
  width: 300%;
  height: 300%;
}
@media only screen and (max-width: 1280px) {
  .icon-bx-wraper.style-5 {
    padding-top: 45px;
  }
  .icon-bx-wraper.style-5 .icon-bx {
    width: 90px;
    height: 90px;
    line-height: 90px;
  }
  .icon-bx-wraper.style-5 .icon-bx i {
    font-size: 40px;
    line-height: 40px;
  }
  .icon-bx-wraper.style-5 .icon-content {
    padding: 65px 20px 30px;
  }
  .icon-bx-wraper.style-5 .icon-content p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 991px) {
  .icon-bx-wraper.style-5 {
    border-radius: 10px;
    overflow: hidden;
    padding: 30px 20px;
    box-shadow: 0px 15px 55px rgba(34, 34, 34, 0.15);
    position: relative;
    z-index: 1;
  }
  .icon-bx-wraper.style-5 .icon-bx {
    position: initial;
    transform: translateY(0);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
  .icon-bx-wraper.style-5 .icon-bx .icon-cell {
    box-shadow: none;
    background-color: transparent;
  }
  .icon-bx-wraper.style-5 .icon-bx .icon-cell i {
    font-size: 45px;
    line-height: 45px;
  }
  .icon-bx-wraper.style-5 .icon-content {
    border-radius: 0;
    padding: 0;
    box-shadow: none;
    position: static;
    background-color: transparent;
  }
  .icon-bx-wraper.style-5:hover .icon-bx .icon-cell,
  .icon-bx-wraper.style-5.active .icon-bx .icon-cell {
    color: #fff;
  }
}

.dz-team {
  transition: all 0.5s;
}
.dz-team .dz-name a {
  transition: all 0.5s;
}
.dz-team .dz-content {
  transition: all 0.5s;
  position: relative;
}
.dz-team .dz-media img {
  width: 100%;
}
.dz-team .dz-position {
  display: block;
}

.dz-team.style-1 {
  box-shadow: 0px 15px 55px rgba(34, 34, 34, 0.15);
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
}
.dz-team.style-1 .dz-media {
  position: relative;
  z-index: 1;
}
.dz-team.style-1 .dz-media img {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-team.style-1 .dz-media::before {
  content: "";
  position: absolute;
  width: 200px;
  height: 200px;
  background: #000;
  top: -100px;
  left: -100px;
  opacity: 0.2;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 50%;
  transform: scale(0.5) translateX(-50%);
  z-index: 1;
}
.dz-team.style-1 .dz-content {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
}
.dz-team.style-1 .dz-content .dz-name {
  margin-bottom: 2px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-team.style-1 .dz-content .dz-position {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary);
}
.dz-team.style-1 .team-social li {
  position: relative;
}
.dz-team.style-1 .team-social li a {
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: var(--border-radius-base);
  background: var(--primary);
  color: #fff;
  font-size: 18px;
}
.dz-team.style-1 .team-social li a.share-btn {
  font-size: 22px;
}
.dz-team.style-1 .team-social .sub-team-social {
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 40px;
  border-radius: var(--border-radius-base);
  opacity: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-team.style-1 .team-social .sub-team-social li {
  margin-bottom: 8px;
}
.dz-team.style-1 .team-social .sub-team-social li a {
  color: #fff;
  display: inline-block;
  text-align: center;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  margin-bottom: -20px;
}
.dz-team.style-1 .team-social:hover li .sub-team-social {
  opacity: 1;
  bottom: 70px;
}
.dz-team.style-1 .team-social:hover li .sub-team-social li a {
  margin-bottom: 0;
}
.dz-team.style-1:hover .dz-media::before {
  transform: scale(5);
}
.dz-team.style-1:hover .dz-media img {
  transform: scale(1.1);
}

.team-detail {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  margin-bottom: 55px;
}
.team-detail .dz-media {
  width: 500px;
  min-width: 500px;
}
.team-detail .dz-info {
  width: 100%;
  margin-left: 50px;
}
.team-detail .dz-info .head {
  padding-bottom: 18px;
  margin-bottom: 15px;
  border-bottom: 2px solid #c6c6c6;
}
.team-detail .dz-info .head .title {
  font-size: 50px;
  line-height: 1.1;
}
.team-detail .dz-info .head .sub-title {
  font-weight: 400;
  font-size: 18px;
}
.team-detail .dz-info p {
  font-size: 18px;
  width: 580px;
  margin-bottom: 20px;
}
.team-detail .dz-info .team-info {
  margin-bottom: 18px;
}
.team-detail .dz-info .team-info li {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 10px;
}
.team-detail .dz-info .team-info li strong {
  font-weight: 400;
  color: var(--title);
  margin-right: 15px;
}
.team-detail .dz-info .dz-social-icon ul {
  margin-right: -20px;
}
.team-detail .dz-info .dz-social-icon ul li {
  display: inline-block;
  padding: 0 5px;
  margin-right: 20px;
}
.team-detail .dz-info .dz-social-icon ul li a {
  font-size: 20px;
}
.team-detail .dz-info .dz-social-icon ul li a i {
  color: #666666;
}
@media only screen and (max-width: 1280px) {
  .team-detail {
    margin-bottom: 40px;
  }
  .team-detail .dz-info .head .title {
    font-size: 35px;
  }
  .team-detail .dz-info p {
    font-size: 16px;
    width: 100%;
    margin-bottom: 15px;
  }
  .team-detail .dz-info .team-info li {
    font-size: 16px;
  }
}
@media only screen and (max-width: 991px) {
  .team-detail .dz-media {
    width: 250px;
    min-width: 250px;
    height: 350px;
  }
  .team-detail .dz-media img {
    height: 100%;
    object-fit: cover;
  }
  .team-detail .dz-info {
    margin-left: 30px;
  }
  .team-detail .dz-info .head {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .team-detail .dz-info .head .title {
    font-size: 25px;
  }
  .team-detail .dz-info p {
    font-size: 14px;
  }
  .team-detail .dz-info .dz-social-icon ul li a {
    font-size: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .team-detail {
    display: block;
    margin-bottom: 20px;
  }
  .team-detail .dz-media {
    width: 100%;
    min-width: 100%;
    height: 100%;
  }
  .team-detail .dz-info {
    margin-left: 0;
    margin-top: 30px;
  }
  .team-detail .dz-info .head .title {
    margin-bottom: 0;
  }
}

.team-bio {
  margin-bottom: 55px;
}
@media only screen and (max-width: 1280px) {
  .team-bio {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .team-bio {
    margin-bottom: 20px;
  }
}

.dish-gallery {
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}
.dish-gallery li {
  margin-bottom: 20px;
  z-index: 1;
  padding-left: 10px;
  padding-right: 10px;
}
.dish-gallery li img {
  width: 100%;
  min-width: 100%;
  height: 150px;
}
@media only screen and (max-width: 767px) {
  .dish-gallery {
    margin-bottom: 10px;
  }
  .dish-gallery li img {
    height: 120px;
  }
}
.dish-gallery.gallery-grid-2 li {
  width: 50%;
  padding-left: 10px;
  padding-right: 10px;
}

.team-skills p {
  width: 480px;
}
@media only screen and (max-width: 1280px) {
  .team-skills p {
    width: 100%;
  }
}

.dz-card {
  position: relative;
  background-color: #fff;
}
.dz-card .dz-info {
  padding: 30px;
  position: relative;
}
.dz-card .dz-title {
  margin-bottom: 15px;
  word-break: break-word;
}
@media only screen and (max-width: 575px) {
  .dz-card .dz-title {
    margin-bottom: 15px;
  }
}
.dz-card.blog-half {
  display: flex;
}
@media only screen and (max-width: 575px) {
  .dz-card.blog-half {
    display: block;
  }
}
.dz-card.blog-half .dz-title {
  margin-bottom: 10px;
}
.dz-card.blog-half .dz-info {
  padding: 30px;
  position: relative;
  flex: 1;
}
.dz-card.blog-half .dz-media {
  margin-bottom: 0;
}
.dz-card.blog-half .dz-media a {
  display: block;
  height: 100%;
}
.dz-card.blog-half .dz-media img {
  height: 100%;
  object-fit: cover;
}
@media only screen and (max-width: 575px) {
  .dz-card.blog-half .dz-media img {
    min-height: auto;
  }
}
.dz-card.blog-half.post-video .post-video-icon {
  width: 60px;
  height: 60px;
  font-size: 20px;
}
.dz-card.blog-half .swiper-container {
  height: 100%;
}

.dz-media.height-sm {
  height: 220px;
}
.dz-media.height-sm img {
  height: 100%;
  object-fit: cover;
}

.dz-media.height-md {
  height: 265px;
}
.dz-media.height-md img {
  height: 100%;
  object-fit: cover;
}
@media only screen and (max-width: 1199px) {
  .dz-media.height-md {
    height: 220px;
  }
}
@media only screen and (max-width: 767px) {
  .dz-media.height-md {
    height: 200px;
  }
}
.dz-media.height-md .post-swiper {
  height: 100%;
}

.dz-media.height-lg {
  height: 350px;
}
@media only screen and (max-width: 1199px) {
  .dz-media.height-lg {
    height: 300px;
  }
}
@media only screen and (max-width: 767px) {
  .dz-media.height-lg {
    height: 220px;
  }
  .dz-media.height-lg img {
    height: 100%;
    object-fit: cover;
  }
}

.dz-meta {
  margin-bottom: 10px;
}
.dz-meta ul {
  margin: 0;
  padding: 0;
}
.dz-meta ul li {
  display: inline-block;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  margin-right: 12px;
}
.dz-meta ul li i {
  font-size: 10px;
  margin-right: 5px;
  color: var(--primary);
  transform: scale(1.2);
  position: relative;
  top: 0px;
}
.dz-meta ul li a {
  color: inherit;
}
@media only screen and (max-width: 575px) {
  .dz-meta ul li {
    margin-right: 10px;
    padding-right: 10px;
  }
}

.dz-badge {
  background-color: var(--primary);
  color: #fff;
  border-radius: 3px;
  padding: 6px 15px;
  font-size: 15px;
}
.dz-badge:hover {
  color: white;
}

.post-video .post-video-icon {
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--primary);
  font-size: 30px;
  margin: 0 auto;
  background-color: #fff;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.5);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
}
@media only screen and (max-width: 575px) {
  .post-video .post-video-icon {
    height: 50px;
    width: 50px;
    line-height: 50px;
    font-size: 14px;
  }
}
.post-video .post-video-icon::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid #fff;
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 100%;
  animation: animationSignal1;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-animation: animationSignal1;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
  z-index: -1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transition: all 0.5;
  -o-transition: all 0.5;
  -webkit-transition: all 0.5;
  -ms-transition: all 0.5;
  transition: all 0.5;
}
.post-video .post-video-icon::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid #fff;
  -webkit-border-radius: 100%;
  -khtml-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  animation: animationSignal2;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-animation: animationSignal2;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
  z-index: -1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transition: all 0.5;
  -o-transition: all 0.5;
  -webkit-transition: all 0.5;
  -ms-transition: all 0.5;
  transition: all 0.5;
}

.dz-card.blog-half.sticky {
  position: relative;
  border: 0.375rem solid var(--primary);
  overflow: hidden;
}
.dz-card.blog-half.sticky .sticky-icon {
  background: var(--primary);
  width: 3.125rem;
  height: 3.125rem;
  position: absolute;
  transform: rotate(45deg);
  top: -1.563rem;
  right: -1.563rem;
  z-index: 1;
  color: #fff;
}
.dz-card.blog-half.sticky .sticky-icon i {
  position: absolute;
  bottom: 0.1875rem;
  right: 1.125rem;
  transform: rotate(5deg);
  color: #fff;
  font-size: 1rem;
}

.swiper-grid-wrapper.swiper {
  width: 100%;
  height: 690px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-grid-wrapper .dz-card.style-1 {
  height: 100%;
}
.swiper-grid-wrapper .dz-card.style-1 .dz-media {
  height: 100% !important;
}

.swiper-grid-wrapper .swiper-slide {
  height: calc((100% - 30px) / 2) !important;
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dz-card.style-1 {
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 15px 55px rgba(34, 34, 34, 0.15);
  overflow: hidden;
  background-color: #fff;
}
.dz-card.style-1 .dz-media {
  width: 100%;
  height: 100%;
}
.dz-card.style-1 .dz-media img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.dz-card.style-1 .dz-info .dz-meta ul li {
  font-weight: 500;
}
.dz-card.style-1 .dz-info .dz-meta ul li i {
  font-size: 16px;
  transform: translateY(-2px) scale(1.15);
}
.dz-card.style-1 .dz-info .dz-title {
  margin-bottom: 8px;
}
@media only screen and (max-width: 1280px) {
  .dz-card.style-1 .dz-info {
    padding: 20px;
  }
}
.dz-card.style-1.blog-half .dz-media {
  min-width: 240px;
  width: 240px;
  height: auto;
}
.dz-card.style-1.blog-half .dz-info {
  padding: 25px 30px;
  display: flex;
  flex-direction: column;
}
.dz-card.style-1.blog-half .dz-info p {
  font-size: 14px;
  line-height: 22px;
}
.dz-card.style-1.blog-half .dz-info .read-btn {
  margin-top: auto;
}
@media only screen and (max-width: 767px) {
  .dz-card.style-1.blog-half .dz-info {
    padding: 20px;
  }
  .dz-card.style-1.blog-half .dz-info .dz-title {
    font-size: 18px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-card.style-1.blog-half .dz-media {
    min-width: 100%;
    width: 100%;
    height: 250px;
  }
}

.dz-card.style-2 {
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.8s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  background: rgba(34, 34, 34, 0.05);
  box-shadow: 0px 15px 55px rgba(34, 34, 34, 0.15);
}
@media only screen and (max-width: 575px) {
  .dz-card.style-2 .dz-info {
    padding: 22px;
  }
}
.dz-card.style-2 .dz-meta {
  margin-bottom: 10px;
}
.dz-card.style-2 .dz-meta ul li {
  font-size: 15px;
  font-weight: 500;
  position: relative;
  margin-right: 35px;
}
.dz-card.style-2 .dz-meta ul li i {
  color: var(--primary);
  font-size: 12px;
}
.dz-card.style-2 .dz-meta ul li.dz-user {
  color: var(--title);
}
.dz-card.style-2 .dz-meta ul li.dz-user span {
  color: var(--primary);
}
.dz-card.style-2 .dz-meta ul li.dz-comment {
  color: var(--title);
}
.dz-card.style-2 .dz-meta ul li:last-child {
  margin-right: 0;
}
.dz-card.style-2 .dz-meta ul li:not(:last-child)::after {
  content: "";
  height: 6px;
  width: 6px;
  position: absolute;
  right: -20px;
  top: 50%;
  border-radius: 50%;
  background-color: var(--primary);
  transform: translate(0%, -50%);
}
@media only screen and (max-width: 991px) {
  .dz-card.style-2 .dz-meta ul li {
    margin-right: 15px;
  }
  .dz-card.style-2 .dz-meta ul li:not(:last-child)::after {
    display: none;
  }
}
@media only screen and (max-width: 575px) {
  .dz-card.style-2 .dz-meta ul li {
    margin-right: 9px;
  }
  .dz-card.style-2 .dz-meta ul li:not(:last-child)::after {
    display: none;
  }
}
.dz-card.style-2.blog-half {
  box-shadow: none;
}
.dz-card.style-2.blog-half .dz-date {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 1;
  background-color: var(--primary);
  color: var(--theme-text-color);
  border-radius: var(--border-radius-base);
  font-size: 14px;
  font-weight: 500;
  padding: 6px 15px;
  margin-right: 0;
  text-transform: uppercase;
  display: block;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.dz-card.style-2.blog-half .dz-info {
  padding: 25px 20px 25px 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 575px) {
  .dz-card.style-2.blog-half .dz-info {
    padding: 25px 20px;
  }
}
.dz-card.style-2.blog-half .dz-info p {
  margin-bottom: 0;
  font-size: 15px;
  line-height: 24px;
}
.dz-card.style-2.blog-half .dz-info .btn-wrapper {
  margin-top: auto;
}
.dz-card.style-2.blog-half .dz-meta {
  margin-bottom: 15px;
}
.dz-card.style-2.blog-half .dz-meta ul li {
  font-size: 15px;
  font-weight: 500;
  position: relative;
  margin-right: 35px;
}
.dz-card.style-2.blog-half .dz-meta ul li i {
  color: var(--primary);
  font-size: 12px;
}
.dz-card.style-2.blog-half .dz-meta ul li.dz-user {
  color: var(--title);
}
.dz-card.style-2.blog-half .dz-meta ul li.dz-user span {
  color: var(--primary);
}
.dz-card.style-2.blog-half .dz-meta ul li.dz-comment {
  color: var(--title);
}
.dz-card.style-2.blog-half .dz-meta ul li:last-child {
  margin-right: 0;
}
.dz-card.style-2.blog-half .dz-meta ul li:not(:last-child)::after {
  content: "";
  height: 6px;
  width: 6px;
  position: absolute;
  right: -20px;
  top: 50%;
  border-radius: 50%;
  background-color: var(--primary);
  transform: translate(0%, -50%);
}
@media only screen and (max-width: 575px) {
  .dz-card.style-2.blog-half .dz-meta ul li {
    margin-right: 9px;
  }
  .dz-card.style-2.blog-half .dz-meta ul li:not(:last-child)::after {
    display: none;
  }
}
.dz-card.style-2.blog-half .dz-media {
  overflow: hidden;
  position: relative;
  border-radius: unset;
  width: 270px;
  min-width: 270px;
}
@media only screen and (max-width: 767px) {
  .dz-card.style-2.blog-half {
    display: block;
  }
  .dz-card.style-2.blog-half .dz-media {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    height: 250px;
  }
}
.dz-card.style-2.blog-half:hover .dz-meta ul li.dz-date {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.dz-card.style-3 {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  height: 100%;
  min-height: 570px;
  background-size: cover;
  object-fit: cover;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  background-position: center;
}
.dz-card.style-3::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    359.53deg,
    #222222 0.4%,
    rgba(34, 34, 34, 0) 99.59%
  );
}
.dz-card.style-3 video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.dz-card.style-3 .dz-media {
  height: 100%;
  position: relative;
}
.dz-card.style-3 .dz-media img {
  filter: grayscale(0.1);
  height: 100%;
  object-fit: cover;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.dz-card.style-3 .dz-info {
  padding: 30px;
  position: unset;
  bottom: 0;
  left: 0;
  z-index: 1;
  margin-top: auto;
}
.dz-card.style-3 .dz-info .dz-meta {
  margin-bottom: 0;
}
.dz-card.style-3 .dz-info .dz-meta ul li {
  position: relative;
}
.dz-card.style-3 .dz-info .dz-meta ul li i {
  color: var(--primary);
  font-size: 12px;
}
.dz-card.style-3 .dz-info .dz-meta ul li.dz-date {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 1;
  background-color: var(--primary);
  color: var(--theme-text-color);
  font-weight: 500;
  border-radius: var(--border-radius-base);
  font-size: 15px;
  text-transform: uppercase;
  display: block;
  padding: 10px 25px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.dz-card.style-3 .dz-info .dz-meta ul li.dz-date:hover {
  background-color: white;
  color: var(--primary);
}
.dz-card.style-3 .dz-info .dz-meta ul li.dz-date::after {
  display: none;
}
.dz-card.style-3 .dz-info .dz-meta ul li.dz-user {
  color: var(--title);
  font-weight: 500;
  color: #fff;
  font-size: 15px;
  margin-right: 35px;
}
.dz-card.style-3 .dz-info .dz-meta ul li.dz-user span {
  color: var(--primary);
}
.dz-card.style-3 .dz-info .dz-meta ul li.dz-user i {
  color: var(--primary);
}
.dz-card.style-3 .dz-info .dz-meta ul li.dz-comment {
  color: var(--title);
  font-weight: 500;
  color: #fff;
  font-size: 15px;
}
.dz-card.style-3 .dz-info .dz-meta ul li.dz-comment i {
  color: var(--primary);
}
.dz-card.style-3 .dz-info .dz-meta ul li:not(:last-child)::after {
  content: "";
  height: 6px;
  width: 6px;
  position: absolute;
  right: -17%;
  top: 50%;
  border-radius: 50%;
  background-color: var(--primary);
  transform: translate(0%, -50%);
}
@media only screen and (max-width: 767px) {
  .dz-card.style-3 .dz-info .dz-meta ul li.dz-date {
    left: 30px;
    top: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-card.style-3 .dz-info .dz-meta ul li.dz-date {
    left: 20px;
    top: 20px;
  }
  .dz-card.style-3 .dz-info .dz-meta ul li.dz-user {
    margin-right: 0px;
  }
  .dz-card.style-3 .dz-info .dz-meta ul li:not(:last-child)::after {
    display: none;
  }
}
.dz-card.style-3:hover .dz-media img {
  transform: rotate(5deg) scale(1.2);
}
.dz-card.style-3.dz-card-large .dz-info {
  padding: 35px;
}
.dz-card.style-3.dz-card-large .dz-info .dz-meta ul li.dz-date {
  top: 35px;
  left: 35px;
}
@media only screen and (max-width: 767px) {
  .dz-card.style-3.dz-card-large .dz-info .dz-meta ul li.dz-date {
    left: 30px;
    top: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-card.style-3.dz-card-large .dz-info .dz-meta ul li.dz-date {
    left: 20px;
    top: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .dz-card.style-3 .dz-info {
    padding: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-card.style-3 {
    min-height: 450px;
  }
  .dz-card.style-3 .dz-info {
    padding: 20px;
  }
  .dz-card.style-3 .dz-info .dz-text {
    margin-bottom: 0;
  }
  .dz-card.style-3 .dz-category {
    margin-bottom: 20px;
  }
}
.dz-card.style-3.overlay {
  background-color: #000;
  opacity: 0.9;
  z-index: 1;
}
.dz-card.style-3.overlay .dz-meta li .post-date {
  color: rgba(255, 255, 255, 0.8);
}

/*=== Pages ===*/
.clients-logo {
  height: 50px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.clients-logo img {
  width: 135px;
}
.clients-logo:hover img {
  -webkit-animation: logoUpToDown 0.4s forwards;
  -moz-animation: logoUpToDown 0.4s forwards;
  animation: logoUpToDown 0.4s forwards;
}
@media only screen and (max-width: 575px) {
  .clients-logo {
    margin: 0 25px;
  }
}

@keyframes logoUpToDown {
  49% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  51% {
    opacity: 1;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--primary);
}

.service-tags a {
  padding: 10px 25px;
  border: 1px solid lightgray;
  background-color: #fff;
  color: #000;
  display: inline-block;
  margin: 9px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.service-tags a:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
  box-shadow: 0px 5px 15px -5px var(--primary);
}

.dzFormMsg,
.dzSubscribeMsg {
  position: fixed;
  right: 5%;
  bottom: 20px;
  z-index: 9999999;
}
.dzFormMsg .alert,
.dzSubscribeMsg .alert {
  margin: 0;
  position: relative;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 1199px) {
  .dzFormMsg,
  .dzSubscribeMsg {
    right: 9%;
    bottom: 17px;
  }
}
@media only screen and (max-width: 767px) {
  .dzFormMsg,
  .dzSubscribeMsg {
    right: 5%;
    bottom: 18%;
    width: 90%;
  }
}
@media only screen and (max-width: 575px) {
  .dzFormMsg,
  .dzSubscribeMsg {
    bottom: 10%;
  }
}

.calculator-listing .dzFormBmi .dzFormInner {
  position: static;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.05);
  text-align: center;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 10px;
  margin: 0;
}
@media only screen and (max-width: 768px) {
  .calculator-listing .dzFormBmi .dzFormInner {
    padding: 20px 25px;
  }
}

.video-box-sec::after {
  content: "";
  /* background-image: url(../images/background/bg-pattern1.svg); */
  position: absolute;
  left: 10px;
  width: 95px;
  bottom: -110px;
  animation: move4 5s infinite;
  height: 230px;
}

.video-box-sec::before {
  content: "";
  /* background-image: url(../images/background/bg-pattern2.svg); */
  position: absolute;
  right: 100px;
  width: 43px;
  animation: move4 3.5s infinite;
  bottom: -35px;
  height: 160px;
}

.schedule-section::after {
  content: "";
  /* background-image: url(../images/background/bg-pattern2.svg); */
  position: absolute;
  left: 50px;
  width: 43px;
  animation: move4 5s infinite;
  top: 85px;
  height: 160px;
}
@media only screen and (max-width: 1680px) {
  .schedule-section::after {
    left: 10px;
  }
}
@media only screen and (max-width: 1480px) {
  .schedule-section::after {
    display: none;
  }
}

.schedule-section .section-head .title {
  line-height: 1.6 !important;
}
@media only screen and (max-width: 1280px) {
  .schedule-section .section-head .title {
    line-height: 1.3 !important;
  }
}

.blog-section::after {
  content: "";
  /* background-image: url(../images/background/bg-pattern1.svg); */
  position: absolute;
  right: 30px;
  top: 50px;
  width: 95px;
  animation: move4 4.5s infinite;
  height: 230px;
}
@media only screen and (max-width: 1680px) {
  .blog-section::after {
    right: 10px;
  }
}
@media only screen and (max-width: 1480px) {
  .blog-section::after {
    display: none;
  }
}

.overlay-content-box {
  position: relative;
}
.overlay-content-box::before {
  content: "";
  /* background-image: url(../images/banner/bnr-3-s2.svg); */
  position: absolute;
  transform: rotateX(180deg) translateX(-100%);
  left: -15px;
  width: 40px;
  bottom: 0px;
  height: 90px;
}
@media only screen and (max-width: 575px) {
  .overlay-content-box::before {
    display: none;
  }
}

.overlay-section::after {
  content: "";
  /* background-image: url(../images/background/bg-pattern2.svg); */
  position: absolute;
  left: 75px;
  top: 200px;
  width: 43px;
  height: 160px;
  animation: move4 5s infinite;
}
@media only screen and (max-width: 1800px) {
  .overlay-section::after {
    left: 35px;
  }
}
@media only screen and (max-width: 1680px) {
  .overlay-section::after {
    left: 10px;
  }
}
@media only screen and (max-width: 1480px) {
  .overlay-section::after {
    display: none;
  }
}

.overlay-section::before {
  content: "";
  /* background-image: url(../images/background/bg-pattern1.svg); */
  position: absolute;
  right: 50px;
  animation: move4 4s infinite;
  top: 65px;
  width: 95px;
  height: 230px;
}
@media only screen and (max-width: 1199px) {
  .overlay-section::before {
    display: none;
  }
}

.contact-section {
  padding-top: 60px;
}
@media only screen and (max-width: 575px) {
  .contact-section {
    padding-top: 35px;
  }
}

.blog-single {
  margin-bottom: 3.75rem;
}
.blog-single .dz-post-meta {
  margin-bottom: 1.25rem;
}
.blog-single .dz-post-text {
  padding-bottom: 5px;
}
.blog-single .dz-post-text::after {
  content: "";
  display: block;
  width: 100%;
  clear: both;
}
.blog-single .dz-post-tags {
  margin-top: 1.25rem;
}
.blog-single .dz-social-icon {
  display: flex;
  align-items: center;
}
.blog-single .dz-social-icon .title {
  margin-right: 18px;
  margin-bottom: 0;
  line-height: 1.35;
}
.blog-single .dz-post-media {
  border-radius: 0.25rem;
}
.blog-single .dz-media,
.blog-single .dz-post-media {
  text-align: center;
}
.blog-single.dz-card .post-tags {
  margin-left: -3px;
  margin-right: -3px;
}
.blog-single .post-author img {
  height: 35px;
  width: 35px;
  border-radius: 100%;
  margin-right: 10px;
}
@media only screen and (max-width: 767px) {
  .blog-single .post-author img {
    height: 27px;
    width: 27px;
    margin-right: 5px;
  }
}
.blog-single .dz-meta {
  margin-bottom: 20px;
}
@media only screen and (max-width: 767px) {
  .blog-single .dz-meta {
    margin-bottom: 8px;
  }
}
.blog-single .dz-meta ul li {
  font-size: 15px;
  font-weight: 500;
  position: relative;
  margin-right: 30px;
  margin-bottom: 5px;
}
.blog-single .dz-meta ul li i {
  font-size: 16px;
  transform: translateY(-2px) scale(1.15);
  top: 1px;
}
.blog-single .dz-meta ul li.post-date {
  padding: 4px 10px;
  font-weight: 500;
  display: inline-block;
  background: var(--primary);
  color: #fff;
  border-radius: 5px;
}
.blog-single .dz-meta ul li.dz-user {
  color: var(--title);
}
.blog-single .dz-meta ul li.dz-user span {
  color: var(--primary);
}
.blog-single .dz-meta ul li.dz-comment {
  color: var(--title);
}
.blog-single .dz-meta ul li:last-child {
  margin-right: 0;
}
.blog-single .dz-meta ul li:not(:last-child)::after {
  content: "";
  height: 6px;
  width: 6px;
  position: absolute;
  right: -17px;
  top: 50%;
  border-radius: 50%;
  background-color: var(--primary);
  transform: translate(0%, -50%);
}
@media only screen and (max-width: 575px) {
  .blog-single .dz-meta ul li {
    margin-right: 9px;
  }
  .blog-single .dz-meta ul li:not(:last-child)::after {
    display: none;
  }
}
.blog-single .post-header {
  text-align: center;
}
@media only screen and (max-width: 1280px) {
  .blog-single .post-header h1,
  .blog-single .post-header .h1 {
    max-width: 600px;
    margin: 0 auto 10px;
  }
}

.dz-post-text .widget_archive li a,
.dz-post-text .wp-block-latest-posts li a,
.dz-post-text .wp-block-categories-list li a,
.dz-post-text .wp-block-archives-list li a,
.dz-post-text .widget_categories li a,
.dz-post-text blockquote a,
.dz-post-text .wp-block-button__link {
  box-shadow: none;
  text-decoration: none;
}

.dz-post-text iframe {
  max-width: 100%;
}

.post-link-in {
  padding: 15px 50px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 22px;
  font-weight: 400;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.post-link-in:hover {
  background: var(--primary);
  color: #fff;
}
@media only screen and (max-width: 767px) {
  .post-link-in {
    padding: 10px 30px;
    font-size: 20px;
  }
}

.side-bar {
  padding-left: 20px;
  padding-bottom: 1px;
}
.side-bar .wp-block-search,
.side-bar .wp-block-archives,
.side-bar .wp-block-latest-posts,
.side-bar .wp-block-latest-comments,
.side-bar .wp-block-categories,
.side-bar .wp-block-calendar {
  margin-bottom: 0;
}
@media only screen and (max-width: 1280px) {
  .side-bar {
    padding-left: 0;
    padding-right: 0;
  }
}
.side-bar.left {
  padding-left: 0;
  padding-right: 20px;
}
@media only screen and (max-width: 1280px) {
  .side-bar.left {
    padding-right: 0;
    padding-left: 0;
  }
}

.alignnone {
  margin: 0.3125rem 0 1.563rem 0;
}
.alignnoneimg,
.alignnone.wp-caption,
.alignnone.is-resized {
  margin: 0.3125rem 0 1.563rem 0;
}

.aligncenter {
  display: block;
  margin: 0.3125rem auto 0.938rem;
  text-align: center;
}
.aligncenterimg,
.aligncenter.wp-caption,
.aligncenter.is-resized {
  display: block;
  margin: 0.3125rem auto 0.938rem;
  text-align: center;
}

.alignright {
  float: right;
  margin: 0.3125rem 0 1.563rem 1.563rem;
}
.alignrightimg,
.alignright.wp-caption,
.alignright.is-resized {
  margin: 0.3125rem 0 1.563rem 1.563rem;
  float: right;
}

.alignleft {
  float: left;
  margin: 0.3125rem 1.563rem 1.563rem 0;
}
.alignleftimg,
.alignleft.is-cropped,
.alignleft.wp-caption,
.alignleft.is-resized {
  margin: 0.3125rem 1.563rem 1.563rem 0;
  float: left;
}
@media only screen and (max-width: 575px) {
  .alignleft.side-img {
    width: 200px;
  }
}

.wp-caption {
  max-width: 100%;
  text-align: center;
}
.wp-caption img[class*="wp-image-"] {
  display: block;
  margin: 0;
}
.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 100%;
  padding: 0;
  width: auto;
}
.wp-caption p.wp-caption-text {
  font-size: 0.813rem;
  line-height: 1.125rem;
  margin: 0;
  padding: 0.625rem 0;
  text-align: left;
}

.wp-block-quote.is-large,
.wp-block-quote.is-style-large,
blockquote.wp-block-quote,
blockquote.wp-block-pullquote,
blockquote.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignleft,
blockquote {
  padding: 1.25rem 2rem 1.25rem 1.75rem;
  font-size: 1.375rem;
  color: var(--title);
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  line-height: 1.875rem;
  position: relative;
  clear: both;
  font-weight: 600;
  z-index: 1;
  border: 0;
  border-radius: 10px;
  box-shadow: 0px 15px 55px rgba(34, 34, 34, 0.15);
}
@media only screen and (max-width: 1280px) {
  .wp-block-quote.is-large,
  .wp-block-quote.is-style-large,
  blockquote.wp-block-quote,
  blockquote.wp-block-pullquote,
  blockquote.wp-block-pullquote.alignright,
  blockquote.wp-block-pullquote.alignleft,
  blockquote {
    padding: 1rem 2rem;
  }
}
@media only screen and (max-width: 575px) {
  .wp-block-quote.is-large,
  .wp-block-quote.is-style-large,
  blockquote.wp-block-quote,
  blockquote.wp-block-pullquote,
  blockquote.wp-block-pullquote.alignright,
  blockquote.wp-block-pullquote.alignleft,
  blockquote {
    margin-top: 1.7rem;
    margin-bottom: 1.7rem;
  }
}
.wp-block-quote.is-large cite,
.wp-block-quote.is-style-large cite,
blockquote.wp-block-quote cite,
blockquote.wp-block-pullquote cite,
blockquote.wp-block-pullquote.alignright cite,
blockquote.wp-block-pullquote.alignleft cite,
blockquote cite {
  font-style: normal;
  position: relative;
  display: block;
  margin-top: -0.3125rem;
  font-weight: 600;
  color: var(--primary);
  line-height: 1.5;
  font-size: 1rem;
}
.wp-block-quote.is-large cite::before,
.wp-block-quote.is-style-large cite::before,
blockquote.wp-block-quote cite::before,
blockquote.wp-block-pullquote cite::before,
blockquote.wp-block-pullquote.alignright cite::before,
blockquote.wp-block-pullquote.alignleft cite::before,
blockquote cite::before {
  content: "";
  margin-right: 0.625rem;
  width: 36px;
  height: 2px;
  background: var(--title);
  display: inline-block;
  vertical-align: middle;
}
.wp-block-quote.is-large b,
.wp-block-quote.is-large strong,
.wp-block-quote.is-large .strong,
.wp-block-quote.is-style-large b,
.wp-block-quote.is-style-large strong,
.wp-block-quote.is-style-large .strong,
blockquote.wp-block-quote b,
blockquote.wp-block-quote strong,
blockquote.wp-block-quote .strong,
blockquote.wp-block-pullquote b,
blockquote.wp-block-pullquote strong,
blockquote.wp-block-pullquote .strong,
blockquote.wp-block-pullquote.alignright b,
blockquote.wp-block-pullquote.alignright strong,
blockquote.wp-block-pullquote.alignright .strong,
blockquote.wp-block-pullquote.alignleft b,
blockquote.wp-block-pullquote.alignleft strong,
blockquote.wp-block-pullquote.alignleft .strong,
blockquote b,
blockquote strong,
blockquote .strong {
  color: inherit;
}
.wp-block-quote.is-large::after,
.wp-block-quote.is-style-large::after,
blockquote.wp-block-quote::after,
blockquote.wp-block-pullquote::after,
blockquote.wp-block-pullquote.alignright::after,
blockquote.wp-block-pullquote.alignleft::after,
blockquote::after {
  content: "";
  width: 5px;
  height: 110px;
  border-radius: 50px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: var(--primary);
  position: absolute;
  box-shadow: 0 0 30px 0 var(--rgba-primary-2);
}
.wp-block-quote.is-large .quotes,
.wp-block-quote.is-style-large .quotes,
blockquote.wp-block-quote .quotes,
blockquote.wp-block-pullquote .quotes,
blockquote.wp-block-pullquote.alignright .quotes,
blockquote.wp-block-pullquote.alignleft .quotes,
blockquote .quotes {
  position: absolute;
  right: 20px;
  bottom: 17px;
  font-size: 64px;
  color: var(--primary);
}
@media only screen and (max-width: 1280px) {
  .wp-block-quote.is-large .quotes,
  .wp-block-quote.is-style-large .quotes,
  blockquote.wp-block-quote .quotes,
  blockquote.wp-block-pullquote .quotes,
  blockquote.wp-block-pullquote.alignright .quotes,
  blockquote.wp-block-pullquote.alignleft .quotes,
  blockquote .quotes {
    bottom: 5px;
  }
}
@media only screen and (max-width: 575px) {
  .wp-block-quote.is-large .quotes,
  .wp-block-quote.is-style-large .quotes,
  blockquote.wp-block-quote .quotes,
  blockquote.wp-block-pullquote .quotes,
  blockquote.wp-block-pullquote.alignright .quotes,
  blockquote.wp-block-pullquote.alignleft .quotes,
  blockquote .quotes {
    display: none;
  }
}
.wp-block-quote.is-large.wp-block-pullquote.alignleft,
.wp-block-quote.is-style-large.wp-block-pullquote.alignleft,
blockquote.wp-block-quote.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.alignright.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.alignleft.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.alignleft {
  margin: 0rem 1.563rem 1.563rem 0rem;
}
.wp-block-quote.is-large.wp-block-pullquote.alignright,
.wp-block-quote.is-style-large.wp-block-pullquote.alignright,
blockquote.wp-block-quote.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignright.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignleft.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignright {
  margin: 0rem 0 1.563rem 1.563rem;
}
.wp-block-quote.is-large.style-1,
.wp-block-quote.is-style-large.style-1,
blockquote.wp-block-quote.style-1,
blockquote.wp-block-pullquote.style-1,
blockquote.wp-block-pullquote.alignright.style-1,
blockquote.wp-block-pullquote.alignleft.style-1,
blockquote.style-1 {
  background: #fff;
  color: var(--primary);
  padding: 25px 32px 20px 100px;
  box-shadow: 0px 15px 50px rgba(34, 34, 34, 0.15);
  margin-top: 22px;
  margin-bottom: 20px;
}
.wp-block-quote.is-large.style-1 p,
.wp-block-quote.is-style-large.style-1 p,
blockquote.wp-block-quote.style-1 p,
blockquote.wp-block-pullquote.style-1 p,
blockquote.wp-block-pullquote.alignright.style-1 p,
blockquote.wp-block-pullquote.alignleft.style-1 p,
blockquote.style-1 p {
  font-size: 22px;
  line-height: 35px;
  color: var(--title);
}
.wp-block-quote.is-large.style-1::after,
.wp-block-quote.is-style-large.style-1::after,
blockquote.wp-block-quote.style-1::after,
blockquote.wp-block-pullquote.style-1::after,
blockquote.wp-block-pullquote.alignright.style-1::after,
blockquote.wp-block-pullquote.alignleft.style-1::after,
blockquote.style-1::after {
  display: none;
}
.wp-block-quote.is-large.style-1 .quotes,
.wp-block-quote.is-style-large.style-1 .quotes,
blockquote.wp-block-quote.style-1 .quotes,
blockquote.wp-block-pullquote.style-1 .quotes,
blockquote.wp-block-pullquote.alignright.style-1 .quotes,
blockquote.wp-block-pullquote.alignleft.style-1 .quotes,
blockquote.style-1 .quotes {
  right: auto;
  left: 25px;
  bottom: auto;
  top: 25px;
  font-size: 52px;
  transform: rotate(180deg);
}
.wp-block-quote.is-large.style-1 .block-author,
.wp-block-quote.is-style-large.style-1 .block-author,
blockquote.wp-block-quote.style-1 .block-author,
blockquote.wp-block-pullquote.style-1 .block-author,
blockquote.wp-block-pullquote.alignright.style-1 .block-author,
blockquote.wp-block-pullquote.alignleft.style-1 .block-author,
blockquote.style-1 .block-author {
  padding-left: 40px;
  position: relative;
  margin-top: 10px;
}
.wp-block-quote.is-large.style-1 .block-author::after,
.wp-block-quote.is-style-large.style-1 .block-author::after,
blockquote.wp-block-quote.style-1 .block-author::after,
blockquote.wp-block-pullquote.style-1 .block-author::after,
blockquote.wp-block-pullquote.alignright.style-1 .block-author::after,
blockquote.wp-block-pullquote.alignleft.style-1 .block-author::after,
blockquote.style-1 .block-author::after {
  content: "";
  width: 35px;
  height: 2px;
  left: 0;
  top: 50%;
  background: var(--primary);
  position: absolute;
  box-shadow: 0 0 30px 0 var(--rgba-primary-2);
}
@media only screen and (max-width: 1280px) {
  .wp-block-quote.is-large.style-1 p,
  .wp-block-quote.is-style-large.style-1 p,
  blockquote.wp-block-quote.style-1 p,
  blockquote.wp-block-pullquote.style-1 p,
  blockquote.wp-block-pullquote.alignright.style-1 p,
  blockquote.wp-block-pullquote.alignleft.style-1 p,
  blockquote.style-1 p {
    font-size: 18px;
    line-height: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .wp-block-quote.is-large.style-1 p,
  .wp-block-quote.is-style-large.style-1 p,
  blockquote.wp-block-quote.style-1 p,
  blockquote.wp-block-pullquote.style-1 p,
  blockquote.wp-block-pullquote.alignright.style-1 p,
  blockquote.wp-block-pullquote.alignleft.style-1 p,
  blockquote.style-1 p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 575px) {
  .wp-block-quote.is-large.style-1,
  .wp-block-quote.is-style-large.style-1,
  blockquote.wp-block-quote.style-1,
  blockquote.wp-block-pullquote.style-1,
  blockquote.wp-block-pullquote.alignright.style-1,
  blockquote.wp-block-pullquote.alignleft.style-1,
  blockquote.style-1 {
    padding: 20px;
  }
}

blockquote.wp-block-pullquote:not(.is-style-solid-color) {
  background: #5608e0;
}

.wp-caption-text {
  font-size: 0.875rem;
  line-height: 1.3;
}

.dz-card.blog-single .dz-post-text blockquote p,
.dz-page-text blockquote p,
blockquote p {
  line-height: 1.5;
  color: var(--title);
  margin-bottom: 1rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.375rem;
}
.dz-card.blog-single .dz-post-text blockquote p cite,
.dz-page-text blockquote p cite,
blockquote p cite {
  margin-top: 20px;
}
@media only screen and (max-width: 1280px) {
  .dz-card.blog-single .dz-post-text blockquote p,
  .dz-page-text blockquote p,
  blockquote p {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 767px) {
  .dz-card.blog-single .dz-post-text blockquote p,
  .dz-page-text blockquote p,
  blockquote p {
    font-size: 1rem;
  }
}

.dz-page-text {
  padding-bottom: 30px !important;
}

.dz-card.blog-single .dz-post-text blockquote.style-1 p,
.dz-page-text blockquote.style-1 p,
blockquote.style-1 p {
  color: var(--primary);
  margin-bottom: 1.5rem;
}

.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
  max-width: 100%;
  height: auto;
}

/* Gutenberg Gallery */
/* Gutenberg Css */
/* .dz-blog.blog-single,
.dz-blog.blog-single + #comment-list */
.min-container,
.dz-page-text,
.dz-page-text + #comment-list,
.dz-page-text + .paginate-links,
.dz-page-text + #comment-list + .paginate-links {
  max-width: 53.125rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.938rem;
  padding-right: 0.938rem;
}

.dz-card.blog-single.sidebar + #comment-list {
  padding: 0;
  max-width: 100%;
}

.max-container {
  max-width: 93.75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 3.4375rem;
  padding-right: 3.4375rem;
}

.dz-page-text + .paginate-links,
.dz-page-text + #comment-list + .paginate-links,
.dz-page-text + #comment-list,
.dz-page-text {
  padding: 0;
}

.dz-page-text.sidebar {
  width: 100%;
  padding: 0;
  max-width: 100%;
}

.dz-page-text > ol,
.dz-page-text > ul,
.dz-page-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text > ul {
  margin-bottom: 1.5rem;
}

.dz-page-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text > .wp-block-gallery {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.dz-page-text > ol,
.dz-page-text > ul,
.dz-card.blog-single .dz-post-text > ol,
.dz-card.blog-single .dz-post-text > ul,
.dz-card.blog-single .dz-post-text.text > ol,
.dz-card.blog-single .dz-post-text.text > ul {
  padding-left: 1.5rem;
}
.dz-page-text > ol ol,
.dz-page-text > ol ul,
.dz-page-text > ul ol,
.dz-page-text > ul ul,
.dz-card.blog-single .dz-post-text > ol ol,
.dz-card.blog-single .dz-post-text > ol ul,
.dz-card.blog-single .dz-post-text > ul ol,
.dz-card.blog-single .dz-post-text > ul ul,
.dz-card.blog-single .dz-post-text.text > ol ol,
.dz-card.blog-single .dz-post-text.text > ol ul,
.dz-card.blog-single .dz-post-text.text > ul ol,
.dz-card.blog-single .dz-post-text.text > ul ul {
  padding-left: 1.563rem;
}
.dz-page-text > ol.wp-block-social-links,
.dz-page-text > ul.wp-block-social-links,
.dz-card.blog-single .dz-post-text > ol.wp-block-social-links,
.dz-card.blog-single .dz-post-text > ul.wp-block-social-links,
.dz-card.blog-single .dz-post-text.text > ol.wp-block-social-links,
.dz-card.blog-single .dz-post-text.text > ul.wp-block-social-links {
  padding-left: 0;
}

.dz-page-text > ol li,
.dz-page-text > ul li,
.dz-card.blog-single .dz-post-text > ol li,
.dz-card.blog-single .dz-post-text > ul li,
.dz-card.blog-single .dz-post-text > ol li,
.dz-card.blog-single .dz-post-text > ul li,
.dz-card.blog-single .dz-post-text.text > ol li,
.dz-card.blog-single .dz-post-text.text > ul li {
  padding: 0.5rem 0.5rem;
  position: relative;
  list-style: inherit;
}

.dz-page-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text.text > .wp-block-gallery {
  padding: 0;
  list-style: none;
}

.dz-post-text > .wp-block-gallery .wp-block-image,
.dz-page-text > .wp-block-gallery .wp-block-image,
.dz-page-text > .wp-block-gallery li,
.dz-card.blog-single .dz-post-text > .wp-block-gallery li,
.dz-card.blog-single .dz-post-text.text > .wp-block-gallery li {
  padding: 0;
  list-style: none;
  margin: 0.25rem;
}

.dz-page-text > .wp-block-gallery li::before,
.dz-card.blog-single .dz-post-text > .wp-block-gallery li::before,
.dz-card.blog-single .dz-post-text.text > .wp-block-gallery li::before {
  content: none;
}

.wp-block-gallery .wp-block-image,
.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  margin: 0 0.5rem 0.5rem 0;
}

.wp-block-gallery.columns-4 .blocks-gallery-image,
.wp-block-gallery.columns-4 .wp-block-image,
.wp-block-gallery.columns-4 .blocks-gallery-item,
.wp-block-gallery.columns-3 .blocks-gallery-image,
.wp-block-gallery.columns-3 .wp-block-image,
.wp-block-gallery.columns-3 .blocks-gallery-item {
  margin-right: 0.5rem;
}

.content-spacious-full .wp-block-gallery {
  margin-left: calc(-12.5rem - (0.313rem / 2));
  margin-right: calc(-12.5rem - (0.313rem / 2));
  width: calc(100% + 25rem + 0.313rem);
  max-width: initial;
}

.wp-block-gallery.alignwide,
.alignfull,
.alignwide {
  margin-left: calc(-6.25rem - (0.313rem / 2));
  margin-right: calc(-6.25rem - (0.313rem / 2));
  width: calc(100% + 12.5rem + 0.313rem);
  max-width: initial;
}

.wp-block-gallery.alignfull,
.wp-block-gallery.alignfullwide,
.alignfull,
.alignfullwide {
  margin-left: calc(
    50% - 15px - calc(50vw - 30px) - (var(--content-share-ml, 0px) / 2)
  );
  width: calc(100vw - 30px);
  max-width: calc(100vw - 30px);
  box-sizing: border-box;
}
.wp-block-gallery.alignfull img,
.wp-block-gallery.alignfullwide img,
.alignfull img,
.alignfullwide img {
  width: 100%;
}
.wp-block-gallery.alignfull:not(.dz-media),
.wp-block-gallery.alignfullwide:not(.dz-media),
.alignfull:not(.dz-media),
.alignfullwide:not(.dz-media) {
  margin-top: 25px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  .wp-block-gallery.alignfull:not(.dz-media),
  .wp-block-gallery.alignfullwide:not(.dz-media),
  .alignfull:not(.dz-media),
  .alignfullwide:not(.dz-media) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.dz-page-text h1,
.dz-page-text .h1,
.dz-post-text h1,
.dz-post-text .h1,
.thm-unit-test .dz-post-text h1,
.thm-unit-test .dz-post-text .h1 {
  margin-bottom: 1rem;
  font-weight: 600;
}

.dz-page-text h2,
.dz-page-text .h2,
.dz-post-text h2,
.dz-post-text .h2,
.thm-unit-test .dz-post-text h2,
.thm-unit-test .dz-post-text .h2 {
  margin-bottom: 1rem;
  font-weight: 600;
}
@media only screen and (max-width: 767px) {
  .dz-page-text h2,
  .dz-page-text .h2,
  .dz-post-text h2,
  .dz-post-text .h2,
  .thm-unit-test .dz-post-text h2,
  .thm-unit-test .dz-post-text .h2 {
    font-size: 1.5rem;
  }
}

.dz-page-text h3,
.dz-page-text .h3,
.dz-post-text h3,
.dz-post-text .h3,
.thm-unit-test .dz-post-text h3,
.thm-unit-test .dz-post-text .h3 {
  margin-bottom: 1rem;
  font-weight: 600;
}

.dz-page-text h4,
.dz-page-text .h4,
.dz-page-text .wp-block-search .wp-block-search__label,
.wp-block-search .dz-page-text .wp-block-search__label,
.dz-post-text h4,
.dz-post-text .h4,
.dz-post-text .wp-block-search .wp-block-search__label,
.wp-block-search .dz-post-text .wp-block-search__label,
.thm-unit-test .dz-post-text h4,
.thm-unit-test .dz-post-text .h4,
.thm-unit-test .dz-post-text .wp-block-search .wp-block-search__label,
.wp-block-search .thm-unit-test .dz-post-text .wp-block-search__label {
  margin-bottom: 0.75rem;
  font-weight: 600;
}

.dz-page-text h5,
.dz-page-text .h5,
.dz-post-text h5,
.dz-post-text .h5,
.thm-unit-test .dz-post-text h5,
.thm-unit-test .dz-post-text .h5 {
  margin-bottom: 0.75rem;
}

.dz-page-text h6,
.dz-page-text .h6,
.dz-post-text h6,
.dz-post-text .h6,
.thm-unit-test .dz-post-text h6,
.thm-unit-test .dz-post-text .h6 {
  margin-bottom: 0.625rem;
}

.wp-block-code,
pre.wp-block-code,
pre {
  margin: 0 0 1.563rem;
  padding: 1.25rem;
  color: #fff;
  background-color: #212326;
  white-space: pre;
  font-size: 0.938rem;
  border-radius: 0.1875rem;
}

.wp-block-code > code {
  font-family: inherit;
  color: unset;
  padding: unset;
  border: unset;
  border-radius: unset;
}

.dz-page-text,
.dz-page-text ul li,
.dz-page-text ol li,
.dz-card.blog-single .dz-post-text,
.dz-card.blog-single .dz-post-text ul li,
.dz-card.blog-single .dz-post-text ol li,
.dz-card.blog-single
  .dz-card.blog-single
  .dz-post-text
  p:not(.has-text-color):not(.has-text-align-center):not(
    .has-text-align-left
  ):not(.has-text-align-right) {
  font-size: 1rem;
  line-height: 1.7;
}

.wp-block-cover,
.wp-block-cover-image {
  color: #fff;
}

.wp-block-cover p:last-child,
.wp-block-cover-image p:last-child {
  margin-bottom: 0;
}

.wp-block-quote.has-text-align-right {
  border-right: 0;
}

.dz-page-text ul li,
.dz-page-text ol li,
.dz-card.blog-single .dz-post-text ul li,
.dz-card.blog-single .dz-post-text ol li {
  position: relative;
}

.open-on-hover-click::before {
  display: none !important;
}

.paginate-links {
  display: flex;
  align-items: center;
  margin: 1.5rem 0 4rem 0;
}
.paginate-links > a,
.paginate-links > span {
  margin: 0 0 0 0.625rem;
  position: relative;
  border: 0rem solid #6cc000;
  color: #777777;
  display: inline-block;
  font-size: 1rem;
  font-weight: 500;
  line-height: 50px;
  min-width: 50px;
  height: 50px;
  text-align: center;
  text-transform: capitalize;
  transition: all 500ms ease 0s;
  background: #f3f4f6;
  border-radius: var(--border-radius-base);
}
.paginate-links .current,
.paginate-links > a:hover,
.paginate-links > span:hover {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
  box-shadow: 0px 5px 12px var(--rgba-primary-4);
}

.wp-block-columns {
  margin-bottom: 0;
}

/* Gutenberg Gallery */
.wp-block-gallery {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
}

.wp-block-gallery .wp-block-image,
.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin: 0 1rem 1rem 0;
  position: relative;
}

.wp-block-gallery .wp-block-image figure,
.wp-block-gallery .blocks-gallery-image figure,
.wp-block-gallery .blocks-gallery-item figure {
  height: 100%;
  margin: 0;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .wp-block-gallery .wp-block-image figure,
  .wp-block-gallery .blocks-gallery-image figure,
  .wp-block-gallery .blocks-gallery-item figure {
    align-items: flex-end;
    display: flex;
    justify-content: flex-start;
  }
}

.wp-block-gallery .wp-block-image img,
.wp-block-gallery .blocks-gallery-image img,
.wp-block-gallery .wp-block-image img,
.wp-block-gallery .blocks-gallery-item img {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%;
  border-radius: 0;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .wp-block-gallery .wp-block-image img,
  .wp-block-gallery .blocks-gallery-image img,
  .wp-block-gallery .blocks-gallery-item img {
    width: auto;
  }
}

.wp-block-gallery .wp-block-image figcaption,
.wp-block-gallery .blocks-gallery-image figcaption,
.wp-block-gallery .blocks-gallery-item figcaption {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.3) 60%,
    transparent
  );
  bottom: 0;
  color: #fff;
  font-size: 0.813rem;
  max-height: 100%;
  overflow: auto;
  padding: 2.5rem 0.625rem 0.3125rem;
  position: absolute;
  text-align: center;
  width: 100%;
}

.wp-block-gallery .wp-block-image figcaption img,
.wp-block-gallery .blocks-gallery-image figcaption img,
.wp-block-gallery .blocks-gallery-item figcaption img {
  display: inline;
}

.wp-block-gallery.is-cropped .blocks-gallery-image a,
.wp-block-gallery.is-cropped .blocks-gallery-image img,
.wp-block-gallery.is-cropped .wp-block-image a,
.wp-block-gallery.is-cropped .blocks-gallery-item a,
.wp-block-gallery.is-cropped .wp-block-image img,
.wp-block-gallery.is-cropped .blocks-gallery-item img {
  width: 100%;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .wp-block-gallery.is-cropped .blocks-gallery-image a,
  .wp-block-gallery.is-cropped .blocks-gallery-image img,
  .wp-block-gallery.is-cropped .wp-block-image img,
  .wp-block-gallery.is-cropped .wp-block-image a,
  .wp-block-gallery.is-cropped .blocks-gallery-item a,
  .wp-block-gallery.is-cropped .blocks-gallery-item img {
    -o-object-fit: cover;
    flex: 1;
    height: 100%;
    object-fit: cover;
  }
}

.wp-block-gallery .wp-block-image,
.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  width: calc(50% - 30px);
}
@media only screen and (max-width: 1280px) {
  .wp-block-gallery .wp-block-image,
  .wp-block-gallery .blocks-gallery-image,
  .wp-block-gallery .blocks-gallery-item {
    width: calc(50% - 10px);
  }
}

.wp-block-gallery .wp-block-image:nth-of-type(2n),
.wp-block-gallery .blocks-gallery-image:nth-of-type(2n),
.wp-block-gallery .blocks-gallery-item:nth-of-type(2n) {
  margin-right: 0;
}

.wp-block-gallery.columns-1 .wp-block-image,
.wp-block-gallery.columns-1 .blocks-gallery-image,
.wp-block-gallery.columns-1 .blocks-gallery-item {
  margin-right: 0;
  width: 100%;
}

@media only screen and (max-width: 1280px) {
  .alignwide {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    max-width: 100%;
  }
  .alignwide .alignleft img {
    width: 100%;
  }
  .alignwide .alignleft {
    margin: 0.3125rem 0rem 1.563rem 0;
    float: none;
  }
}

@media (min-width: 37.5rem) {
  .wp-block-gallery.columns-2 .wp-block-image,
  .wp-block-gallery.columns-2 .blocks-gallery-image,
  .wp-block-gallery.columns-2 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(50% - 1.875rem);
  }
  .wp-block-gallery.columns-3 .wp-block-image,
  .wp-block-gallery.columns-3 .blocks-gallery-image,
  .wp-block-gallery.columns-3 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(33.33333% - 1.875rem);
  }
  .wp-block-gallery.columns-4 .wp-block-image,
  .wp-block-gallery.columns-4 .blocks-gallery-image,
  .wp-block-gallery.columns-4 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(25% - 1.875rem);
  }
  .wp-block-gallery.columns-5 .wp-block-image,
  .wp-block-gallery.columns-5 .blocks-gallery-image,
  .wp-block-gallery.columns-5 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(20% - 1.875rem);
  }
  .wp-block-gallery.columns-6 .wp-block-image,
  .wp-block-gallery.columns-6 .blocks-gallery-image,
  .wp-block-gallery.columns-6 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(16.66667% - 1.875rem);
  }
  .wp-block-gallery.columns-7 .wp-block-image,
  .wp-block-gallery.columns-7 .blocks-gallery-image,
  .wp-block-gallery.columns-7 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(14.28571% - 1.875rem);
  }
  .wp-block-gallery.columns-8 .wp-block-image,
  .wp-block-gallery.columns-8 .blocks-gallery-image,
  .wp-block-gallery.columns-8 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(12.5% - 1.875rem);
  }
  .wp-block-gallery.columns-1 .wp-block-image:nth-of-type(1n),
  .wp-block-gallery.columns-1 .blocks-gallery-image:nth-of-type(1n),
  .wp-block-gallery.columns-1 .blocks-gallery-item:nth-of-type(1n),
  .wp-block-gallery.columns-2 .blocks-gallery-image:nth-of-type(2n),
  .wp-block-gallery.columns-2 .wp-block-image:nth-of-type(2n),
  .wp-block-gallery.columns-2 .blocks-gallery-item:nth-of-type(2n),
  .wp-block-gallery.columns-3 .wp-block-image:nth-of-type(3n),
  .wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(3n),
  .wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(3n),
  .wp-block-gallery.columns-4 .blocks-gallery-image:nth-of-type(4n),
  .wp-block-gallery.columns-4 .wp-block-image:nth-of-type(4n),
  .wp-block-gallery.columns-4 .blocks-gallery-item:nth-of-type(4n),
  .wp-block-gallery.columns-5 .blocks-gallery-image:nth-of-type(5n),
  .wp-block-gallery.columns-5 .wp-block-image:nth-of-type(5n),
  .wp-block-gallery.columns-5 .blocks-gallery-item:nth-of-type(5n),
  .wp-block-gallery.columns-6 .blocks-gallery-image:nth-of-type(6n),
  .wp-block-gallery.columns-6 .wp-block-image:nth-of-type(6n),
  .wp-block-gallery.columns-6 .blocks-gallery-item:nth-of-type(6n),
  .wp-block-gallery.columns-7 .wp-block-image:nth-of-type(7n),
  .wp-block-gallery.columns-7 .blocks-gallery-image:nth-of-type(7n),
  .wp-block-gallery.columns-7 .blocks-gallery-item:nth-of-type(7n),
  .wp-block-gallery.columns-8 .wp-block-image:nth-of-type(8n),
  .wp-block-gallery.columns-8 .blocks-gallery-image:nth-of-type(8n),
  .wp-block-gallery.columns-8 .blocks-gallery-item:nth-of-type(8n) {
    margin-right: 0;
  }
}

.wp-block-gallery .wp-block-image:last-child,
.wp-block-gallery .blocks-gallery-image:last-child,
.wp-block-gallery .blocks-gallery-item:last-child {
  margin-right: 0;
}

.wp-block-gallery .wp-block-image.has-add-item-button,
.wp-block-gallery .blocks-gallery-item.has-add-item-button {
  width: 100%;
}

.wp-block-image.alignfullwide img {
  border-radius: 0;
}

.wp-block-image img {
  border-radius: var(--border-radius-base);
}

.wp-block-cover,
.wp-block-cover-image,
.wp-block-embed,
.wp-block-image {
  margin-bottom: 1.875rem;
  margin-top: 1.875rem;
}

.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
  max-width: 19.0625rem;
  width: 100%;
}

.wp-block-gallery.aligncenter,
.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
  display: flex;
}

.wp-block-gallery.aligncenter .wp-block-image figure,
.wp-block-gallery.aligncenter .blocks-gallery-item figure {
  justify-content: center;
}

.blog-single.dz-blog.sidebar .dz-post-text .wp-block-gallery {
  width: calc(100% + 30px);
}
@media only screen and (max-width: 1280px) {
  .blog-single.dz-blog.sidebar .dz-post-text .wp-block-gallery {
    width: calc(100% + 10px);
  }
}

.blog-overlap {
  background: #fff;
  margin-top: -8.75rem;
  padding: 1.25rem 1.25rem 0;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
}

.blog-single.dz-card.sidebar {
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.dz-post-text table,
.dz-page-text table,
.wp-block-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  font-size: 1rem;
  margin-bottom: 1.875rem;
}

.wp-block-image figcaption,
.dz-post-text figcaption,
.dz-page-text figcaption {
  font-size: 0.875rem;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  margin-top: 0.625rem;
}

.dz-post-text table tbody tr:nth-of-type(odd),
.dz-page-text table tbody tr:nth-of-type(odd),
.wp-block-table tbody tr:nth-of-type(odd) {
  background-color: #fafafa;
}

.dz-post-text td,
.dz-post-text th,
.dz-page-text td,
.dz-page-text th,
.wp-block-table td,
.wp-block-table th {
  padding: 0.625rem 0.938rem;
  border: 0.0625rem solid #e4e4e4;
  border-right: 0;
  border-left: 0;
}

.wp-block-media-text {
  margin-bottom: 30px;
}
.wp-block-media-text .wp-block-media-text__content {
  padding: 0 1.875rem;
}

.wp-block-column ol li::before,
.wp-block-column ul li::before {
  content: none !important;
}

.wp-block-pullquote {
  padding: 1em 0;
  border-top: 0;
  border-bottom: 0;
}
.wp-block-pullquote.alignright,
.wp-block-pullquote.alignleft {
  padding: 0;
  border-top: 0;
  border-bottom: 0;
}
.wp-block-pullquote.alignright blockquote,
.wp-block-pullquote.alignleft blockquote {
  margin: 0;
}

.dz-title-text {
  font-size: 14px;
  margin-bottom: 45px;
}

.dz-post-text .wp-block-cover-image-text,
.dz-post-text .wp-block-cover-text,
.dz-post-text section.wp-block-cover-image h2,
.dz-post-text section.wp-block-cover-image .h2,
.dz-page-text .wp-block-cover-image-text,
.dz-page-text .wp-block-cover-text,
.dz-page-text section.wp-block-cover-image h2,
.dz-page-text section.wp-block-cover-image .h2 {
  color: #fff;
}

.dz-post-text .bootstrap-select .dropdown-menu li a,
.dz-page-text .bootstrap-select .dropdown-menu li a {
  box-shadow: none;
  font-size: 0.875rem;
  font-family: "Poppins", sans-serif;
}

.dz-post-text .wp-block-calendar a {
  box-shadow: none !important;
  text-decoration: unset;
}

.wp-block-search,
.wp-block-archives,
.wp-block-latest-posts,
.wp-block-latest-comments,
.wp-block-categories,
.wp-block-calendar {
  margin-bottom: 2.5rem;
}

/* Admin Bar */
.admin-bar .is-fixed .main-bar {
  top: 1.875rem;
}

.wp-block-quote.is-style-large cite::before {
  display: none;
}

/* Theme Unit Test */
.post-password-form label {
  font-size: 1rem;
}

.post-password-form input[type="submit"] {
  padding: 0.625rem 1.563rem;
  background-color: var(--primary);
  border: none;
  height: 2.8125rem;
  font-weight: 600;
  font-size: 0.875rem;
  outline: none;
}

.post-password-form input[type="submit"]:hover {
  background-color: var(--primary);
}

.post-password-form input[type="password"] {
  height: 2.8125rem;
  border: 0.0625rem solid #ced4da;
}

.wp-block-tag-cloud a {
  position: relative;
  border: 2px solid;
  padding: 10px 20px;
  display: inline-block;
  margin: 0 10px 10px 0;
  font-size: 14px;
  color: inherit;
  line-height: 1.4;
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius-base);
}
.wp-block-tag-cloud a:hover {
  background-color: var(--primary-hover);
  border-color: var(--primary-hover);
  color: #fff;
}

.wp-block-search .wp-block-search__button {
  background: var(--primary);
  border: 0;
  color: #fff;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.625rem 1.563rem;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.wp-block-search .wp-block-search__input {
  border: 0.0625rem solid #e1e6eb;
  height: 2.8125rem;
  padding: 0.625rem 1.25rem;
  font-size: 0.875rem;
  outline: none;
}

.wp-block-latest-comments {
  padding: 0 !important;
}
.wp-block-latest-comments .avatar {
  width: 3.4375rem;
  height: 3.4375rem;
  border-radius: 3.4375rem;
}
.wp-block-latest-comments
  .wp-block-latest-comments__comment
  .wp-block-latest-comments__comment-excerpt {
  margin-left: 4.375rem;
}
.wp-block-latest-comments
  .wp-block-latest-comments__comment
  .wp-block-latest-comments__comment-meta {
  color: inherit;
  font-size: 1.063rem;
  line-height: 1.7;
  margin-bottom: 0;
}
.wp-block-latest-comments
  .wp-block-latest-comments__comment
  .wp-block-latest-comments__comment-meta
  a {
  color: inherit;
  box-shadow: none;
  text-decoration: none;
}
.wp-block-latest-comments
  img
  + article
  .wp-block-latest-comments__comment
  .wp-block-latest-comments__comment-meta {
  margin-left: 4.375rem;
}
.wp-block-latest-comments .wp-block-latest-comments__comment-meta time {
  display: block;
  width: 100%;
  color: #9fa1a4;
  font-size: 0.813rem;
  font-weight: 400;
}
.wp-block-latest-comments .wp-block-latest-comments__comment-excerpt p {
  font-size: 1rem !important;
  line-height: 1.5 !important;
  margin-bottom: 0.625rem !important;
}
.wp-block-latest-comments li {
  padding: 0 !important;
  border-bottom: 0.0625rem solid #eee;
  padding-bottom: 0.625rem !important;
  margin-bottom: 0.625rem;
}
.wp-block-latest-comments li::before {
  content: none !important;
}

.wp-block-latest-comments__comment-avatar {
  width: 3.4375rem;
  height: 3.4375rem;
  border-radius: 3.4375rem;
}

.comment-content.dz-page-text {
  max-width: 100%;
  padding-bottom: 0 !important;
}
.comment-content.dz-page-text > :last-child {
  margin-bottom: 0;
}

.wp-block-rss {
  padding: 0 !important;
}
.wp-block-rss .wp-block-rss__item {
  padding: 0.3125rem 0 !important;
  border-bottom: 0.0625rem solid #eee;
}
.wp-block-rss .wp-block-rss__item::before {
  content: none !important;
}
.wp-block-rss .wp-block-rss__item a {
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
  box-shadow: unset !important;
  font-weight: 600;
  color: var(--title);
  text-decoration: none;
}

@media only screen and (max-width: 75rem) {
  .dz-page-text .wp-block-gallery,
  .dz-card.blog-single .dz-post-text .wp-block-gallery {
    margin-left: calc(-6.25rem - (0.625rem / 2));
    margin-right: calc(-6.25rem - (0.625rem / 2));
    width: calc(100% + 12.5rem + 0.625rem);
  }
}

@media only screen and (max-width: 1280px) {
  .dz-page-text .wp-block-gallery,
  .dz-blog.blog-single .dz-post-text .wp-block-gallery {
    margin-left: calc(-5rem - (0.625rem / 2));
    margin-right: calc(-5rem - (0.625rem / 2));
    width: calc(100% + 10rem + 0.625rem);
  }
}

@media only screen and (max-width: 61.9375rem) {
  .admin-bar .mo-left .header-nav {
    top: 1.875rem;
    height: calc(100vh - 1.875rem) !important;
  }
  .dz-page-text .wp-block-gallery,
  .dz-card.blog-single .dz-post-text .wp-block-gallery {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  .dz-page-text,
  .dz-page-text ul li,
  .dz-page-text ol li,
  .dz-page-text p,
  .dz-card.blog-single .dz-post-text,
  .dz-card.blog-single .dz-post-text ul li,
  .dz-card.blog-single .dz-post-text ol li,
  .dz-card.blog-single .dz-post-text p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 48.875rem) {
  .admin-bar .is-fixed .main-bar {
    top: 2.8125rem;
  }
  .admin-bar .mo-left .header-nav {
    top: 2.8125rem;
    height: calc(100vh - 2.812rem) !important;
  }
  .wp-block-media-text {
    display: block;
  }
  .wp-block-media-text .wp-block-media-text__media {
    margin-bottom: 0.938rem;
  }
  .wp-block-media-text .wp-block-media-text__content {
    padding: 0;
  }
}

@media only screen and (max-width: 37.5rem) {
  .admin-bar .is-fixed .main-bar {
    top: 0;
  }
  .admin-bar .mo-left .header-nav {
    top: 2.8125rem;
    height: calc(100vh - 2.812rem) !important;
  }
  .admin-bar .mo-left .is-fixed .header-nav {
    top: 0;
    height: 100vh !important;
  }
}

.post-footer {
  border-top: 0.0625rem solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.938rem 0 0;
}
.post-footer .dz-meta .tag-list {
  padding-bottom: 0;
  text-align: left;
  margin: 0;
  padding: 0;
  list-style: none;
  text-transform: capitalize;
  display: inline;
}
.post-footer .dz-meta ul li {
  margin-right: 0.3125rem;
  padding: 0;
  display: inline-block;
  color: #333333;
  font-weight: 500;
  font-size: 0.938rem;
  font-style: italic;
}
.post-footer .post-tag a {
  text-transform: capitalize;
  font-size: 0.938rem;
  color: #999;
  background: transparent;
  padding: 0;
  border: 0;
  line-height: 0.875rem;
  font-weight: 500;
}

.share-post li {
  display: inline-block;
}

.share-post a {
  width: 2.1875rem;
  height: 2.1875rem;
  border-radius: 2.1875rem;
  border: 0.0625rem solid #eee;
  display: block;
  text-align: center;
  line-height: 2.0625rem;
  color: #222222;
}

.extra-blog {
  margin-bottom: 60px;
}

@media only screen and (max-width: 575px) {
  .comment-respond.style-1 .comment-reply-title {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .default-form {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .default-form {
    margin-bottom: 30px;
  }
}

.comments-area .comment-list {
  margin-bottom: 60px;
  padding: 0;
}
@media only screen and (max-width: 767px) {
  .comments-area .comment-list {
    margin-bottom: 40px;
  }
}
.comments-area .comment-list .default-form {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}
.comments-area .comment-list .default-form small,
.comments-area .comment-list .default-form .small {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
}
.comments-area .comment-list .default-form small a,
.comments-area .comment-list .default-form .small a {
  color: #e10000;
}
.comments-area .comment-list .dz-page-text {
  padding-bottom: 0 !important;
  margin: 0;
}
.comments-area .comment-list > .comment .comment-body {
  position: relative;
  padding-left: 100px;
  margin-bottom: 30px;
  min-height: 95px;
  border-bottom: 1px solid rgba(34, 34, 34, 0.1);
  padding-bottom: 25px;
}
@media only screen and (max-width: 767px) {
  .comments-area .comment-list > .comment .comment-body {
    padding-bottom: 15px;
    padding-left: 75px;
    margin-bottom: 20px;
  }
}
.comments-area .comment-list > .comment .comment-body .star-rating {
  font-size: 14px;
  margin-bottom: 10px;
}
.comments-area .comment-list > .comment .comment-body .comment-author .avatar {
  position: absolute;
  left: 0;
  height: 80px;
  width: 80px;
  border-radius: 100%;
}
@media only screen and (max-width: 767px) {
  .comments-area
    .comment-list
    > .comment
    .comment-body
    .comment-author
    .avatar {
    height: 60px;
    width: 60px;
  }
}
.comments-area .comment-list > .comment .comment-body .comment-author .fn {
  font-size: 1rem;
  line-height: 1.2;
  font-family: var(--font-family-title);
  color: var(--title);
  font-weight: 600;
  font-style: normal;
  margin-bottom: 8px;
  display: block;
}
@media only screen and (max-width: 767px) {
  .comments-area .comment-list > .comment .comment-body .comment-author .fn {
    font-size: 1.125rem;
  }
}
.comments-area .comment-list > .comment .comment-body .comment-meta {
  margin-bottom: 5px;
}
.comments-area .comment-list > .comment .comment-body .comment-meta a {
  color: var(--secondary);
}
.comments-area .comment-list > .comment .comment-body p {
  margin-bottom: 10px;
  font-size: 15px;
}
.comments-area .comment-list > .comment .comment-body p:last-child {
  margin-bottom: 0;
}
.comments-area
  .comment-list
  > .comment
  .comment-body
  .reply
  .comment-reply-link {
  text-transform: capitalize;
  font-size: 14px;
  position: absolute;
  top: -3px;
  right: 0;
  font-weight: 500;
  display: inline-block;
  line-height: 1.3;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border-bottom: 2px solid var(--primary);
}
.comments-area
  .comment-list
  > .comment
  .comment-body
  .reply
  .comment-reply-link
  i {
  margin-right: 8px;
}
.comments-area .comment-list > .comment .children {
  padding-left: 120px;
}
@media only screen and (max-width: 767px) {
  .comments-area .comment-list > .comment .children {
    padding-left: 75px;
  }
}
@media only screen and (max-width: 575px) {
  .comments-area .comment-list > .comment .children {
    padding-left: 30px;
  }
}

.comment-form-cookies-consent {
  display: none;
}

.comment-reply-title a {
  font-size: 14px;
  font-weight: 400;
}

.comment-respond small,
.comment-respond .small {
  font-size: 14px;
  margin-left: 7px;
}
.comment-respond small a,
.comment-respond .small a {
  color: #ef060f;
}

.comment-respond .comment-form {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}
.comment-respond .comment-form p {
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  width: 50%;
}
@media only screen and (max-width: 767px) {
  .comment-respond .comment-form p {
    margin-bottom: 20px;
  }
}
.comment-respond .comment-form p label {
  display: none;
}
.comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p textarea {
  height: 60px;
  border: 2px solid;
  border-color: #f3f4f6;
  padding: 15px 20px;
  font-size: 15px;
  font-weight: 400;
  color: #555;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  background: #f3f4f6;
  width: 100%;
  border-radius: var(--border-radius-base);
}
.comment-respond .comment-form p.comment-form-author input:focus,
.comment-respond .comment-form p.comment-form-author input:active,
.comment-respond .comment-form p.comment-form-email input:focus,
.comment-respond .comment-form p.comment-form-email input:active,
.comment-respond .comment-form p.comment-form-url input:focus,
.comment-respond .comment-form p.comment-form-url input:active,
.comment-respond .comment-form p textarea:focus,
.comment-respond .comment-form p textarea:active {
  background: #fff;
  border-color: var(--primary);
}
.comment-respond .comment-form p textarea {
  height: 120px !important;
}
.comment-respond .comment-form p.comment-form-url {
  width: 100%;
}
.comment-respond .comment-form .comment-form-comment {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

@media only screen and (max-width: 575px) {
  .comment-respond .comment-form .comment-form-author,
  .comment-respond .comment-form .comment-form-email,
  .comment-respond .comment-form .comment-form-url {
    width: 100% !important;
  }
}

.dz-page-text [class*="galleryid-"],
.dz-post-text [class*="galleryid-"] {
  clear: both;
  margin: 0 auto;
  overflow: hidden;
}

.gallery .gallery-item {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  padding: 0.3125rem;
  position: relative;
  box-sizing: border-box;
}
.gallery .gallery-item img {
  float: left;
  padding: 0 0rem;
  width: 100%;
  border: none !important;
}

.gallery .gallery-caption {
  font-size: 0.813rem;
  color: #707070;
  display: block;
  font-family: "Noto Sans", sans-serif;
  line-height: 1.5;
  padding: 0.5em 0;
  clear: both;
}

.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
  display: none;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-icon img {
  margin: 0 auto;
}

.post-password-form {
  position: relative;
  clear: both;
}
.post-password-form label {
  display: block;
  font-size: 1rem;
}
.post-password-form input[type="password"] {
  width: 100%;
  border: 0.0625rem solid #ebedf2;
  padding: 0.625rem 6.25rem 0.625rem 0.938rem;
  height: 2.8125rem;
  border: 0.0625rem solid #ced4da;
}
.post-password-form input[type="submit"] {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.625rem 1.25rem;
  background: var(--primary);
  color: #fff;
  border: none;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.875rem;
  outline: none;
  height: 2.8125rem;
}
.post-password-form input[type="submit"]:hover {
  background: var(--primary-hover);
}

@media only screen and (max-width: 991px) {
  .side-bar.sticky-top {
    position: unset;
  }
}

.blog-post-banner {
  min-height: 300px;
  padding-top: 70px;
  padding-bottom: 70px;
}
.blog-post-banner .dz-title {
  color: #fff;
  font-size: 32px;
  margin-bottom: 20px;
}
.blog-post-banner .dz-meta {
  color: #fff;
}
.blog-post-banner .dz-meta ul {
  display: flex;
  opacity: 0.7;
}
.blog-post-banner .dz-meta ul li {
  margin-right: 20px;
}
.blog-post-banner .dz-meta ul li a {
  color: #fff;
}

.post-outside {
  margin-top: -120px;
}

.single-post .main-bar {
  border-bottom: 1px solid #eee;
}

.dz-load-more i::before,
.loadmore-btn i::before {
  -webkit-animation: unset !important;
  -moz-animation: unset !important;
  animation: unset !important;
}

.dz-share-post {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-top: 1px solid rgba(34, 34, 34, 0.1);
  border-bottom: 1px solid rgba(34, 34, 34, 0.1);
}
.dz-share-post .post-tags a {
  position: relative;
  padding: 3px;
  display: inline-block;
  margin: 3px;
  font-size: 14px;
  color: inherit;
  line-height: 1.3;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-share-post .post-tags a span {
  display: inline-block;
}
.dz-share-post .post-tags a:hover {
  color: var(--primary);
}
@media only screen and (max-width: 767px) {
  .dz-share-post {
    display: block;
  }
  .dz-share-post .post-tags {
    margin-top: 0px;
    margin-bottom: 5px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-share-post .post-tags a {
    margin-right: 5px;
  }
}

#comment-list:empty + .paginate-links {
  margin-top: -30px;
}

.blog-single .dz-media + .dz-info {
  padding: 25px 0 0;
}

.wp-block-code > code {
  color: #fff;
}

.post-swiper,
.post-swiper-thumb,
.service2-swiper,
.service-swiper {
  position: relative;
}
.post-swiper .btn-prev,
.post-swiper .btn-next,
.post-swiper .prev-post-swiper-btn,
.post-swiper .next-post-swiper-btn,
.post-swiper .prev-service-swiper-btn,
.post-swiper .next-service-swiper-btn,
.post-swiper-thumb .btn-prev,
.post-swiper-thumb .btn-next,
.post-swiper-thumb .prev-post-swiper-btn,
.post-swiper-thumb .next-post-swiper-btn,
.post-swiper-thumb .prev-service-swiper-btn,
.post-swiper-thumb .next-service-swiper-btn,
.service2-swiper .btn-prev,
.service2-swiper .btn-next,
.service2-swiper .prev-post-swiper-btn,
.service2-swiper .next-post-swiper-btn,
.service2-swiper .prev-service-swiper-btn,
.service2-swiper .next-service-swiper-btn,
.service-swiper .btn-prev,
.service-swiper .btn-next,
.service-swiper .prev-post-swiper-btn,
.service-swiper .next-post-swiper-btn,
.service-swiper .prev-service-swiper-btn,
.service-swiper .next-service-swiper-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 45px;
  width: 45px;
  line-height: 45px;
  border-radius: 0;
  text-align: center;
  font-size: 20px;
  background-color: #fff;
  color: var(--primary);
  z-index: 1;
  margin: 0 10px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.post-swiper .btn-prev:hover,
.post-swiper .btn-next:hover,
.post-swiper .prev-post-swiper-btn:hover,
.post-swiper .next-post-swiper-btn:hover,
.post-swiper .prev-service-swiper-btn:hover,
.post-swiper .next-service-swiper-btn:hover,
.post-swiper-thumb .btn-prev:hover,
.post-swiper-thumb .btn-next:hover,
.post-swiper-thumb .prev-post-swiper-btn:hover,
.post-swiper-thumb .next-post-swiper-btn:hover,
.post-swiper-thumb .prev-service-swiper-btn:hover,
.post-swiper-thumb .next-service-swiper-btn:hover,
.service2-swiper .btn-prev:hover,
.service2-swiper .btn-next:hover,
.service2-swiper .prev-post-swiper-btn:hover,
.service2-swiper .next-post-swiper-btn:hover,
.service2-swiper .prev-service-swiper-btn:hover,
.service2-swiper .next-service-swiper-btn:hover,
.service-swiper .btn-prev:hover,
.service-swiper .btn-next:hover,
.service-swiper .prev-post-swiper-btn:hover,
.service-swiper .next-post-swiper-btn:hover,
.service-swiper .prev-service-swiper-btn:hover,
.service-swiper .next-service-swiper-btn:hover {
  background-color: var(--primary);
  color: #fff;
}
.post-swiper .btn-prev i,
.post-swiper .btn-next i,
.post-swiper .prev-post-swiper-btn i,
.post-swiper .next-post-swiper-btn i,
.post-swiper .prev-service-swiper-btn i,
.post-swiper .next-service-swiper-btn i,
.post-swiper-thumb .btn-prev i,
.post-swiper-thumb .btn-next i,
.post-swiper-thumb .prev-post-swiper-btn i,
.post-swiper-thumb .next-post-swiper-btn i,
.post-swiper-thumb .prev-service-swiper-btn i,
.post-swiper-thumb .next-service-swiper-btn i,
.service2-swiper .btn-prev i,
.service2-swiper .btn-next i,
.service2-swiper .prev-post-swiper-btn i,
.service2-swiper .next-post-swiper-btn i,
.service2-swiper .prev-service-swiper-btn i,
.service2-swiper .next-service-swiper-btn i,
.service-swiper .btn-prev i,
.service-swiper .btn-next i,
.service-swiper .prev-post-swiper-btn i,
.service-swiper .next-post-swiper-btn i,
.service-swiper .prev-service-swiper-btn i,
.service-swiper .next-service-swiper-btn i {
  font-family: "feather";
  font-style: normal;
}
@media only screen and (max-width: 767px) {
  .post-swiper .btn-prev,
  .post-swiper .btn-next,
  .post-swiper .prev-post-swiper-btn,
  .post-swiper .next-post-swiper-btn,
  .post-swiper .prev-service-swiper-btn,
  .post-swiper .next-service-swiper-btn,
  .post-swiper-thumb .btn-prev,
  .post-swiper-thumb .btn-next,
  .post-swiper-thumb .prev-post-swiper-btn,
  .post-swiper-thumb .next-post-swiper-btn,
  .post-swiper-thumb .prev-service-swiper-btn,
  .post-swiper-thumb .next-service-swiper-btn,
  .service2-swiper .btn-prev,
  .service2-swiper .btn-next,
  .service2-swiper .prev-post-swiper-btn,
  .service2-swiper .next-post-swiper-btn,
  .service2-swiper .prev-service-swiper-btn,
  .service2-swiper .next-service-swiper-btn,
  .service-swiper .btn-prev,
  .service-swiper .btn-next,
  .service-swiper .prev-post-swiper-btn,
  .service-swiper .next-post-swiper-btn,
  .service-swiper .prev-service-swiper-btn,
  .service-swiper .next-service-swiper-btn {
    height: 35px;
    width: 35px;
    line-height: 35px;
  }
}
.post-swiper .btn-prev,
.post-swiper .prev-post-swiper-btn,
.post-swiper .prev-service-swiper-btn,
.post-swiper-thumb .btn-prev,
.post-swiper-thumb .prev-post-swiper-btn,
.post-swiper-thumb .prev-service-swiper-btn,
.service2-swiper .btn-prev,
.service2-swiper .prev-post-swiper-btn,
.service2-swiper .prev-service-swiper-btn,
.service-swiper .btn-prev,
.service-swiper .prev-post-swiper-btn,
.service-swiper .prev-service-swiper-btn {
  left: 0;
}
.post-swiper .btn-next,
.post-swiper .next-post-swiper-btn,
.post-swiper .next-service-swiper-btn,
.post-swiper-thumb .btn-next,
.post-swiper-thumb .next-post-swiper-btn,
.post-swiper-thumb .next-service-swiper-btn,
.service2-swiper .btn-next,
.service2-swiper .next-post-swiper-btn,
.service2-swiper .next-service-swiper-btn,
.service-swiper .btn-next,
.service-swiper .next-post-swiper-btn,
.service-swiper .next-service-swiper-btn {
  right: 0;
}
.post-swiper .swiper-slide img,
.post-swiper-thumb .swiper-slide img,
.service2-swiper .swiper-slide img,
.service-swiper .swiper-slide img {
  width: 100%;
}

.author-box {
  padding: 50px 30px;
  text-align: center;
  background: #f8f8f8;
}
.author-box .author-profile-info {
  align-items: center;
}
.author-box .author-profile-info .author-profile-pic {
  width: 150px;
  height: 150px;
  overflow: hidden;
  min-width: 150px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
@media only screen and (max-width: 575px) {
  .author-box .author-profile-info .author-profile-pic {
    width: 100px;
    height: 100px;
    min-width: 100px;
    margin-bottom: 20px;
  }
}
.author-box .author-profile-info .author-profile-pic img {
  width: 100%;
}
.author-box .author-profile-info .author-profile-content p {
  margin-bottom: 15px;
}
.author-box .author-profile-info .author-profile-content img {
  width: 180px;
  margin-bottom: 15px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.author-box .author-profile-info .author-profile-content ul {
  padding: 0;
  margin: 0;
  margin-right: 5px;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 575px) {
  .author-box .author-profile-info .author-profile-content ul {
    display: inline-block;
    float: none;
    margin-right: 0;
  }
}
.author-box .author-profile-info .author-profile-content ul li {
  padding: 0;
  margin: 0;
  margin-right: 10px;
  float: left;
}
@media only screen and (max-width: 575px) {
  .author-box .author-profile-info .author-profile-content ul li {
    margin-right: 5px;
    margin-left: 5px;
  }
}
.author-box .author-profile-info .author-profile-content ul li a {
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  background: var(--primary);
  color: #fff;
  vertical-align: middle;
  display: block;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: var(--border-radius-sm);
}
.author-box .author-profile-info .author-profile-content ul li a:hover {
  background: var(--primary-hover);
}

#comment-list:empty + .paginate-links {
  margin-top: -30px;
}

.bypostauthor {
  font-size: normal;
}

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}
.screen-reader-text:focus {
  background-color: #eee;
  clip: auto !important;
  clip-path: none;
  color: #444;
  display: block;
  font-size: 1em;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

.wp-block-gallery.has-nested-images
  figure.wp-block-image:not(#individual-image) {
  margin: 0 var(--gallery-block--gutter-size, 15px)
    var(--gallery-block--gutter-size, 15px) 0;
}
@media only screen and (max-width: 1280px) {
  .wp-block-gallery.has-nested-images
    figure.wp-block-image:not(#individual-image) {
    margin: 0 var(--gallery-block--gutter-size, 10px)
      var(--gallery-block--gutter-size, 10px) 0;
  }
}

.post-audio iframe {
  height: 400px;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .post-audio iframe {
    height: 350px;
  }
}
@media only screen and (max-width: 575px) {
  .post-audio iframe {
    height: 250px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-blog.blog-single .embed-responsive iframe {
    width: 100%;
  }
}

.wp-block-group.has-background {
  padding: 1.5em 2em;
}

:where(.wp-block-group.has-background) {
  padding: 1.5em 2em;
}

.post-btn {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #ededee;
  border-bottom: 1px solid #ededee;
  padding: 15px 0;
  margin: 50px 0px;
  position: relative;
}
.post-btn::after {
  content: "";
  height: calc(100% - 30px);
  width: 1px;
  background: #ededee;
  position: absolute;
  left: 50%;
  top: 15px;
}
.post-btn .prev-post,
.post-btn .next-post {
  max-width: 50%;
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  position: relative;
}
.post-btn .prev-post img,
.post-btn .next-post img {
  height: 70px;
  width: 85px;
  object-fit: cover;
}
@media only screen and (max-width: 767px) {
  .post-btn .prev-post img,
  .post-btn .next-post img {
    display: none;
  }
}
.post-btn .prev-post .start,
.post-btn .prev-post .end,
.post-btn .next-post .start,
.post-btn .next-post .end {
  color: #b5b5b5;
  cursor: not-allowed;
  width: 100%;
  padding: 0;
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  .post-btn .prev-post .title,
  .post-btn .next-post .title {
    font-size: 14px;
  }
}
.post-btn .prev-post .title a,
.post-btn .next-post .title a {
  display: block;
  margin-bottom: 5px;
}
.post-btn .prev-post .title .post-date,
.post-btn .next-post .title .post-date {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #666;
}
@media only screen and (max-width: 767px) {
  .post-btn .prev-post .title .post-date,
  .post-btn .next-post .title .post-date {
    font-size: 12px;
  }
}
.post-btn .next-post {
  padding-right: 30px;
  padding-left: 10px;
}
@media only screen and (max-width: 767px) {
  .post-btn .next-post {
    padding-right: 20px;
  }
}
.post-btn .next-post .title {
  text-align: right;
  width: 100%;
  margin-bottom: 0;
}
.post-btn .next-post .title + img {
  margin-left: 20px;
}
.post-btn .next-post .end {
  text-align: right;
}
.post-btn .next-post::after {
  content: "\f105";
  font-family: "FontAwesome";
  color: var(--primary);
  position: absolute;
  right: 0;
  top: 50%;
  font-size: 30px;
  transform: translateY(-50%);
}
.post-btn .prev-post {
  padding-left: 30px;
  padding-right: 10px;
}
@media only screen and (max-width: 767px) {
  .post-btn .prev-post {
    padding-left: 20px;
  }
}
.post-btn .prev-post .title {
  margin-bottom: 0;
  width: 100%;
}
.post-btn .prev-post img + .title {
  padding: 0 20px;
}
.post-btn .prev-post::after {
  content: "\f104";
  font-family: "FontAwesome";
  color: var(--primary);
  position: absolute;
  left: 0;
  top: 50%;
  font-size: 30px;
  transform: translateY(-50%);
}

.error-page {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  min-height: 700px;
}
.error-page .particles-js-canvas-el {
  position: absolute;
  left: 0;
  z-index: 1;
  top: 0;
}
.error-page::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(38, 38, 38, 0.8);
  top: 0;
  left: 0;
}
.error-page .inner-content {
  padding-top: 150px;
  position: relative;
  z-index: 9;
}
.error-page .error-head {
  font-family: "Lobster";
  margin: 0 auto 20px;
  font-weight: 400;
  font-size: 140px;
  line-height: 1;
  font-style: normal;
  text-align: center;
  color: #ffffff;
}
.error-page .error-para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 1.5;
  color: #efefef;
  margin-bottom: 5px;
}
.error-page p {
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 40px;
  color: rgba(255, 255, 255, 0.5);
}
@media only screen and (max-width: 1480px) {
  .error-page {
    min-height: 650px;
  }
}
@media only screen and (max-width: 1280px) {
  .error-page .error-para {
    font-size: 30px;
    line-height: 1.4;
    margin-bottom: 10px;
  }
  .error-page p {
    margin-bottom: 25px;
  }
}
@media only screen and (max-width: 991px) {
  .error-page {
    min-height: 500px;
  }
  .error-page .inner-content {
    padding-top: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .error-page .error-head {
    font-size: 120px;
  }
  .error-page .error-para {
    font-size: 22px;
    line-height: 1.3;
  }
}
@media only screen and (max-width: 575px) {
  .error-page {
    min-height: 450px;
  }
}

.offcanvas-form {
  background: #fff;
  padding: 60px 70px;
}
@media only screen and (max-width: 1280px) {
  .offcanvas-form {
    padding: 40px;
  }
}
.offcanvas-form .login-head {
  text-align: center;
}
.offcanvas-form .login-head .title {
  font-size: 30px;
}
.offcanvas-form .login-head p {
  margin-bottom: 35px;
}
.offcanvas-form .login-head .login-title {
  text-align: center;
  position: relative;
  z-index: 1;
  display: flex;
  margin: 30px 0;
  align-items: center;
}
.offcanvas-form .login-head .login-title::before,
.offcanvas-form .login-head .login-title::after {
  content: "";
  height: 1px;
  flex: 1 1;
  left: 0;
  background-color: #c4c4c4;
  top: 50%;
  z-index: -1;
  margin: 0;
  padding: 0;
}
.offcanvas-form .login-head .login-title span {
  padding: 0 10px;
}
.offcanvas-form input {
  height: 50px !important;
}
.offcanvas-form .form-group .form-label {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
}

.search-input {
  position: relative;
}
.search-input .show-pass {
  cursor: pointer;
  position: absolute;
  right: 0;
  height: 100%;
  width: 50px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}
.search-input .show-pass .eye-close {
  display: block;
}
.search-input .show-pass .eye-open {
  display: none;
}
.search-input .show-pass.active .eye-close {
  display: none;
}
.search-input .show-pass.active .eye-open {
  display: block;
}

.offcanvas {
  z-index: 999999;
  border: 0;
}
.offcanvas.offcanvas-end {
  width: 510px;
  overflow-y: auto;
}
.offcanvas .offcanvas-body {
  padding: 0;
  overflow-y: initial;
}
.offcanvas .btn-close {
  margin: 15px 20px;
}
.offcanvas .btn-close.style-1 {
  background: none;
  font-size: 28px;
  padding: 0;
  line-height: 28px;
  margin: 15px 15px;
}
@media only screen and (max-width: 1280px) {
  .offcanvas.offcanvas-end {
    width: 400px;
  }
}
@media only screen and (max-width: 575px) {
  .offcanvas.offcanvas-end {
    width: 325px;
  }
}

.offcanvas-backdrop {
  z-index: 99999;
}

.offcanvas .offcanvas-body .category-check-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.offcanvas .offcanvas-body .category-check-list .form-check {
  width: 50%;
  margin-bottom: 10px;
}
.offcanvas .offcanvas-body .category-check-list .form-check .form-check-input {
  border: 1px solid rgba(34, 34, 34, 0.3);
  border-radius: 4px;
  width: 15px;
  height: 15px;
}
.offcanvas
  .offcanvas-body
  .category-check-list
  .form-check
  .form-check-input:checked {
  background-color: var(--primary);
  border: 1px solid var(--primary);
}
.offcanvas .offcanvas-body .category-check-list .form-check .form-check-label {
  font-family: var(--font-family-title);
  margin-left: 0px;
  font-size: 14px;
}

.offcanvas.filter-category-sidebar {
  padding: 50px 30px 20px;
}
.offcanvas.filter-category-sidebar .btn-close {
  background: none;
  font-size: 28px;
  padding: 0;
  line-height: 28px;
  margin: 0;
  position: absolute;
  left: 10px;
  top: 10px;
}
.offcanvas.filter-category-sidebar .btn-close i {
  color: #000;
}
.offcanvas.filter-category-sidebar .widget {
  margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  .offcanvas.filter-category-sidebar {
    padding: 50px 20px 20px;
  }
}

.dz-shop-card {
  position: relative;
  overflow: hidden;
}
.dz-shop-card.style-1 {
  display: flex;
  margin-bottom: 30px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  margin-bottom: 20px;
}
.dz-shop-card.style-1 .dz-media {
  width: 100px;
  min-width: 100px;
}
.dz-shop-card.style-1 .dz-media img {
  height: 100%;
  object-fit: cover;
}
.dz-shop-card.style-1 .dz-content {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.dz-shop-card.style-1 .dz-content .dz-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.dz-shop-card.style-1 .dz-content .dz-head .dz-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--title);
}
.dz-shop-card.style-1 .dz-content .dz-head .rate {
  background: #fe9f10;
  color: #fff;
  border-radius: 5px;
  padding: 2px 5px;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
}
.dz-shop-card.style-1 .dz-content .dz-body {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}
.dz-shop-card.style-1 .dz-content .dz-body ul {
  display: flex;
  margin: 0 -10px;
}
.dz-shop-card.style-1 .dz-content .dz-body ul li {
  margin: 0 10px;
  color: #727272;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.dz-shop-card.style-1 .dz-content .dz-body ul li i {
  color: var(--primary);
  font-size: 20px;
  margin-right: 4px;
}
.dz-shop-card.style-1 .dz-content .dz-body p {
  font-size: 14px;
  line-height: 21px;
}
.dz-shop-card.style-1:hover {
  border-color: transparent;
  box-shadow: 0px 15px 55px rgba(34, 34, 34, 0.15);
}
@media only screen and (max-width: 991px) {
  .dz-shop-card.style-1 {
    border-radius: 6px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-shop-card.style-1 .dz-content {
    padding: 8px;
  }
  .dz-shop-card.style-1 .dz-content .dz-head,
  .dz-shop-card.style-1 .dz-content .dz-body {
    display: block;
  }
  .dz-shop-card.style-1 .dz-content .dz-head {
    margin-bottom: 8px;
  }
  .dz-shop-card.style-1 .dz-content .dz-head .rate {
    display: inline-block;
    position: absolute;
    bottom: 10px;
    right: 12px;
  }
  .dz-shop-card.style-1 .dz-content .dz-body ul {
    margin: 0 -10px 10px;
  }
}
.dz-shop-card.style-2 .dz-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.dz-shop-card.style-2 .dz-content .dz-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}
.dz-shop-card.style-2 .dz-content .dz-head .header-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}
.dz-shop-card.style-2 .dz-content .dz-head .header-text a {
  color: var(--title);
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}
.dz-shop-card.style-2 .dz-content .dz-head .header-text:hover a {
  color: var(--primary);
}
.dz-shop-card.style-2 .dz-content .dz-head .img-line {
  width: 100%;
  height: 1px;
  margin: 0 15px;
  border-bottom: 1px dashed #7d7d7d;
}
.dz-shop-card.style-2 .dz-content .dz-head .header-price {
  color: var(--primary);
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}
.dz-shop-card.style-2 .dz-content .dz-body {
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 0;
}
@media only screen and (max-width: 991px) {
  .dz-shop-card.style-2 .dz-content .dz-head .header-text {
    font-size: 16px;
    line-height: 25px;
  }
}

.cart-item {
  position: relative;
}
.cart-item.style-1 {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(34, 34, 34, 0.1);
}
.cart-item.style-1 .dz-media {
  width: 80px;
  min-width: 80px;
  height: 80px;
  border-radius: 10px;
}
.cart-item.style-1 .dz-media img {
  height: 100%;
  object-fit: cover;
}
.cart-item.style-1 .dz-content {
  margin-left: 15px;
  width: 100%;
}
.cart-item.style-1 .dz-content .title {
  font-size: 16px;
}
.cart-item.style-1 .dz-content .dz-head,
.cart-item.style-1 .dz-content .dz-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cart-item.style-1 .dz-content .dz-head {
  margin-bottom: 10px;
}
.cart-item.style-1 .dz-content .dz-body .price {
  font-size: 18px;
}

.menu-head {
  margin-bottom: 25px;
}
.menu-head .title {
  font-family: Lobster;
  font-weight: 400;
  font-size: 35px;
}

.btn-quantity.style-1 .bootstrap-touchspin {
  width: 105px;
  min-width: 105px;
  position: relative;
  height: 31px;
}

.btn-quantity.style-1 input {
  height: 31px;
  width: auto;
  padding: 0;
  text-align: center;
  z-index: 1 !important;
  background-color: #eaeaea;
  border: 2px solid #eaeaea;
  border-radius: var(--border-radius-base) !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--title);
  margin-left: 0 !important;
}

.btn-quantity.style-1 .input-group-btn-vertical {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 2px;
  margin-left: 0 !important;
}
.btn-quantity.style-1 .input-group-btn-vertical .btn,
.btn-quantity.style-1 .input-group-btn-vertical .wp-block-button__link {
  padding: 0px;
  min-width: 26px;
  text-align: center;
  line-height: 26px;
  z-index: 1;
  background-color: #fff;
  color: var(--title);
  position: relative;
  border-radius: 4px;
  justify-content: center;
}
.btn-quantity.style-1 .input-group-btn-vertical .btn i,
.btn-quantity.style-1 .input-group-btn-vertical .wp-block-button__link i {
  font-weight: 900;
  font-size: 14px;
}

.btn-quantity.style-1 .input-group {
  margin-bottom: 0;
}

.panel-btn {
  display: none;
  padding: 5px 18px;
  margin-bottom: 15px;
}

.panel-close-btn {
  display: none;
}
.panel-close-btn i {
  font-size: 20px;
}

@media only screen and (max-width: 991px) {
  .shop-filter {
    padding: 30px 20px;
    position: fixed;
    left: -345px;
    top: 0px;
    z-index: 999999999;
    background: #fff;
    height: 100%;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    width: 300px;
    overflow: scroll;
  }
  .shop-filter.style-1 {
    width: 350px;
    left: -355px;
  }
  .shop-filter.show {
    left: 0;
  }
  .panel-btn {
    display: block;
  }
  .panel-close-btn {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .shop-filter.style-1 {
    width: 325px;
  }
}

.rating-filter ul li {
  display: inline-block;
}
.rating-filter ul li span {
  font-size: 16px;
  font-weight: 500;
  color: var(--title);
}
.rating-filter ul li i {
  color: #fe9f10;
  font-family: feather;
  font-style: normal;
  vertical-align: middle;
}
.rating-filter ul li:first-child {
  margin-right: 8px;
}

.rating-stars ul {
  list-style-type: none;
  padding: 0;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.rating-stars ul > li.star {
  display: inline-block;
}

/* Idle State of the stars */
.rating-stars ul > li.star > i.fas {
  font-size: 14px;
  /* Change the size of the stars */
  color: #ccc;
  /* Color on idle state */
}

/* Hover state of the stars */
.rating-stars ul > li.star.hover > i.fas {
  color: #ffcc36;
}

/* Selected state of the stars */
.rating-stars ul > li.star.selected > i.fas {
  color: #ff912c;
}

.product-detail .badge {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #666666;
  display: flex;
  align-items: center;
  padding: 2px;
  margin-bottom: 10px;
}
.product-detail .badge svg {
  margin-right: 8px;
}

.product-detail .detail-media {
  border-radius: 10px;
  overflow: hidden;
}
.product-detail .detail-media img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.product-detail .detail-info {
  position: relative;
}
.product-detail .detail-info .dz-head .rating {
  font-size: 14px;
  line-height: 21px;
}
.product-detail .detail-info .dz-head .rating i {
  color: var(--secondary);
}
.product-detail .detail-info .text {
  font-size: 15px;
  margin-top: 20px;
}
.product-detail .detail-info .detail-list {
  display: flex;
  margin: 25px 0px;
}
.product-detail .detail-info .detail-list li {
  margin-right: 45px;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
}
.product-detail .detail-info .detail-list li span {
  display: flex;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}
.product-detail .detail-info .add-product {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px 30px;
}
.product-detail .detail-info .add-product li {
  padding: 5px;
  width: 50%;
}
.product-detail .detail-info .add-product .mini-modal {
  display: inline-flex;
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid rgba(34, 34, 34, 0.1);
  align-items: center;
}
.product-detail .detail-info .add-product .mini-modal .dz-media {
  width: 45px;
  min-width: 45px;
  height: 45px;
  border-radius: 6px;
}
.product-detail .detail-info .add-product .mini-modal .dz-content {
  margin-left: 15px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.product-detail .detail-info .add-product .mini-modal .dz-content p {
  margin-bottom: 0;
  font-weight: 500;
  color: var(--title);
}
.product-detail .detail-info .modal-btn-group {
  display: flex;
  margin: 0 -7px;
}
.product-detail .detail-info .modal-btn-group li {
  margin: 0 7px;
}

@media only screen and (max-width: 991px) {
  .product-detail .detail-info .modal-btn-group li {
    margin: 0 7px 10px;
  }
}

@media only screen and (max-width: 575px) {
  .product-detail .detail-info .text {
    margin-top: 10px;
  }
  .product-detail .detail-info .detail-list {
    margin: 15px 0;
  }
  .product-detail .detail-info .add-product {
    margin: 0 0 20px;
  }
  .product-detail .detail-info .add-product li {
    padding: 5px 0;
    width: 100%;
  }
  .product-detail .detail-info .modal-btn-group {
    display: block;
  }
}

.modal-content .modal-body.product-detail {
  display: flex;
  padding: 6px;
}
.modal-content .modal-body.product-detail .detail-info {
  padding: 25px 20px 25px 35px;
}
.modal-content .modal-body.product-detail .detail-media {
  width: 365px;
  min-width: 365px;
}
@media only screen and (max-width: 991px) {
  .modal-content .modal-body.product-detail {
    display: block;
  }
  .modal-content .modal-body.product-detail .detail-info {
    padding: 15px;
  }
  .modal-content .modal-body.product-detail .detail-media {
    width: 100%;
    min-width: 100%;
  }
  .modal-content .modal-body.product-detail .detail-media img {
    height: 400px;
    width: 100%;
  }
}
@media only screen and (max-width: 575px) {
  .modal-content .modal-body.product-detail .detail-media img {
    height: 280px;
  }
}

.section-wrapper-1 {
  position: relative;
  z-index: 1;
}
.section-wrapper-1::after {
  content: "";
  position: absolute;
  z-index: -1;
  background-color: rgba(21, 21, 21, 0.7);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(6px);
}

.section-wrapper-2 {
  position: relative;
}
.section-wrapper-2 .bg1,
.section-wrapper-2 .bg2 {
  position: absolute;
}
.section-wrapper-2 .bg1 {
  bottom: 0;
  left: -275px;
}
.section-wrapper-2 .bg2 {
  top: 100px;
  right: 40px;
}
.section-wrapper-2.right .bg1 {
  bottom: 0;
  right: -355px;
  left: auto;
}
@media only screen and (max-width: 1680px) {
  .section-wrapper-2 .bg1 {
    left: -320px;
  }
  .section-wrapper-2 .bg2 {
    top: 90px;
    right: 15px;
  }
}
@media only screen and (max-width: 1480px) {
  .section-wrapper-2 .bg1 {
    display: none;
  }
  .section-wrapper-2 .bg2 {
    top: 28px;
    right: 0px;
  }
  .section-wrapper-2.right .bg1 {
    display: none;
  }
}

@media only screen and (max-width: 1480px) and (max-width: 1280px) {
  .section-wrapper-2 .bg2 {
    display: none;
  }
}

.section-wrapper-3 {
  position: relative;
  z-index: 1;
}
.section-wrapper-3::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-light);
  z-index: -1;
}
.section-wrapper-3::after {
  content: "";
  height: 200px;
  width: 100%;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.section-wrapper-3 .inner-section-wrapper {
  margin-bottom: 80px;
}
.section-wrapper-3 .bg1,
.section-wrapper-3 .bg2 {
  position: absolute;
  z-index: -1;
}
.section-wrapper-3 .bg2 {
  top: 255px;
  right: 85px;
}
.section-wrapper-3 .bg1 {
  bottom: 300px;
  left: 70px;
}
@media only screen and (max-width: 1680px) {
  .section-wrapper-3 .bg2 {
    top: 120px;
    right: 40px;
  }
  .section-wrapper-3 .bg1 {
    bottom: 280px;
    left: 30px;
  }
  .section-wrapper-3 .inner-section-wrapper {
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 1480px) {
  .section-wrapper-3 .bg2 {
    top: 50px;
    right: 30px;
  }
  .section-wrapper-3 .bg1 {
    bottom: 350px;
    left: 10px;
  }
}
@media only screen and (max-width: 1280px) {
  .section-wrapper-3 .inner-section-wrapper {
    margin-bottom: 60px;
  }
  .section-wrapper-3 .bg1,
  .section-wrapper-3 .bg2 {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .section-wrapper-3 .inner-section-wrapper {
    margin-bottom: 45px;
  }
}

.section-wrapper-4 {
  margin-left: 100px;
  margin-right: 100px;
  background-color: var(--bg-light);
  border-radius: 30px;
}
@media only screen and (max-width: 1480px) {
  .section-wrapper-4 {
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 0;
  }
}

.section-wrapper-5 {
  margin-left: 100px;
  margin-right: 100px;
  z-index: 1;
  position: relative;
  border-radius: 30px;
}
.section-wrapper-5::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: blur(6px);
  background-color: rgba(21, 21, 21, 0.7);
  width: 100%;
  height: 100%;
  z-index: -1;
}
@media only screen and (max-width: 1480px) {
  .section-wrapper-5 {
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 0;
  }
}

.section-wrapper-6 {
  position: relative;
  z-index: 1;
}
.section-wrapper-6::after {
  content: "";
  height: 125px;
  width: 100%;
  background-color: rgba(34, 34, 34, 0.05);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.section-wrapper-6 .inner-section-wrapper {
  margin-bottom: 80px;
}
@media only screen and (max-width: 767px) {
  .section-wrapper-6 .inner-section-wrapper {
    margin-bottom: 45px;
  }
}
@media only screen and (max-width: 575px) {
  .section-wrapper-6 .inner-section-wrapper {
    margin-bottom: 25px;
  }
}

.section-wrapper-7 {
  position: relative;
  z-index: 1;
}
.section-wrapper-7::after {
  content: "";
  width: 850px;
  height: 850px;
  background-color: #ffeebb;
  position: absolute;
  top: 110px;
  right: -19%;
  z-index: -2;
  filter: blur(9px);
  border-radius: 100%;
}
.section-wrapper-7 .inner-section-wrapper {
  margin-bottom: 50px;
}
.section-wrapper-7 .bg1 {
  position: absolute;
  top: 85px;
  left: -110px;
}
.section-wrapper-7 .bg2 {
  position: absolute;
  right: -54px;
  top: 30px;
  z-index: -1;
}
@media only screen and (max-width: 1480px) {
  .section-wrapper-7::after {
    width: 700px;
    height: 700px;
    top: 45px;
    right: -22%;
  }
  .section-wrapper-7 .bg1 {
    display: none;
  }
  .section-wrapper-7 .bg2 {
    width: 600px;
  }
}
@media only screen and (max-width: 1280px) {
  .section-wrapper-7::after {
    width: 600px;
    height: 600px;
  }
  .section-wrapper-7 .bg2 {
    width: 500px;
  }
}
@media only screen and (max-width: 1024px) {
  .section-wrapper-7::after {
    display: none;
  }
  .section-wrapper-7 .bg2 {
    top: auto;
    bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .section-wrapper-7 .bg2 {
    display: none;
  }
  .section-wrapper-7 .inner-section-wrapper {
    margin-bottom: 0px;
  }
}

.section-wrapper-8 {
  position: relative;
  background-color: rgba(34, 34, 34, 0.04);
  border-radius: 30px;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 270px;
  margin-bottom: 100px;
  padding-left: 30px;
  padding-right: 30px;
}
.section-wrapper-8 .inner-section-wrapper {
  margin-bottom: 80px;
  margin-top: -215px;
}
@media only screen and (max-width: 1480px) {
  .section-wrapper-8 {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 190px;
    margin-bottom: 70px;
  }
  .section-wrapper-8 .inner-section-wrapper {
    margin-top: -170px;
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 991px) {
  .section-wrapper-8 {
    background-color: #fff;
    margin: 0;
  }
  .section-wrapper-8 .inner-section-wrapper {
    margin-top: 0;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .section-wrapper-8 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .section-wrapper-8 .inner-section-wrapper {
    margin-bottom: 45px;
  }
}

.coming-wrapper {
  position: relative;
  z-index: 1;
  padding: 25px;
}
.coming-wrapper .logo-header {
  float: unset;
  margin: 0 auto 10px;
  height: 65px;
  width: 160px;
}
.coming-wrapper .bg-img {
  position: absolute;
  top: 20px;
  z-index: -1;
}
.coming-wrapper .dz-social-icon ul {
  margin-right: -20px;
}
.coming-wrapper .dz-social-icon ul li {
  display: inline-block;
  padding: 0 6px;
  margin-right: 20px;
}
.coming-wrapper .dz-social-icon ul li a {
  font-size: 18px;
}
.coming-wrapper .dz-social-icon ul li a i {
  color: #000000;
}
@media only screen and (max-width: 1480px) {
  .coming-wrapper .bg-img {
    top: 25px;
    left: 80px;
  }
}
@media only screen and (max-width: 1280px) {
  .coming-wrapper {
    padding: 45px 25px 25px;
  }
  .coming-wrapper .bg-img {
    top: 10px;
    left: 14px;
  }
}
@media only screen and (max-width: 991px) {
  .coming-wrapper {
    padding: 25px;
  }
  .coming-wrapper .bg-img {
    display: none;
  }
  .coming-wrapper .dz-social-icon ul li a {
    font-size: 16px;
  }
}
@media only screen and (max-width: 575px) {
  .coming-wrapper {
    padding: 25px 0 25px;
  }
}
.coming-wrapper .inner-content {
  width: 100%;
  min-height: 650px;
  overflow: hidden;
}
.coming-wrapper .inner-content .coming-head {
  font-size: 32px;
  line-height: 1.3;
  letter-spacing: 0.01em;
  color: #323232;
  margin-bottom: 0;
}
.coming-wrapper .inner-content .coming-para {
  color: #828282;
  letter-spacing: 0.01em;
}
.coming-wrapper .inner-content .coming-media {
  transform: scale(1.1);
  z-index: -1;
  position: relative;
}
@media only screen and (max-width: 1480px) {
  .coming-wrapper .inner-content {
    min-height: 500px;
  }
  .coming-wrapper .inner-content .center-logo {
    margin-bottom: 25px;
  }
  .coming-wrapper .inner-content .coming-media {
    transform: scale(1);
  }
}
@media only screen and (max-width: 991px) {
  .coming-wrapper .inner-content {
    min-height: 600px;
  }
  .coming-wrapper .inner-content .coming-media {
    transform: scale(1);
  }
}
@media only screen and (max-width: 767px) {
  .coming-wrapper .inner-content {
    min-height: 500px;
  }
  .coming-wrapper .inner-content .coming-head {
    font-size: 25px;
  }
}
@media only screen and (max-width: 575px) {
  .coming-wrapper .inner-content {
    min-height: 400px;
  }
}
.coming-wrapper .middle-content {
  margin-bottom: 60px;
}
@media only screen and (max-width: 1480px) {
  .coming-wrapper .middle-content {
    margin-bottom: 35px;
  }
}
.coming-wrapper .coming-footer p {
  color: #828282;
  letter-spacing: 0.01em;
  margin-bottom: 0;
}

.style-8 .countdown-timer {
  margin-bottom: 50px;
}

.style-8 .dez-title {
  font-size: 140px;
  line-height: 140px;
  font-family: cookie-regular;
  color: #c90103;
  font-weight: 500;
}

.style-8 .countdown-timer .clock .val {
  color: #000;
  font-family: cookie-regular;
  font-size: 95px;
  line-height: 165px;
  margin-bottom: 5px;
}

.style-8 .countdown-timer .clock .kineticjs-content:after {
  border: 1px solid #d2d2d2;
}

.style-8 .countdown-timer .clock .type-time {
  color: #000;
}

.water-layer3,
.water-layer1 {
  background-size: 95%;
}

.map-iframe iframe {
  width: 100%;
}

.map-iframe.style-1 {
  position: relative;
}
.map-iframe.style-1::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #222;
  mix-blend-mode: color;
  z-index: 0;
  pointer-events: none;
}
.map-iframe.style-1 iframe {
  height: 400px;
  margin-bottom: -10px;
}
@media only screen and (max-width: 991px) {
  .map-iframe.style-1 iframe {
    height: 350px;
  }
}
@media only screen and (max-width: 575px) {
  .map-iframe.style-1 iframe {
    height: 300px;
  }
}

.contact-box {
  padding-right: 100px;
  margin-bottom: 70px;
}
@media only screen and (max-width: 575px) {
  .contact-box {
    padding-right: 0;
    margin-bottom: 30px;
  }
}

.widget_working {
  padding: 30px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  margin-bottom: 0;
}
.widget_working .head {
  margin-bottom: 40px;
}
.widget_working .head .title {
  font-size: 24px;
}
.widget_working .head p {
  font-size: 15px;
  margin-bottom: 0;
  opacity: 0.7;
}
.widget_working ul li {
  color: #fff;
  display: flex;
  margin-bottom: 25px;
  padding-left: 40px;
  position: relative;
}
.widget_working ul li p {
  margin-bottom: 0;
}
.widget_working ul li i {
  position: absolute;
  font-size: 30px;
  left: 0;
  top: 5px;
  color: var(--secondary);
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.widget_working ul li:last-child {
  margin-bottom: 0;
}
@media only screen and (max-width: 991px) {
  .widget_working {
    margin-bottom: 30px !important;
  }
}
@media only screen and (max-width: 575px) {
  .widget_working {
    padding: 15px;
  }
}

.contact-area {
  padding: 50px 50px 20px;
  border-radius: 10px;
  background-color: #222222;
}
.contact-area .contact-head {
  margin-bottom: 30px;
}
@media only screen and (max-width: 1280px) {
  .contact-area {
    padding: 30px 30px 0;
  }
}
@media only screen and (max-width: 575px) {
  .contact-area {
    border-radius: 0px;
  }
}

.under-construction {
  position: relative;
  overflow: hidden;
  min-height: 750px;
  height: 100vh;
}
.under-construction > div {
  z-index: 2;
  position: relative;
}
.under-construction .particles-js-canvas-el {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
@media only screen and (max-width: 991px) {
  .under-construction {
    min-height: 600px;
    height: 100vh;
  }
}
@media only screen and (max-width: 575px) {
  .under-construction {
    min-height: 450px;
    height: 100vh;
  }
}
.under-construction .inner-construction .dz-head {
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 15px;
  line-height: 1.2;
}
.under-construction .inner-construction .const-p {
  font-size: 18px;
  color: #828282;
  margin-bottom: 25px;
}
.under-construction .inner-construction .space-right {
  max-width: 350px;
}
.under-construction .inner-construction .space-right .input-group .form-control,
.under-construction
  .inner-construction
  .space-right
  .input-group
  .wp-block-categories-dropdown
  select,
.wp-block-categories-dropdown
  .under-construction
  .inner-construction
  .space-right
  .input-group
  select,
.under-construction
  .inner-construction
  .space-right
  .input-group
  .wp-block-archives-dropdown
  select,
.wp-block-archives-dropdown
  .under-construction
  .inner-construction
  .space-right
  .input-group
  select {
  height: 50px;
  margin-bottom: 15px;
}
.under-construction .inner-construction .space-right .input-group button {
  border-radius: 6px !important;
}
@media only screen and (max-width: 1280px) {
  .under-construction .inner-construction .const-p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 991px) {
  .under-construction .inner-construction .dz-head {
    font-size: 34px;
  }
  .under-construction .inner-construction .space-right {
    max-width: 270px;
  }
  .under-construction .inner-construction .const-p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 767px) {
  .under-construction .inner-construction .space-right {
    max-width: 100%;
  }
}
.under-construction .construction-media {
  position: relative;
  z-index: 1;
}
.under-construction .construction-media img {
  position: absolute;
  left: 120px;
  top: 0;
  width: 100%;
  min-width: 35vw;
}
.under-construction .construction-media::after {
  position: absolute;
  content: "";
  top: -15vw;
  left: 10vw;
  z-index: -1;
  width: 50vw;
  height: 50vw;
  background: var(--secondary);
  opacity: 0.2;
  border-radius: 100%;
}
@media only screen and (max-width: 1480px) {
  .under-construction .construction-media img {
    left: 0;
    min-width: 830px;
  }
  .under-construction .construction-media::after {
    top: -285px;
    left: 70px;
  }
}
@media only screen and (max-width: 1280px) {
  .under-construction .construction-media img {
    min-width: 730px;
  }
  .under-construction .construction-media::after {
    top: -214px;
    width: 850px;
    left: 40px;
    height: 850px;
  }
}
@media only screen and (max-width: 991px) {
  .under-construction .construction-media img {
    min-width: 550px;
  }
  .under-construction .construction-media::after {
    top: -170px;
    width: 600px;
    left: 34px;
    height: 600px;
  }
}
@media only screen and (max-width: 767px) {
  .under-construction .construction-media {
    margin-top: 30px;
  }
  .under-construction .construction-media img {
    min-width: 100%;
    position: unset;
  }
  .under-construction .construction-media::after {
    top: 0;
    width: 450px;
    left: 0;
    height: 450px;
  }
}
@media only screen and (max-width: 575px) {
  .under-construction .construction-media::after {
    display: none;
  }
}


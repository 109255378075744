[data-color="color_1"] {
	--primary: #000000;
	--primary-hover: #000000;
	--primary-dark: #000000;
	--rgba-primary-1: rgba(0, 0, 0, 0.1);
	--rgba-primary-2: rgba(0, 0, 0, 0.2);
	--rgba-primary-3: rgba(0, 0, 0, 0.3);
	--rgba-primary-4: rgba(0, 0, 0, 0.4);
	--rgba-primary-5: rgba(0, 0, 0, 0.5);
	--rgba-primary-05: rgba(0, 0, 0, 0.05);
	--rgba-primary-6: rgba(0, 0, 0, 0.6);
	--rgba-primary-7: rgba(0, 0, 0, 0.7);
	--rgba-primary-8: rgba(0, 0, 0, 0.8);
	--rgba-primary-9: rgba(0, 0, 0, 0.9);
	--theme-text-color: #ffffff; /* White text for better contrast */
	--secondary: #cacf7a; /* Secondary color remains the same */
	--title: #000000; /* Title color changed to white for visibility */
	--bg-light: #000000; /* Background color changed to black */
  }

[data-color="color_2"]{
	
	// Primary
	--primary: #FE9F10;
	--primary-hover:#d38004;
	--primary-dark: #D27E00;
	
	// Primary RGBA
	--rgba-primary-1: rgba(254, 159, 16, 0.1);
	--rgba-primary-2: rgba(254, 159, 16, 0.2);
	--rgba-primary-3: rgba(254, 159, 16, 0.3);
	--rgba-primary-4: rgba(254, 159, 16, 0.4);
	--rgba-primary-5: rgba(254, 159, 16, 0.5);
	--rgba-primary-05: rgba(254, 159, 16, 0.05);
	--rgba-primary-6: rgba(254, 159, 16, 0.6);
	--rgba-primary-7: rgba(254, 159, 16, 0.7);
	--rgba-primary-8: rgba(254, 159, 16, 0.8);
	--rgba-primary-9: rgba(254, 159, 16, 0.9);
	
	// Other variables
	--theme-text-color: #fff;
	--secondary: #a8cf96;
	--title: #222222;
	--bg-light: #fff8e2;
}

[data-color="color_3"]{
	
	// Primary
	--primary: #EE2737;
	--primary-hover:#e10d1e;
	--primary-dark: #b91522;
	
	// Primary RGBA
	--rgba-primary-1: rgba(238, 39, 55, 0.1);
	--rgba-primary-2: rgba(238, 39, 55, 0.2);
	--rgba-primary-3: rgba(238, 39, 55, 0.3);
	--rgba-primary-4: rgba(238, 39, 55, 0.4);
	--rgba-primary-5: rgba(238, 39, 55, 0.5);
	--rgba-primary-05: rgba(238, 39, 55, 0.05);
	--rgba-primary-6: rgba(238, 39, 55, 0.6);
	--rgba-primary-7: rgba(238, 39, 55, 0.7);
	--rgba-primary-8: rgba(238, 39, 55, 0.8);
	--rgba-primary-9: rgba(238, 39, 55, 0.9);
	
	// Other variables
	--theme-text-color: #fff;
	--secondary: #cacf7a;
	--title: #222222;
	--bg-light: #ffeff0;
}

[data-color="color_4"]{
	
	// Primary
	--primary: #A75D5D;
	--primary-hover: #954c4c;
	--primary-dark: #5e2c2c;
	
	// Primary RGBA
	--rgba-primary-1: rgba(167,93,93,0.1);
	--rgba-primary-2: rgba(167,93,93,0.2);
	--rgba-primary-3: rgba(167,93,93,0.3);
	--rgba-primary-4: rgba(167,93,93,0.4);
	--rgba-primary-5: rgba(167,93,93,0.5);
	--rgba-primary-05: rgba(167,93,93,0.05);
	--rgba-primary-6: rgba(167,93,93,0.6);
	--rgba-primary-7: rgba(167,93,93,0.7);
	--rgba-primary-8: rgba(167,93,93,0.8);
	--rgba-primary-9: rgba(167,93,93,0.9);
	
	// Other
	--theme-text-color: #fff;
	--secondary: #FFC3A1;
	--title: #222222;
	--bg-light: #fff2f2;
}

[data-color="color_5"]{
	
	// Primary
	--primary: #EA5C2B;
	--primary-hover: #bf4115;
	--primary-dark: #ad2c00;
	
	// Primary RGBA
	--rgba-primary-1: rgba(234,92,43,0.1);
	--rgba-primary-2: rgba(234,92,43,0.2);
	--rgba-primary-3: rgba(234,92,43,0.3);
	--rgba-primary-4: rgba(234,92,43,0.4);
	--rgba-primary-5: rgba(234,92,43,0.5);
	--rgba-primary-05: rgba(234,92,43,0.05);
	--rgba-primary-6: rgba(234,92,43,0.6);
	--rgba-primary-7: rgba(234,92,43,0.7);
	--rgba-primary-8: rgba(234,92,43,0.8);
	--rgba-primary-9: rgba(234,92,43,0.9);
	
	// Other
	--theme-text-color: #fff;
	--secondary: #ebce5a;
	--title: #222222;
	--bg-light: #fff6f3;
	
}

[data-color="color_6"]{
	
	// Primary
	--primary: #A9907E;
	--primary-hover: #91735e;
	--primary-dark: #6a4a33;
	
	// Primary RGBA
	--rgba-primary-1: rgba(169,144,126,0.1);
	--rgba-primary-2: rgba(169,144,126,0.2);
	--rgba-primary-3: rgba(169,144,126,0.3);
	--rgba-primary-4: rgba(169,144,126,0.4);
	--rgba-primary-5: rgba(169,144,126,0.5);
	--rgba-primary-05: rgba(169,144,126,0.05);
	--rgba-primary-6: rgba(169,144,126,0.6);
	--rgba-primary-7: rgba(169,144,126,0.7);
	--rgba-primary-8: rgba(169,144,126,0.8);
	--rgba-primary-9: rgba(169,144,126,0.9);
	
	// Other
	--theme-text-color: #fff;
	--secondary: #ABC4AA;
	--title: #222222;
	--bg-light: #fff7f2;
	
}

[data-color="color_7"]{
	
	// Primary
	--primary: #87805E;
    --primary-hover: #585230;
    --primary-dark: #544d2b;
	
	// Primary RGBA
	--rgba-primary-1: rgba(135,128,94,0.1);
	--rgba-primary-2: rgba(135,128,94,0.2);
	--rgba-primary-3: rgba(135,128,94,0.3);
	--rgba-primary-4: rgba(135,128,94,0.4);
	--rgba-primary-5: rgba(135,128,94,0.5);
	--rgba-primary-05: rgba(135,128,94,0.05);
	--rgba-primary-6: rgba(135,128,94,0.6);
	--rgba-primary-7: rgba(135,128,94,0.7);
	--rgba-primary-8: rgba(135,128,94,0.8);
	--rgba-primary-9: rgba(135,128,94,0.9);

	// Other
	--theme-text-color: #fff;
    --secondary: #D8CCA3;
    --title: #222222;
    --bg-light: #f9f7ee;
	
}

.testimonial-2{
	background:#fff;
	position:relative;
	display:flex;
	
	.dz-media{
		border-radius: 8px;
		width: 570px;
		min-width: 570px;
		
		img{
			width:100%;
			height:100%;
			object-fit:cover;
		}
	}
	.testimonial-detail{
		padding: 20px 30px 30px 0;
		display:flex;
		flex-direction:column;
		width:100%;
		position: relative;
	}
	.dz-media + .testimonial-detail{
		padding: 20px 0 30px 30px;
	}
	.testimonial-text{
		p{
			font-weight: 500;
			font-size: 18px;
			line-height: 32px;
			color:var(--title);
		}
	}
	.testimonial-info{
		position: relative;
		z-index: 1;
		margin-top: auto;
		padding-left: 20px;
		
		&::after{
			background:var(--primary);
			border-radius: 4px;
			height: 5px;
			width: 50px;
			content:"";	
			position:absolute;
			z-index:-1;
			transform:rotate(90deg);
			top:25px;
			left:-22px;
		}
		.testimonial-name{
			font-size: 22px;
			line-height: 33px;
			font-weight:700;
			margin-bottom:0;
		}
		.testimonial-position{
			font-weight: 400;
			font-size: 14px;
			line-height: 21px;
			color:var(--primary);
		}
	}
	.quote{
		position:absolute;
		right: 26px;
		bottom: 15px;
		font-size: 102px;
		color:var(--primary);
	}
	@include respond('tab-land'){
		.dz-media{
			width:450px;
			min-width:450px;
			img{
				height:100%;
				object-fit:cover;
			}
		}
		.testimonial-text{
			margin: 0 0 15px;
			p{
				font-size: 16px;
				line-height: 30px;
			}
		}
	}
	@include respond('tab-port'){
		border-radius:0 0 10px 10px;
		flex-direction: column;
		
		.testimonial-detail{
			padding: 20px 0;
		}
		.dz-media + .testimonial-detail{
			padding: 20px 0;
		}
		.dz-media{
			width:100%;
			min-width:100%;
		}
		.testimonial-detail{
			padding: 15px 0;
		}
		.quote{
			font-size: 65px;
			bottom: 10px;
			right: 10px;
		}
		&.right{
			flex-direction: column-reverse;
		}
		.testimonial-info{
			.testimonial-name{
				font-size: 18px;
			}
		}
	}
}